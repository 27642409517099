<template>
	<div class="d-flex flex-column flex h100 page-body">
		<div class="navbar teal bg box-shadow">
			<div>
				<a href="#/" class="navbar-brand oscar-logo">
					<img src="static/OSCAR-logo-white-cropped.png" alt="." class />
				</a>
			</div>
		</div>
		<div id="content-body">
			<div class="banner"></div>
			<div class="py-2 text-center w-100">
				<div class="mx-auto col-6 w-auto-xs" style="min-width: 320px">
					<div class="px-6 mt-3">
						<div class="box page-shadow">
							<div class="box-body p-5">
								<img
									style="width: 90%; opacity: 0.7"
									class="logo ng-scope pb-3"
									src="static/OSCAR-logo-black-cropped.png"
								/>

								<!-- Original login -->
								<template v-if="loginMode == 'original'">
									<form @submit.prevent name="form">
										<div class="form-group">
											<input
												v-model.trim="email"
												type="text"
												class="form-control form-control-lg"
												placeholder="Email"
												required
											/>
										</div>
										<div class="form-group">
											<input
												v-model.trim="password"
												type="password"
												class="form-control form-control-lg"
												placeholder="Password"
												required
											/>
										</div>
										<div v-if="debugCode" class="form-group">
											<label>Debug Code</label>
											<input
												v-model="debugCode"
												type="text"
												class="form-control form-control-lg is-valid"
												readonly
											/>
										</div>

										<button
											v-if="!submitting"
											@click="login()"
											type="submit"
											class="btn btn-block btn-lg teal"
										>
											Sign in
										</button>
										<button v-if="submitting" type="submit" class="btn btn-block btn-lg teal">
											Signing in...
											<loading type="icon" />
										</button>
									</form>
									<div class="my-4">
										<a href="#/resetpw" class="text-primary _600">Forgot password?</a>
									</div>
									<div
										v-if="attemptsLeftShort <= 3 || attemptsLeftLong <= 5"
										class="text-danger _600"
									>
										<div class="d-inline-block card px-3 py-2">
											<div
												v-if="
													attemptsLeftShort <= 3 &&
													attemptsLeftShort > 1 &&
													attemptsLeftShort < attemptsLeftLong
												"
											>
												{{ $t("Login.attempts_left_short", { n: attemptsLeftShort }) }}
											</div>
											<div v-if="attemptsLeftShort == 1 && attemptsLeftShort < attemptsLeftLong">
												{{ $t("Login.attempt_left_short", { n: attemptsLeftShort }) }}
											</div>
											<div v-if="attemptsLeftLong <= 5 && attemptsLeftLong > 1">
												{{ $t("Login.attempts_left_long", { n: attemptsLeftLong }) }}
											</div>
											<div v-if="attemptsLeftLong == 1">
												{{ $t("Login.attempt_left_long", { n: attemptsLeftLong }) }}
											</div>
											<div v-if="attemptsLeftShort <= 0 && attemptsLeftLong > 0">
												<div>{{ $t("Login.limited_short") }}</div>
												<div>{{ $t("Login.try_again_short") }}</div>
											</div>
											<div v-if="attemptsLeftLong <= 0">
												<div>{{ $t("Login.limited_long") }}</div>
												<div>{{ $t("Login.try_again_long") }}</div>
											</div>
										</div>
									</div>
								</template>

								<!-- SAML Login -->
								<template v-if="loginMode == 'saml'">
									<div class="d-flex flex-column align-items-center">
										<a
											@click="samlAuth()"
											type="submit"
											class="btn btn-block mx-auto btn-lg teal form-group text-white btn-xl my-5"
											:class="{ disabled: submitting }"
										>
											<loading
												v-if="submitting"
												type="icon"
												class="mr-1"
												style="margin-bottom: -3px"
											/>
											Log In
										</a>
										<!-- <label class="md-check">
											<input v-model="skipLoginPage" type="checkbox" />
											<i class="theme-accent"></i> Don't show this page again
										</label> -->
									</div>
								</template>

								<div class="footer d-flex flex-row">
									<!-- <div class="mr-3">
										<a
											v-if="loginMode == 'original'"
											class="text-primary text-xxs _600"
											@click="loginMode = 'saml'"
										>
											<i class="fas fa-universal-access mr-1" />SAML Login
										</a>
										<a
											v-if="loginMode == 'saml'"
											class="text-primary text-xxs _600"
											@click="loginMode = 'original'"
										>
											<i class="fas fa-key mr-1" />Username/Password Login
										</a>
									</div> -->
									<div class="ml-auto">
										<a
											href="/static/docs/OSCAR.Terms.of.Use.pdf"
											class="text-primary text-xxs _600"
											target="_blank"
										>
											<i class="fas fa-shield-alt mr-1" />Terms of Use
										</a>
										<a
											href="/static/docs/OSCAR.Privacy.Policy.pdf"
											class="text-primary text-xxs _600 ml-2"
											target="_blank"
										>
											<i class="fas fa-lock-alt mr-1" />Privacy Policy
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-3 b-t mt-auto teal">
			{{ $t("Footer.copyright", { year: new Date().getFullYear(), version: version }) }}
		</div>
	</div>
</template>

<script>
import AuthService from "@/services/AuthService";
import notie from "@/services/NotieService";
import rawnotie from "notie";
import Store from "@/services/Store";
import Router from "@/router";
import Cookies from "js-cookie";
import pack from "../../../package.json";

export default {
	name: "Login",
	components: {},
	data() {
		console.log("PACKAGE", pack);
		return {
			email: "",
			password: "",
			version: pack.version,
			submitting: false,

			attemptsLeftShort: 5,
			attemptsLeftLong: 20,

			debugCode: null,
			showLoginTest: false,

			loginMode: Store.getDefault(this, "login.loginMode", "original"),
			proceed: null,
			hide: false,
		};
	},
	created() {
		AuthService.clearState();
		var url = location.href;
		var host = location.host;
		if (url.indexOf("https") == -1 && url.indexOf("localhost") == -1 && url.indexOf("192.168.") == -1) {
			location.href = "https://" + host;
		}

		if (this.$route.query) {
			if (this.$route.query.mode) {
				this.loginMode = this.$route.query.mode;
			}

			if (this.$route.query.proceed) {
				this.proceed = this.$route.query.proceed;
				this.submitting = true;

				if (this.proceed == "saml") {
					let token = Cookies.get("Oscar-Auth-Token");
					console.log("GOT TOKEN", token);
					this.hide = true;
					this.loginWithToken(token);
				} else if (this.proceed == "error") {
					let err = Cookies.get("Oscar-Auth-Error");
					if (err) {
						err = atob(err);
					}
					setTimeout(() => {
						notie.userError("Failed to log in", err);
						Router.push({ path: "/" });
					}, 100);
				}
			}
			if (this.$route.query.debug) {
				this.debugCode = this.$route.query.debug;
			}
		}
	},

	mounted() {},

	watch: {
		loginMode() {
			Store.set(this, "login.loginMode", this.loginMode);
		},
	},

	computed: {
		isLocalHost() {
			const LOCAL_DOMAINS = ["localhost", "127.0.0.1", ""];
			return LOCAL_DOMAINS.includes(window.location.hostname);
		},
		isKiosk() {
			return window.kiosk;
		},
	},

	methods: {
		login() {
			if (this.submitting) {
				console.log("already submitting");
				return;
			}

			// Seems like this would have never worked anyway, but now it's unnecessary because we don't
			// captcha-restrict logins
			// let captchaResponseToken = this.captchaResponseToken;
			// if (this.isKiosk) {
			// 	captchaResponseToken = "kiosk";
			// }

			this.submitting = true;
			AuthService.login(this.email, this.password)
				.then((resp) => {
					AuthService.getUser()
						.then((user) => {
							this.submitting = false;
							if (this.debugCode) {
								AuthService.followDebugCodeLink(this.debugCode, Router)
									.then((r) => {
										let successMsg = `
<h5>${this.$i18n.t("Navbar.logged_in_as")}</h5>
<div class="text-xs text-muted" style="line-height: 1.3">${this.$i18n.t("Navbar.logged_in_as_explanation")}</div>
<div class="card px-3 py-2 mt-2" style="background-color: transparent; border-color: rgba(255, 255, 255, 0.5)">
	<div>
		<span class="_600">${this.$i18n.t("Navbar.environment")}</span>:
		<span>${r.env}</span>
	</div>
	<div>
		<span class="_600">${this.$i18n.t("Navbar.user")}</span>:
		<span>${r.user_name}</span>
	</div>
	<div>
		<span class="_600">${this.$i18n.t("Navbar.tenant")}</span>:
		<span>${r.client_name}</span>
	</div>
	<div>
		<span class="_600">${this.$i18n.t("Navbar.page")}</span>:
		<span>${r.path}</span>
	</div>
</div>`;
										let notieCtx = {
											type: "success",
											text: successMsg,
											time: 15,
										};
										rawnotie.alert(notieCtx);
									})
									.catch((e) => {
										if (e.internalError) {
											notie.userError(e.internalError.emsg, e.internalError.e);
										} else {
											notie.userError("Failed to follow debug code link", e);
										}
										Router.push({ path: "/landing" });
									});
							} else {
								const { redirect, client_id } = this.$route.query;
								if (client_id && user.client.id !== client_id) {
									AuthService.changeClient(client_id).then(() => {
										AuthService.getUser(true).then(() =>
											// refresh with new client
											Router.push({ path: redirect || "/landing" })
										);
									});
								} else {
									Router.push({ path: redirect || "/landing" });
								}
							}
						})
						.catch((err) => {
							this.submitting = false;
							console.log(err);
							notie.userError("Failed to initialize user", err);
						});
				})
				.catch((err) => {
					this.submitting = false;
					console.log(err);
					if (err.response.data.data === "No active clients") {
						Router.push({ path: "/landing/no_clients" });
					}
					delete err.suppress;
					notie.userError("Failed to log in", err);

					if (err.response.data.data) {
						for (let stat of err.response.data.data) {
							if (stat.duration == 60000000000) {
								this.attemptsLeftShort = stat.limit - stat.count - 1;
							}
							if (stat.duration == 86400000000000) {
								this.attemptsLeftLong = stat.limit - stat.count - 1;
							}
						}
					}
				});
		},

		samlAuth() {
			this.submitting = true;
			window.location = `/identity/saml`;
		},

		async loginWithToken(token) {
			AuthService.authWithToken(token);

			try {
				await AuthService.getUser();
				this.submitting = false;

				Router.push({ path: "/landing" });
			} catch (err) {
				this.submitting = false;
				console.log(err);
				if (err && err.response && err.response.data && err.response.data.data === "No active clients") {
					Router.push({ path: "/landing/no_clients" });
				}
				delete err.suppress;
				notie.userError("Failed to log in", err);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
	position: absolute;
	left: 1rem;
	right: 1rem;
	bottom: 0.5rem;
}
</style>
