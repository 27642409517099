import axios from "axios";
import moment from "moment";

export default {
	getTeamsScoreVolume(teamID, rt) {
		const ctx = {
			params: {
				rt: rt.toISOString(true),
			},
		};
		if (teamID) {
			ctx.params.teamIDs = [teamID];
		}
		return axios.get(`/v1/dashboard/teams/scorevolume`, ctx);
	},

	getTeamAssignments(teamID) {
		let ctx = {};
		if (teamID) {
			ctx.params = {
				teamIDs: [teamID],
			};
		}
		return axios.get(`/v1/dashboard/teams/assignments`, ctx);
	},

	getProjectCompletionTotals(projectID, labels) {
		return axios.get(`/v1/dashboard/project/completion/${projectID}`, {
			params: { labels: labels },
		});
	},

	compileProjectSections(data) {
		let sections = [];

		_.each(_.uniqBy(data, "section_id"), (sRow) => {
			let section = {
				id: sRow.section_id,
				name: sRow.section_name,
				items: [],
			};

			let iRows = _.filter(data, {
				section_id: section.id,
			});

			_.each(iRows, (iRow) => {
				let item = {
					id: iRow.item_id,
					name: iRow.item_name,
					total: iRow.total,
					complete: iRow.complete,
					withheld: iRow.withheld,
					exported: iRow.exported,
				};
				section.items.push(item);
			});

			sections.push(section);
		});

		return sections;
	},

	getProjectCompletionSections(projectID, labels) {
		return new Promise((resolve, reject) => {
			axios
				.get(`/v1/dashboard/project/sections/${projectID}`, {
					params: { labels: labels },
				})
				.then((resp) => {
					var compiledData = this.compileProjectSections(resp.data);
					resolve({ data: compiledData });
				})
				.catch((err) => {
					reject(err);
				});
		});
	},

	getProjectScoreVolume(projectID, labels, rt) {
		return axios.get(`/v1/dashboard/project/scorevolume/${projectID}`, {
			params: {
				labels: labels,
				//for now, only ever get the current year/week
				rt: moment(rt).toISOString(true),
			},
		});
	},

	getProjectItemCapacity(projectID, labels) {
		//mockup
		let resp = {
			data: {
				items: [
					{
						name: "Science Blue Form",
						active: 4,
						assigned: 25,
					},
					{
						name: "Science Red Form",
						active: 6,
						assigned: 10,
					},
					{
						name: "Math Blue Form",
						active: 16,
						assigned: 50,
					},
					{
						name: "Math Red Form",
						active: 10,
						assigned: 25,
					},
					{
						name: "Math & Science Form",
						active: 20,
						assigned: 100,
					},
					{
						name: "ELA Green Form",
						active: 20,
						assigned: 100,
					},
					{
						name: "Extra Responses",
						active: 0,
						assigned: 5,
					},
					{
						name: "More Extra Responses",
						active: 1,
						assigned: 5,
					},
				],
			},
		};
		return new Promise(function (resolve, reject) {
			setTimeout(resolve, 200, resp);
		});

		//real
		return axios.get(`/v1/projectdash/capacity/${projectID}`, {
			params: { labels: labels },
		});
	},

	sectionExportCSV(sectionID) {
		return axios.get(`/v1/dashboard/project/section/${sectionID}/csv`);
	},

	tenantExportCSV() {
		return axios.get(`/v1/dashboard/project/all_sections/csv`);
	},

	sectionExportAPI(sectionID, useExported) {
		return axios.post(`/v1/dashboard/project/section/${sectionID}/export?use_exported=${useExported}`);
	},
};
