<template>
	<div class="box">
		<week-select @newTimeSpan="newWeekSpan" class="pt-2 pl-2" />
		<div class="p-2 m-3">
			<div class="d-flex flex-row">
				<div class="flex">
					<h5 class="_600">
						<span v-if="totalScores">{{ totalScores.toLocaleString() }}</span>
						<span v-if="!totalScores" class="text-very-muted">——</span>
					</h5>
					<h6 class="text-muted mb-0">{{ $t("fields.scores") }}</h6>
					<div class="text-muted mb-4">{{ $t("fields.overall_paren") }}</div>
				</div>
				<div class="flex">
					<h5 class="_600">
						<span class="float-right text-muted">
							<a @click="loadData" class="no-select btn-subtle" v-tippy :title="$t('tooltip.refresh')">
								<i class="fa fa-sync text-sm" :class="{ 'fa-spin': busy }"></i>
							</a>
						</span>
						<span v-if="totalScores">{{ totalScoresWeek.toLocaleString() }}</span>
						<span v-if="!totalScores" class="text-very-muted">——</span>
					</h5>
					<h6 class="text-muted mb-0">{{ $t("fields.scores") }}</h6>
					<div class="text-muted mb-4">{{ $t("fields.this_week_paren") }}</div>
				</div>
			</div>
			<div class="py-3">
				<canvas id="chart-line-2" v-chartjs="scoreChart" style="height: 200px"></canvas>
			</div>
		</div>
	</div>
</template>

<style scoped>
a {
	opacity: 0.35;
	transition: opacity 0.25s;
}
a:hover {
	opacity: 1;
}
table {
	width: 100%;
}
td {
	padding-bottom: 2rem;
}
</style>

<script>
import DashboardService from "@/services/DashboardService";
import fs from "@/services/FormatService";
require("@/directives/chartjs");
import moment from "moment";
import WeekSelect from "@/components/WeekSelect";

export default {
	name: "ProjectDashScoreVolume",

	components: {
		WeekSelect,
	},

	props: ["project", "labels"],

	data() {
		return {
			fs: fs,

			selectedWeek: moment(),

			busy: false,
			totalTime: 0,
			totalTimeWeek: 0,
			totalScores: 0,
			totalScoresWeek: 0,
			scoreChart: {
				type: "line",
				data: {
					labels: ["S", "M", "T", "W", "T", "F", "S"],
					datasets: [
						{
							label: this.$i18n.t("fields.scores"),
							data: [],
							fill: false,
							backgroundColor: "rgba(0, 188, 212, 0.)",
							borderColor: "rgba(0, 188, 212, 0.25)",
							pointBorderColor: "rgba(0, 188, 212, 0.4)",
							borderWidth: 4,
							borderCapStyle: "butt",
							borderDash: [],
							borderDashOffset: 0.0,
							borderJoinStyle: "miter",
							pointBackgroundColor: "#fff",
							pointBorderWidth: 3,
							pointHoverRadius: 4,
							pointHoverBorderColor: "rgba(0, 188, 212, 0.8)",
							pointHoverBorderWidth: 3,
							pointRadius: [],
							pointHitRadius: 10,
							spanGaps: false,
							yAxisID: "scores",
						},
						{
							label: this.$i18n.t("fields.rate"),
							data: [],
							fill: false,
							backgroundColor: "rgba(212, 148, 0, 0.1)",
							borderColor: "rgba(212, 148, 0, 0.25)",
							pointBorderColor: "rgba(212, 148, 0, 0.4)",
							borderWidth: 4,
							borderCapStyle: "butt",
							borderDash: [],
							borderDashOffset: 0.0,
							borderJoinStyle: "miter",
							pointBackgroundColor: "#fff",
							pointBorderWidth: 3,
							pointHoverRadius: 4,
							pointHoverBorderColor: "rgba(212, 148, 0, 0.8)",
							pointHoverBorderWidth: 3,
							pointRadius: [],
							pointHitRadius: 10,
							spanGaps: false,
							yAxisID: "time",
						},
					],
				},
				options: {
					tooltips: {
						mode: "point",
						displayColors: false,
						titleFontSize: 14,
						bodyFontSize: 14,
						callbacks: {
							title: (items, data) => {
								let i = items[0].index;
								let day = this.selectedWeek.day(i);
								return `${day.format("ddd l")}`;
							},
							label: (item, data) => {
								let i = item.datasetIndex;
								let j = item.index;
								let axis = data.datasets[i].yAxisID;
								let val = data.datasets[i].data[j];
								if (axis == "scores") {
									return `${this.$i18n.t("Dashboard.scores")}: ${val}`;
								} else if (axis == "time") {
									return `${this.$i18n.t("Dashboard.avg_time")}: ${fs.shortDuration(val)}`;
								}
							},
						},
					},
					maintainAspectRatio: false,
					scales: {
						xAxes: [
							{
								display: true,
							},
						],
						yAxes: [
							{
								id: "scores",
								display: false,
								ticks: {
									suggestedMin: 0,
								},
							},
							{
								id: "time",
								display: false,
								ticks: {
									suggestedMin: 0,
								},
							},
						],
					},
					legend: {
						display: false,
					},
					layout: {
						padding: {
							top: 10,
						},
					},
				},
			},
		};
	},
	created() {
		this.loadData();
	},
	watch: {
		"project.id"() {
			this.loadData();
		},
		labels() {
			this.loadData();
		},
	},
	methods: {
		newWeekSpan({ selectedTime }) {
			this.selectedWeek = selectedTime;
			this.loadData();
		},

		loadData() {
			if (!this.project) {
				return;
			}
			if (this.busy) {
				return;
			}

			this.busy = true;
			DashboardService.getProjectScoreVolume(this.project.id, this.labels, this.selectedWeek)
				.then((resp) => {
					this.loadChartData(resp.data.daily_stats, resp.data.start_time, resp.data.end_time);
					this.totalTime = resp.data.total_time;
					this.totalTimeWeek = resp.data.total_time_week;
					this.totalScores = resp.data.total_scores;
					this.totalScoresWeek = resp.data.total_scores_week;
					this.busy = false;
				})
				.catch((err) => {
					console.log(err);
					this.busy = false;
				});
		},

		loadChartData(stats, start, end) {
			for (let d = 0; d < 7; d++) {
				let day = moment(start).day(d);
				let isFuture = day.isAfter(moment(), "day");
				let score = (stats[d] && stats[d].count) || 0;
				let time = (stats[d] && stats[d].time) || 0;
				time = time / score;
				time = isNaN(time) ? 0 : time;
				if (isFuture) {
					score = NaN;
					time = NaN;
				}

				this.scoreChart.data.datasets[0].data[d] = score;
				this.scoreChart.data.datasets[0].pointRadius[d] = 4;

				this.scoreChart.data.datasets[1].data[d] = time;
				this.scoreChart.data.datasets[1].pointRadius[d] = 4;
			}
		},
	},
};
</script>
