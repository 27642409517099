<template>
	<div class="w-100">
		<mz-pagination
			size="md"
			:total-rows="tableItems.length"
			v-model="currentPage"
			:per-page="perPage"
		></mz-pagination>
		<b-table hover :per-page="perPage" :current-page="currentPage" :fields="tableFields" :items="tableItems">
			<template #cell(state)="{ value: { textColor, name } }">
				<span :class="textColor">{{ name }}</span>
			</template>

			<template #cell(completed_at)="{ value }">
				<span v-if="value">{{ value }}</span>
			</template>

			<!-- cell for each training element -->
			<template
				v-for="element in (selectedCourse && selectedCourse.elements) || []"
				#[`cell(${element.id})`]="{ value: { textColor, icon } }"
			>
				<i class="far" :key="element.id" :class="[textColor, icon]"></i>
			</template>

			<template #cell(action)="{ value: { user_id } }">
				<div class="dropdown">
					<button class="btn btn-sm theme-accent m-0" data-toggle="dropdown" aria-expanded="false">
						<i class="fas fa-server"></i>
					</button>
					<div class="dropdown-menu dropdown-menu-right">
						<a class="dropdown-item" @click="resetCourse(user_id)">
							<i class="far fa-sync faw"></i>
							<label class="mb-0">{{ $t("ReportTrainingCompletion.reset_course") }}</label>
						</a>
						<a class="dropdown-item" @click="manualPassCourse(user_id)">
							<i class="far fa-sort-amount-down faw"></i>
							<label class="mb-0">{{ $t("ReportTrainingCompletion.manual_pass") }}</label>
						</a>
					</div>
				</div>
			</template>
		</b-table>
	</div>
</template>

<script>
import moment from "moment";

import Notie from "@/services/NotieService";
import fs from "@/services/FormatService";
import TrainingService from "@/services/TrainingService";
import MzPagination from "@/components/MzPagination.vue";

import { TRAINING_PROGRESS_STATES, TRAINING_ELEMENT_TYPES } from "@/services/Constants";
const { COMPLETE, INCOMPLETE, IN_PROGRESS, FAILED } = TRAINING_PROGRESS_STATES;
const { QUALIFICATION, PRACTICE } = TRAINING_ELEMENT_TYPES;

export default {
	name: "TrainingCompletionTable",

	components: {
		MzPagination,
	},

	props: {
		selectedCourse: Object,
		userProgress: Array,
	},

	data() {
		return { currentPage: 1 };
	},

	computed: {
		tableFields() {
			let fields = [
				{ key: "scorer_id", label: "Scorer", sortable: true, sortDirection: "desc" },
				{
					key: "state",
					label: "State",
					sortable: true,
					sortByFormatted: ({ id }) => (id === INCOMPLETE.id ? null : id),
					sortDirection: "desc",
				},
				{
					key: "completed_at",
					label: "Completed At",
					sortable: true,
					sortDirection: "desc",
				},
				{
					key: "training_time",
					label: "Time Spent",
					sortable: true,
					sortDirection: "desc",
				},
			];
			if (this.selectedCourse) {
				fields = fields.concat(this.selectedCourse.elements.map(({ name, id }) => ({ key: id, label: name })));
			}
			return fields.concat([{ key: "action", label: "Actions" }]);
		},

		tableItems() {
			return this.userProgress.map(({ user_id, scorer_id, training_progress, seconds_training }) => {
				const { overall_progress, progress_elements, completed_at } = training_progress;

				const items = {
					scorer_id,
					state: this.stateStyle(overall_progress),
					action: { user_id },
					completed_at: overall_progress === COMPLETE.id && moment(completed_at).format("MM/DD/YY h:mm:ss a"),
					training_time: fs.medDuration(seconds_training),
				};

				(progress_elements || []).forEach(({ element_id, progress }) => {
					items[element_id] = this.stateStyle(progress);
				});

				return items;
			});
		},

		perPage() {
			return 10;
		},
	},

	methods: {
		async resetCourse(user_id) {
			try {
				await TrainingService.resetCourse(this.selectedCourse.id, user_id);
				Notie.info(this.$i18n.t("ReportTrainingCompletion.reset_success"));
				this.setLocalElementProgress(user_id, INCOMPLETE.id);
			} catch (err) {
				Notie.error(this.$i18n.t("ReportTrainingCompletion.reset_fail"), err);
				console.log("Failed to Reset Course", err);
			}
		},

		async manualPassCourse(user_id) {
			let canPass = true;
			const hasSetInCourse = this.selectedCourse.elements.some(({ type }) =>
				[QUALIFICATION.id, PRACTICE.id].includes(type)
			);
			if (hasSetInCourse) {
				canPass = await this.$bvModal.msgBoxConfirm(this.$i18n.t("ReportTrainingCompletion.pass_modal_text"), {
					title: this.$i18n.t("ReportTrainingCompletion.manual_pass"),
					centered: true,
					noCloseOnBackdrop: true,
				});
			}

			if (canPass) {
				try {
					await TrainingService.manualPassCourse(this.selectedCourse.id, user_id);
					Notie.info(this.$i18n.t("ReportTrainingCompletion.manual_pass_success"));
					this.setLocalElementProgress(user_id, COMPLETE.id);
				} catch (err) {
					Notie.error(this.$i18n.t("ReportTrainingCompletion.manual_pass_fail"), err);
					console.log("Failed to Pass Course", err);
				}
			}
		},

		setLocalElementProgress(userID, progress) {
			const { training_progress } = this.userProgress.find(({ user_id }) => user_id === userID);
			training_progress.overall_progress = progress;
			if (!training_progress.progress_elements) {
				training_progress.progress_elements = this.selectedCourse.elements.map(({ id }) => ({
					element_id: id,
					progress,
				}));
			} else {
				training_progress.progress_elements.forEach((element) => {
					element.progress = progress;
				});
			}
		},

		stateStyle(state) {
			switch (state) {
				case COMPLETE.id:
					return {
						id: COMPLETE.id,
						name: COMPLETE.name,
						textColor: "text-success",
						icon: "fa-check",
					};
				case IN_PROGRESS.id:
					return {
						id: IN_PROGRESS.id,
						name: IN_PROGRESS.name,
						textColor: "text-warning",
						icon: "fa-exclamation-square",
					};
				case INCOMPLETE.id:
					return {
						id: INCOMPLETE.id,
						name: INCOMPLETE.name,
						textColor: "text-secondary",
						icon: "fa-dash",
					};
				case FAILED.id:
					return {
						id: FAILED.id,
						name: FAILED.name,
						textColor: "text-danger",
						icon: "fa-ban",
					};
			}
		},
	},
};
</script>
