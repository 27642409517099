var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"img",staticClass:"image-box"},[(!_vm.forPrinting)?_c('canvas',{staticStyle:{"margin-bottom":"-8px"},attrs:{"id":_vm.page.id}}):_c('img',{ref:"altImage",staticStyle:{"max-width":"100%"},attrs:{"src":_vm.page.url}}),_vm._l((_vm.annotationRegions),function(ar){return [_c('div',{key:`box-${ar.timestamp}`,staticClass:"annotation-box",class:[`_highlight-box-${ar.annotation.color}`, { hovered: ar.hovered }],style:({
				left: `${ar.boxLeft * _vm.totalScale}px`,
				top: `${ar.boxTop * _vm.totalScale}px`,
				width: `${ar.boxWidth * _vm.totalScale}px`,
				height: `${ar.boxHeight * _vm.totalScale}px`,
			}),attrs:{"id":`box-${ar.timestamp}`},on:{"mouseenter":function($event){return _vm.applyHoverToGroup(ar.timestamp)},"mouseleave":function($event){return _vm.applyUnhoverToGroup(ar.timestamp)}}}),_c('div',{key:`controls-${ar.timestamp}`,staticClass:"annotation-margin",style:({
				left: `${(ar.boxLeft + ar.boxWidth) * _vm.totalScale + 8}px`,
				top: `${ar.boxTop * _vm.totalScale}px`,
			}),attrs:{"id":`overlay-${ar.timestamp}`},on:{"mouseenter":function($event){return _vm.applyHoverToGroup(ar.timestamp)},"mouseleave":function($event){return _vm.applyUnhoverToGroup(ar.timestamp)}}},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
					placement: 'top-end',
					theme: 'popover',
					interactive: true,
					reactive: true,
					hideOnClick: false,
					trigger: 'hover',
					html: `#overlay-popup-${ar.timestamp}`,
				}),expression:"{\n\t\t\t\t\tplacement: 'top-end',\n\t\t\t\t\ttheme: 'popover',\n\t\t\t\t\tinteractive: true,\n\t\t\t\t\treactive: true,\n\t\t\t\t\thideOnClick: false,\n\t\t\t\t\ttrigger: 'hover',\n\t\t\t\t\thtml: `#overlay-popup-${ar.timestamp}`,\n\t\t\t\t}"}],staticClass:"annotation-side-border",class:{ [`highlight-${ar.annotation.color}`]: true },style:({ height: `${ar.boxHeight * _vm.totalScale}px` }),attrs:{"id":`overlay-border-${ar.timestamp}`}}),_c('div',{staticClass:"overlay-popup",attrs:{"id":`overlay-popup-${ar.timestamp}`}},[(!ar.editing)?_c('span',{domProps:{"innerHTML":_vm._s(ar.annotation.text)}}):_vm._e(),(ar.editing)?_c('div',{staticClass:"d-flex flex-row"},[_c('RichText',{staticClass:"flex mb-0",attrs:{"initial":_vm.annotationInput,"text":_vm.annotationOutput,"options":{ height: 104 }},on:{"update:text":function($event){_vm.annotationOutput=$event}}}),_c('div',{staticClass:"pl-2 ml-2 border-left d-flex flex-column"},[_c('div',{staticClass:"flex d-flex flex-row"},[_c('div',{staticClass:"flex d-flex flex-column"},[_c('button',{staticClass:"btn-xs btn-icon btn-rounded mb-1 highlight-blue",class:{
										selected: ar.annotation.color == 'blue',
									},on:{"click":function($event){return _vm.changeHighlightColor(ar, 'blue')}}}),_c('button',{staticClass:"btn-xs btn-icon btn-rounded mb-1 highlight-red",class:{
										selected: ar.annotation.color == 'red',
									},on:{"click":function($event){return _vm.changeHighlightColor(ar, 'red')}}}),_c('button',{staticClass:"btn-xs btn-icon btn-rounded mb-1 highlight-yellow",class:{
										selected: ar.annotation.color == 'yellow',
									},on:{"click":function($event){return _vm.changeHighlightColor(ar, 'yellow')}}})]),_c('div',{staticClass:"flex d-flex flex-column ml-1"},[_c('button',{staticClass:"btn-xs btn-icon btn-rounded mb-1 highlight-green",class:{
										selected: ar.annotation.color == 'green',
									},on:{"click":function($event){return _vm.changeHighlightColor(ar, 'green')}}}),_c('button',{staticClass:"btn-xs btn-icon btn-rounded mb-1 highlight-orange",class:{
										selected: ar.annotation.color == 'orange',
									},on:{"click":function($event){return _vm.changeHighlightColor(ar, 'orange')}}}),_c('button',{staticClass:"btn-xs btn-icon btn-rounded mb-1 highlight-purple",class:{
										selected: ar.annotation.color == 'purple',
									},on:{"click":function($event){return _vm.changeHighlightColor(ar, 'purple')}}})])]),_c('div',{staticClass:"btn btn-sm",on:{"click":function($event){return _vm.saveAnnotationRegion(ar)}}},[_vm._v("Save")])])],1):_vm._e()]),(ar.editable)?_c('div',{staticClass:"position-absolute",staticStyle:{"top":"0","left":"-8px","width":"10px"},style:({ height: `${ar.boxHeight * _vm.totalScale}px` })},[_c('a',{staticClass:"btn-subtle overlay-btn position-absolute",staticStyle:{"left":"8px","font-size":"18px"},style:({ transform: `rotate(-${_vm.rotate}deg)` }),on:{"click":function($event){return _vm.toggleEditAnnotation(ar)}}},[_c('i',{staticClass:"fa-comment",class:{ [ar.editing ? 'fas' : 'far']: true }})]),_c('a',{staticClass:"btn-subtle overlay-btn position-absolute",staticStyle:{"font-size":"18px"},style:({
						left: ar.boxHeight >= 56 ? '8px' : '36px',
						top: ar.boxHeight >= 56 ? '28px' : '0',
						transform: `rotate(-${_vm.rotate}deg)`,
					}),on:{"click":function($event){return _vm.removeAnnotationRegion(ar.timestamp)}}},[_c('i',{staticClass:"far fa-trash"})])]):_vm._e(),(!ar.editable)?_c('div',{staticClass:"position-absolute",staticStyle:{"top":"0","left":"-8px","width":"10px"},style:({ height: `${ar.boxHeight * _vm.totalScale}px` })},[_vm._m(0,true)]):_vm._e()])]})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"overlay-btn position-absolute",staticStyle:{"font-size":"18px","left":"10px"}},[_c('i',{staticClass:"far fa-comment-lines"})])
}]

export { render, staticRenderFns }