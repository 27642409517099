<template>
	<div class="rubric">
		<span>{{ localTrait.name }}</span>
		<div v-if="localTrait" class="trait-row">
			<button
				@click="select(sp)"
				:class="{ active: isSelected(sp) }"
				v-for="sp in localTrait.score_points"
				:key="sp.sequence"
				class="score-point btn waves-effect waves-light"
			>
				{{ sp.val }}
			</button>
		</div>
	</div>
</template>

<style scoped>
</style>

<script>
import _ from "lodash";
import "@/assets/css/rubric.css";

export default {
	name: "ConfigTrait",
	props: [
		"trait", //The trait to render
		"rubric", //The rubric containing the trait
		"single", //If true, only a single SP can be selected at once
		"masked", //Render masked SPs
		"autoselect", //Select the first SP by default
		"selected", //Array of SP values that are selected
	],
	data() {
		return {
			localRubric: this.rubric,
			localTrait: this.trait,
			localSelected: _.cloneDeep(this.selected), //Should clone arrays
		};
	},

	created() {
		this.setupScorePoints(this.localTrait, this.localRubric);
		if (this.autoselect) {
			this.select(this.localTrait.score_points[0]);
		}
	},
	watch: {
		localSelected() {
			this.$emit("update:selected", this.localSelected);
		},
	},

	methods: {
		//Determine css class
		isSelected(sp) {
			return _.find(this.localSelected, { mask_value: sp.val });
		},

		//When a sp is clicked
		select(sp) {
			var mask = { mask_value: sp.val };
			if (this.single) {
				this.localSelected = [];
			}
			if (_.find(this.localSelected, { mask_value: sp.val })) {
				this.localSelected = _.without(this.localSelected, _.find(this.localSelected, { mask_value: sp.val }));
			} else {
				this.localSelected.push(mask);
			}
		},

		//Setup
		setupScorePoints(trait, rubric) {
			if (!trait || !rubric || trait.is_parent) {
				return;
			}

			var step = trait.step;
			var sps = [];
			var min = trait.min;
			var max = trait.max;

			if (rubric.reverse_score_points) {
				for (var i = max; i >= min; i -= step) {
					sps.push({
						sequence: i,
						val: i,
						selected: false,
					});
				}
			} else {
				for (var i = min; i <= max; i += step) {
					sps.push({
						sequence: i,
						val: i,
						selected: false,
					});
				}
			}

			trait.score_points = sps;
			this.$forceUpdate();
		},
	},
};
</script>
