<template>
	<div>
		<div class="mb-2">{{ $t("TrainingEdit.Details.package") }}</div>
		<div v-if="element.scorm && element.scorm.s3_dir">
			<div class="d-flex flex-row align-items-center">
				<input type="text" class="form-control" :value="scormName" readonly />
				<div class="ml-2">
					<button class="btn btn-primary" @click="goToScormPlayer(element)">
						{{ $t("TrainingEdit.Details.preview") }}
					</button>
				</div>
				<div class="ml-2">
					<button class="btn btn-danger btn-sm btn-icon btn-rounded" @click="deselectFile">
						<i class="fas fa-times" />
					</button>
				</div>
			</div>
		</div>
		<div v-else-if="!element.upload_scorm_file" class="col-12 mb-2">
			<input ref="fileUpload" class="d-none" type="file" accept=".zip" @change="selectScormFile($event)" />
			<b-row>
				<b-col cols="3">
					<div class="pb-2">Upload File</div>
					<button class="btn btn-success" @click="openFileSelect">
						{{ $t("TrainingEdit.Details.select_file") }}
					</button>
				</b-col>
				<b-col>
					<b-row>
						<b-col>
							<label>Source Bucket</label>
							<config-select :options="locationOptions" v-model="selectedLocation" />
						</b-col>
						<b-col>
							<label>Scorm Package</label>
							<config-select :options="poolOptions" v-model="selectedPackage" />
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</div>
		<div v-else>
			<div class="d-flex flex-row align-items-center">
				<input type="text" class="form-control" v-model="element.upload_scorm_file.name" readonly />
				<div class="ml-2">
					<button class="btn btn-danger btn-sm btn-icon btn-rounded" @click="deselectFile">
						<i class="fas fa-times" />
					</button>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import ConfigSelect from "@/components/ConfigSelect";

const NO_SELECTED_COURSE = { name: " ", id: -1 };

export default {
	name: "DetailsScorm",

	components: {
		ConfigSelect,
	},

	props: {
		element: Object,
		course: Object,
		scormLocations: Array,
	},

	data() {
		return {
			selectedLocation: { name: " ", id: -1, packages: [] },
			selectedPackage: NO_SELECTED_COURSE,
		};
	},

	watch: {
		selectedPackage() {
			const { element, selectedLocation, selectedPackage } = this;
			element.fromPool = {
				selectedLocation,
				selectedPackage,
			};
		},

		locationOptions() {
			this.selectedLocation = this.locationOptions.find(({ name }) => name === "local");
		},
	},

	computed: {
		scormName() {
			return this.element.scorm.s3_dir.split("/").at(-2);
		},

		locationOptions() {
			return this.scormLocations.map(({ id, bucket, packages }) => ({
				id,
				packages,
				name: bucket,
			}));
		},

		poolOptions() {
			return this.selectedLocation.packages.map((pack) => ({
				name: pack.ref_id,
				id: pack.ref_id,
				...pack,
			}));
		},
	},

	methods: {
		async loadScormLocations() {
			try {
				const { data: locations } = await TrainingService.listScormLocations();
				const reqs = await Promise.all(
					locations
						.map(({ bucket, id }) => (bucket === "local" ? "local" : id))
						.map(TrainingService.getScormLocationWithPackages)
				);
				this.scormLocations = reqs.map(({ data }) => data);
			} catch (err) {
				console.log(err);
				Notie.error("Failed to get scorm pool", err);
			}
		},

		openFileSelect() {
			this.$refs.fileUpload.click();
		},

		goToScormPlayer() {
			const { element, course } = this;
			const { scorm } = element;

			if (!scorm) {
				console.error("Can't go to SCORM player, element did not contain scorm field");
				return;
			}

			const { import_record_id } = scorm;
			if (import_record_id) {
				this.$router.push({
					path: `/my_training/scorm_preview/${import_record_id}`,
					query: { courseID: course.id },
				});
				return;
			}

			this.$router.push(`/my_training/scorm/${course.id}/${element.id}?preview=true`);
		},

		//TODO: rework this to be reactive, no forceUpdate
		selectScormFile(event) {
			const file = event.target.files[0];
			if (file) {
				this.element.fromPool = null;
				this.element.upload_scorm_file = file;
				this.$forceUpdate();
			}
		},

		deselectFile() {
			this.element.upload_scorm_file = null;
			this.element.scorm = { id: null, s3_dir: null };
			this.selectedPackage = NO_SELECTED_COURSE;
			this.$forceUpdate();
		},
	},
};
</script>