<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg flex-nowrap box-shadow">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("Auditing.title") }}
					<span class="far fa-fw fa-angle-right"></span>
					{{ $t("Auditing.responses") }}
				</div>
				<div
					v-if="user.role.delete_data"
					class="btn-topnav b-l ml-0 d-flex flex-column justify-content-center"
					@click="attemptScoreImport()"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.import_scores')"
				>
					<i class="fas fa-lg fa-box-check"></i>
				</div>
				<div
					v-if="user.role.delete_data"
					class="btn-topnav b-l ml-0 d-flex flex-column justify-content-center"
					@click="attemptImport()"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.import_responses')"
				>
					<i class="fas fa-lg fa-upload"></i>
				</div>
				<div
					v-if="user.role.export_scores"
					class="btn-topnav b-l ml-0 d-flex flex-column justify-content-center"
					@click="attemptExport()"
					v-tippy="{ placement: 'bottom' }"
					:title="$t('tooltip.export_scores')"
				>
					<i class="fas fa-lg fa-download"></i>
				</div>
				<!-- Multiselect actions -->
				<div class="topnav-margins b-r" style="width: 1px; height: 56px" v-if="numSelected > 0"></div>
				<div class="btn-group pl-3 topnav-margins" v-if="numSelected > 0">
					<div class="navbar-text nav-title no-grow mr-2" id="pageTitle">
						{{ $t("Auditing.num_selected", { num: numSelected }) }}
					</div>
					<button class="btn white" data-toggle="dropdown" aria-expanded="false">
						<i class="fas fa-ellipsis-v faw"></i>
					</button>
					<div class="dropdown-menu dropdown-menu-right">
						<a
							v-if="user.role.delete_data"
							class="dropdown-item"
							data-toggle="modal"
							data-target="#deleteResponses"
						>
							<i class="far fa-trash faw"></i>
							<label class="mb-0">{{ $t("Auditing.delete") }}</label>
						</a>
						<a
							v-if="user.role.delete_data"
							class="dropdown-item"
							data-toggle="modal"
							data-target="#resetResponses"
						>
							<i class="far fa-history faw"></i>
							<label class="mb-0">{{ $t("Auditing.reset_scores") }}</label>
						</a>
						<a
							v-if="user.role.unprotect_responses"
							class="dropdown-item"
							data-toggle="modal"
							data-target="#unprotectResponses"
						>
							<i class="far fa-unlock-alt faw"></i>
							<label class="mb-0">{{ $t("Auditing.unprotect") }}</label>
						</a>
						<a
							v-if="user.role.delete_data"
							class="dropdown-item"
							data-toggle="modal"
							data-target="#invalidateResponses"
						>
							<i class="far fa-times-square faw"></i>
							<label class="mb-0">{{ $t("Auditing.invalidate") }}...</label>
						</a>
						<a
							v-if="assignedDeliveryItem && user.role.assign_scorers"
							class="dropdown-item"
							data-toggle="modal"
							data-target="#assignScorer"
							@click="loadSelectedSchoolCodes"
						>
							<i class="far fa-bullseye-pointer faw"></i>
							<label class="mb-0">{{ $t("Auditing.assign_scorer_") }}</label>
						</a>
						<a
							v-if="numSelected === 1 && user.role.delete_data"
							class="dropdown-item"
							data-toggle="modal"
							@click="refIDModalOpen = true"
						>
							<i class="far fa-font faw"></i>
							<label class="mb-0">{{ $t("Auditing.change_ref_id") }}</label>
						</a>
						<a class="dropdown-item" data-toggle="modal" data-target="#setPriority">
							<i class="far fa-sort-amount-down faw"></i>
							<label class="mb-0">{{ $t("Auditing.set_priority") }}</label>
						</a>
						<a class="dropdown-item" data-toggle="modal" data-target="#addLabels">
							<i class="far fa-tags faw"></i>
							<label class="mb-0">{{ $t("Auditing.add_labels_dots") }}</label>
						</a>
						<a class="dropdown-item" data-toggle="modal" data-target="#removeLabels">
							<i class="far fa-tags faw"></i>
							<label class="mb-0">{{ $t("Auditing.remove_labels_dots") }}</label>
						</a>
						<a class="dropdown-item" data-toggle="modal" data-target="#moveResponses">
							<i class="far fa-random faw"></i>
							<label class="mb-0">{{ $t("Auditing.move_item") }}</label>
						</a>
						<a class="dropdown-item" data-toggle="modal" data-target="#releaseWithheldResponses">
							<i class="far fa-random faw"></i>
							<label class="mb-0">{{ $t("Auditing.release_withheld_responses") }}</label>
						</a>
						<a
							v-if="reliabilityItem"
							class="dropdown-item"
							data-toggle="modal"
							data-target="#confirmReliability"
						>
							<i class="far fa-check-double faw"></i>
							<label class="mb-0">{{ $t("Auditing.mark_reliability") }}</label>
						</a>
						<a v-if="user.role.page_qc_setup" class="dropdown-item" @click="qcModalOpen = true">
							<i class="far fa-balance-scale faw"></i>
							<label class="mb-0">{{ $t("Auditing.escalate_qc") }}</label>
						</a>
						<a v-if="user.role.page_rf_setup" class="dropdown-item" @click="rfEscalating = true">
							<i class="far fa-drafting-compass faw"></i>
							<label class="mb-0">{{ $t("Auditing.escalate_rangefinding") }}</label>
						</a>
						<a
							v-if="apiItem && user.role.queue_for_export"
							class="dropdown-item"
							@click="queueExportModalOpen = true"
						>
							<i class="far fa-pallet-alt faw"></i>
							<label class="mb-0">{{ $t("Auditing.queue_export") }}</label>
						</a>
					</div>
				</div>
			</div>
		</template>

		<!-- Page Content -->
		<div
			class="d-flex flex flex-row h100 mb-auto align-items-stretch"
			style="background-color: white; overflow: auto"
		>
			<div class="aside d-flex scroll-y">
				<div class="modal-dialog w-md light b-r b-t white p-2 audit-search d-flex flex-column">
					<div class="form-group">
						<label>{{ $t("Auditing.project") }}</label>
						<config-select :options="projects" v-model="selectedProject"></config-select>
					</div>
					<div class="form-group">
						<label>{{ $t("Auditing.section") }}</label>
						<config-select :options="sections" v-model="selectedSection"></config-select>
					</div>
					<div class="form-group">
						<label>{{ $t("Auditing.item") }}</label>
						<config-select :options="items" v-model="selectedItem"></config-select>
					</div>
					<div v-if="selectedItem && selectedItem.api_export_config.enabled" class="form-group">
						<label>{{ $t("Auditing.exported") }}</label>
						<v-select :options="exportStates" :searchable="false" v-model="selectedExportState"> </v-select>
					</div>
					<div v-if="user && user.client && user.client.protect_on_export" class="form-group">
						<label>{{ $t("Auditing.protected") }}</label>
						<v-select
							:options="protectedStates"
							:searchable="false"
							v-model="selectedProtectedState"
						></v-select>
					</div>
					<div class="form-group">
						<label>{{ $t("Auditing.state") }}</label>
						<v-select :options="states" :searchable="false" v-model="selectedState"></v-select>
					</div>
					<div class="form-group">
						<label>{{ $t("Auditing.has_flag") }}</label>
						<config-select :options="alerts" :searchable="false" v-model="selectedFlagType">
						</config-select>
					</div>
					<div class="form-group">
						<label>{{ $t("Auditing.flags_reviewed") }}</label>
						<config-select
							:options="flagReviewTypes"
							:searchable="false"
							v-model="selectedFlagReviewType"
						></config-select>
					</div>
					<div class="form-group">
						<label>{{ $t("Auditing.response_id") }}</label>
						<input v-model="response_id" type="text" class="form-control" />
					</div>
					<div class="form-group">
						<label>{{ $t("Auditing.scored_by") }}</label>
						<config-user-search
							:clientID="user.client && user.client.id"
							v-model="users"
							includeTeams
							multiple
						/>
					</div>
					<div class="form-group">
						<label>{{ $t("Auditing.has_score_type") }}</label>
						<v-select multiple :options="score_types" v-model="filter_types"></v-select>
					</div>
					<div class="form-group">
						<label>{{ $t("Auditing.labels") }}</label>
						<config-label-search
							v-model="labels"
							:selectedIDs="[]"
							:selectAll="true"
							:query="getQueryAll()"
						/>
					</div>
					<div class="form-group">
						<label>{{ $t("Auditing.irr_12") }}</label>
						<v-select :options="agreementTypes" v-model="agreement" :reduce="(o) => o.id"></v-select>
					</div>
					<div v-if="user && user.client && user.client.iea_integration" class="form-group">
						<label>{{ "IEA Status" }}</label>
						<v-select
							:options="ieaStatuses"
							:searchable="false"
							v-model="selectedIeaStatus"
							label="name"
							value="id"
						/>
					</div>
					<template v-if="selectedItem && selectedItem.assigned_delivery">
						<div class="form-group">
							<label>{{ $t("Auditing.assigned_first_score") }}</label>
							<config-user-search
								:clientID="user.client && user.client.id"
								v-model="assignedFirstScore"
								multiple
							/>
						</div>
						<div class="form-group">
							<label>{{ $t("Auditing.assigned_second_score") }}</label>
							<config-user-search
								:clientID="user.client && user.client.id"
								v-model="assignedSecondScore"
								multiple
							/>
						</div>
						<div class="form-group">
							<label>{{ $t("Auditing.assigned_res_score") }}</label>
							<config-user-search
								:clientID="user.client && user.client.id"
								v-model="assignedResScore"
								multiple
							/>
						</div>
					</template>
					<div v-if="user.role.admin_metadata">
						<div v-for="mk in queryMetadataKeys" :key="mk.id" class="form-group">
							<label>
								<i
									class="far fa-list-ul fa-fw text-muted mr-1"
									v-tippy
									:title="$t('Auditing.metadata')"
								></i
								>{{ mk.display_name }}
							</label>
							<input v-model="meta[mk.key]" type="text" class="form-control" />
						</div>
					</div>
					<div class="flex">
						<score-query
							v-if="selectedItem"
							:rubric="selectedItem.rubric"
							:initTraitScores="audit_trait_scores"
							@newScoreFilters="newScoreFilters"
						/>
					</div>

					<div
						class="btn-group align-self-end mb-2"
						role="group"
						style="height: 35px"
						aria-label="First group"
					>
						<button
							type="button"
							class="btn mb-2"
							:class="{ theme: view == 'single' }"
							style="height: 35px"
							@click="view = 'single'"
							v-tippy
							:title="$t('tooltip.preview')"
						>
							<i class="fas fa-window-maximize" />
						</button>
						<button
							type="button"
							class="btn mb-2"
							:class="{ theme: view == 'list' }"
							style="height: 35px"
							@click="view = 'list'"
							v-tippy
							:title="$t('tooltip.list')"
						>
							<i class="fas fa-th-list" />
						</button>
					</div>
				</div>
			</div>
			<div class="flex d-flex flex-column" style="overflow: hidden">
				<div class="flex scroll-y scroll-x">
					<!-- Page content goes here -->
					<b-table
						ref="table"
						striped
						hover
						:show-empty="true"
						:items="provider"
						:fields="fields"
						:current-page="currentPage"
						:per-page="perPage"
						:filter="filter"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
					>
						<!-- Select -->
						<template #head(select)>
							<table-multiselect
								v-model="selectedIDs"
								:selectAll.sync="selectAll"
								master
								:items="currentItems"
								byField="id"
								:totalRows="totalRows"
								:numSelected.sync="numSelected"
								:query="query"
								:reset="resetSelection"
								:sampleCallback="randomSample"
							>
							</table-multiselect>
						</template>
						<template #cell(select)="data">
							<table-multiselect
								:value="selectedIDs"
								:selectAll="selectAll"
								:id="data.item.id"
							></table-multiselect>
						</template>

						<!-- Ref ID -->
						<template #cell(ref_id)="data">
							<div
								v-if="data.item.reliability_score"
								class="dot"
								v-tippy
								:title="$t('Auditing.marked_reliability')"
							></div>
							{{ data.item.ref_id }}
							<template v-if="data.item.school_code">
								<i
									class="far fa-barcode-read fa-fw text-muted ml-1"
									:id="`school-code-${data.item.id}`"
									v-b-popover
								></i>
								<b-popover :target="`school-code-${data.item.id}`" triggers="hover">
									<template slot="title">{{ $t("Auditing.school_code") }}:</template>
									<div>{{ data.item.school_code }}</div>
								</b-popover>
							</template>
							<i
								v-if="data.item.labels && data.item.labels.length > 0"
								class="far fa-tags fa-fw text-muted ml-1"
								:id="`labels-${data.item.id}`"
								v-b-popover
							></i>
							<b-popover
								v-if="data.item.labels && data.item.labels.length > 0"
								:target="`labels-${data.item.id}`"
								triggers="hover"
							>
								<template slot="title">{{ $t("Auditing.labels") }}:</template>
								<li v-for="(label, i) in data.item.labels" :key="i">{{ label }}</li>
							</b-popover>
							<template v-if="queryMetadataKeys && queryMetadataKeys.length > 0">
								<i
									class="far fa-list-ul fa-fw text-muted ml-1"
									:id="`meta-${data.item.id}`"
									v-b-popover
								></i>
								<b-popover :target="`meta-${data.item.id}`" triggers="hover">
									<template slot="title">{{ $t("Auditing.metadata") }}:</template>
									<li v-for="mk in queryMetadataKeys" :key="mk.id">
										<span class="text-muted">{{ mk.display_name }}</span>
										<div v-if="data.item.meta[mk.key]">
											{{ data.item.meta[mk.key] }}
										</div>
										<div v-else class="text-extra-muted">({{ $t("data_description.none") }})</div>
									</li>
								</b-popover>
							</template>
						</template>

						<!-- Priority -->
						<template #cell(priority)="data">
							<span v-if="data.item.priority > 0">+{{ data.item.priority }}</span>
							<span v-if="data.item.priority == 0" class="text-muted"></span>
						</template>

						<!-- State -->
						<template #cell(state)="data">
							<i
								v-if="data.item.protected"
								class="fas fa-lock mr-1"
								v-tippy="{ theme: 'popover' }"
								title="Protected"
							/>
							{{ getState(data.item.state) }}
							<i
								v-if="reliabilityItem && data.item.state == 1 && data.item.reliability_score"
								class="far fa-check-double text-muted ml-1"
								v-tippy="{ placement: 'top-start' }"
								:title="$t('tooltip.go_to_reliability')"
							></i>
							<i
								v-if="data.item.flags && data.item.flags.length > 0 && !allFlagsReviewed(data.item)"
								class="far fa-flag text-muted ml-1"
								:id="`flags-${data.item.id}`"
								v-b-popover
							></i>
							<i
								v-if="data.item.flags && data.item.flags.length > 0 && allFlagsReviewed(data.item)"
								class="fas fa-flag text-muted ml-1"
								:id="`flags-${data.item.id}`"
								v-b-popover
							></i>
							<b-popover
								v-if="data.item.flags && data.item.flags.length > 0"
								:target="`flags-${data.item.id}`"
								triggers="hover"
								placement="bottomright"
							>
								<template slot="title">{{ $t("Auditing.flags") }}:</template>
								<div v-for="(flag, i) in data.item.flags" :key="i">
									<i v-if="flag.reviewed == true" class="far fa-fw fa-eye text-success"></i>
									<i v-else-if="flag.reviewed == false" class="far fa-fw fa-eye text-danger"></i>
									<i v-else class="far fa-fw fa-eye-slash"></i>
									{{ getFlagName(flag) }}
								</div>
							</b-popover>
							<template v-if="data.item.cf_synced">
								<i
									v-if="data.item.cf_synced == 1"
									class="fas fa-unlink text-very-muted ml-1"
									:id="`cfstatus-${data.item.id}`"
									v-b-popover
								></i>
								<i
									v-if="data.item.cf_synced == 2"
									class="fas fa-link text-muted ml-1"
									:id="`cfstatus-${data.item.id}`"
									v-b-popover
								></i>
								<i
									v-if="data.item.cf_synced == -1"
									class="fas fa-link text-danger ml-1"
									:id="`cfstatus-${data.item.id}`"
									v-b-popover
								></i>
							</template>
							<b-popover
								v-if="data.item.cf_synced"
								:target="`cfstatus-${data.item.id}`"
								triggers="hover"
								placement="bottomright"
							>
								<template slot="title">{{ $t("Auditing.continuous_flow") }}:</template>
								<template v-if="data.item.cf_synced == 1">
									{{ $t("Auditing.cf_waiting") }}
								</template>
								<template v-if="data.item.cf_synced == 2">
									{{ $t("Auditing.cf_complete") }}
								</template>
								<template v-if="data.item.cf_synced == -1">
									{{ $t("Auditing.cf_error") }}: {{ data.item.cf_error }}
								</template>
							</b-popover>

							<span
								v-if="data.item.state === 10 && data.item.final_score"
								:key="data.item.final_score.id"
							>
								<span
									v-tippy="{
										theme: 'popover',
										html: `#s-${data.item.final_score.id}`,
										placement: 'top-start',
									}"
								>
									<i
										class="fas text-muted ml-1"
										:class="fs.scoreTypeIconClass(data.item.final_score.score_type)"
									></i>
								</span>
								<div class="d-none p-0" :id="`s-${data.item.final_score.id}`">
									<Score
										v-if="selectedItem"
										:score="data.item.final_score"
										:rubric="selectedItem.rubric"
									></Score>
								</div>
							</span>
						</template>

						<!-- Scores -->
						<template #cell(scores)="data">
							<span class="d-flex flex-row">
								<template v-for="score in data.item.scores">
									<span v-if="data.item.scores.length && showScore(score)" :key="score.id">
										<span
											v-tippy="{
												theme: 'popover',
												html: `#s-${score.id}`,
												placement: 'top-start',
											}"
											class="badge theme mr-1 pointer"
										>
											<i class="material-icons score-badge">{{
												fs.scoreTypeIcon(score.score_type)
											}}</i>
										</span>
										<div class="d-none p-0" :id="`s-${score.id}`">
											<Score
												v-if="selectedItem"
												:score="score"
												:rubric="selectedItem.rubric"
											></Score>
										</div>
									</span>
								</template>

								<template
									v-if="
										selectedItem &&
										selectedItem.assigned_delivery &&
										data.item.first_scorer_id &&
										!hasScore(data.item, 1)
									"
								>
									<span
										v-tippy="{
											theme: 'popover',
											html: `#as-${data.item.id}-1`,
											placement: 'top-start',
										}"
										class="badge theme-border mr-1 pointer text-muted"
									>
										<div class="text-theme" style="margin-top: 5px">1</div>
									</span>
									<div class="d-none p-0" :id="`as-${data.item.id}-1`">
										<div class="text-xxs text-muted">
											{{ $t("Auditing.assigned_first_score") }}
										</div>
										<div>{{ data.item.first_scorer.full_name }}</div>
										<div v-if="data.item.first_scorer.scorer_id" class="text-xxs">
											({{ data.item.first_scorer.scorer_id }})
										</div>
									</div>
								</template>

								<template
									v-if="
										selectedItem &&
										selectedItem.assigned_delivery &&
										data.item.second_scorer_id &&
										!hasScore(data.item, 2)
									"
								>
									<template v-if="firstScoreSwitched(data.item)">
										<template v-if="data.item.first_scorer">
											<span
												v-tippy="{
													theme: 'popover',
													html: `#as-${data.item.id}-1`,
													placement: 'top-start',
												}"
												class="badge theme-border mr-1 pointer text-muted"
											>
												<div class="text-theme" style="margin-top: 5px">1</div>
											</span>
											<div class="d-none p-0" :id="`as-${data.item.id}-1`">
												<div class="text-xxs text-muted">
													{{ $t("Auditing.assigned_first_score") }}
												</div>
												<div>{{ data.item.first_scorer.full_name }}</div>
												<div v-if="data.item.first_scorer.scorer_id" class="text-xxs">
													({{ data.item.first_scorer.scorer_id }})
												</div>
											</div>
										</template>
									</template>
									<template v-else>
										<span
											v-tippy="{
												theme: 'popover',
												html: `#as-${data.item.id}-2`,
												placement: 'top-start',
											}"
											class="badge theme-border mr-1 pointer text-muted"
										>
											<div class="text-theme" style="margin-top: 5px">2</div>
										</span>
										<div class="d-none p-0" :id="`as-${data.item.id}-2`">
											<div class="text-xxs text-muted">
												{{ $t("Auditing.assigned_second_score") }}
											</div>
											<div>{{ data.item.second_scorer.full_name }}</div>
											<div v-if="data.item.second_scorer.scorer_id" class="text-xxs">
												({{ data.item.second_scorer.scorer_id }})
											</div>
										</div>
									</template>
								</template>

								<template
									v-if="
										selectedItem &&
										selectedItem.assigned_delivery &&
										data.item.res_scorer_id &&
										!hasScore(data.item, 3)
									"
								>
									<span
										v-tippy="{
											theme: 'popover',
											html: `#as-${data.item.id}-3`,
											placement: 'top-start',
										}"
										class="badge theme-border mr-1 pointer text-muted"
									>
										<div class="text-theme" style="margin-top: 5px">R</div>
									</span>
									<div class="d-none p-0" :id="`as-${data.item.id}-3`">
										<div class="text-xxs text-muted">
											{{ $t("Auditing.assigned_res_score") }}
										</div>
										<div>{{ data.item.res_scorer.full_name }}</div>
										<div v-if="data.item.res_scorer.scorer_id" class="text-xxs">
											({{ data.item.res_scorer.scorer_id }})
										</div>
									</div>
								</template>
							</span>
						</template>

						<!-- Created At -->
						<template #cell(created_at)="data">{{ format(data.item.created_at) }}</template>

						<!-- Exported At -->
						<template #cell(exported_at)="data">
							<span v-if="data.item.exporting" v-tippy :title="$t('Auditing.exporting_tooltip')">{{
								$t("Auditing.exporting")
							}}</span>
							<template v-if="!data.item.exporting">
								<span
									:title="formatExportTooltip(data.item.export_error)"
									v-tippy
									v-if="data.item.export_error"
								>
									<i class="fas fa-exclamation-triangle"></i>
								</span>
								<span v-if="data.item.exported">{{ format(data.item.exported_at) }}</span>
								<span v-if="!data.item.exported" class="text-muted"></span>
							</template>
						</template>

						<!-- Edit -->
						<template #cell(actions)="data">
							<div class="d-flex flex-rows">
								<div v-if="selectedSection" class="w30">
									<a
										:href="`#/audit/${selectedSection.id}/${selectedItem.id}/${data.item.id}`"
										class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
										v-tippy
										:title="$t('tooltip.view')"
									>
										<i class="far fa-search"></i>
									</a>
								</div>
								<div v-if="canScore(data.item)" class="w30">
									<a
										@click="score(data.item)"
										class="btn btn-sm btn-icon btn-rounded theme text-white m-0"
										v-tippy
										:title="$t('tooltip.score')"
									>
										<i v-if="data.item.state <= 2" class="material-icons tiny">check</i>
										<i v-if="data.item.state === 4" class="material-icons tiny">gavel</i>
										<i
											v-else-if="data.item.state > 2 && data.item.state != 10"
											class="material-icons tiny"
											>done_all</i
										>
										<i
											v-if="data.item.state == 10 && !data.item.has_backread"
											class="material-icons tiny"
											>verified_user</i
										>
									</a>
								</div>
								<div v-if="data.item.locked" class="w30">
									<a
										@click="unlock(data.item)"
										class="btn btn-sm btn-icon btn-rounded theme text-white m-0"
										v-tippy
										:title="$t('tooltip.unlock')"
									>
										<i class="far fa-unlock"></i>
									</a>
								</div>
								<div v-if="user.role.escalate_with_score" class="w30">
									<escalate-score-button
										:response="data.item"
										:item="selectedItem"
										:user="user"
									></escalate-score-button>
								</div>
							</div>
						</template>
					</b-table>

					<media
						v-if="view == 'single' && currentItems && currentItems[0]"
						:response="!busy && currentItems[0]"
						noChan
					/>
				</div>
				<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
					<a class="flex mx-3"
						>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
						{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
					>
					<div>
						<mz-pagination
							size="md"
							class="m-1"
							:total-rows="totalRows"
							v-model="currentPage"
							:per-page="perPage"
						></mz-pagination>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="alreadyScoredWarning" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("Auditing.warning") }}</h5>
					</div>
					<div class="modal-body text-center">
						<span class="text-md" v-if="selected_resp">
							{{ $t("Auditing.already_scored_response") }}
							<strong>{{ selected_resp.ref_id }}</strong>
						</span>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<button
							@click="scoreResponse(selected_resp, true)"
							class="btn btn-flat theme"
							data-dismiss="modal"
						>
							{{ $t("buttons.score") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="confirmReliability" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("Auditing.mark_reliability") }}</h5>
					</div>
					<div class="modal-body">
						<h6>
							{{
								$t("Auditing.mark_num_second_read", {
									num: numSelected,
									r: numSelected == 1 ? "response" : "responses",
								})
							}}
						</h6>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<button @click="markReliability()" class="btn btn-flat theme" data-dismiss="modal">
							{{ $t("buttons.ok") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<range-finding-model
			:visible="rfEscalating"
			:selectedIDs="selectedIDs"
			:selectAll="selectAll"
			:query="getQuery()"
			:sectionId="selectedSection && selectedSection.id"
			:itemId="selectedItem && selectedItem.id"
			@onSubmit="refreshAndResetSelection"
			@cancel="rfEscalating = false"
		>
		</range-finding-model>

		<qc-modal
			:visible="qcModalOpen"
			:selectedIDs="selectedIDs"
			:selectAll="selectAll"
			:query="getQuery()"
			:sectionId="selectedSection && selectedSection.id"
			:itemId="selectedItem && selectedItem.id"
			@close="qcModalOpen = false"
			@onSubmit="refreshAndResetSelection"
		>
		</qc-modal>

		<queue-export-modal
			v-if="queueExportModalOpen"
			:selectedItem="selectedItem"
			:numSelected="numSelected"
			:selectedIDs="selectedIDs"
			:selectAll="selectAll"
			:query="getQuery()"
			@close="queueExportModalOpen = false"
			@onSubmit="refreshAndResetSelection"
		/>

		<div class="modal" id="setPriority" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("Auditing.set_priority") }}</h5>
					</div>
					<div class="modal-body">
						<h6>
							{{
								$t("Auditing.set_num_priority", {
									num: numSelected,
									r: numSelected == 1 ? "response" : "responses",
								})
							}}
						</h6>
						<div>
							<div class="form-group">
								<input class="form-control" type="number" v-model.number="pickPriority" />
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<button @click="setPriority(pickPriority)" class="btn btn-flat theme" data-dismiss="modal">
							{{ $t("Auditing.set_priority") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="addLabels" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("Auditing.add_labels") }}</h5>
					</div>
					<div class="modal-body">
						<h6>
							{{
								$t("Auditing.add_num_labels", {
									num: numSelected,
									r: numSelected == 1 ? "response" : "responses",
								})
							}}
						</h6>
						<div>
							<div class="form-group">
								<config-label-search
									v-model="pickLabels"
									:selectedIDs="selectedIDs"
									:selectAll="selectAll"
									:query="getQuery()"
									taggable
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<button
							@click="addLabels(pickLabels)"
							class="btn btn-flat btn-secondary"
							data-dismiss="modal"
							:class="{ theme: pickLabels && pickLabels.length > 0 }"
							:disabled="!pickLabels || pickLabels.length == 0"
						>
							{{ $t("buttons.add_labels") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="removeLabels" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("Auditing.remove_labels") }}</h5>
					</div>
					<div class="modal-body">
						<h6>
							{{
								$t("Auditing.remove_num_labels", {
									num: numSelected,
									r: numSelected == 1 ? "response" : "responses",
								})
							}}
						</h6>
						<div>
							<div class="form-group">
								<config-label-search
									v-model="pickLabels"
									:selectedIDs="selectedIDs"
									:selectAll="selectAll"
									:query="getQuery()"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<button
							@click="removeLabels(pickLabels)"
							class="btn btn-flat theme"
							data-dismiss="modal"
							:disabled="!pickLabels || pickLabels.length == 0"
						>
							{{ $t("buttons.remove_labels") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="releaseWithheldResponses" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("Auditing.release_withheld_responses") }}</h5>
					</div>
					<div class="modal-body">
						<h6>
							{{
								$t("Auditing.release_num_withheld_responses", {
									num: numSelected,
								})
							}}
						</h6>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<button @click="releaseWithheldResponses()" class="btn btn-flat theme" data-dismiss="modal">
							{{ $t("buttons.release") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="moveResponses" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("Auditing.move_responses") }}</h5>
					</div>
					<div class="modal-body">
						<div class="form-group">
							<label>{{ $t("Auditing.to_project") }}</label>
							<config-select :options="projects" v-model="moveToProject"></config-select>
						</div>
						<div class="form-group">
							<label>{{ $t("Auditing.to_section") }}</label>
							<config-select :options="moveToSections" v-model="moveToSection"></config-select>
						</div>
						<div class="form-group">
							<label>{{ $t("Auditing.to_item") }}</label>
							<config-select :options="moveToItems" v-model="moveToItem"></config-select>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<button
							@click="moveResponses(moveToSection, moveToItem)"
							class="btn btn-flat theme"
							data-dismiss="modal"
							:disabled="!(moveToSection && moveToItem)"
						>
							{{ $t("buttons.move") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="deleteResponses" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("Auditing.delete_responses") }}</h5>
					</div>
					<div class="modal-body">
						<h6 class="mb-4">
							{{
								$t("Auditing.confirm_delete", {
									num: numSelected,
									r: numSelected == 1 ? "response" : "responses",
								})
							}}
						</h6>
						<h6>
							<span class="text-danger far faw fa-exclamation-triangle"></span>
							{{ $t("Auditing.delete_warning") }}
						</h6>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<button @click="deleteResponses()" class="btn btn-flat btn-danger">
							{{ $t("buttons.delete") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal"
			id="invalidateResponses"
			ref="invalidateResponses"
			data-backdrop="static"
			data-keyboard="false"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="mb-0">{{ $t("Auditing.invalidate_responses") }}</h5>
					</div>
					<div class="modal-body">
						<h6 class="mb-0">
							<span class="far faw fa-info-circle mr-1"></span>
							{{ $t("Auditing.invalidate_explanation_1") }}
						</h6>
						<h6 class="mt-3 mb-0">
							{{ $t("Auditing.invalidate_explanation_2") }}
						</h6>
						<div class="mt-2 text-muted">
							{{ $t("Auditing.invalidate_explanation_3") }}
						</div>
						<div class="mt-2 form-group">
							<label>{{ $t("Auditing.new_ref_id_pattern") }}</label>
							<input class="form-control" v-model="invalidateRefIdPattern" />
							<small v-if="!invalidateRefIdPattern.includes('{ref_id}')" class="text-danger">
								{{ $t("Auditing.must_contain_ref_id") }}
							</small>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<AwaitButton
							:click="invalidateResponses"
							class="btn btn-flat btn-danger"
							:name="$t('Auditing.invalidate')"
							:loadingName="$t('Auditing.invalidating')"
							:clickable="invalidateRefIdPattern.includes('{ref_id}')"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="resetResponses" ref="resetResponses" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("Auditing.reset_responses") }}</h5>
					</div>
					<div class="modal-body">
						<h6 class="mb-4">
							{{
								$t("Auditing.confirm_reset_responses", {
									num: numSelected,
									r: numSelected == 1 ? "response" : "responses",
								})
							}}
						</h6>
						<h6>
							<span class="text-danger far faw fa-exclamation-triangle"></span>
							{{ $t("Auditing.delete_warning") }}
						</h6>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<AwaitButton
							:click="resetResponses"
							class="btn btn-flat btn-danger"
							:name="$t('Auditing.reset_scores')"
							:loadingName="$t('Auditing.resetting')"
						/>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal"
			id="unprotectResponses"
			ref="unprotectResponses"
			data-backdrop="static"
			data-keyboard="false"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5>{{ $t("Auditing.unprotect_responses") }}</h5>
					</div>
					<div class="modal-body">
						<h6 class="mb-4">
							{{ $t("Auditing.confirm_unprotect_responses") }}
						</h6>
						<h6>
							<span class="text-danger far faw fa-exclamation-triangle"></span>
							{{ $t("Auditing.unprotect_warning") }}
						</h6>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<AwaitButton
							:click="unprotectResponses"
							class="btn btn-flat btn-danger"
							:name="$t('Auditing.unprotect')"
							:loadingName="$t('Auditing.unprotecting')"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="assignScorer" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="mb-0">{{ $t("Auditing.assign_scorer") }}</h5>
					</div>
					<div class="modal-body">
						<h6>
							{{
								$t("Auditing.assign_num_scorers", {
									num: numSelected,
									r: numSelected == 1 ? "response" : "responses",
								})
							}}
						</h6>
						<div v-if="!loadingSchoolCodes">
							<template v-if="selectedSchoolCodes && selectedSchoolCodes.length > 0">
								<span class="mr-1">{{ $t("fields.school_codes") }}:</span>
								<span class="text-muted">{{ selectedSchoolCodes.join(", ") }}</span>
								<span
									v-if="selectedItem && selectedItem.school_code_config.mode == 0"
									class="text-muted text-xxs ml-1"
									v-tippy
									:title="$t('ItemEdit.Scoring.school_code_behavior.exclusive_assignment_tooltip')"
									>({{ $t("ItemEdit.Scoring.school_code_behavior.exclusive") }})</span
								>
								<span
									v-if="selectedItem && selectedItem.school_code_config.mode == 1"
									class="text-muted text-xxs ml-1"
									v-tippy
									:title="$t('ItemEdit.Scoring.school_code_behavior.inclusive_assignment_tooltip')"
									>({{ $t("ItemEdit.Scoring.school_code_behavior.inclusive") }})</span
								>
							</template>
						</div>
						<div v-else><loading type="icon mr-1" />{{ $t("Auditing.checking_school_codes") }}</div>
						<div class="form-group mt-3">
							<label>{{ $t("Auditing.score_type") }}</label>
							<v-select :options="assignableScoreTypes" v-model="assignScorerType"></v-select>
						</div>
						<div class="form-group">
							<div class="d-flex flex-row">
								<label>{{ $t("Auditing.scorer") }}</label>
							</div>
							<config-user-search
								:clientID="user.client && user.client.id"
								v-model="assignScorerUser"
								:matchSchoolCodes="loadingSchoolCodes ? [] : selectedSchoolCodes"
								hideUnmatchedSchoolCodes
								:schoolCodeMatchInclusive="selectedItem && selectedItem.school_code_config.mode == 1"
							/>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat btn-primary" data-dismiss="modal">
							{{ $t("buttons.cancel") }}
						</button>
						<button
							@click="assignScorer(assignScorerUser, assignScorerType)"
							class="btn btn-flat btn-secondary"
							data-dismiss="modal"
							:class="{ theme: assignScorerUser && assignScorerType }"
							:disabled="
								!(assignScorerUser && assignScorerType) ||
								schoolCodeMismatchWithInclusivity(
									assignScorerUser,
									loadingSchoolCodes ? [] : selectedSchoolCodes
								)
							"
						>
							{{ $t("buttons.assign_scorer") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<ref-id-modal v-if="refIDModalOpen" :response="getSelectedResponse()" @close="refIDModalOpen = false" />

		<!-- Import -->
		<response-import-modal
			id="importModal"
			:visible="importingItem != false"
			:section="selectedSection"
			:item="importingItem"
			@cancel="importingItem = false"
			@refresh="refreshTable"
		/>

		<!-- Import scores -->
		<score-import-modal
			id="scoreImportModal"
			:visible="scoreImportingItem != false"
			:section="selectedSection"
			:item="scoreImportingItem"
			@cancel="scoreImportingItem = false"
			@refresh="refreshTable"
		/>

		<!-- Export -->
		<b-modal
			id="exportModal"
			:visible="exportingItem != false"
			v-if="exportingItem"
			@hidden="exportingItem = false"
		>
			<template slot="modal-title">{{ $t("Auditing.export_scores") }}</template>
			<div class="row p-4">
				<div class="col-12 form-group">
					<h5>{{ $t("Auditing.export_scores_to", { name: exportingItem.name }) }}</h5>
				</div>
				<div class="col-12 form-group">
					<label>{{ $t("Auditing.export_type") }}</label>
					<v-select :options="exportOptions" v-model="exportType"></v-select>
				</div>
				<div class="col-12">
					<ul style="list-style-type: circle">
						<li style="list-style-type: circle">
							<strong>{{ $t("Auditing.response_per_row") }}:</strong>
							{{ $t("Auditing.each_response_row") }}
						</li>
						<li style="list-style-type: circle">
							<strong>{{ $t("Auditing.score_per_row") }}:</strong> {{ $t("Auditing.multiple_rows") }}
						</li>
					</ul>
				</div>
				<div class="col-12">
					<template v-if="exportType && exportType.id == 1">
						<div class="checkbox">
							<label class="md-check">
								<input type="checkbox" v-model="includeResponse" />
								<i class="theme-accent"></i> Include response text
							</label>
						</div>
						<div class="checkbox" v-if="!limitSelection">
							<label class="md-check">
								<input type="checkbox" v-model="includeIncomplete" />
								<i class="theme-accent"></i> Include incomplete responses
							</label>
						</div>
						<div class="checkbox">
							<label class="md-check">
								<input type="checkbox" v-model="includeMetadata" />
								<i class="theme-accent"></i> Include response metadata
							</label>
						</div>
					</template>
					<div class="checkbox" v-if="exportType && numSelected && !allScores">
						<label class="md-check">
							<input type="checkbox" v-model="limitSelection" />
							<i class="theme-accent"></i> Limit to selection ({{ numSelected }})
						</label>
					</div>
					<!--
						TODO: uncomment if the 502 response was in fact not a result of this export option
						
						Hiding this because CAS user gets a 502 bad gateway response when using this export option.
						Even though it is likely a server communication error, since the export API endpoint /v1/admin/export/scores
						only ever returns 200, 500, or 400, disabling for now as requested in issue #1981. Will re-enable
						this option once we absolutely determine the source of the 502 response for the CAS user is not
						due to this export option.
					-->
					<!-- <div class="checkbox" v-if="exportType && !limitSelection">
						<label class="md-check">
							<input type="checkbox" v-model="allScores" />
							<i class="theme-accent"></i> All Scores in Tenant Grouped By Rubric Setup
						</label>
					</div> -->
					<small v-if="exportType && exportType.id == 1 && !includeIncomplete">
						Note: Incomplete responses will not be exported even if they are selected
					</small>
				</div>
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="stopExport">{{ $t("buttons.cancel") }}</button>
				<button
					class="btn btn-flat"
					@click="
						exportScores(
							exportType,
							includeResponse,
							includeIncomplete,
							limitSelection,
							includeMetadata,
							allScores
						)
					"
					:disabled="!exportType"
					:class="{ success: exportType }"
				>
					{{ $t("buttons.export") }}
				</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w40 {
	width: 40px;
}

.w30 {
	width: 30px;
}

.audit-search > .form-group {
	margin-bottom: 5px;
}

.score-badge {
	font-size: 1rem;
	line-height: 1.5rem;
}

.badge {
	width: 1.25rem;
	height: 1.5rem;
	padding: 0 0;
}

.empty-badge {
	box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.mi-small {
	font-size: 1rem;
}

.ats-table {
	white-space: nowrap;
}

label {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

.dot {
	height: 5px;
	width: 5px;
	background-color: #bbb;
	border-radius: 50%;
	display: inline-block;
}

.nodot {
	height: 5px;
	width: 5px;
	border-radius: 50%;
	display: inline-block;
}

.btn-topnav {
	margin-top: -8px;
	margin-bottom: -8px;
	width: 56px;
	height: 56px;
	text-align: center;
	cursor: pointer;
}

.btn-topnav:last-child {
	margin-right: -16px;
	margin-left: -16px;
}

.btn-topnav:hover {
	background-color: rgba(0, 0, 0, 0.075);
}

.btn-topnav a {
	line-height: 56px;
}

.topnav-margins {
	margin-top: -8px;
	margin-bottom: -8px;
}
</style>

<script>
//UI Components
//Libraries
//import _ from "lodash";
import moment from "moment";
import $ from "jquery";

//Services
import ConfigService from "../../services/ConfigService";
import AuditingService from "../../services/AuditingService";
import ScoringService from "../../services/ScoringService";
import SectionService from "@/services/SectionService";
import ResGroupService from "@/services/ResGroupService";
import TenantService from "@/services/TenantService";
import FileService from "@/services/FileService";
import fs from "../../services/FormatService";
import Notie from "../../services/NotieService";
import store from "../../services/Store";
import BB from "bluebird";

import TableMultiselect from "../../components/TableMultiselect";
import ConfigUserSearch from "../../components/ConfigUserSearch";
import ConfigLabelSearch from "../../components/ConfigLabelSearch";
import ConfigSelect from "../../components/ConfigSelect";
import Score from "../../components/Score";
import Media from "../../components/viewer/Media";
import ResponseImportModal from "../../components/ResponseImportModal";
import ScoreImportModal from "../../components/ScoreImportModal";
import QcModal from "../../components/Auditing/QcModal.vue";
import RangeFindingModel from "../../components/Auditing/RangeFindingModel.vue";
import QueueExportModal from "../../components/Auditing/QueueExportModal.vue";
import RefIdModal from "./Auditing/RefIDModal";
import EscalateScoreButton from "../../components/Auditing/EscalateScoreButton.vue";
import MzPagination from "../../components/MzPagination";
import ScoreQuery from "@/components/ScoreQuery";
import AwaitButton from "@/components/AwaitButton";

import { CF_STATUSES, isInvalidedScore } from "@/services/Constants";

export default {
	name: "Auditing",
	props: ["user", "client"],
	components: {
		Score,
		Media,
		TableMultiselect,
		ConfigUserSearch,
		ConfigLabelSearch,
		ConfigSelect,
		RangeFindingModel,
		ResponseImportModal,
		ScoreImportModal,
		QcModal,
		QueueExportModal,
		EscalateScoreButton,
		MzPagination,
		RefIdModal,
		ScoreQuery,
		AwaitButton,
	},
	data() {
		var exportStates = [
			{ label: "　", value: null },
			{ label: "Yes", value: true },
			{ label: "No", value: false },
		];

		var protectedStates = [
			{ label: "　", value: null },
			{ label: "Yes", value: true },
			{ label: "No", value: false },
		];

		var agreementTypes = [
			{ label: "　", id: null },
			{ label: "No Comparison", id: "" },
			{ label: "Exact", id: "e" },
			{ label: "Adjacent", id: "a" },
			{ label: "Non-Adjacent", id: "n" },
		];

		var scoreTypes = [
			{ label: fs.scoreTypeAbr(1, this.$i18n), type: 1 },
			{ label: fs.scoreTypeAbr(2, this.$i18n), type: 2 },
			{ label: fs.scoreTypeAbr(3, this.$i18n), type: 3 },
			{ label: fs.scoreTypeAbr(4, this.$i18n), type: 4 },
			{ label: fs.scoreTypeAbr(5, this.$i18n), type: 5 },
			{ label: fs.scoreTypeAbr(17, this.$i18n), type: 17 },
			{ label: fs.scoreTypeAbr(18, this.$i18n), type: 18 },
		];

		var assignableScoreTypes = [
			{ label: fs.scoreTypeAbr(1, this.$i18n), type: 1 },
			{ label: fs.scoreTypeAbr(2, this.$i18n), type: 2 },
			{ label: fs.scoreTypeAbr(3, this.$i18n), type: 3 },
		];

		var flagReviewTypes = [
			{ name: "　", id: null, meta: true },
			{ name: this.$i18n.t("flag_review_types.none"), id: "none", meta: true },
			{ name: this.$i18n.t("flag_review_types.partial"), id: "part", meta: true },
			{ name: this.$i18n.t("flag_review_types.all"), id: "all", meta: true },
		];

		var state = store.resolveSelectedRespState(this, fs.responseStates(), "audit.r.selectedState");
		var exportState = store.resolveFromList(this, exportStates, "value", 0, "audit.r.exportState");
		var protectedState = store.resolveFromList(this, protectedStates, "value", 0, "audit.r.protectedState");

		var labels = store.get(this, "audit.r.labels");
		var filter_types = store.resolveSelectedScoreTypes(this, scoreTypes, "audit.r.filter_types");
		var response_id = store.get(this, "audit.r.response_id");
		var users = store.getDefault(this, "audit.r.users", []);
		var agreement = store.get(this, "audit.r.agreement");
		var scoreQueryType = store.getDefault(this, "audit.r.scoreQueryType", 0);
		var minScore = null;
		var maxScore = null;
		var sortBy = store.get(this, "audit.r.sortBy");
		var sortDesc = store.get(this, "audit.r.sortDesc");
		var currentPage = store.get(this, "audit.r.currentPage");
		// debugger;

		var assignedFirstScore = store.get(this, "audit.r.assignedFirstScore");
		var assignedSecondScore = store.get(this, "audit.r.assignedSecondScore");
		var assignedResScore = store.get(this, "audit.r.assignedResScore");

		var view = store.getDefault(this, "audit.r.view", "list");

		console.log("STATES", this.states);

		var meta = store.getDefault(this, "audit.r.meta", {});
		_.each(this.user.client.metadata_keys, (mk) => {
			if (!meta[mk.ket]) {
				meta[mk.key] = "";
			}
		});

		//Dynamically bound fields
		return {
			vm: this,
			fs: fs,
			loading: true,
			fields: null,
			currentPage: currentPage,
			perPage: view == "list" ? 20 : 1,
			totalRows: 0,
			filter: "",
			sortBy: sortBy,
			sortDesc: sortDesc,
			currentItems: [],
			query: null,
			selectedIDs: [],
			selectAll: false,
			numSelected: 0,
			queueResetSelection: false,
			view: view,

			projects: [],
			selectedProject: null,

			sections: [],
			selectedSection: null,

			items: [],
			selectedItem: null,
			item: null,

			selectedState: state ? state : states[0],
			exportStates: exportStates,
			selectedExportState: exportState,
			protectedStates: protectedStates,
			selectedProtectedState: protectedState,

			selectedFlagType: null,
			flagReviewTypes: flagReviewTypes,
			selectedFlagReviewType: null,

			response_id: response_id ? response_id : "",
			users: users,
			labels: labels,
			selectedIeaStatus: null,

			agreementTypes: agreementTypes,
			agreement: agreement,

			selected_resp: null,

			//Score Query
			score_types: scoreTypes,
			filter_types: filter_types,
			scoreQueryType: scoreQueryType,
			minScore: minScore,
			maxScore: maxScore,
			traitSelectionMap: null,
			audit_trait_scores: [],

			//Labeling
			pickLabels: [],
			//Metadata
			meta: meta,
			//Priority
			pickPriority: 0,
			//Invalidate
			invalidateRefIdPattern: "INV:{ref_id}",

			//Moving project/section/item
			moveToProject: null,
			moveToSections: [],
			moveToSection: null,
			moveToItems: [],
			moveToItem: null,

			rfEscalating: false,
			qcModalOpen: false,
			refIDModalOpen: false,
			queueExportModalOpen: false,

			// Assigned delivery
			assignableScoreTypes: assignableScoreTypes,
			assignScorerUser: null,
			assignScorerType: null,
			assignedFirstScore: assignedFirstScore,
			assignedSecondScore: assignedSecondScore,
			assignedResScore: assignedResScore,
			loadingSchoolCodes: false,
			selectedSchoolCodes: [],

			oldScoreQuery: null,
			queryModal: false,

			importingItem: false,
			scoreImportingItem: false,

			exportingItem: false,
			exportOptions: [
				{ label: this.$i18n.t("Auditing.response_per_row"), id: 1 },
				{ label: this.$i18n.t("Auditing.score_per_row"), id: 2 },
			],
			exportType: null,
			includeResponse: false,
			includeIncomplete: false,
			includeMetadata: false,
			limitSelection: false,
			allScores: false,

			resGroupMap: {},
		};
	},

	created() {
		this.constructFields();
		this.loadConfigData();
		this.refreshTableDelayed = _.debounce(() => {
			this.$refs.table.refresh();
		}, 500);
		this.refreshTable = _.debounce(() => {
			this.debug("REFRESH");
			this.$refs.table.refresh();
		}, 10);
	},

	computed: {
		alerts() {
			let alerts = [];
			alerts.push({ id: null, name: "　", meta: true });
			alerts.push({ id: "none", name: this.$i18n.t("Auditing.no_flags"), meta: true });
			alerts.push({ id: "any", name: this.$i18n.t("Auditing.any_type"), meta: true });
			if (this.selectedItem && this.selectedItem.alerts.length > 0) {
				_.each(this.selectedItem.alerts, (alert) => {
					alerts.push({
						id: alert.id,
						name: alert.description || alert.code,
						requires_score: alert.requires_score,
					});
				});

				this.selectedFlagType = store.bindFromList(
					this,
					alerts,
					"id",
					0,
					"selectedFlagType",
					"audit.r.selectedFlagType"
				);
			}

			return alerts;
		},
		reliabilityItem() {
			return (
				this.selectedItem && this.selectedItem.type != "SINGLE" && this.selectedItem.allow_manual_reliability
			);
		},
		apiItem() {
			return this.selectedItem && this.selectedItem.api_export_config.enabled;
		},
		assignedDeliveryItem() {
			return this.selectedItem && this.selectedItem.assigned_delivery;
		},
		states() {
			const states = fs.responseStates();

			if (this.selectedSection && this.selectedItem) {
				if (!this.isInResGroup(this.selectedSection.id, this.selectedItem.id)) {
					return _.without(states, { value: 40 });
				}
			}

			return states;
		},
		queryMetadataKeys() {
			let qmk = [];
			if (!(this.user && this.user.client && this.user.client.metadata_keys)) {
				return qmk;
			}

			_.each(this.user.client.metadata_keys, (mk) => {
				if (!mk.admin_only || this.user.role.admin_metadata) {
					qmk.push(mk);
				}
			});

			return qmk;
		},

		ieaStatuses() {
			const { ERROR, SENT, COMPLETED } = CF_STATUSES;
			return [{ id: null, name: "　" }, ERROR, SENT, COMPLETED];
		},
	},

	watch: {
		moveToProject() {
			if (!this.moveToProject) {
				return;
			}
			return ConfigService.listProjectSectionsWithItems(this.moveToProject.id)
				.then((resp) => {
					if (resp.data.length && resp.data.length > 0) {
						this.moveToSections = resp.data;
					} else {
						this.moveToSections = [];
						this.moveToSection = null;
					}
				})
				.catch((err) => {
					console.log("Failed to load sections for project:", err);
				});
		},
		moveToSection() {
			if (!this.moveToSection) {
				return;
			}
			this.moveToItems = this.moveToSection.items;
			if (!this.moveToItem || !_.find(this.moveToItems, { id: this.moveToItem.id })) {
				if (this.moveToItems && this.moveToItems.length > 0) {
					this.moveToItem = this.moveToItems[0];
				}
			}
		},
		selectedState() {
			this.refreshTable();
			store.set(this, "audit.r.selectedState", this.selectedState ? this.selectedState.value : null);
		},
		selectedExportState() {
			this.refreshTable();
			store.set(this, "audit.r.exportState", this.selectedExportState ? this.selectedExportState.value : null);
		},
		selectedProtectedState() {
			this.refreshTable();
			store.set(
				this,
				"audit.r.protectedState",
				this.selectedProtectedState ? this.selectedProtectedState.value : null
			);
		},
		selectedProject() {
			if (!this.selectedProject) {
				return;
			}
			this.sections = [];
			this.selectedSection = null;
			this.items = [];
			this.selectedItem = null;
			this.item = null;
			this.moveToItem = null;
			this.moveToItems = [];
			this.moveToSection = null;
			this.loadSections().then(() => {
				this.refreshTable();
			});
			store.set(this, "audit.r.selectedProject", this.selectedProject ? this.selectedProject.id : null);
		},
		selectedSection() {
			if (!this.selectedSection) {
				return;
			}
			this.loadItems().then(() => {
				this.refreshTable();
			});

			store.set(this, "audit.r.selectedSection", this.selectedSection ? this.selectedSection.id : null);
		},
		selectedItem() {
			console.log("SELECTED ITEM");
			this.audit_trait_scores = store.resolveAuditingTraitScores(this, this.selectedItem, "audit.r.trait_scores");
			console.log("resolved trait scores", this.audit_trait_scores);
			this.setupTraitScoreSelections();
			console.log("set up selections");

			this.refreshTable();
			console.log("refreshed table");
			store.set(this, "audit.r.selectedItem", this.selectedItem ? this.selectedItem.id : null);
		},
		selectedFlagType() {
			this.refreshTable();
		},
		selectedFlagReviewType() {
			this.refreshTable();
		},
		response_id() {
			this.refreshTableDelayed();
			store.set(this, "audit.r.response_id", this.response_id);
		},
		users() {
			this.refreshTable();
			store.set(this, "audit.r.users", this.users);
		},
		assignedFirstScore() {
			this.refreshTable();
			store.set(this, "audit.r.assignedFirstScore", this.assignedFirstScore);
		},
		assignedSecondScore() {
			this.refreshTable();
			store.set(this, "audit.r.assignedSecondScore", this.assignedSecondScore);
		},
		assignedResScore() {
			this.refreshTable();
			store.set(this, "audit.r.assignedResScore", this.assignedResScore);
		},
		agreement() {
			this.refreshTable();
			store.set(this, "audit.r.agreement", this.agreement);
		},
		filter_types() {
			this.refreshTable();
			store.set(this, "audit.r.filter_types", this.filter_types);
		},
		scoreQueryType() {
			this.audit_trait_scores = [];
			this.minScore = null;
			this.maxScore = null;
			store.set(this, "audit.r.scoreQueryType", this.scoreQueryType);
			store.set(this, "audit.r.minScore", this.minScore);
			store.set(this, "audit.r.maxScore", this.maxScore);
		},

		view(newVal, oldVal) {
			if (newVal == "list" && oldVal != "list") {
				this.currentPage = Math.floor((this.currentPage - 1) / 20) + 1;
				this.perPage = 20;
			}
			if (newVal == "single" && oldVal != "single") {
				this.currentPage = (this.currentPage - 1) * 20 + 1;
				this.perPage = 1;
			}
			store.set(this, "audit.r.view", this.view);
		},
		labels() {
			this.refreshTable();
			store.set(this, "audit.r.labels", this.labels);
		},
		selectedIeaStatus() {
			this.refreshTable();
		},
		meta: {
			handler: function (newVal) {
				this.refreshTable();
				store.set(this, "audit.r.meta", this.meta);
			},
			deep: true,
		},
		pickPriority() {
			if (this.pickPriority < 0) {
				this.pickPriority = 0;
			} else if (this.pickPriority > 100) {
				this.pickPriority = 100;
			}
		},
	},

	methods: {
		loadConfigData() {
			console.log("LOAD CONFIG DATA");
			this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
			var ctx = {};
			ctx.showHiddenProjects = this.showHiddenProjects;
			BB.props({
				client: TenantService.getClient(),
				projects: ConfigService.listProjectsShallow(ctx),
				resGroups: ResGroupService.listResGroups(),
			}).then((resps) => {
				_.each(resps.resGroups.data.res_groups, (rg) => {
					_.each(rg.elements, (el) => {
						let slug = `${el.section_id}-${el.item_id}`;
						this.resGroupMap[slug] = true;
					});
				});

				// Load the client
				this.client = resps.client.data;

				// Load projects from database and setup section select
				this.projects = resps.projects.data;
				this.selectedProject = store.resolveSelectedProject(this, this.projects, "audit.r.selectedProject");

				// Load the project's sections
				this.loadSections();

				this.selectedFlagReviewType = store.bindFromList(
					this,
					this.flagReviewTypes,
					"id",
					0,
					"selectedFlagReviewType",
					"audit.r.selectedFlagReviewType"
				);

				this.loading = false;
				this.refreshTable();
			});
		},

		provider(ctx) {
			this.debug("GET PROVIDER");
			store.set(this, "audit.r.sortBy", this.sortBy);
			store.set(this, "audit.r.sortDesc", this.sortDesc);
			store.set(this, "audit.r.currentPage", this.currentPage);
			if (this.loading) {
				return [];
			}
			let query = this.getQuery();
			if (!query.item_id) return [];
			if (!query.section_id) return [];
			return AuditingService.auditQuery(query, ctx)
				.then((resp) => {
					if (this.queueResetSelection) {
						this.resetSelection();
						this.queueResetSelection = false;
					}

					this.constructFields();
					this.query = query;
					this.totalRows = resp.data.totalRows;
					this.currentItems = resp.data.rows;
					return this.currentItems;
				})
				.catch((err) => {
					return [];
				});
		},

		constructFields() {
			let fields = [
				{
					key: "select",
					label: this.$i18n.t("fields.select"),
					thClass: "p-custom p-0 v-mid",
					thStyle: "width: 40px;",
					tdClass: "p-custom p-0 v-mid",
				},
				{ key: "ref_id", label: this.$i18n.t("fields.ref_id_short"), sortable: true },
				{ key: "priority", label: this.$i18n.t("fields.priority"), sortable: true },
				{ key: "word_count", label: this.$i18n.t("fields.words"), sortable: true },
				{ key: "state", label: this.$i18n.t("fields.state"), sortable: true },
				{
					key: "scores",
					label: this.$i18n.t("fields.scores"),
					tdClass: "p-custom p-0 v-mid",
					sortable: false,
				},
				// { key: "has_backread", sortable: true },
				// { key: "has_resolution", sortable: true },
				{
					key: "created_at",
					label: this.$i18n.t("fields.created_at"),
					tdClass: "text-truncate",
					sortable: true,
				},
			];
			if (this.selectedItem && this.selectedItem.api_export_config.enabled) {
				fields.push({
					key: "exported_at",
					label: this.$i18n.t("fields.exported_at"),
					tdClass: "text-truncate",
					sortable: true,
				});
			}
			fields.push({ key: "actions", label: this.$i18n.t("fields.actions"), tdClass: "p-custom p-0 v-mid" });

			this.fields = fields;
		},

		loadSections() {
			console.log("LOAD SECTIONS");
			if (!this.selectedProject) {
				return new Promise();
			}

			return ConfigService.listProjectSectionsWithItems(this.selectedProject.id)
				.then((resp) => {
					if (resp.data.length && resp.data.length > 0) {
						this.sections = resp.data;
						this.selectedSection = store.resolveSelectedSection(
							this,
							this.sections,
							"audit.r.selectedSection"
						);
					} else {
						this.sections = [];
						this.selectedSection = null;
					}
				})
				.catch((err) => {
					console.log("Failed to load sections for project:", err);
				});
		},

		loadItems() {
			console.log("LOAD ITEMS");
			if (!this.selectedSection) {
				return new Promise();
			}

			return ConfigService.getSection(this.selectedSection.id)
				.then((resp) => {
					// Section may not have items, protect against this
					if (resp.data.items) {
						this.items = resp.data.items;
						if (this.selectedSection && this.items) {
							this.selectedSection.items = this.items;

							this.selectedItem = store.resolveSelectedItem(
								this,
								this.selectedSection,
								"audit.r.selectedItem"
							);
							console.log("RESOLVE ITEM", this.selectedItem, this.selectedItem.name);
							if (!this.selectedItem || !_.find(this.items, { id: this.selectedItem.id })) {
								if (this.items && this.items.length > 0) {
									this.selectedItem = this.items[0];
								}
							}

							if (this.selectedItem) {
								console.log("RESOLVE auditing trait scores");
								this.audit_trait_scores = store.resolveAuditingTraitScores(
									this,
									this.selectedItem,
									"audit.r.trait_scores"
								);
								console.log("finish");
							}
						}
					}
				})
				.catch((err) => {
					console.log(err);
					Notie.error("Failed to load items", err);
				});
		},

		getQuery() {
			let user_ids = [];
			_.each(this.users, (u) => {
				if (u.users) {
					_.each(u.users, (tu) => {
						user_ids.push(tu.id);
					});
				} else {
					user_ids.push(u.id);
				}
			});

			let trait_scores = [];
			if (this.scoreQueryType == 0 || this.scoreQueryType == 1) {
				_.each(this.audit_trait_scores, (ats) => {
					let ts = _.cloneDeep(ats);
					if (isNaN(ts.score)) {
						ts.condition = ts.score;
						ts.score = -1;
					}
					trait_scores.push(ts);
				});
			}

			let queryMeta = {};
			_.each(this.meta, (v, k) => {
				if (v != "") {
					queryMeta[k] = v;
				}
			});

			let query = {
				client_id: this.user && this.user.client.id,
				section_id: this.selectedSection && this.selectedSection.id,
				item_id: this.selectedItem && this.selectedItem.id,
				state: this.selectedState && this.selectedState.value,
				export_state:
					this.selectedItem && this.selectedItem.api_export_config.enabled && this.selectedExportState
						? this.selectedExportState.value
						: null,
				protected:
					this.user.client && this.user.client.protect_on_export && this.selectedProtectedState
						? this.selectedProtectedState.value
						: null,
				alert_id: this.selectedFlagType && this.selectedFlagType.id,
				reviewed: this.selectedFlagReviewType && this.selectedFlagReviewType.id,
				response_id: this.response_id,
				user_ids: user_ids,
				trait_scores: trait_scores,
				min_score: this.minScore,
				max_score: this.maxScore,
				labels: this.labels,
				iea_status: this.selectedIeaStatus && this.selectedIeaStatus.id,
				meta: queryMeta,
				score_types: _.map(this.filter_types, (t) => {
					return t.type;
				}),
				agreement: this.agreement,
			};

			if (this.selectedItem && this.selectedItem.assigned_delivery) {
				let afsIDs = [];
				_.each(this.assignedFirstScore, (afs) => {
					afsIDs.push(afs.id);
				});
				let assIDs = [];
				_.each(this.assignedSecondScore, (ass) => {
					assIDs.push(ass.id);
				});
				let arsIDs = [];
				_.each(this.assignedResScore, (ars) => {
					arsIDs.push(ars.id);
				});
				query.assigned_first_score = afsIDs;
				query.assigned_second_score = assIDs;
				query.assigned_res_score = arsIDs;
			}

			return query;
		},

		getQueryAll() {
			let query = {
				client_id: this.user && this.user.client.id,
				section_id: this.selectedSection && this.selectedSection.id,
				item_id: this.selectedItem && this.selectedItem.id,
			};

			return query;
		},

		getState(state) {
			// console.log(state);
			var stateObj = _.find(this.states, { value: state });
			if (stateObj) {
				return stateObj.label;
			}
			return "?";
		},

		format(date) {
			return moment(date).format(this.$i18n.t("Auditing.date_format"));
		},

		tsChange(trait_id, score_type, sp) {
			this.traitSelectionMap[trait_id][score_type].selected = sp;
			//Remove all entries for this trait_id and score_type
			_.remove(this.audit_trait_scores, (e) => {
				return e.trait_id == trait_id && e.score_type == score_type;
			});

			if (sp) {
				this.audit_trait_scores.push({
					trait_id: trait_id,
					score_type: score_type,
					score: sp.value,
				});
			}
		},

		newScoreFilters({ minScore, maxScore, auditTraitScores, traitSelectionMap }) {
			this.minScore = minScore;
			this.maxScore = maxScore;
			this.audit_trait_scores = auditTraitScores;
			store.set(this, "audit.r.trait_scores", auditTraitScores);
			this.traitSelectionMap = traitSelectionMap;
			this.refreshTable();
		},

		setupTraitScoreSelections() {
			//For each trait, and each score type, we need to create selection lists
			this.traitSelectionMap = null;
			var traitSelectionMap = {};
			if (!this.selectedItem) {
				return;
			}
			_.each(this.selectedItem.rubric.traits, (t) => {
				if (t.separator) return;
				if (t.is_parent) return;

				var scoreTypeMap = {};
				var sps = [];
				if (t.step <= 0) {
					t.step = 1;
				}
				for (var i = t.min; i <= t.max; i += t.step) {
					sps.push({
						trait_id: t.id,
						label: "" + i,
						value: i,
					});
				}

				_.each(t.condition_codes, (cc) => {
					if (cc.action == 1) {
						sps.push({
							trait_id: t.id,
							label: cc.symbol,
							value: cc.symbol,
						});
					}
				});

				let selectedATS = _.find(this.audit_trait_scores, {
					trait_id: t.id,
					score_type: 0,
				});
				let selectedSP = selectedATS ? _.find(sps, { value: selectedATS.score }) : null;
				scoreTypeMap[0] = { selected: selectedSP, sps: sps };
				_.each(this.score_types, (st) => {
					let selectedATS = _.find(this.audit_trait_scores, {
						trait_id: t.id,
						score_type: 0,
					});
					let selectedSP = selectedATS ? _.find(sps, { value: selectedATS.score }) : null;
					scoreTypeMap[st.type] = { selected: selectedSP, sps: sps };
				});

				_.each(scoreTypeMap, (st) => {
					_.each(st.sps, (sp) => {
						sp.st = st.type;
					});
				});

				traitSelectionMap[t.id] = scoreTypeMap;
			});
			this.traitSelectionMap = traitSelectionMap;
		},

		clearScoreSearch() {
			this.audit_trait_scores = [];
			this.score_specific_query = false;
			this.traitSelectionMap = null;
			this.minScore = null;
			this.maxScore = null;
			this.setupTraitScoreSelections();
			this.$forceUpdate();
			this.refreshTable();
		},

		markReliability() {
			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};
			AuditingService.markReliability(selection)
				.then((resp) => {
					let success = resp.data.success;
					if (success > 0) {
						Notie.success(
							this.$i18n.t("notie.marked_for_reliability", {
								success: success,
								r: success > 1 ? "responses" : "response",
							})
						);
					} else {
						Notie.info(this.$i18n.t("notie.already_marked"));
					}
					this.refreshAndResetSelection();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.mark_for_reliability_fail"), err);
				});
		},

		setPriority(priority) {
			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};
			AuditingService.setResponsePriority(selection, priority)
				.then((resp) => {
					Notie.success(this.$i18n.t("notie.priorities_set"));
					this.pickPriority = 0;
					this.refreshAndResetSelection();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.set_priorities_fail"), err);
				});
		},

		addLabels(labels) {
			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};
			AuditingService.addResponseLabels(selection, labels)
				.then(() => {
					Notie.success(this.$i18n.t("notie.labels_added"));
					this.pickLabels = [];
					this.refreshAndResetSelection();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.add_labels_fail"), err);
				});
		},

		removeLabels(labels) {
			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};
			AuditingService.removeResponseLabels(selection, labels)
				.then((resp) => {
					Notie.success(this.$i18n.t("notie.labels_removed"));
					this.pickLabels = [];
					this.refreshAndResetSelection();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.remove_labels_fail"), err);
				});
		},

		moveResponses(section, item) {
			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};
			AuditingService.moveResponses(selection, section.id, item.id)
				.then((resp) => {
					Notie.success(this.$i18n.t("notie.responses_moved"));
					this.moveToSection = null;
					this.moveToItem = null;
					this.refreshAndResetSelection();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.move_responses_fail"), err);
				});
		},

		deleteResponses(labels) {
			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};
			AuditingService.deleteResponses(selection, labels)
				.then((resp) => {
					Notie.success(this.$i18n.t("notie.responses_deleted"));
					this.pickLabels = [];
					this.refreshAndResetSelection();
					$("#deleteResponses").modal("hide");
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.delete_responses_fail"), err);
				});
		},

		async invalidateResponses() {
			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};

			try {
				await AuditingService.invalidateResponses(selection, this.invalidateRefIdPattern);

				Notie.success(this.$i18n.t("Auditing.responses_invalidated", { num: this.numSelected }));
				this.refreshAndResetSelection();
				$("#invalidateResponses").modal("hide");
			} catch (err) {
				Notie.error(this.$i18n.t("Auditing.invalidate_responses_fail"), err);
			}
		},

		async resetResponses() {
			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};

			try {
				await AuditingService.resetResponses(selection);

				Notie.success(this.$i18n.t("Auditing.responses_reset", { num: this.numSelected }));
				this.refreshAndResetSelection();
				$("#resetResponses").modal("hide");
			} catch (err) {
				Notie.error(this.$i18n.t("Auditing.reset_responses_fail"), err);
			}
		},

		async unprotectResponses() {
			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};

			try {
				await AuditingService.unprotectResponses(selection);

				Notie.success(this.$i18n.t("Auditing.responses_unprotected", { num: this.numSelected }));
				this.refreshAndResetSelection();
				$("#unprotectResponses").modal("hide");
			} catch (err) {
				Notie.error(this.$i18n.t("Auditing.unprotect_responses_fail"), err);
			}
		},

		releaseWithheldResponses() {
			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};
			AuditingService.releaseWithheldResponses(selection, this.user.id)
				.then((resp) => {
					Notie.success(resp.data.total + " " + this.$i18n.t("notie.withheld_responses_released"));
					this.pickLabels = [];
					this.refreshAndResetSelection();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.withheld_responses_released_failed"), err);
				});
		},

		showScore(score) {
			return !isInvalidedScore(score.score_type);
		},

		//Scoring stuff
		canScore(resp) {
			return ScoringService.canScore(this.user, resp);
		},

		score(resp) {
			if (!this.user.active) {
				Notie.error(this.$i18n.t("notie.cannot_score_response"), this.$i18n.t("notie.user_not_active"));
				return;
			}

			this.selected_resp = resp;
			var scr = _.find(resp.scores, { user_id: this.user.id });
			if (scr) {
				this.showAlreadyScoredModal(resp);
				return;
			}
			this.scoreResponse(resp);
		},

		scoreResponse(resp, forcedRepeatScore) {
			ScoringService.auditScore(resp, this.$router, null, forcedRepeatScore);
		},

		showAlreadyScoredModal(resp) {
			$("#alreadyScoredWarning").modal("show");
		},

		unlock(resp) {
			ScoringService.unlockResponse(resp)
				.then(() => {
					Notie.info(this.$i18n.t("notie.response_unlocked"));
					this.refreshTable();
				})
				.catch((e) => {
					Notie.error(this.$i18n.t("notie.unlock_response_fail"));
					this.refreshTable();
				});
		},

		resetSelection() {
			this.selectedIDs = [];
			this.selectAll = false;
		},

		refreshAndResetSelection() {
			this.queueResetSelection = true;
			this.refreshTable();
		},

		showQueryModal() {
			this.oldScoreQuery = _.cloneDeep(this.audit_trait_scores);
			this.applyATS = false;
			this.updateTraitScoreMap();
			this.queryModal = true;
		},

		updateTraitScoreMap() {
			_.each(this.traitSelectionMap, (trait) => {
				_.each(trait, (opt) => {
					opt.selected = null;
				});
			});

			_.each(this.audit_trait_scores, (ats) => {
				let trait = this.traitSelectionMap[ats.trait_id];
				if (trait) {
					let opt = trait[ats.score_type];
					if (opt) {
						let choice = _.find(opt.sps, { value: ats.score });
						if (choice) {
							opt.selected = choice;
						}
					}
				}
			});

			this.$forceUpdate();
		},

		hideQueryModal() {
			if (!this.applyATS) {
				this.audit_trait_scores = _.cloneDeep(this.oldScoreQuery);
			} else {
				this.applyATS = false;
			}
		},

		applyQueryModal() {
			this.applyATS = true;
			this.queryModal = false;
			this.refreshTable();
		},

		allFlagsReviewed(response) {
			if (!(response && response.flags)) return true;

			for (let flag of response.flags) {
				if (!(flag.reviewed == true || flag.reviewed == false)) {
					return false;
				}
			}

			return true;
		},

		getFlagName(flag) {
			let alert = _.find(this.alerts, { id: flag.alert_id });
			if (alert) {
				return alert.name;
			} else {
				return this.$i18n.t("flag_review_types.unknown_flag_type");
			}
		},

		attemptImport(item) {
			this.importingItem = this.selectedItem;
		},

		attemptScoreImport(item) {
			this.scoreImportingItem = this.selectedItem;
		},

		attemptExport(item) {
			this.exportingItem = this.selectedItem;
			this.limitSelection = false;
		},

		selectImportFile(event) {
			if (event.target.files[0]) {
				this.importingFile = event.target.files[0];
			}
		},

		stopImport() {
			this.importingItem = false;
			this.importingFile = false;
			this.importingResponses = false;
			this.importType = null;
		},

		importResponses(section, item, file) {
			console.log(file.type);
			if (file.type != "text/csv" && file.type != "application/vnd.ms-excel") {
				Notie.error(this.$i18n.t("notie.must_be_csv"));
				return;
			}

			var data = new FormData();
			data.append("file", file);
			data.append("itemId", item.id);
			data.append("sectionId", section.id);

			this.importingResponses = true;
			SectionService.uploadResponses(data)
				.then((resp) => {
					this.stopImport();
					Notie.info(
						this.$i18n.t("notie.uploaded_responses_to", { num: resp.data.numResponses, name: item.name })
					);
				})
				.catch((err) => {
					console.log(err);
					this.importingResponses = false;
					if (err.response.data.includes("duplicate key")) {
						Notie.error(
							this.$i18n.t("notie.upload_fail"),
							this.$i18n.t("notie.csv_contains_existing_resp_id")
						);
					} else {
						Notie.error(this.$i18n.t("notie.upload_fail"), err);
					}
				});
		},

		selectUploadFiles() {},

		stopExport() {
			this.exportingItem = false;
			this.exportType = null;
			this.includeResponse = false;
			this.includeIncomplete = false;
			this.includeMetadata = false;
			this.allScores = false;
		},

		exportScores(type, includeResponse, includeIncomplete, limitSelection, includeMetadata, allScores) {
			if (allScores) {
				this.exportAllScoresToZip(type.id, includeResponse, includeIncomplete, includeMetadata);
			} else {
				this.exportScoresToCsv(type.id, includeResponse, includeIncomplete, limitSelection, includeMetadata);
			}
		},

		async exportAllScoresToZip(typeID, includeResponse, includeIncomplete, includeMetadata) {
			const selection = {
				selected_ids: [],
				select_all: true,
				query: this.getQuery(),
			};
			try {
				const { data } = await AuditingService.exportAllScoresByRubricConfig(
					selection,
					typeID,
					includeResponse,
					includeIncomplete,
					includeMetadata
				);
				FileService.downloadZipFile(`All_Scores_Rubric_Grouped_${moment().format("YY-MM-DD")}.zip`, data);
				this.stopExport();
			} catch (err) {
				Notie.error("Failed to create csv exports", err);
			}
		},

		async exportScoresToCsv(typeID, includeResponse, includeIncomplete, limitSelection, includeMetadata) {
			if (limitSelection) {
				includeIncomplete = true;
			}
			const selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};
			try {
				const csv = await this.getScoresCsvText(
					selection,
					typeID,
					includeResponse,
					includeIncomplete,
					limitSelection,
					includeMetadata
				);
				const filename = `${this.selectedSection.ref_id}_${this.exportingItem.ref_id}_${moment().format(
					"YY-MM-DD"
				)}.csv`;
				FileService.downloadText(filename, csv);
				this.refreshAndResetSelection();
				this.stopExport();
			} catch (err) {
				Notie.error(this.$i18n.t("notie.failed_to_export_scores"), err);
			}
		},

		async getScoresCsvText(selection, typeID, includeResponse, includeIncomplete, limitSelection, includeMetadata) {
			const { data } = await AuditingService.exportScores(
				selection,
				typeID,
				includeResponse,
				includeIncomplete,
				limitSelection,
				includeMetadata
			);
			return data;
		},

		click(id) {
			var el = document.getElementById(id);
			if (el) {
				el.click();
			}
		},

		isInResGroup(sectionID, itemID) {
			let slug = `${sectionID}-${itemID}`;
			return this.resGroupMap[slug];
		},

		assignScorer(user, scoreType) {
			if (!(user && scoreType)) {
				return;
			}

			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};

			AuditingService.assignScorer(selection, user.id, scoreType.type)
				.then((r) => {
					let count = r.data;
					if (count == 0) {
						Notie.info("No responses were affected");
					} else if (count == 0) {
						Notie.info(`Assigned ${user.full_name} as ${scoreType.label} scorer for 1 response`);
					} else {
						Notie.info(`Assigned ${user.full_name} as ${scoreType.label} scorer for ${count} responses`);
					}
					this.refreshAndResetSelection();
				})
				.catch((e) => {
					console.log(e);
					Notie.error("Failed to assign scorer", e);
				});
		},

		loadSelectedSchoolCodes() {
			console.log("loadSelectedSchoolCodes");

			let selection = {
				selected_ids: this.selectedIDs,
				select_all: this.selectAll,
				query: this.getQuery(),
			};

			this.loadingSchoolCodes = true;
			AuditingService.getSelectionSchoolCodes(selection)
				.then((r) => {
					this.loadingSchoolCodes = false;
					this.selectedSchoolCodes = r.data;
				})
				.catch((e) => {
					this.loadingSchoolCodes = false;
					console.log(e);
					Notie.error("Failed to collect school codes for responses", e);
				});
		},

		hasScore(resp, scoreType) {
			for (let score of resp.scores) {
				if (score.score_type == scoreType) {
					return true;
				}
			}

			return false;
		},

		firstScoreSwitched(resp) {
			console.log("FIRST SCORE SWITCHED CHECK", resp.ref_id);
			let firstScore = null;
			for (let score of resp.scores) {
				if (score.score_type == 1) {
					firstScore = score;
				}
			}
			console.log("firstScore", firstScore);

			if (!firstScore) return false;
			console.log("check", resp.second_scorer_id, resp.second_scorer_id == firstScore.user_id);

			return resp.second_scorer_id == firstScore.user_id;
		},

		formatExportTooltip(str) {
			let parts = str.split(" | ");
			str = parts.join("<br />");
			return str;
		},

		schoolCodeMismatchWithInclusivity(user, matchSchoolCodes) {
			if (this.selectedItem && this.selectedItem.school_code_config.mode == 1) {
				return this.schoolCodeMismatch(user, matchSchoolCodes);
			} else {
				return !this.schoolCodeMismatch(user, matchSchoolCodes);
			}
		},

		// Returns true if the user does NOT match the given school codes under Inclusive logic;
		// i.e., if the user does not have all school codes required by the response set.
		//
		// When either the response set or the user have no school codes, this always returns true,
		// since there can be no Inclusive match when either set is empty.
		schoolCodeMismatch(user, matchSchoolCodes) {
			if (!(matchSchoolCodes && matchSchoolCodes.length > 0)) return true;
			if (!(user.school_codes && user.school_codes.length > 0)) return true;

			for (let msc of matchSchoolCodes) {
				let found = false;
				for (let usc of user.school_codes) {
					if (msc == usc) {
						found = true;
						break;
					}
				}
				if (!found) return true;
			}
			return false;
		},

		getSelectedResponse() {
			const { selectedIDs, currentItems } = this;
			const id = selectedIDs[0];
			const resp = currentItems.find((resp) => resp.id === id);
			return resp || { id, ref_id: "" };
		},

		randomSample(num) {
			let query = this.getQuery();
			if (!query.item_id) return [];
			return new Promise((resolve, reject) => {
				AuditingService.getAuditSample(query, {}, num)
					.then((r) => {
						resolve(r.data);
					})
					.catch((e) => {
						this.logError(e);
						Notie.error("Failed to randomly select responses", e);
						reject(e);
					});
			});
		},
	},
};
</script>
