import axios from "axios"

import TenantService from "@/services/TenantService"
import RubricService from "@/services/RubricService"
import UserService from "@/services/UserService"
import Notie from "@/services/NotieService"
import fs from "@/services/FormatService"
import { ADJUDICATION_RULES } from "@/services/Constants";
const BlankObjectId = "000000000000000000000000"

var adjudicationRules = Object.values(ADJUDICATION_RULES)

var cachedObjects = {}

var createObjIfMissing = {
	"items.school_code_config": true,
	"items.validity_config": true,
	"items.validity_config.threshold_rule": true,
	"items.cf_config": true,
	"items.qualification_config": true,
	"items.calibration_config": true,
	"items.api_export_config": true,
	"items.writeshift_config": true,
	"items.emma_config": true,
	"items.learnosity_config": true,
	"items.blank_scoring_config": true,
}

// Get a key on an object where the key can be in dot format ("item.cf_config.enabled")
function getObjKey(objType, obj, key) {
	try {
		return getObjKeyParts(obj, key.split("."), objType)
	} catch (e) {
		console.error("Current object:", obj)
		throw new Error(`Could not access path '${key}' in ${objType}: ` + e)
	}
}

function getObjKeyParts(obj, keyParts, keyPath) {
	let key = keyParts[0]
	if (keyParts.length == 1) {
		return obj[key]
	} else {
		return getObjKeyParts(obj[key], keyParts.slice(1), keyPath + "." + key)
	}
}

// Set a key on an object where the key can be in dot format ("item.cf_config.enabled")
function setObjKey(objType, obj, key, val) {
	try {
		setObjKeyParts(obj, key.split("."), objType, val)
	} catch (e) {
		console.error("Current object:", obj)
		throw new Error(`Could not access path '${key}' in ${objType}: ` + e)
	}
}

function setObjKeyParts(obj, keyParts, keyPath, val) {
	let key = keyParts[0]
	let newKeyPath = keyPath + "." + key
	console.log('setObjKeyParts')
	console.log(obj)
	console.log(key)
	if (keyParts.length == 1) {
		obj[key] = val
	} else {
		if (obj[key]) {
			return setObjKeyParts(obj[key], keyParts.slice(1), newKeyPath, val)
		} else if (createObjIfMissing[newKeyPath]) {
			obj[key] = {}
			return setObjKeyParts(obj[key], keyParts.slice(1), newKeyPath, val)
		} else {
			throw new Error("Could not access field " + keyParts[0]);
		}
	}
}

function with0Option(list) {
	list.push({ id: 0, name: "" })
	return list
}

function withBlankOption(list) {
	list.push({ id: "", name: "" })
	return list
}

async function ensureRubrics() {
	if (cachedObjects.rubrics) {
		return cachedObjects.rubrics
	}

	try {
		let resp = await RubricService.listAllRubrics()
		cachedObjects.rubrics = resp.data.rubrics
	} catch (e) {
		console.error(e);
		Notie.error("Failed to load rubrics", e);
	}
}

async function ensureCredentials() {
	if (cachedObjects.credentials) {
		return cachedObjects.credentials
	}

	try {
		let resp = await TenantService.listCredentialsSafe("current")
		cachedObjects.credentials = resp.data
	} catch (e) {
		console.error(e);
		Notie.error("Failed to load credentials", e);
	}
}

async function ensureFlags() {
	if (cachedObjects.flags) {
		return cachedObjects.flags
	}

	try {
		let resp = await TenantService.getClient("current")
		cachedObjects.flags = resp.data.alerts
	} catch (e) {
		console.error(e);
		Notie.error("Failed to load flags", e);
	}
}

async function ensureUserScorerIDs() {
	if (cachedObjects.scorer_id_users) {
		return cachedObjects.scorer_id_users
	}

	try {
		let resp = await UserService.listScorerIDs()
		cachedObjects.scorer_id_users = resp.data
	} catch (e) {
		console.error(e);
		Notie.error("Failed to load user scorer IDs", e);
	}
}

var genericHandlers = {
	string(displayName, key) {
		return {
			displayName: displayName,
			type: "string",
			toFieldValue(objType, obj) {
				return getObjKey(objType, obj, key)
			},
			toUpdateDocument(objType, obj, doc, val) {
				setObjKey(objType, doc, key, val)
			},
			validate(objType, obj, val) {
				return { valid: true }
			}
		}
	},

	requiredString(displayName, key) {
		return {
			displayName: displayName,
			type: "string",
			toFieldValue(objType, obj) {
				return getObjKey(objType, obj, key)
			},
			toUpdateDocument(objType, obj, doc, val) {
				setObjKey(objType, doc, key, val)
			},
			validate(objType, obj, val) {
				if (!val) {
					return {
						valid: false,
						errMsg: "Value must be a non-blank string"
					}
				}
				return { valid: true }
			}
		}
	},

	requiredBlankScoringActionString(displayName, key) {
		return {
			displayName: displayName,
			type: "string",
			toFieldValue(objType, obj) {
				return getObjKey(objType, obj, key)
			},
			toUpdateDocument(objType, obj, doc, val) {
				setObjKey(objType, doc, key, val)
			},
			validate(objType, obj, val) {
				const allowed_values = ['apply_score', 'withhold'];
				if (val){
					if (!allowed_values.includes(val)){
						return {
							valid: false,
							errMsg: "Value must be apply_score or withhold"
						}
					}else{
						return { valid: true }
					}
				}
				if (!val) {
					return {
						valid: false,
						errMsg: "Value must be apply_score or withhold"
					}
				}
			}
		}
	},

	requiredStringWithDelimeter(displayName, key) {
		return {
			displayName: displayName,
			type: "string",
			toFieldValue(objType, obj) {
				return getObjKey(objType, obj, key)
			},
			toUpdateDocument(objType, obj, doc, val) {
				setObjKey(objType, doc, key, val)
			},
			validate(objType, obj, val) {
				if (val){
					let configArray = val.split(';');
					let lengthNotEqualThree = false
					let valuesNotValid = false
					let configArrayCounter = 0
					configArray.forEach((config) => {
						let values = config.split(',');
						if (configArray.length < configArrayCounter - 1){
							if (values.length != 3){
								lengthNotEqualThree = true
							}
							if (!values[0] || !values[2]){
								valuesNotValid = true
							}
						}
						configArrayCounter = configArrayCounter + 1
					})
					if (lengthNotEqualThree){
						return {
							valid: false,
							errMsg: "Value contains incorrect number of parts. Format is: 'key,value,percent;' Split each configuration with a semi-colon. You can have multiples."
						}
					}else if (valuesNotValid){
						return {
							valid: false,
							errMsg: "Value must be a non-blank string. Format is: 'key,value,percent;' Split each configuration with a semi-colon. You can have multiples."
						}
					}else{
						return { valid: true }
					}
				}else {
					return {
						valid: false,
						errMsg: "Value must be a non-blank string"
					}
				}
			}
		}
	},

	requiredFlagCodeString(displayName, key) {
		return {
			displayName: displayName,
			type: "string",
			toFieldValue(objType, obj) {
				return getObjKey(objType, obj, key)
			},
			toUpdateDocument(objType, obj, doc, val) {
				setObjKey(objType, doc, key, val)
			},
			validate(objType, obj, val) {
				if (val){
					let configArray = val.split(';');
					if (configArray.length == 0){
						return {
							valid: false,
							errMsg: "Value contains incorrect number of parts. Format is: 'flagcode;' Split each configuration with a semi-colon. You can have multiples."
						}
					}else{
						return { valid: true }
					}
				}else {
					return {
						valid: false,
						errMsg: "Value must be a non-blank string"
					}
				}
			}
		}
	},

	requiredValidityConfigWindowString(displayName, key) {
		return {
			displayName: displayName,
			type: "string",
			toFieldValue(objType, obj) {
				return getObjKey(objType, obj, key)
			},
			toUpdateDocument(objType, obj, doc, val) {
				setObjKey(objType, doc, key, val)
			},
			validate(objType, obj, val) {
				if (val){
					let configArray = val.split(';');
					let lengthNotEqualSix = false;
					let valuesNotValid = false;
					let configArrayCounter = 1;
					var ruleNotValid = false;
					configArray.forEach((config) => {
						let values = config.split(',');
						if (configArray.length > configArrayCounter){
							if (values.length != 6){
								lengthNotEqualSix = true
							}
							if (!values[0] || !values[1]){
								valuesNotValid = true
							}
							let allowed_values = ['exact_agreement_by_response','exact_adjacent_by_response','exact_agreement_by_trait','exact_adjacent_by_trait','omaha']
							if (!allowed_values.includes(values[0])){
								ruleNotValid = true
							}
						}
						configArrayCounter = configArrayCounter + 1
					})
					if (lengthNotEqualSix){
						return {
							valid: false,
							errMsg: "Value contains incorrect number of parts. Format is: 'rule,pass_percent,trait_pass_percent,omaha_min_max,omaha_max_non_adj,omaha_max_weighted_score_diff;' Split each configuration with a semi-colon. You can have multiples." +
							`<br>
							<br>
							supported rules: <br>
							"exact_agreement_by_response<br>"
							"exact_agreement_by_trait<br>"
							"exact_adjacent_by_response<br>"
							"exact_adjacent_by_trait<br>"
							"omaha"`
						}
					}else if (valuesNotValid){
						return {
							valid: false,
							errMsg: "Value must be a non-blank string. Format is: 'rule,pass_percent,trait_pass_percent,omaha_min_max,omaha_max_non_adj,omaha_max_weighted_score_diff;' Split each configuration with a semi-colon. You can have multiples." +
							`<br>
							<br>
							supported rules: <br>
							"exact_agreement_by_response<br>"
							"exact_agreement_by_trait<br>"
							"exact_adjacent_by_response<br>"
							"exact_adjacent_by_trait<br>"
							"omaha"`
						}
					}else if (ruleNotValid){
						return {
							valid: false,
							errMsg: "Allowed Rules: exact_agreement_by_response, exact_adjacent_by_response, exact_agreement_by_trait, exact_adjacent_by_trait, omaha"
						}
					}else{
						return { valid: true }
					}
				}else {
					return {
						valid: false,
						errMsg: "Value must be a non-blank string"
					}
				}
			}
		}
	},

	requiredQualityConfigRequirementsString(displayName, key) {
		return {
			displayName: displayName,
			type: "string",
			toFieldValue(objType, obj) {
				return getObjKey(objType, obj, key)
			},
			toUpdateDocument(objType, obj, doc, val) {
				setObjKey(objType, doc, key, val)
			},
			validate(objType, obj, val) {
				if (val){
					let configArray = val.split(';');
					let lengthNotEqualThree = false
					let configArrayCounter = 0
					configArray.forEach((config) => {
						let values = config.split(',');
						if (config != ''){
							if (values.length != 3){
								lengthNotEqualThree = true
							}
						}
						configArrayCounter = configArrayCounter + 1
					})
					if (lengthNotEqualThree){
						return {
							valid: false,
							errMsg: "Value contains incorrect number of parts. Format is: 'type,requirement(integer or percent),trait_name;' Split each configuration with a semi-colon. You can have multiples."+
							`<br>
							<br>
							supported types: <br>
							"exact"<br>
							"exact_adj"<br>
							"trait_exact"<br>
							"trait_exact_adj"<br>
							"trait_both"<br>
							"max_discrepant"<br>
							"trait_max_discrepant"<br>
							"drop_set"<br>
							"keep_best_trait"<br>
							"trait_pass"`
		
						}
					}else{
						return { valid: true }
					}
				}else {
					return {
						valid: false,
						errMsg: "Value must be a non-blank string"
					}
				}
			}
		}
	},

	refId(displayName, key) {
		return {
			displayName: displayName,
			type: "string",
			toFieldValue(objType, obj) {
				return getObjKey(objType, obj, key)
			},
			toUpdateDocument(objType, obj, doc, val) {
				setObjKey(objType, doc, key, val)
			},
			validate(objType, obj, val) {
				if (!fs.isGoodRefID(val)) {
					return {
						valid: false,
						errMsg: "Value must be a valid Ref ID (no spaces or special characters)"
					}
				}
				return { valid: true }
			},
			enforceUnique: true
		}
	},

	boolean(displayName, key) {
		return {
			displayName: displayName,
			type: "boolean",
			toFieldValue(objType, obj) {
				return getObjKey(objType, obj, key) ? "true" : "false"
			},
			toUpdateDocument(objType, obj, doc, val) {
				if (val == "true") {
					setObjKey(objType, doc, key, true)
				} else if (val == "false") {
					setObjKey(objType, doc, key, false)
				} else {
					console.error("Unexpected value in generic boolean handler", val)
				}
			},
			validate(objType, obj, val) {
				if (!(val == "true" || val == "false")) {
					return {
						valid: false,
						errMsg: "Value must be \"true\" or \"false\"."
					}
				}

				return { valid: true }
			}
		}
	},

	boundedInteger(displayName, key, min, max) {
		return {
			displayName: displayName,
			type: "number",
			toFieldValue(objType, obj) {
				return getObjKey(objType, obj, key)
			},
			toUpdateDocument(objType, obj, doc, val) {
				let num = parseInt(val)
				if (num == NaN || num != val) {
					console.error("Unexpected value in generic integer handler", val)
				}
				setObjKey(objType, doc, key, num)
			},
			validate(objType, obj, val) {
				let num = parseInt(val)
				if (num == NaN || num != val || num < min || num > max) {
					return {
						valid: false,
						errMsg: `Value must be an integer between ${min} and ${max}`
					}
				}
				return { valid: true }
			}
		}
	},

	percent(displayName, key) {
		return this.boundedInteger(displayName, key, 0, 100)
	},

	lowerBoundedInteger(displayName, key, min) {
		return {
			displayName: displayName,
			type: "number",
			toFieldValue(objType, obj) {
				return getObjKey(objType, obj, key)
			},
			toUpdateDocument(objType, obj, doc, val) {
				let num = parseInt(val)
				if (num == NaN || num != val) {
					console.error("Unexpected value in generic integer handler", val)
				}
				setObjKey(objType, doc, key, num)
			},
			validate(objType, obj, val) {
				let num = parseInt(val)
				if (num == NaN || num != val || num < min) {
					return {
						valid: false,
						errMsg: `Value must be an integer ≥ ${min}`
					}
				}
				return { valid: true }
			}
		}
	},

	integer(displayName, key) {
		return {
			displayName: displayName,
			type: "number",
			toFieldValue(objType, obj) {
				return getObjKey(objType, obj, key)
			},
			toUpdateDocument(objType, obj, doc, val) {
				let num = parseInt(val)
				if (num == NaN || num != val) {
					console.error("Unexpected value in generic integer handler", val)
				}
				setObjKey(objType, doc, key, num)
			},
			validate(objType, obj, val) {
				let num = parseInt(val)
				if (num == NaN || num != val) {
					return {
						valid: false,
						errMsg: `Value must be an integer`
					}
				}
				return { valid: true }
			}
		}
	},

	number(displayName, key) {
		return {
			displayName: displayName,
			type: "number",
			toFieldValue(objType, obj) {
				return getObjKey(objType, obj, key)
			},
			toUpdateDocument(objType, obj, doc, val) {
				if (val == NaN) {
					console.error("Unexpected value in generic number handler", val)
				}
				setObjKey(objType, doc, key, val)
			},
			validate(objType, obj, val) {
				if (val == NaN) {
					return {
						valid: false,
						errMsg: `Value must be an number`
					}
				}
				return { valid: true }
			}
		}
	},

	list(displayName, key, options, idField = "id", displayField = "name") {
		return {
			displayName: displayName,
			type: "list",
			toFieldValue(objType, obj) {
				let val = getObjKey(objType, obj, key)
				let matchedOption = null
				for (let i = 0; i < options.length; i++) {
					let option = options[i]
					if (option[idField] == val) {
						matchedOption = option
						break
					}
				}

				if (matchedOption) {
					return matchedOption[displayField]
				}

				return `(NOT FOUND: "${val}")`
			},
			toUpdateDocument(objType, obj, doc, val) {
				if (val == null) {
					val = ""
				}
				let matchedOption = null
				for (let i = 0; i < options.length; i++) {
					let option = options[i]
					if (option[displayField] == val) {
						matchedOption = option
						setObjKey(objType, doc, key, option[idField])
						break
					}
				}

				if (!matchedOption) {
					console.error("List option not found", options, val)
				}
			},
			validate(objType, obj, val) {
				if (val == null) {
					val = ""
				}
				let matchedOption = null
				for (let i = 0; i < options.length; i++) {
					let option = options[i]
					if (option[displayField] == val) {
						matchedOption = option
						break
					}
				}

				if (!matchedOption) {
					let possibleValues = ""
					_.each(options, option => {
						let val = option[displayField]
						if (val == "") {
							possibleValues += "<li class=\"text-extra-muted\" style=\"list-style-type: '-  '\">(blank)</li>"
						} else {
							possibleValues += "<li>" + val + "</li>"
						}
					})
					return {
						valid: false,
						errMsg: `Value must be one of the following:<ul class="mb-0">${possibleValues}</ul>`
					}
				}

				return { valid: true }
			}
		}
	},

	rubric(displayName, key) {
		return {
			displayName: displayName,
			type: "reference",
			async init() {
				await ensureRubrics()
			},
			toFieldValue(objType, obj) {
				let val = getObjKey(objType, obj, key)
				let rubrics = cachedObjects.rubrics

				let matchedRubric = null
				for (let rubric of rubrics) {
					if (rubric.id == val) {
						matchedRubric = rubric
						break
					}
				}

				if (matchedRubric) {
					return matchedRubric.ref_id
				}

				return `(NOT FOUND: "${val}")`
			},
			toUpdateDocument(objType, obj, doc, val) {
				let rubrics = cachedObjects.rubrics

				let matchedRubric = null
				for (let rubric of rubrics) {
					if (rubric.ref_id == val) {
						matchedRubric = rubric
						setObjKey(objType, doc, key, rubric.id)
						break
					}
				}

				if (!matchedRubric) {
					console.error("Rubric not found", val)
				}
			},
			validate(objType, obj, val) {
				let rubrics = cachedObjects.rubrics

				let matchedRubric = null
				for (let rubric of rubrics) {
					if (rubric.ref_id == val) {
						matchedRubric = rubric
						break
					}
				}

				if (!matchedRubric) {
					if (val) {
						return {
							valid: false,
							errMsg: `Value must be the ref ID of an existing Rubric. \"${val}\" was not found.`
						}
					} else {
						return {
							valid: false,
							errMsg: `Value must be the ref ID of an existing Rubric.`
						}
					}
				}

				return { valid: true }
			}
		}
	},

	credential(displayName, key) {
		return {
			displayName: displayName,
			type: "reference",
			async init() {
				await ensureCredentials()
			},
			toFieldValue(objType, obj) {
				let val = getObjKey(objType, obj, key)
				let credentials = cachedObjects.credentials

				let matchedCredential = null
				for (let credential of credentials) {
					if (credential.id == val) {
						matchedCredential = credential
						break
					}
				}

				if (matchedCredential) {
					return matchedCredential.cred_id
				}

				return `(NOT FOUND: "${val}")`
			},
			toUpdateDocument(objType, obj, doc, val) {
				let credentials = cachedObjects.credentials

				let matchedCredential = null
				for (let credential of credentials) {
					if (credential.cred_id == val) {
						matchedCredential = credential
						setObjKey(objType, doc, key, credential.id)
						break
					}
				}

				if (!matchedCredential) {
					console.error("Credential not found", val)
				}
			},
			validate(objType, obj, val) {
				let credentials = cachedObjects.credentials

				let matchedCredential = null
				for (let credential of credentials) {
					if (credential.cred_id == val) {
						matchedCredential = credential
						break
					}
				}

				if (!matchedCredential) {
					if (val) {
						return {
							valid: false,
							errMsg: `Value must be the ref ID of an existing Credential. \"${val}\" was not found.`
						}
					} else {
						return {
							valid: false,
							errMsg: `Value must be the ref ID of an existing Credential.`
						}
					}
				}

				return { valid: true }
			}
		}
	},

	optionalCredential(displayName, key) {
		return {
			displayName: displayName,
			type: "reference",
			async init() {
				await ensureCredentials()
			},
			toFieldValue(objType, obj) {
				let val = getObjKey(objType, obj, key)
				if (val == null) {
					return null
				}
				let credentials = cachedObjects.credentials

				let matchedCredential = null
				for (let credential of credentials) {
					if (credential.id == val) {
						matchedCredential = credential
						break
					}
				}

				if (matchedCredential) {
					return matchedCredential.cred_id
				}

				return `(NOT FOUND: "${val}")`
			},
			toUpdateDocument(objType, obj, doc, val) {
				if (val == "" || val == null) {
					setObjKey(doc, key, null)
				}
				let credentials = cachedObjects.credentials

				let matchedCredential = null
				for (let credential of credentials) {
					if (credential.cred_id == val) {
						matchedCredential = credential
						setObjKey(objType, doc, key, credential.id)
						break
					}
				}

				if (!matchedCredential) {
					console.error("Credential not found", val)
				}
			},
			validate(objType, obj, val) {
				if (val == "" || val == null) {
					return { valid: true }
				}
				let credentials = cachedObjects.credentials

				let matchedCredential = null
				for (let credential of credentials) {
					if (credential.cred_id == val) {
						matchedCredential = credential
						break
					}
				}

				if (!matchedCredential) {
					return {
						valid: false,
						errMsg: `Value must be the ref ID of an existing Credential, or blank. \"${val}\" was not found.`
					}
				}

				return { valid: true }
			}
		}
	},

	flag(displayName, key) {
		return {
			displayName: displayName,
			type: "reference",
			async init() {
				await ensureFlags()
			},
			toFieldValue(objType, obj) {
				let val = getObjKey(objType, obj, key)
				let flags = cachedObjects.flags

				let matchedFlag = null
				for (let flag of flags) {
					if (flag.id == val) {
						matchedFlag = flag
						break
					}
				}

				if (matchedFlag) {
					return matchedFlag.code
				}

				return `(NOT FOUND: "${val}")`
			},
			toUpdateDocument(objType, obj, doc, val) {
				let flags = cachedObjects.flags

				let matchedFlag = null
				for (let flag of flags) {
					if (flag.code == val) {
						matchedFlag = flag
						setObjKey(objType, doc, key, flag.id)
						break
					}
				}

				if (!matchedFlag) {
					console.error("Flag not found", val)
				}
			},
			validate(objType, obj, val) {
				let flags = cachedObjects.flags

				let matchedFlag = null
				for (let flag of flags) {
					if (flag.code == val) {
						matchedFlag = flag
						break
					}
				}

				if (!matchedFlag) {
					if (val) {
						return {
							valid: false,
							errMsg: `Value must be the code of an existing Flag. \"${val}\" was not found.`
						}
					} else {
						return {
							valid: false,
							errMsg: `Value must be the code of an existing Flag.`
						}
					}
				}

				return { valid: true }
			}
		}
	},

	optionalFlag(displayName, key) {
		let handler = this.flag(displayName, key)
		let origToFieldValue = handler.toFieldValue
		handler.toFieldValue = function (objType, obj) {
			let val = getObjKey(objType, obj, key)
			if (val == "" || val == null) {
				return null
			} else {
				return origToFieldValue(objType, obj)
			}
		}
		let origToUpdateDocument = handler.toUpdateDocument
		handler.toUpdateDocument = function (objType, obj, doc, val) {
			if (val == "" || val == null) {
				setObjKey(objType, doc, key, null)
			} else {
				origToUpdateDocument(objType, obj, doc, val)
			}
		}
		let origValidate = handler.validate
		handler.validate = function (objType, obj, val) {
			if (val == "" || val == null) {
				return { valid: true }
			} else {
				return origValidate(objType, obj, val)
			}
		}
		return handler
	},

	scorerId(displayName, key) {
		return {
			displayName: displayName,
			type: "reference",
			async init() {
				await ensureUserScorerIDs()
			},
			toFieldValue(objType, obj) {
				let val = getObjKey(objType, obj, key)
				let users = cachedObjects.scorer_id_users

				let matchedUser = null
				for (let user of users) {
					if (user.id == val) {
						matchedUser = user
						break
					}
				}

				if (matchedUser) {
					return matchedUser.scorer_id
				}

				return `(NOT FOUND: "${val}")`
			},
			toUpdateDocument(objType, obj, doc, val) {
				let users = cachedObjects.scorer_id_users

				let matchedUser = null
				for (let user of users) {
					if (user.scorer_id == val) {
						matchedUser = user
						setObjKey(objType, doc, key, user.id)
						break
					}
				}

				if (!matchedUser) {
					console.error("Scorer ID not found", val)
				}
			},
			validate(objType, obj, val) {
				let users = cachedObjects.scorer_id_users

				let matchedUser = null
				for (let user of users) {
					if (user.scorer_id == val) {
						matchedUser = user
						break
					}
				}

				if (!matchedUser) {
					if (val) {
						return {
							valid: false,
							errMsg: `Value must be the scorer ID of an existing User. \"${val}\" was not found.`
						}
					} else {
						return {
							valid: false,
							errMsg: `Value must be the scorer ID of an existing User.`
						}
					}
				}

				return { valid: true }
			}
		}
	},

	optionalScorerId(displayName, key) {
		let handler = this.scorerId(displayName, key)
		let origToFieldValue = handler.toFieldValue
		handler.toFieldValue = function (objType, obj) {
			let val = getObjKey(objType, obj, key)
			if (val == "" || val == null || val == BlankObjectId) {
				return null
			} else {
				return origToFieldValue(objType, obj)
			}
		}
		let origToUpdateDocument = handler.toUpdateDocument
		handler.toUpdateDocument = function (objType, obj, doc, val) {
			if (val == "" || val == null || BlankObjectId) {
				setObjKey(objType, doc, key, BlankObjectId)
			} else {
				origToUpdateDocument(objType, obj, doc, val)
			}
		}
		let origValidate = handler.validate
		handler.validate = function (objType, obj, val) {
			if (val == "" || val == null || val == BlankObjectId) {
				return { valid: true }
			} else {
				return origValidate(objType, obj, val)
			}
		}
		return handler
	},
}
var gh = genericHandlers

export default {
	clearObjectCache(cacheKey) {
		if (!cacheKey) {
			cachedObjects = {}
		} else {
			delete cachedObjects[cacheKey]
		}
	},

	getHandler(collection, field) {
		return this.getColumnHandlers()[`${collection}.${field}`]
	},

	columnHandlers: null,

	// This list of handlers needs to be constructed in a function rather than 
	// defined in a base level object because it calls for FormatService lists that depend
	// on the $i18n object on the vm to be initialized, and that is not the case yet
	// when a base level object is defined.
	getColumnHandlers() {
		if (!this.columnHandlers) {
			this.columnHandlers = {
				// ---- Item Details ----
				"items.name": gh.requiredString("Name", "name"),
				"items.ref_id": gh.refId("Ref ID", "ref_id"),
				"items.description": gh.string("Description", "description"),
				"items.active": gh.boolean("Active", "active"),
				// -- Scoring Setup ----
				"items.rubric_id": gh.rubric("Rubric", "rubric_id"),
				"items.type": gh.list("Resolution Type", "type", fs.itemTypes()),
				"items.weighted_resolution_threshold": gh.integer("Weighted Resolution - Max Weight Diff.", "weighted_resolution_threshold"),
				"items.weighted_resolution_min": gh.integer("Weighted Resolution - Range Min", "weighted_resolution_range_min"),
				"items.weighted_resolution_max": gh.integer("Weighted Resolution - Range Max", "weighted_resolution_range_max"),
				"items.resolve_zeros": gh.boolean("Resolve Zeros", "resolve_zeros"),
				"items.backread_to_resolution": gh.boolean("Scoring Option - Backread to Resolution", "backread_to_resolution"),
				"items.allow_manual_reliability": gh.boolean("Scoring Option - Allow Manual Reliability", "allow_manual_reliability"),
				// Blank scoring
				"items.blank_scoring_config.enabled": gh.boolean("Blank Scoring - enabled", "blank_scoring_config.enabled"),
				"items.blank_scoring_config.action": gh.requiredBlankScoringActionString("Blank Scoring - action. Allowed values are withhold or apply_score.", "blank_scoring_config.action"),
				"items.blank_scoring_config.system_first_scorer_id": gh.string("Blank Scoring - first scorer id", "blank_scoring_config.system_first_scorer_id"),
				"items.blank_scoring_config.system_reliability_scorer_id": gh.string("Blank Scoring - reliability scorer id", "blank_scoring_config.system_reliability_scorer_id"),
				"items.blank_scoring_config.alert_id": gh.string("Blank Scoring - flag code", "blank_scoring_config.alert_id"),
				"items.blank_scoring_config.ignore_reliability": gh.boolean("Blank Scoring - ignore reliability", "blank_scoring_config.ignore_reliability"),
				"items.stateless_reliability": gh.boolean("Individual Reliability", "stateless_reliability"),
				// Metadata reliability
				"items.metadata_reliability_enabled": gh.boolean("Metadata Reliability - enabled", "metadata_reliability_enabled"),
				"items.metadata_reliability_config": gh.requiredStringWithDelimeter("Metadata Reliability Config", "metadata_reliability_config"),
				// Recalculate reliability
				"items.reliability_percent": gh.percent("Reliability Percent", "reliability_percent"),
				"items.assigned_delivery": gh.boolean("Assigned Delivery", "assigned_delivery"),
				"items.deliver_either_order": gh.boolean("Assigned Delivery - Deliver in Either Order", "deliver_either_order"),
				"items.show_other_assigned_scorer": gh.boolean("Assigned Delivery - Show Other Assigned Scorer ID", "show_other_assigned_scorer"),
				"items.show_first_score": gh.boolean("Show First Score", "show_first_score"),
				"items.live_res": gh.boolean("Resolution in 1st/2nd Queue", "live_res"),
				"items.resolution_choose_score": gh.boolean("Choose to Keep Score", "resolution_choose_score"),
				"items.require_view_all": gh.boolean("Require to View Whole Response", "require_view_all"),
				"items.resolution_blind": gh.boolean("Blind Resolution", "resolution_blind"),
				"items.resolution_hide_users": gh.boolean("Hide User Info in Resolution", "resolution_hide_users"),
				"items.allow_manual_reliability": gh.boolean("Allow Manual Reliability", "allow_manual_reliability"),
				"items.adjudication_enabled": gh.boolean("Adjudication Enabled", "adjudication_enabled"),
				"items.adjudication_rule": gh.list("Adjudication Rule", "adjudication_rule", adjudicationRules, "id", "name"),
				"items.school_code_config.mode": gh.list("School Code - Behavior", "school_code_config.mode", fs.schoolCodeModes()),
				"items.school_code_config.first": gh.boolean("School Code - First", "school_code_config.first"),
				"items.school_code_config.second": gh.boolean("School Code - Second", "school_code_config.second"),
				"items.school_code_config.res": gh.boolean("School Code - Resolution", "school_code_config.res"),
				// --- Viewer Controls ----
				"items.zoom_enabled": gh.boolean("Zoom", "zoom_enabled"),
				"items.angle_enabled": gh.boolean("Angle Tool", "angle_enabled"),
				"items.ruler_enabled": gh.boolean("Ruler Tool", "ruler_enabled"),
				"items.ruler_dpi": gh.number("Ruler Tool - Pixels Per", "ruler_dpi"),
				"items.ruler_units": gh.list("Ruler Tool - Unit", "ruler_units", with0Option(fs.rulerUnitTypes())),
				"items.rotate_enabled": gh.boolean("Rotate", "rotate_enabled"),
				"items.invert_enabled": gh.boolean("Invert Colors", "invert_enabled"),
				"items.calc_enabled": gh.boolean("Calculator", "calc_enabled"),
				"items.notes_enabled": gh.boolean("Personal Notes", "notes_enabled"),
				"items.annotations_enabled": gh.boolean("Annotations", "annotations_enabled"),
				"items.playback_speed_enabled": gh.boolean("Playback Speed", "playback_speed_enabled"),
				"items.fit_to_content": gh.boolean("Fit Page to Content", "fit_to_content"),
				"items.show_timeout_timer": gh.boolean("Show Timeout Timer", "show_timeout_timer"),
				"items.hide_response_id": gh.boolean("Hide Response ID", "hide_response_id"),
				"items.prefetch_media": gh.boolean("Prefetch Media", "prefetch_media"),
				"items.use_mathjax": gh.boolean("Format Math Formulas", "use_mathjax"),
				"items.mathjax_delimiter": gh.string("Mathjax Delimiter", "mathjax_delimiter"),
				"items.clip_percent": gh.number("Clip % (from +top/-bottom)", "clip_percent"),
				"items.clip_v_percent": gh.number("Clip % (from +left/-right)", "clip_v_percent"),
				// ---- Resources ----
				// skip for now
				// ---- Quality Settings ----
				"items.uses_practice": gh.boolean("Practice Enabled", "uses_practice"),
				"items.validity_config.enabled": gh.boolean("Validity - Enabled", "validity_config.enabled"),
				"items.validity_config.calibration": gh.list("Validity - Lockout Behavior", "validity_config.calibration", fs.validityLockoutBehaviors()),
				"items.validity_config.uses_threshold": gh.list("Validity - Window Mode", "validity_config.uses_threshold", fs.validityWindowModes()),
				"items.validity_config.window": gh.lowerBoundedInteger("Validity - Window Size", "validity_config.window", 1),
				"items.validity_config.threshold": gh.lowerBoundedInteger("Validity - # Incorrect", "validity_config.threshold", 0),
				"items.validity_config.interval_low": gh.lowerBoundedInteger("Validity - Response Interval Low", "validity_config.interval_low", 0),
				// Could put custom logic for high > low here
				"items.validity_config.interval_high": gh.lowerBoundedInteger("Validity - Response Interval High", "validity_config.interval_high", 0),
				"items.validity_config.threshold_rule.rule": gh.list("Validity - Rule", "validity_config.threshold_rule.rule", withBlankOption(fs.qcRules())),
				"items.validity_config.threshold_rule.pass_percent": gh.percent("Validity - Pass Percent", "validity_config.threshold_rule.pass_percent"),
				"items.validity_config.threshold_rule.trait_pass_percent": gh.percent("Validity - Trait Pass Percent", "validity_config.threshold_rule.trait_pass_percent"),
				"items.validity_config.window_rules": gh.requiredValidityConfigWindowString("Validity - Window Rules", "validity_config.window_rules"),
				"items.qualification_config.enabled": gh.boolean("Qualification - Enabled", "qualification_config.enabled"),
				"items.qualification_config.must_see": gh.lowerBoundedInteger("Qualification - Must See", "qualification_config.must_see", 0),
				"items.qualification_config.must_pass": gh.lowerBoundedInteger("Qualification - Must Pass", "qualification_config.must_pass", 0),
				// Could put custom logic for must see > must pass here
				// Cross-set requirements
				"items.qualification_config.requirements": gh.requiredQualityConfigRequirementsString("Qualification - Cross-set Requirements", "qualification_config.requirements", 0),
				"items.qualification_config.lock_on_fail": gh.boolean("Qualification - Lock On Fail", "qualification_config.lock_on_fail"),
				"items.calibration_config.enabled": gh.boolean("Calibration - Enabled", "calibration_config.enabled"),
				"items.calibration_config.continue_cal": gh.boolean("Calibration - Continue Calibration", "calibration_config.continue_cal"),
				"items.calibration_config.lockout": gh.boolean("Calibration - Lockout", "calibration_config.lockout"),
				"items.calibration_config.unlock_on_pass": gh.boolean("Calibration - Unlock To Pass", "calibration_config.unlock_on_pass"),
				// Rate exceptions
				// FD exceptions
				// ---- Quotas ----
				// ---- Integrations ----
				"items.learnosity_config.sync_enabled": gh.boolean("ADAM - Enabled", "learnosity_config.sync_enabled"),
				"items.learnosity_config.learnosity_activity_id": gh.string("ADAM - Activity ID", "learnosity_config.learnosity_activity_id"),
				"items.learnosity_config.learnosity_item_ref": gh.string("ADAM - Item Reference", "learnosity_config.learnosity_item_ref"),
				"items.learnosity_config.organisation_id": gh.integer("ADAM - Organisation Id", "learnosity_config.organisation_id"),
				"items.learnosity_config.section_ref_id": gh.string("ADAM - Section Ref ID", "learnosity_config.section_ref_id"),
				"items.learnosity_config.learnosity_question_ref": gh.string("ADAM - Optional Question Ref", "learnosity_config.learnosity_question_ref"),
				"items.learnosity_config.filter": gh.string("ADAM - Response Filter", "learnosity_config.filter"),
				"items.learnosity_config.render_item": gh.boolean("ADAM - Render Item", "learnosity_config.render_item"),
				"items.writeshift_config.enabled": gh.boolean("Writeshift - Enabled", "writeshift_config.enabled"),
				"items.writeshift_config.prompt_code": gh.string("Writeshift - Prompt ID", "writeshift_config.prompt_code"),
				"items.writeshift_config.system_user": gh.string("Writeshift - AI Scorer ID", "writeshift_config.system_user"),
				"items.writeshift_config.credential_id": gh.optionalCredential("Writeshift - Credential", "writeshift_config.credential_id"),
				"items.writeshift_config.work_keys": gh.boolean("Writeshift - WorkKeys", "writeshift_config.work_keys"),
				"items.writeshift_config.scoring_type": gh.list("Writeshift - AI Score Type", "writeshift_config.scoring_type", withBlankOption(fs.aiScoringTypes())),
				"items.writeshift_config.percent": gh.percent("Writeshift - Percentage", "writeshift_config.percent"),
				"items.writeshift_config.holistic_only": gh.boolean("Writeshift - Holistic Only", "writeshift_config.holistic_only"),
				// Custom logic to prevent holistic_only unless rubric has "holistic" trait?
				// "items.writeshift_config.reroute_errors": gh.boolean("Writeshift - ", "writeshift_config.reroute_errors"),
				// Reroute to section/item
				"items.emma_config.enabled": gh.boolean("EMMA - Enabled", "emma_config.enabled"),
				"items.emma_config.prompt_code": gh.string("EMMA - Prompt ID", "emma_config.prompt_code"),
				"items.emma_config.system_user": gh.string("EMMA - AI Scorer ID", "emma_config.system_user"),
				"items.emma_config.url_override": gh.string("EMMA - URL Override", "emma_config.url_override"),
				"items.emma_config.scoring_type": gh.list("EMMA - AI Score Type", "emma_config.scoring_type", withBlankOption(fs.aiScoringTypes())),
				"items.emma_config.percent": gh.percent("EMMA - Percentage", "emma_config.percent"),
				"items.cf_config.admin_name": gh.string("IEA - Admin Name", "cf_config.admin_name"),
				"items.cf_config.item_id": gh.string("IEA - Item ID", "cf_config.item_id"),
				"items.cf_config.system_user_id": gh.optionalScorerId("IEA - System User", "cf_config.system_user_id"),
				"items.cf_config.catchall_flag_id": gh.optionalFlag("IEA - Catch-all Flag", "cf_config.catchall_flag_id"),
				"items.cf_config.score_passback": gh.boolean("IEA - Score Passback", "cf_config.score_passback"),
				"items.cf_config.conditions_as_scores": gh.boolean("IEA - Conditions as Scores", "cf_config.conditions_as_scores"),
				"items.cf_config.flag_lid": gh.boolean("IEA - Flag LID", "cf_config.flag_lid"),
				"items.cf_config.lid_flag_id": gh.optionalFlag("IEA - Catch-all Flag", "cf_config.lid_flag_id"),
				"items.cf_config.ignore_score": gh.boolean("IEA - Ignore Score", "cf_config.ignore_score"),
				"items.api_export_config.enabled": gh.boolean("Scores API - Enabled", "api_export_config.enabled"),
				"items.api_export_config.url": gh.string("Scores API - Endpoint", "api_export_config.url"),
				"items.api_export_config.loopback": gh.boolean("Scores API - Loopback", "api_export_config.loopback"),
				"items.api_export_config.type": gh.list("Scores API - Endpoint Type", "api_export_config.type", fs.endpointTypes()),
				"items.api_export_config.auth_url": gh.string("Scores API - Auth URL", "api_export_config.auth_url"),
				"items.api_export_config.credential_id": gh.optionalCredential("Scores API - Credential", "api_export_config.credential_id"),
				"items.api_export_config.only_last_score": gh.boolean("Scores API - Last Score Only", "api_export_config.only_last_score"),
				"items.api_export_config.use_last_score_final_score": gh.boolean("Scores API - Last Score Final Score", "api_export_config.use_last_score_final_score"),
				"items.api_export_config.include_media": gh.boolean("Scores API - Include Media", "api_export_config.include_media"),
				"items.api_export_config.trait_score_final_score": gh.boolean("Scores API - Calculate final score by trait", "api_export_config.trait_score_final_score"),
				"items.api_export_config.include_blank_score_flag": gh.boolean("Scores API - Include Blank Score Flag", "api_export_config.include_blank_score_flag"),
				"items.api_export_config.final_score_type": gh.list("Scores API - Final Score Rule", "api_export_config.final_score_type", withBlankOption(fs.finalScoreTypes())),
				"items.api_export_config.auto_deliver_enabled": gh.boolean("Scores API - Auto Deliver", "api_export_config.auto_deliver_enabled"),
				"items.api_export_config.auto_deliver_type": gh.list("Scores API - Delivery Type", "api_export_config.auto_deliver_type", with0Option(fs.autoDeliverTypes())),
				"items.api_export_config.delay_hours": gh.lowerBoundedInteger("Scores API - Delivery Delay (hours)", "api_export_config.delay_hours", 0),
				"items.api_export_config.complete_subsequent": gh.boolean("Scores API Export On - Reach Complete Subsequent Times", "api_export_config.complete_subsequent"),
				"items.api_export_config.complete_backread": gh.boolean("Scores API Export On - Backread Score Is Applied", "api_export_config.complete_backread"),
				"items.api_export_config.complete_rescore": gh.boolean("Scores API Export On - Rescore Is Applied", "api_export_config.complete_rescore"),
				"items.alert_ids": gh.requiredFlagCodeString("Flag Codes", "alert_ids"),
			}
		}
		return this.columnHandlers
	},

	shouldProtectField(collection, obj, column) {
		let collectionOptions = this.collectionOptions()
		let colOpt = _.find(collectionOptions, { id: collection })
		if (!colOpt) {
			console.error(`Failed to find collection named "${collection}"`)
			return
		}
		return colOpt.protectField(obj, column)
	},

	collectionOptions() {
		return [
			{
				id: "items",
				name: "Items",
				requiredFields: [
					"ref_id",
				],
				requiredFieldsForNew: [
					"ref_id",
					"rubric_id",
					"type"
				],
				protectField(obj, key) {
					if (!obj.isScored) {
						return false
					}
					return [
						"ref_id",
						"rubric_id"
					].includes(key)
				}
			},
			// {
			// 	id: "sections",
			// 	name: "Sections",
			// 	requiredFields: [
			// 		"ref_id",
			// 	],
			// 	requiredFieldsForNew: [
			// 		"ref_id",
			// 		"rubric_id",
			// 		"type"
			// 	],
			// 	protectField(obj, key) {
			// 		if (!obj.isScored) {
			// 			return false
			// 		}
			// 		return [
			// 			"rubric_id"
			// 		].includes(key)
			// 	}
			// },
			// {
			// 	id: "projects",
			// 	name: "Projects",
			// 	requiredFields: [
			// 		"ref_id",
			// 	],
			// 	requiredFieldsForNew: [
			// 		"ref_id",
			// 		"rubric_id",
			// 		"type"
			// 	],
			// 	protectField(obj, key) {
			// 		if (!obj.isScored) {
			// 			return false
			// 		}
			// 		return [
			// 			"rubric_id"
			// 		].includes(key)
			// 	}
			// },
		]
	},

	async getRubrics(){
		let resp = await RubricService.listAllRubrics()
		let rubrics = resp.data.rubrics
		return rubrics
	},

	getColumnOptions(collection) {
		console.log("getColumnOptions", collection)
		let options = []
		let collectionDef = _.find(this.collectionOptions(), { id: collection })
		if (!collectionDef) {
			return [{ id: "unknown", name: "UNKNOWN" }]
		}
		for (let key of Object.keys(this.getColumnHandlers())) {
			let keyParts = key.split(".")
			let collectionKey = keyParts[0]
			let columnKey = keyParts.slice(1).join(".")
			if (collectionKey == collection) {
				let details = this.getColumnHandlers()[key]
				let newOption = {
					name: details.displayName,
					type: details.type,
					id: columnKey,
				}
				if (collectionDef.requiredFields.includes(columnKey)) {
					newOption.required = true
				}
				if (collectionDef.requiredFieldsForNew.includes(columnKey)) {
					newOption.requiredForNew = true
				}
				options.push(newOption)
			}
		}
		return options
	}
}