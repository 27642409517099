<template>
	<div class="px-2">
		<div v-if="pages.length == 0" class="resource loader" :style="{ color: themeColor + ' !important' }">
			<p class="respText">
				<loading type="icon" />
			</p>
		</div>

		<div v-for="page in pages" :key="page.id">
			<PDFResource v-if="page.type == 'pdf'" :page="page"></PDFResource>
			<VideoMedia v-if="page.type == 'video'" :page="page"></VideoMedia>
			<AudioMedia v-if="page.type == 'audio'" :page="page"></AudioMedia>
			<ImageMedia v-if="page.type == 'image'" :page="page"></ImageMedia>
			<IframeMedia
				v-if="page.type == 'html'"
				:page="page"
				:resource="true"
				:trainingElement="trainingElement"
				:directRender="directRenderIFrame"
			></IframeMedia>
			<ExcelMedia
				v-if="page.type == 'excel' || page.type == 'csv'"
				:page="page"
				:key="page.id"
				resizeContainer="resources-container"
				:xPadding="56"
				:yPadding="80"
			></ExcelMedia>
			<BrightcoveMedia v-if="page.type == 'brightcove'" :page="page"></BrightcoveMedia>
			<div v-if="page.blank" ref="resource" class="resource">
				<h5 class="text-center" style="padding-top: 80px">
					<div>
						<span :style="{ color: themeColor + ' !important' }">OSCAR™</span> does not currently support
						file type
					</div>
					<div>'{{ mut_resource.mime_type }}'</div>
					<br />
					<div>{{ mut_resource.name }}</div>
					<a class="external-link" @click="openResource">
						Open in New Window
						<i class="far fa-external-link fa-fw"></i>
					</a>
				</h5>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import ResponseMediaService from "../../services/ResponseMediaService";
import ThemeService from "../../services/ThemeService";

import PDFResource from "@/components/viewer/PDFResource";
import AudioMedia from "@/components/viewer/AudioMedia";
import VideoMedia from "@/components/viewer/VideoMedia";
import ImageMedia from "@/components/viewer/ImageMedia";
import ExcelMedia from "@/components/viewer/ExcelMedia";
import IframeMedia from "@/components/viewer/IframeMedia";
import BrightcoveMedia from "@/components/viewer/BrightcoveMedia";

export default {
	name: "Resource",
	props: ["resource", "item", "trainingCourse", "trainingElement", "directRenderIFrame"],
	components: { PDFResource, VideoMedia, ImageMedia, AudioMedia, ExcelMedia, IframeMedia, BrightcoveMedia },
	data() {
		return {
			loaded: false,
			mut_resource: null,
			pages: [],
			themeColor: ThemeService.getThemeColorHex(),
			pdfObj: null,
		};
	},

	watch: {
		resource: {
			handler: function (newVal) {
				this.mut_resource = _.cloneDeep(newVal);
				this.pages = [];
				this.setup();
			},
			deep: true,
			immediate: true,
		},
	},

	created() {
		// console.log(this.mresponse);
		if (!this.chan) {
			this.newChan = new Vue();
		}
	},

	destroyed() {
		if (this.pdfObj) {
			console.log("Destroy PDF resource");
			this.pdfObj.cleanup();
			this.pdfObj.destroy();
			this.pdfObj = null;
		}
	},

	methods: {
		setup() {
			//Destroy any existing PDF object
			if (this.pdfObj) {
				console.log("Destroy PDF resource");
				this.pdfObj.cleanup();
				this.pdfObj.destroy();
				this.pdfObj = null;
			}

			var now = new Date().getTime();
			if (this.mut_resource) {
				let call = null;
				if (this.item) {
					call = ResponseMediaService.setupResource(this.mut_resource, this.item);
				} else if (this.trainingCourse && this.trainingElement) {
					call = ResponseMediaService.setupTrainingResource(
						this.mut_resource,
						this.trainingCourse,
						this.trainingElement
					);
				} else {
					console.error("Resource requires either item or trainingCourse & trainingElement");
				}
				call.then((result) => {
					console.log("RESOURCE RESULT", result);
					this.pages = result.pages.map((p) => {
						p.id = Math.random().toString(36).substr(2, 9);
						p.start = now;
						return p;
					});
					console.log("Render time:" + (new Date().getTime() - now));
					this.$emit("setup", true);
					this.$forceUpdate();

					this.pdfObj = result.pdf;
				});
			}
		},

		openResource() {
			this.$emit("open", this.resource);
		},
	},
};
</script>

<style >
.resource {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;

	min-width: 400px;
	max-width: 800px;
	font-size: 18px;

	/*padding:80px;*/
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
}
.resource::before {
	content: "";
	float: left;
	width: 1px;
	margin-left: -1px;
	padding-top: 125%;
}
.resource::after {
	content: "";
	display: table;
	clear: both;
}

.loader {
	font-size: 128px;
	text-align: center;
}

.resource:last-of-type {
	margin-bottom: 50px;
}

.respText {
	white-space: pre-wrap; /* css-3 */
	word-wrap: break-word; /* Internet Explorer 5.5+ */
	word-break: normal;
	padding: 80px;
}

.resourceSpinner {
	height: 200px;
	width: 200px;
	margin-left: 300px;
}

a.external-link {
	opacity: 0.7;
	transition: opacity 0.25s;
	text-decoration: underline !important;
}
a.external-link:hover {
	opacity: 1;
}
</style>
