/**
 * FileService
 *
 * Service used to construct and export/download files and reports
 *
 *
 */

import pdfMake from "pdfmake/build/pdfmake.min.js";
import vfs_fonts from "pdfmake/build/vfs_fonts.js";
import fs from "@/services/FormatService";
import moment from "moment";
pdfMake.vfs = vfs_fonts.pdfMake.vfs;
import _ from "lodash";
import { idToName, QC_TYPES, QC_STATES } from "@/services/Constants";

export default {
	completionReportPDF(project, user, pcr, showAwaitingRes) {
		console.log(project, user);
		var docDefinition = {
			pageOrientation: "landscape",
			info: {
				title: "Completion Report",
			},
			styles: {
				all: {
					fontSize: 8,
				},
				datacol: {
					alignment: "right",
				},
			},
			content: [
				{
					text:
						"Completion Report (" +
						user.client.name +
						")            Generated: " +
						moment().format("dddd, MMMM Do YYYY, h:mm:ss a"),
					bold: true,
					style: "all",
				},
				{
					layout: "lightHorizontalLines", // optional
					style: "all",
					table: {
						// headers are automatically repeated if the table spans over multiple pages
						// you can declare how many rows should be treated as headers
						headerRows: 2,
						widths: [
							"*",
							"auto",
							"auto",
							"auto",
							"auto",
							"auto",
							"auto",
							"auto",
							"auto",
							"auto",
							"auto",
							"auto",
							"auto",
							"auto",
						],

						body: [
							[
								"",
								"Total",
								"Unscored",
								"Withheld",
								"Reliability",
								"Resolution",
								"Flagged",
								"Flags Reviewed",
								"Complete",
								"Backread",
								"Res. Reqd.",
								"Appeals",
								"Rescore",
								"Invalidated",
							],
						],
					},
				},
			],
		};

		if (showAwaitingRes) {
			docDefinition.content[1].table.widths.splice(6, 0, "auto");
			docDefinition.content[1].table.body[0].splice(6, 0, "Awaiting Resolution");
		}

		function datacol(text) {
			return { text: text, style: "datacol" };
		}
		//Totals
		var arr = [{ text: "Totals", bold: true }];
		arr.push(datacol(project.totals.total));
		arr.push(datacol(project.totals.unscored));
		arr.push(datacol(project.totals.withheld));
		arr.push(datacol(project.totals.reliability));
		if (showAwaitingRes) {
			arr.push(datacol(project.totals.awaiting));
		}
		arr.push(datacol(project.totals.resolution));
		arr.push(datacol(project.totals.flagged));
		arr.push(
			datacol(
				project.totals.flagged_reviewed +
					"(" +
					pcr(project.totals.flagged_reviewed, project.totals.flagged) +
					"%)"
			)
		);
		arr.push(datacol(project.totals.complete + "(" + pcr(project.totals.complete, project.totals.total) + "%)"));
		arr.push(datacol(project.totals.backread + "(" + pcr(project.totals.backread, project.totals.complete) + "%)"));
		arr.push(
			datacol(
				project.totals.resolution_required +
					"(" +
					pcr(project.totals.resolution_required, project.totals.complete + project.totals.resolution) +
					"%)"
			)
		);
		arr.push(datacol(project.totals.appeals));
		arr.push(datacol(project.totals.rescores));
		arr.push(datacol(project.totals.invalidated));
		docDefinition.content[1].table.body.push(arr);

		_.each(project.sections, (section) => {
			if (showAwaitingRes) {
				docDefinition.content[1].table.body.push([
					{ text: section.name, color: "grey", bold: true },
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
				]);
			} else {
				docDefinition.content[1].table.body.push([
					{ text: section.name, color: "grey" },
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
				]);
			}

			_.each(section.items, (item) => {
				var arr = [];
				arr.push(item.name);
				arr.push(datacol(item.total));
				arr.push(datacol(item.unscored));
				arr.push(datacol(item.withheld));
				arr.push(datacol(item.reliability));
				if (showAwaitingRes) {
					arr.push(datacol(item.awaiting));
				}
				arr.push(datacol(item.resolution));
				arr.push(datacol(item.flagged));
				arr.push(datacol(item.flagged_reviewed + "(" + pcr(item.flagged_reviewed, item.flagged) + "%)"));
				arr.push(datacol(item.complete + "(" + pcr(item.complete, item.total) + "%)"));
				arr.push(datacol(item.backread + "(" + pcr(item.backread, item.complete) + "%)"));
				arr.push(
					datacol(
						item.resolution_required +
							"(" +
							pcr(item.resolution_required, item.complete + item.resolution) +
							"%)"
					)
				);
				arr.push(datacol(item.appeals));
				arr.push(datacol(item.rescores));
				arr.push(datacol(item.invalidated));
				docDefinition.content[1].table.body.push(arr);
			});
		});

		this.exportPDF(docDefinition, `ComplRpt ${user.client.name} ${project.name}`);
	},

	completionReportCSV(project, user, pcr, showAwaitingRes) {
		var filenameFields = ["ComplRpt", user.client.name, project.name, moment().format("M.D.YY-H.mm.ss")];
		var rows = [
			[
				"Section",
				"Item",
				"Total",
				"Unscored",
				"Withheld",
				"Reliability",
				"Resolution",
				"Flagged",
				"Flags Reviewed",
				"Flags Reviewed %",
				"Complete",
				"Complete %",
				"Backread",
				"Backread %",
				"Resolution Required",
				"Resolution Required %",
				"Appeals",
				"Rescore Requested",
				"Invalidated",
			],
		];

		if (showAwaitingRes) {
			rows[0].splice(6, 0, "Awaiting Resolution");
		}

		_.each(project.sections, (section) => {
			_.each(section.items, (item) => {
				var row = [];
				row.push(section.name);
				row.push(item.name);
				row.push(item.total);
				row.push(item.unscored);
				row.push(item.withheld);
				row.push(item.reliability);
				if (showAwaitingRes) {
					row.push(item.awaiting);
				}
				row.push(item.resolution);
				row.push(item.flagged);
				row.push(item.flagged_reviewed);
				row.push(pcr(item.flagged_reviewed, item.flagged));
				row.push(item.complete);
				row.push(pcr(item.complete, item.total));
				row.push(item.backread);
				row.push(pcr(item.backread, item.complete));
				row.push(item.resolution_required);
				row.push(pcr(item.resolution_required, item.complete + item.resolution));
				row.push(item.appeals);
				row.push(item.rescores);
				row.push(item.invalidated);

				rows.push(row);
			});
		});

		var totals = [];
		totals.push("Totals");
		totals.push("Totals");
		totals.push(project.totals.total);
		totals.push(project.totals.unscored);
		totals.push(project.totals.withheld);
		totals.push(project.totals.reliability);
		if (showAwaitingRes) {
			totals.push(project.totals.awaiting);
		}
		totals.push(project.totals.resolution);
		totals.push(project.totals.flagged);
		totals.push(project.totals.flagged_reviewed);
		totals.push(pcr(project.totals.flagged_reviewed, project.totals.flagged));
		totals.push(project.totals.complete);
		totals.push(pcr(project.totals.complete, project.totals.total));
		totals.push(project.totals.backread);
		totals.push(pcr(project.totals.backread, project.totals.complete));
		totals.push(project.totals.resolution_required);
		totals.push(pcr(project.totals.resolution_required, project.totals.complete + project.totals.resolution));
		totals.push(project.totals.appeals);
		totals.push(project.totals.rescores);
		totals.push(project.totals.invalidated);

		rows.push(totals);

		this.exportCSV(rows, filenameFields);
	},

	scorersReportPDF(vue) {
		var col1 = "";
		col1 += "Scorer Report (" + vue.user.client.name + ")\n";
		if (vue.selectedProject) {
			col1 += "Project :" + vue.selectedProject.name + "\n";
		}
		if (vue.selectedSection) {
			col1 += "Section:" + vue.selectedSection.name + "\n";
		}
		if (vue.selectedItem) {
			col1 += "Item     :" + vue.selectedItem.name + "\n";
		}
		if (vue.selectedTeam) {
			col1 += "Team   :" + vue.selectedTeam.name + "\n";
		}

		var col2 = "";
		col2 += " Generated: " + moment().format("dddd, MMMM Do YYYY, h:mm:ss a") + "\n";
		if (vue.fromDate) {
			col2 += " From  : " + moment(vue.fromDate).startOf("day").format("dddd, MMMM Do YYYY, h:mm:ss a") + "\n";
		}
		if (vue.toDate) {
			col2 += " To       : " + moment(vue.toDate).endOf("day").format("dddd, MMMM Do YYYY, h:mm:ss a");
		}
		var columns = [];
		columns[0] = {
			width: "*",
			text: col1,
			bold: true,
		};
		columns[1] = {
			width: "auto",
			text: col2,
			bold: true,
		};

		var docDefinition = {
			pageOrientation: "landscape",
			info: {
				title: "Scorer Report",
			},
			styles: {
				all: {
					fontSize: 8,
				},
				datacol: {
					alignment: "right",
				},
			},
			content: [
				{
					columns: columns,
				},

				{
					layout: "lightHorizontalLines", // optional
					style: "all",
					table: {
						// headers are automatically repeated if the table spans over multiple pages
						// you can declare how many rows should be treated as headers
						headerRows: 2,
						widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],

						body: [
							[
								"Scorer",
								"Score Time",
								"Avg. Score Time",
								"Rate",
								"Scores",
								"Resolutions",
								"Validity",
								"Calibration",
								"Flags",
								"Total",
								"Res. Required",
								"Res. Required %",
								"Res. Disagreed",
								"Res. Disagreed %",
							],
						],
					},
				},
			],
		};
		function datacol(text) {
			return { text: text, style: "datacol" };
		}
		//Totals
		var arr = [{ text: "Totals", bold: true }];
		arr.push(datacol(fs.medDuration(vue.totalsRow.total_time)));
		arr.push(datacol(fs.medDuration(vue.totalsRow.avg_time)));
		arr.push(datacol(fs.fixed1d(3600 / vue.totalsRow.avg_time)));
		arr.push(datacol(vue.totalsRow.total));
		arr.push(datacol(vue.totalsRow.res_count));
		arr.push(datacol(vue.totalsRow.val_count));
		arr.push(datacol(vue.totalsRow.cal_count));
		arr.push(datacol(vue.totalsRow.flag_unscored_count + vue.totalsRow.flag_scored_count));
		arr.push(datacol(vue.totalsRow.sum));
		arr.push(datacol(vue.totalsRow.res_required_total));
		arr.push(datacol(fs.fixedPercent1d(vue.totalsRow.res_required_percent)));
		arr.push(datacol(vue.totalsRow.res_changed));
		arr.push(datacol(fs.fixedPercent1d(vue.totalsRow.res_changed_percent)));

		docDefinition.content[1].table.body.push(arr);

		_.each(vue.scorerStats, (stat) => {
			var arr = [];
			arr.push(fs.scorerID(stat.user));
			arr.push(datacol(fs.medDuration(stat.total_time)));
			arr.push(datacol(fs.medDuration(stat.avg_time)));
			arr.push(datacol(fs.fixed1d(3600 / stat.avg_time)));
			arr.push(datacol(stat.total));
			arr.push(datacol(stat.res_count));
			arr.push(datacol(stat.val_count));
			arr.push(datacol(stat.cal_count));
			arr.push(datacol(stat.flag_unscored_count + stat.flag_scored_count));
			arr.push(datacol(stat.sum));
			arr.push(datacol(stat.res_required_total));
			arr.push(datacol(fs.fixedPercent1d(stat.res_required_percent)));
			arr.push(datacol(stat.res_changed));
			arr.push(datacol(fs.fixedPercent1d(stat.res_changed_percent)));
			docDefinition.content[1].table.body.push(arr);
		});
		this.exportPDF(docDefinition, "ScorerRpt " + vue.user.client.name);
	},

	scorersReportCSV(vue) {
		var filenameFields = ["ScorerRpt", vue.user.client.name, vue.selectedProject.name];
		if (vue.selectedSection && vue.selectedSection.name != "All") {
			filenameFields.push(vue.selectedSection.name);
		}
		if (vue.selectedItem && vue.selectedItem.name != "All") {
			filenameFields.push(vue.selectedItem.name);
		}
		if (vue.selectedTeam && vue.selectedTeam.name != "All") {
			filenameFields.push(vue.selectedTeam.name);
		}
		if (vue.fromDate) {
			filenameFields.push("from_" + moment(vue.fromDate).format("M.D.YY"));
		}
		if (vue.toDate) {
			filenameFields.push("to_" + moment(vue.toDate).format("M.D.YY"));
		}
		filenameFields.push(moment().format("M.D.YY-H.mm.ss"));

		var header = [
			"Scorer",
			"Score Time",
			"Avg. Score Time",
			"Rate per hour",
			"Scores",
			"Resolutions",
			"Validity",
			"Calibration",
			"Flags",
			"Total",
			"Res. Required",
			"Res. Required %",
			"Res. Disagreed",
			"Res. Disagreed %",
		];

		var rows = [header];

		_.each(vue.scorerStats, (stat) => {
			var row = [];
			row.push(fs.scorerID(stat.user));
			row.push(fs.medDuration(stat.total_time));
			row.push(fs.medDuration(stat.avg_time));
			row.push(fs.fixed1d(3600 / stat.avg_time));
			row.push(stat.total);
			row.push(stat.res_count);
			row.push(stat.val_count);
			row.push(stat.cal_count);
			row.push(stat.flag_unscored_count + stat.flag_scored_count);
			row.push(stat.sum);
			row.push(stat.res_required_total);
			row.push(fs.fixedPercent1d(stat.res_required_percent));
			row.push(stat.res_changed);
			row.push(fs.fixedPercent1d(stat.res_changed_percent));

			rows.push(row);
		});

		var totals = [];
		totals.push("Totals");
		totals.push(fs.medDuration(vue.totalsRow.total_time));
		totals.push(fs.medDuration(vue.totalsRow.avg_time));
		totals.push(fs.fixed1d(3600 / vue.totalsRow.avg_time));
		totals.push(vue.totalsRow.total);
		totals.push(vue.totalsRow.res_count);
		totals.push(vue.totalsRow.val_count);
		totals.push(vue.totalsRow.cal_count);
		totals.push(vue.totalsRow.flag_unscored_count + vue.totalsRow.flag_scored_count);
		totals.push(vue.totalsRow.sum);
		totals.push(vue.totalsRow.res_required_total);
		totals.push(fs.fixedPercent1d(vue.totalsRow.res_required_percent));
		totals.push(vue.totalsRow.res_changed);
		totals.push(fs.fixedPercent1d(vue.totalsRow.res_changed_percent));

		rows.push(totals);

		this.exportCSV(rows, filenameFields);
	},

	payrollReportPDF(vue, sumPerUser) {
		var col1 = "";
		col1 += "Payroll Report (" + vue.user.client.name + ")\n";
		if (vue.selectedProject) {
			col1 += "Project :" + vue.selectedProject.name + "\n";
		}
		if (vue.selectedTeam) {
			col1 += "Team   :" + vue.selectedTeam.name + "\n";
		}

		var col2 = "";
		col2 += " Generated: " + moment().format("dddd, MMMM Do YYYY, h:mm:ss a") + "\n";
		if (vue.fromDate) {
			col2 += " From  : " + moment(vue.fromDate).startOf("day").format("dddd, MMMM Do YYYY, h:mm:ss a") + "\n";
		}
		if (vue.toDate) {
			col2 += " To       : " + moment(vue.toDate).endOf("day").format("dddd, MMMM Do YYYY, h:mm:ss a");
		}
		var columns = [];
		columns[0] = {
			width: "*",
			text: col1,
			bold: true,
		};
		columns[1] = {
			width: "auto",
			text: col2,
			bold: true,
		};

		var docDefinition = {
			pageOrientation: "landscape",
			info: {
				title: "Payroll Report",
			},
			styles: {
				all: {
					fontSize: 8,
				},
				datacol: {
					alignment: "right",
				},
			},
			content: [
				{
					columns: columns,
				},

				{
					layout: "lightHorizontalLines", // optional
					style: "all",
					table: {
						// headers are automatically repeated if the table spans over multiple pages
						// you can declare how many rows should be treated as headers
						headerRows: 2,
						widths: [],
						body: [],
					},
				},
			],
		};
		if (sumPerUser) {
			docDefinition.content[1].table.widths = ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"];
			docDefinition.content[1].table.body[0] = [
				"First Name",
				"Last Name",
				"Scorer ID",
				"Role",
				"Score Time",
				"Scores",
				"Resolution",
				"Backread",
				"Validity",
				"Calibration",
				"Flags",
				"Total",
			];
		} else {
			docDefinition.content[1].table.widths = [
				"*",
				"*",
				"*",
				"*",
				"*",
				"*",
				"*",
				"*",
				"*",
				"*",
				"*",
				"*",
				"*",
				"*",
			];
			docDefinition.content[1].table.body[0] = [
				"First Name",
				"Last Name",
				"Scorer ID",
				"Role",
				"Section",
				"Item",
				"Score Time",
				"Scores",
				"Resolution",
				"Backread",
				"Validity",
				"Calibration",
				"Flags",
				"Total",
			];
		}
		function datacol(text) {
			return { text: text, style: "datacol" };
		}
		//Totals
		var arr = [{ text: "Totals", bold: true }, "", "", ""];
		if (!sumPerUser) {
			arr.push("");
			arr.push("");
		}
		arr.push(datacol(fs.medDuration(vue.reportTotals.total_time)));
		arr.push(datacol(vue.reportTotals.total));
		arr.push(datacol(vue.reportTotals.res_count));
		arr.push(datacol(vue.reportTotals.br_count));
		arr.push(datacol(vue.reportTotals.val_count));
		arr.push(datacol(vue.reportTotals.cal_count));
		arr.push(datacol(vue.reportTotals.flag_unscored_count + vue.reportTotals.flag_scored_count));
		arr.push(datacol(vue.reportTotals.sum));

		docDefinition.content[1].table.body.push(arr);

		_.each(vue.scorerStats, (stat) => {
			var arr = [];
			arr.push(stat.user.first_name);
			arr.push(stat.user.last_name);
			arr.push(stat.user.scorer_id);
			arr.push(stat.role_name);
			if (!sumPerUser) {
				arr.push(stat.section_name);
				arr.push(stat.item_name);
			}
			arr.push(datacol(fs.medDuration(stat.total_time)));
			arr.push(datacol(stat.total));
			arr.push(datacol(stat.res_count));
			arr.push(datacol(stat.br_count));
			arr.push(datacol(stat.val_count));
			arr.push(datacol(stat.cal_count));
			arr.push(datacol(stat.flag_unscored_count + stat.flag_scored_count));
			arr.push(datacol(stat.sum));

			docDefinition.content[1].table.body.push(arr);
		});
		console.log(docDefinition.content[1].table);
		this.exportPDF(docDefinition, "PayrollRpt " + vue.user.client.name);
	},
	payrollReportPearsonCompCSV(vue) {
		var filenameFields = ["PayrollRpt", vue.user.client.name, vue.selectedProject.name];
		if (vue.selectedTeam && vue.selectedTeam.name != "All") {
			filenameFields.push(vue.selectedTeam.name);
		}
		if (vue.fromDate) {
			filenameFields.push("from_" + moment(vue.fromDate).format("M.D.YY"));
		}
		if (vue.toDate) {
			filenameFields.push("to_" + moment(vue.toDate).format("M.D.YY"));
		}

		var header = [
			"EmployeeNumber",
			"EmployeeRole",
			"ItemID",
			"ScoringTimeOnItem",
			"TrainingTimeOnItem",
			"OperationalReads",
			"ReviewUnits",
			"ResolutionUnits",
			"AdjudicationUnits",
			"BackreadUnits",
			"PracticeReads",
			"QualificationReads",
			"ValidityReads",
			"Validity",
			"CumulativeValidityAgreementPercent",
			"IRRPerfectAgreement",
			"IRRAdjacentAgreement",
		];
		var rows = [header];

		_.each(vue.reportStats, (stat) => {
			var row = [];
			row.push(stat.EmployeeNumber);
			row.push(stat.EmployeeRole);
			row.push(stat.ItemID);
			row.push(stat.ScoringTimeOnItem);
			row.push(stat.TrainingTimeOnItem);
			row.push(stat.OperationalReads);
			row.push(stat.ReviewUnits);
			row.push(stat.ResolutionUnits);
			row.push(stat.AdjudicationUnits);
			row.push(stat.BackreadUnits);
			row.push(stat.PracticeReads);
			row.push(stat.QualificationReads);
			row.push(stat.ValidityReads);
			row.push(stat.Validity);
			row.push(stat.CumulativeValidityAgreementPercent);
			row.push(stat.IRRPerfectAgreement);
			row.push(stat.IRRAdjacentAgreement);

			rows.push(row);
		});

		console.log(rows);

		this.exportCSV(rows, filenameFields);
	},

	payrollReportCSV(vue, sumPerUser) {
		var filenameFields = ["PayrollRpt", vue.user.client.name, vue.selectedProject.name];
		if (vue.selectedTeam && vue.selectedTeam.name != "All") {
			filenameFields.push(vue.selectedTeam.name);
		}
		if (vue.fromDate) {
			filenameFields.push("from_" + moment(vue.fromDate).format("M.D.YY"));
		}
		if (vue.toDate) {
			filenameFields.push("to_" + moment(vue.toDate).format("M.D.YY"));
		}
		filenameFields.push(moment().format("M.D.YY-H.mm.ss"));

		var header = ["First Name", "Last Name", "Scorer ID", "Role"];
		if (!sumPerUser) {
			header = header.concat("Section", "Item", "Tenant Ref ID", "Section Ref ID", "Item Ref ID");
		}
		header = header.concat([
			"Score Time",
			"Scores",
			"Resolution",
			"Backread",
			"Validity",
			"Calibration",
			"Flags",
			"Total",
		]);

		var rows = [header];

		_.each(vue.reportStats, (stat) => {
			var row = [];
			row.push(stat.user.first_name);
			row.push(stat.user.last_name);
			row.push(stat.user.scorer_id);
			row.push(stat.role_name);
			if (!sumPerUser) {
				row.push(stat.section_name);
				row.push(stat.item_name);
				row.push(vue.client.ref_id);
				row.push(stat.section_ref_id);
				row.push(stat.item_ref_id);
			}
			let total_time = stat.total_time ? fs.medDuration(stat.total_time) : "0:00:00";
			row.push(total_time);
			row.push(stat.total);
			row.push(stat.res_count);
			row.push(stat.br_count);
			row.push(stat.val_count);
			row.push(stat.cal_count);
			row.push(stat.flag_unscored_count + stat.flag_scored_count);
			row.push(stat.sum);

			rows.push(row);
		});

		console.log(rows);

		var totals = [];
		totals.push("Totals");
		totals.push("");
		totals.push("");
		totals.push("");
		if (!sumPerUser) {
			totals.push("");
			totals.push("");
			totals.push("");
			totals.push("");
			totals.push("");
		}
		totals.push(fs.medDuration(vue.reportTotals.total_time));
		totals.push(vue.reportTotals.total);
		totals.push(vue.reportTotals.res_count);
		totals.push(vue.reportTotals.br_count);
		totals.push(vue.reportTotals.val_count);
		totals.push(vue.reportTotals.cal_count);
		totals.push(vue.reportTotals.flag_unscored_count + vue.reportTotals.flag_scored_count);
		totals.push(vue.reportTotals.sum);

		rows.push(totals);

		this.exportCSV(rows, filenameFields);
	},

	dailyReportPDF(vue) {
		var col1 = "";
		col1 += "Daily Report (" + vue.user.client.name + ")\n";
		col1 += "Project :" + vue.selectedProject.name + "\n";
		col1 += "Section:" + vue.selectedSection.name + "\n";
		col1 += "Item     :" + vue.selectedItem.name + "\n";
		if (vue.selectedTeam) {
			col1 += "Team   :" + vue.selectedTeam.name + "\n";
		}
		col1 += "Trait   :" + vue.selectedTrait.name + "\n";

		var col2 = "";
		col2 += " Generated: " + moment().format("dddd, MMMM Do YYYY, h:mm:ss a") + "\n";
		if (vue.from_date) {
			col2 += " From  : " + moment(vue.from_date).startOf("day").format("dddd, MMMM Do YYYY, h:mm:ss a") + "\n";
		}
		if (vue.to_date) {
			col2 += " To       : " + moment(vue.to_date).endOf("day").format("dddd, MMMM Do YYYY, h:mm:ss a");
		}
		var columns = [];
		columns[0] = {
			width: "*",
			text: col1,
			bold: true,
		};
		columns[1] = {
			width: "auto",
			text: col2,
			bold: true,
		};

		//Generate the header
		var header = ["Scorer", "# Scores", "Has Other Score", "Avg Time"];
		_.each(vue.scorePoints, (sp) => {
			header.push(datacol(sp.val));
		});
		header = header.concat(["Exact", "Adjacent", "Adjacent High", "Adjacent Low", "Non Adjacent"]);

		var docDefinition = {
			pageOrientation: "landscape",
			info: {
				title: "Daily Report",
			},
			styles: {
				all: {
					fontSize: 10,
				},
				datacol: {
					alignment: "right",
				},
			},
			content: [
				{
					columns: columns,
				},

				{
					layout: "lightHorizontalLines", // optional
					style: "all",
					table: {
						// headers are automatically repeated if the table spans over multiple pages
						// you can declare how many rows should be treated as headers
						headerRows: 2,
						//widths: [ 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto' ],

						body: [header],
					},
				},
			],
		};
		function datacol(text) {
			return { text: text, style: "datacol" };
		}
		//Totals
		var arr = [{ text: "Totals", bold: true }];
		arr.push(datacol(vue.reportTotals.fd_total));
		arr.push(datacol(vue.reportTotals.adj_total));
		arr.push(datacol(fs.medDuration(vue.reportTotals.avg_time)));
		_.each(vue.scorePoints, (sp) => {
			var key = `fd_stat_${sp.val}`;
			var data = vue.reportTotals[key];
			arr.push(datacol(vue.pc(data.val)));
		});
		arr.push(datacol(vue.pc(vue.reportTotals.exact)));
		arr.push(datacol(vue.pc(vue.reportTotals.adjacent)));
		arr.push(datacol(vue.pc(vue.reportTotals.adjacent_high)));
		arr.push(datacol(vue.pc(vue.reportTotals.adjacent_low)));
		arr.push(datacol(vue.pc(vue.reportTotals.non_adjacent)));

		docDefinition.content[1].table.body.push(arr);

		_.each(vue.reportStats, (stat) => {
			if (!stat.fd_total) {
				return;
			}
			var arr = [];
			arr.push(stat.scorer);
			arr.push(datacol(stat.fd_total));
			arr.push(datacol(stat.adj_total));
			arr.push(datacol(fs.medDuration(stat.avg_time)));
			_.each(vue.scorePoints, (sp) => {
				var key = `fd_stat_${sp.val}`;
				var data = stat[key];
				var text = "";
				text += vue.pc(data.val) + "\n";
				text += data.offset;
				arr.push(datacol(text));
			});
			arr.push(datacol(vue.pc(stat.exact)));
			arr.push(datacol(vue.pc(stat.adjacent)));
			arr.push(datacol(vue.pc(stat.adjacent_high)));
			arr.push(datacol(vue.pc(stat.adjacent_low)));
			arr.push(datacol(vue.pc(stat.non_adjacent)));

			docDefinition.content[1].table.body.push(arr);
		});
		pdfMake.createPdf(docDefinition).download("DailyRpt " + vue.user.client.name);
	},

	dailyReportCSV(vue) {
		var filenameFields = [
			"DailyRpt",
			vue.user.client.name,
			vue.selectedProject.name,
			vue.selectedSection.name,
			vue.selectedItem.name,
		];
		if (vue.selectedTeam) {
			filenameFields.push(vue.selectedTeam.name);
		}
		filenameFields.push(vue.selectedTrait.name);

		if (vue.from_date) {
			filenameFields.push("from_" + moment(vue.from_date).format("M.D.YY"));
		}
		if (vue.to_date) {
			filenameFields.push("to_" + moment(vue.to_date).format("M.D.YY"));
		}
		filenameFields.push(moment().format("M.D.YY-H.mm.ss"));

		var header = ["Scorer", "# Scores", "Has Other Score", "Avg Time"];
		_.each(vue.scorePoints, (sp) => {
			header.push(sp.val + " - Freq");
			header.push(sp.val + " - Dev from Avg");
		});
		header = header.concat(["Exact", "Adjacent", "Adjacent High", "Adjacent Low", "Non Adjacent"]);

		var rows = [header];

		_.each(vue.reportStats, (stat) => {
			if (!stat.fd_total) {
				return;
			}
			var row = [];
			row.push(stat.scorer);
			row.push(stat.fd_total);
			row.push(stat.adj_total);
			row.push(fs.medDuration(stat.avg_time));
			_.each(vue.scorePoints, (sp) => {
				var key = `fd_stat_${sp.val}`;
				var data = stat[key];
				row.push(vue.pct(data.val));
				row.push(data.offset);
			});
			row.push(vue.pct(stat.exact));
			row.push(vue.pct(stat.adjacent));
			row.push(vue.pct(stat.adjacent_high));
			row.push(vue.pct(stat.adjacent_low));
			row.push(vue.pct(stat.non_adjacent));

			rows.push(row);
		});

		var totals = [];
		totals.push("Totals");
		totals.push(vue.reportTotals.fd_total); //Total scores
		totals.push(vue.reportTotals.adj_total); //Has other score
		totals.push(fs.medDuration(vue.reportTotals.avg_time)); //Average think time
		_.each(vue.scorePoints, (sp) => {
			var key = `fd_stat_${sp.val}`;
			var data = vue.reportTotals[key];
			totals.push(vue.pct(data.val)); //Average score frequency
			totals.push(0); //Deviation from average - always 0 because this is the average anyway
		});
		totals.push(vue.pct(vue.reportTotals.exact)); //Exact agreement
		totals.push(vue.pct(vue.reportTotals.adjacent)); //Adjacent scores
		totals.push(vue.pct(vue.reportTotals.adjacent_high)); //Adjacent high
		totals.push(vue.pct(vue.reportTotals.adjacent_low)); //Adjacent low (these should always be equal for totals, shouldn't they?)
		totals.push(vue.pct(vue.reportTotals.non_adjacent)); //Non-adjacent

		rows.push(totals);

		this.exportCSV(rows, filenameFields);
	},

	userAgreementReportPDF(vue, showTraits) {
		var stats = vue.reportStats;
		var totals = vue.reportTotals;
		var user = vue.user;
		var sectionName = vue.selectedSection.name;
		var itemName = vue.selectedItem.name;

		var docDefinition = {
			pageOrientation: "landscape",
			info: {
				title: "User Agreement Report",
			},
			styles: {
				all: {
					fontSize: 10,
				},
				datacol: {
					alignment: "right",
				},
			},
			content: [
				{
					text:
						"User Agreement Report (" +
						user.client.name +
						")\nSection: " +
						sectionName +
						"\nItem: " +
						itemName +
						"\nGenerated: " +
						moment().format("dddd, MMMM Do YYYY, h:mm:ss a"),
					bold: true,
					style: "all",
				},
				{
					layout: "lightHorizontalLines", // optional
					style: "all",
					table: {
						// headers are automatically repeated if the table spans over multiple pages
						// you can declare how many rows should be treated as headers
						headerRows: 2,
						widths: ["*", "*", "auto", "auto", "auto", "auto", "auto", "auto"],

						body: [
							[
								"User",
								"Trait",
								"IRR (exact)",
								"IRR (exact/adjacent)",
								"Validity (exact)",
								"Validity (exact/adjacent)",
								"Resolution Required",
								"Resolution Disagreed",
							],
						],
					},
				},
			],
		};
		function datacol(text) {
			console.log("datacol", text);
			return { text: text, style: "datacol" };
		}
		//Totals
		var arr = [{ text: "Totals", bold: true }, ""];
		arr.push(datacol(fs.fixedPercent1d(totals.irr_exact_percent)));
		arr.push(datacol(fs.fixedPercent1d(totals.irr_exact_adj_percent)));
		arr.push(datacol(fs.fixedPercent1d(totals.val_exact_percent)));
		arr.push(datacol(fs.fixedPercent1d(totals.val_exact_adj_percent)));
		arr.push(datacol(fs.fixedPercent1d(totals.res_required_percent)));
		arr.push(datacol(fs.fixedPercent1d(totals.res_changed_percent)));
		docDefinition.content[1].table.body.push(arr);

		_.each(stats, (stat) => {
			var arr = [];
			arr.push(stat.scorer);
			arr.push("Overall");
			arr.push(datacol(fs.fixedPercent1d(stat.irr_exact_percent)));
			arr.push(datacol(fs.fixedPercent1d(stat.irr_exact_adj_percent)));
			arr.push(datacol(fs.fixedPercent1d(stat.val_exact_percent)));
			arr.push(datacol(fs.fixedPercent1d(stat.val_exact_adj_percent)));
			var res_required = fs.fixedPercent1d(stat.res_required_percent);
			res_required += ` (${stat.res_required} out of ${stat.res_total})`;
			arr.push(datacol(res_required));
			var res_changed = fs.fixedPercent1d(stat.res_changed_percent);
			res_changed += ` (${stat.res_changed} out of ${stat.res_required})`;
			arr.push(datacol(res_changed));
			docDefinition.content[1].table.body.push(arr);

			if (showTraits) {
				_.each(stat.traits, (trait) => {
					if (trait.separator) return;
					if (trait.is_parent) return;

					var arr = [""];
					arr.push(trait.trait_name);
					arr.push(datacol(fs.fixedPercent1d(trait.irr_exact_percent)));
					arr.push(datacol(fs.fixedPercent1d(trait.irr_exact_adj_percent)));
					arr.push(datacol(fs.fixedPercent1d(trait.val_exact_percent)));
					arr.push(datacol(fs.fixedPercent1d(trait.val_exact_adj_percent)));
					arr.push(datacol(""));
					arr.push(datacol(""));
					docDefinition.content[1].table.body.push(arr);
				});
			}
		});

		this.exportPDF(docDefinition, "UserAgrRpt " + user.client.name);
	},

	userAgreementReportCSV(vue, showTraits) {
		var stats = vue.reportStats;
		var totals = vue.reportTotals;
		var user = vue.user;
		var sectionName = vue.selectedSection.name;
		var itemName = vue.selectedItem.name;

		var filenameFields = ["UserAgrRpt", user.client.name, sectionName, itemName, moment().format("M.D.YY-H.mm.ss")];
		var rows = [
			["User", "Trait", "IRR (exact)", "IRR (exact/adjacent", "Validity (exact)", "Validity (exact/adjacent)"],
		];

		_.each(stats, (stat) => {
			var row = [];
			row.push(stat.scorer);
			row.push("Overall");
			row.push(fs.fixedPercent1d(stat.irr_exact_percent));
			row.push(fs.fixedPercent1d(stat.irr_exact_adj_percent));
			var val_exact = stat.val_total > 0 ? fs.fixedPercent1d(stat.val_exact_percent) : "";
			row.push(val_exact);
			var val_exact_adj = stat.val_total > 0 ? fs.fixedPercent1d(stat.val_exact_adj_percent) : "";
			row.push(val_exact_adj);
			row.push(fs.fixedPercent1d(stat.res_required_percent));
			row.push(fs.fixedPercent1d(stat.res_changed_percent));

			rows.push(row);

			if (showTraits) {
				_.each(stat.traits, (trait) => {
					if (trait.separator) return;
					if (trait.is_parent) return;

					var traitRow = [];
					traitRow.push(stat.scorer);
					traitRow.push(trait.trait_name);
					traitRow.push(fs.fixedPercent1d(trait.irr_exact_percent));
					traitRow.push(fs.fixedPercent1d(trait.irr_exact_adj_percent));
					var val_exact = trait.val_total > 0 ? fs.fixedPercent1d(trait.val_exact_percent) : "";
					traitRow.push(val_exact);
					var val_exact_adj = trait.val_total > 0 ? fs.fixedPercent1d(trait.val_exact_adj_percent) : "";
					traitRow.push(val_exact_adj);
					traitRow.push("");
					traitRow.push("");

					rows.push(traitRow);
				});
			}
		});

		var totalRow = [];
		totalRow.push("Totals");
		totalRow.push("Totals");
		totalRow.push(fs.fixedPercent1d(totals.irr_exact_percent));
		totalRow.push(fs.fixedPercent1d(totals.irr_exact_adj_percent));
		var val_exact = totals.val_total > 0 ? fs.fixedPercent1d(totals.val_exact_percent) : "";
		totalRow.push(val_exact);
		var val_exact_adj = totals.val_total > 0 ? fs.fixedPercent1d(totals.val_exact_adj_percent) : "";
		totalRow.push(val_exact_adj);
		totalRow.push(fs.fixedPercent1d(totals.res_required_percent));
		totalRow.push(fs.fixedPercent1d(totals.res_changed_percent));

		rows.push(totalRow);

		this.exportCSV(rows, filenameFields);
	},

	projectAgreementReportPDF(vue, showTraits) {
		var stats = vue.reportStats;
		var totals = vue.reportTotals;
		var user = vue.user;
		var showVal = vue.showVal;
		var projectName = vue.selectedProject.name;

		var docDefinition = {
			pageOrientation: "landscape",
			info: {
				title: "Project Agreement Report",
			},
			styles: {
				all: {
					fontSize: 10,
				},
				datacol: {
					alignment: "right",
				},
			},
			content: [
				{
					text:
						"Project Agreement Report (" +
						user.client.name +
						")\nProject: " +
						projectName +
						"\nGenerated: " +
						moment().format("dddd, MMMM Do YYYY, h:mm:ss a"),
					bold: true,
					style: "all",
				},
				{
					layout: "lightHorizontalLines", // optional
					style: "all",
					table: {
						// headers are automatically repeated if the table spans over multiple pages
						// you can declare how many rows should be treated as headers
						headerRows: 2,
						widths: ["*", "*", "*", "auto", "auto", "auto", "auto", "auto"],

						body: [
							[
								"Section",
								"Item",
								"Trait",
								"IRR (exact)",
								"IRR (exact/adjacent)",
								"Validity (exact)",
								"Validity (exact/adjacent)",
								"Responses (total completed)",
							],
						],
					},
				},
			],
		};
		function datacol(text) {
			console.log("datacol", text);
			return { text: text, style: "datacol" };
		}
		//Totals
		var arr = [{ text: "Totals", bold: true }, "", ""];
		arr.push(datacol(fs.fixedPercent1d(totals.irr_exact_percent)));
		arr.push(datacol(fs.fixedPercent1d(totals.irr_exact_adj_percent)));
		var val_exact = showVal ? fs.fixedPercent1d(totals.val_exact_percent) : "——";
		arr.push(datacol(val_exact));
		var val_exact_adj = showVal ? fs.fixedPercent1d(totals.val_exact_adj_percent) : "——";
		arr.push(datacol(val_exact_adj));
		arr.push(datacol(totals.completed_resps));
		docDefinition.content[1].table.body.push(arr);

		_.each(stats, (stat) => {
			var arr = [];
			arr.push(stat.section_name);
			arr.push(stat.item_name);
			arr.push("Overall");
			arr.push(datacol(fs.fixedPercent1d(stat.irr_exact_percent)));
			arr.push(datacol(fs.fixedPercent1d(stat.irr_exact_adj_percent)));
			var val_exact = showVal ? fs.fixedPercent1d(stat.val_exact_percent) : "——";
			arr.push(datacol(val_exact));
			var val_exact_adj = showVal ? fs.fixedPercent1d(stat.val_exact_adj_percent) : "——";
			arr.push(datacol(val_exact_adj));
			arr.push(datacol(stat.completed_resps));
			docDefinition.content[1].table.body.push(arr);

			if (showTraits) {
				_.each(stat.traits, (trait) => {
					if (trait.separator) return;
					if (trait.is_parent) return;

					var arr = ["", ""];
					arr.push(trait.trait_name);
					arr.push(datacol(fs.fixedPercent1d(trait.irr_exact_percent)));
					arr.push(datacol(fs.fixedPercent1d(trait.irr_exact_adj_percent)));
					var val_exact = showVal ? fs.fixedPercent1d(trait.val_exact_percent) : "——";
					arr.push(datacol(val_exact));
					var val_exact_adj = showVal ? fs.fixedPercent1d(trait.val_exact_adj_percent) : "——";
					arr.push(datacol(val_exact_adj));
					arr.push(datacol(stat.completed_resps));
					docDefinition.content[1].table.body.push(arr);
				});
			}
		});

		this.exportPDF(docDefinition, "ProjAgrRpt " + user.client.name);
	},

	projectAgreementReportCSV(vue, showTraits) {
		var stats = vue.reportStats;
		var totals = vue.reportTotals;
		var user = vue.user;
		var showVal = vue.showVal;
		var projectName = vue.selectedProject.name;

		var filenameFields = ["ProjAgrRpt", user.client.name, projectName, moment().format("M.D.YY-H.mm.ss")];
		var rows = [
			[
				"Section",
				"Item",
				"Trait",
				"IRR (exact)",
				"IRR (exact/adjacent",
				"Validity (exact)",
				"Validity (exact/adjacent)",
				"Responses (total completed",
			],
		];

		_.each(stats, (stat) => {
			var row = [];
			row.push(stat.section_name);
			row.push(stat.item_name);
			row.push("Overall");
			row.push(fs.fixedPercent1d(stat.irr_exact_percent));
			row.push(fs.fixedPercent1d(stat.irr_exact_adj_percent));
			var val_exact = showVal ? fs.fixedPercent1d(stat.val_exact_percent) : "";
			row.push(val_exact);
			var val_exact_adj = showVal ? fs.fixedPercent1d(stat.val_exact_adj_percent) : "";
			row.push(val_exact_adj);
			row.push(stat.completed_resps);

			rows.push(row);

			if (showTraits) {
				_.each(stat.traits, (trait) => {
					if (trait.separator) return;
					if (trait.is_parent) return;

					var traitRow = [];
					traitRow.push(stat.section_name);
					traitRow.push(stat.item_name);
					traitRow.push(trait.trait_name);
					traitRow.push(fs.fixedPercent1d(trait.irr_exact_percent));
					traitRow.push(fs.fixedPercent1d(trait.irr_exact_adj_percent));
					var val_exact = showVal ? fs.fixedPercent1d(trait.val_exact_percent) : "";
					traitRow.push(val_exact);
					var val_exact_adj = showVal ? fs.fixedPercent1d(trait.val_exact_adj_percent) : "";
					traitRow.push(val_exact_adj);

					rows.push(traitRow);
				});
			}
		});

		var totalRow = [];
		totalRow.push("Totals");
		totalRow.push("Totals");
		totalRow.push("Totals");
		totalRow.push(fs.fixedPercent1d(totals.irr_exact_percent));
		totalRow.push(fs.fixedPercent1d(totals.irr_exact_adj_percent));
		var val_exact = showVal ? fs.fixedPercent1d(totals.val_exact_percent) : "";
		totalRow.push(val_exact);
		var val_exact_adj = showVal ? fs.fixedPercent1d(totals.val_exact_adj_percent) : "";
		totalRow.push(val_exact_adj);
		totalRow.push(totals.completed_resps);

		rows.push(totalRow);

		this.exportCSV(rows, filenameFields);
	},

	teamRosterCSV(vue, team) {
		let user = vue.user;

		let filenameFields = [team.name, "Roster", user.client.name, moment().format("M.D.YY-H.mm.ss")];

		let rows = [];
		rows.push([team.name]);
		rows.push([""]);
		rows.push([`Sections: (${team.sections.length})`]);
		_.each(team.sections, (section) => {
			rows.push([section.name]);
		});
		rows.push([""]);
		rows.push([`Users: (${team.users.length})`, "Scorer ID:", "Role:"]);
		_.each(team.users, (user) => {
			rows.push([user.user.full_name, user.user.scorer_id, user.role.name]);
		});

		this.exportCSV(rows, filenameFields);
	},

	projectManifestCSV(client, project) {
		let filenameFields = [client.name, project.name, "Manifest", moment().format("M.D.YY-H.mm.ss")];

		let rows = [
			[
				"Tenant Name",
				"Project Name",
				"Section Name",
				"Item Name",
				"Tenant Ref ID",
				"Section Ref ID",
				"Item Ref ID",
			],
		];
		_.each(project.sections, (section) => {
			_.each(section.items, (item) => {
				rows.push([
					client.name,
					project.name,
					section.name,
					item.name,
					client.ref_id,
					section.ref_id,
					item.ref_id,
				]);
			});
		});

		this.exportCSV(rows, filenameFields);
	},

	rfReportCSV(responses, cols, scoreTable, section, item, set, team) {
		const getRefComment = (respID, scoreID) => {
			const resp = responses.find(({ id }) => id === respID);
			if (!resp) return "";
			const comment = resp.comments.find(({ score_id }) => score_id === scoreID);
			if (!comment) return "";
			return comment.text.replace(/[\u{0080}-\u{FFFF}]/gu, "");
		};

		let filenameFields = [section.name, item.name, set.name];
		if (team) {
			filenameFields.push(team.name);
		}
		filenameFields.push("RFReport");
		filenameFields.push(moment().format("M.D.YY-H.mm.ss"));

		// var getScore = function(resp, row) {
		// 	if (row) {
		// 		return this.scores[row.user.id][resp.id];
		// 	} else {
		// 		return resp.true_score;
		// 	}
		// },

		let rows = [];
		let headers = ["response", "text", "feedback"];
		_.each(item.rubric.traits, (trait, i) => {
			if (trait.separator) return;
			if (trait.is_parent) return;

			headers.push(`true_score_T${i + 1}`);
		});
		headers.push("tags");
		//headers.push("annotation");
		_.each(cols, (col) => {
			_.each(item.rubric.traits, (trait, i) => {
				if (trait.separator) return;
				if (trait.is_parent) return;

				headers.push(`${fs.scorerID(col.user)}_T${i + 1}`);
				if (item.rubric.collect_evidence) {
					headers.push(`${fs.scorerID(col.user)}_T${i + 1}_evidence`);
				}
			});
			headers.push(`${fs.scorerID(col.user)}_comments`);
		});
		rows.push(headers);

		_.each(responses, (response) => {
			let row = [];
			row.push(response.ref_id);

			let respText = "";
			if (response.mime_type === "text") {
				respText += response.text;
			}
			respText += response.media
				.filter((m) => m.mime_type === "text")
				.map((m) => m.value)
				.join("\n");
			row.push(respText);

			row.push(response.feedback);

			//True scores by trait
			_.each(item.rubric.traits, (trait) => {
				if (trait.separator) return;
				if (trait.is_parent) return;

				if (response.true_score) {
					let sp = _.find(response.true_score.trait_scores, {
						trait_id: trait.id,
					});
					if (sp) {
						if (sp.score == -1) {
							row.push(sp.condition);
						} else {
							row.push(sp.score);
						}
					} else {
						row.push("");
					}
				} else {
					row.push("");
				}
			});

			if (response.true_score) {
				if (response.qc_tags && response.qc_tags.length > 0) {
					row.push(fs.qcTagAbrs(response.qc_tags));
				} else {
					row.push("");
				}
				// if (response.feedback) {
				// 	row.push(response.feedback);
				// } else {
				// 	row.push("");
				// }
			} else {
				row.push("");
				//row.push("");
			}

			_.each(cols, (col) => {
				let userScore = scoreTable[col.user.id][response.id];
				_.each(item.rubric.traits, (trait) => {
					if (trait.separator) return;
					if (trait.is_parent) return;

					if (userScore) {
						let sp = _.find(userScore.trait_scores, {
							trait_id: trait.id,
						});
						if (sp) {
							if (sp.score == -1) {
								row.push(sp.condition);
							} else {
								row.push(sp.score);
							}

							if (item.rubric.collect_evidence) {
								row.push(sp.evidence2);
							}
						} else {
							row.push("");
							if (item.rubric.collect_evidence) {
								row.push("");
							}
						}
					} else {
						row.push("");
						if (item.rubric.collect_evidence) {
							row.push("");
						}
					}
				});
				row.push(userScore ? getRefComment(response.id, userScore.id) : "");
			});

			rows.push(row);
		});

		this.exportCSV(rows, filenameFields);
	},

	candidateAuditingCSV(project, stats) {
		let filenameFields = ["CandidateAuditing", project.name, moment().format("M.D.YY-H.mm.ss")];
		let rows = [["Ref ID", "Total Score", "Complete Responses", "Total Responses"]];

		_.each(stats, (stat) => {
			let row = [];
			row.push(stat.ref_id);
			row.push(stat.total_score);
			row.push(stat.complete_count);
			row.push(stat.count);

			rows.push(row);
		});

		this.exportCSV(rows, filenameFields);
	},

	candidateAuditingCSVDetailed(project, responseGroups) {
		let filenameFields = [];
		if (responseGroups.length == 1) {
			filenameFields = ["CandidateAuditing", responseGroups[0].ref_id, moment().format("M.D.YY-H.mm.ss")];
		} else {
			filenameFields = ["CandidateAuditing", moment().format("M.D.YY-H.mm.ss")];
		}
		let rows = [["Ref ID", "Section", "Item", "Complete", "Score", "Total Candidate Score"]];

		_.each(responseGroups, (responseGroup) => {
			_.each(responseGroup.responses, (resp) => {
				let row = [];
				row.push(resp.ref_id);
				row.push(resp.section_name);
				row.push(resp.item_name);
				if (resp.state == 10) {
					row.push("Yes");
				} else {
					row.push("No");
				}
				row.push(resp.total_score);
				row.push(responseGroup.total_score);

				rows.push(row);
			});
		});

		console.log("FILESERVICE");
		console.log(rows, filenameFields);

		this.exportCSV(rows, filenameFields);
	},

	qcReportCSV(responses, rows, exportType, set, rubric, allUserAgreementStats, traits) {
		const [csvFile, fileName] = this.qcReportFileInfo(
			responses,
			rows,
			exportType,
			set,
			rubric,
			allUserAgreementStats,
			traits
		);
		this.downloadFile(fileName, csvFile, "text/csv;charset=utf-8;");
	},

	qcReportFileInfo(responses, rows, exportType, set, rubric, allUserAgreementStats, traits) {
		let filenameFields = ["QCReport", set.name, moment().format("M.D.YY-H.mm.ss")];

		let headers = ["Scorer ID", "First Name", "Last Name"];

		if (set.qc_type == 2) {
			// Qual
			console.log("IN QUAL");
			headers.push("State");
			headers.push("Completed");
		}

		let getScoreAgreement = function (ts1, ts2) {
			let trait = _.find(rubric.traits, { id: ts1.trait_id });
			if (!trait.step) {
				trait.step = 1;
			}
			let diff = Math.abs(ts1.score - ts2.score);
			if (diff < trait.step) {
				return "Exact";
			}
			if (diff < 2 * trait.step) {
				return "Adjacent";
			}
			return "Discrepant";
		};

		let getResponseAgreement = function (s1, s2) {
			if (!(s1 && s2)) {
				return "";
			}
			console.log("getResponseAgreement", s1, s2);

			let biggestDiff = 0;
			_.each(rubric.traits, (trait) => {
				if (!trait.step) {
					trait.step = 1;
				}
				let ts1 = _.find(s1.trait_scores, { trait_id: trait.id });
				let ts2 = _.find(s2.trait_scores, { trait_id: trait.id });
				if (!(ts1 && ts2)) {
					return "";
				}
				let diff = Math.abs(ts1.score - ts2.score) / trait.step;
				biggestDiff = Math.max(biggestDiff, diff);
			});

			if (biggestDiff < 1) {
				return "Exact";
			}
			if (biggestDiff < 2) {
				return "Adjacent";
			}
			return "Discrepant";
		};

		let getStateString = function (row) {
			var state = "-";
			if (row.stat) {
				if (row.stat.state == 1) {
					state = "In Progress";
				}
				if (row.stat.state == 2) {
					state = "Passed";
				}
				if (row.stat.state == 3) {
					state = "Not Qualified";
				}
			}
			return state;
		};

		_.each(responses, (resp) => {
			if (exportType == 1) {
				headers.push(resp.ref_id);
			}
			if (exportType == 2) {
				_.each(resp.true_score.trait_scores, (ts) => {
					let trait = _.find(rubric.traits, { id: ts.trait_id });
					headers.push(`${resp.ref_id} - ${trait.name} - Score`);
					headers.push(`${resp.ref_id} - ${trait.name} - Agreement (${ts.score})`);
					if (rubric.collect_evidence) {
						headers.push(`${resp.ref_id} - ${trait.name} - Evidence`);
					}
				});

				_.each(rubric.meta_traits, (trait) => {
					headers.push(`${resp.ref_id} - ${trait.name} - Text Trait`);
				});
			}
		});

		_.each(traits, (trait) => {
			headers.push(trait.name + " - low non-adj%");
			headers.push(trait.name + " - low adj%");
			headers.push(trait.name + " - agree%");
			headers.push(trait.name + " - high adj%");
			headers.push(trait.name + " - high non-adj%");
		});

		let ers = [headers];

		_.each(rows, (row) => {
			let er = [];

			er.push(row.user.scorer_id);
			er.push(row.user.first_name);
			er.push(row.user.last_name);

			if (set.qc_type == 2) {
				// Qual
				er.push(getStateString(row));
				if (row.stat) {
					er.push(moment(row.stat.updated_at).format("MM/DD/YY h:mm:ss a"));
				} else {
					er.push("");
				}
			}

			_.each(responses, (resp) => {
				let score = _.find(row.qc_scores, { qc_response_id: resp.id });
				if (exportType == 1) {
					er.push(getResponseAgreement(score, resp.true_score));
				}
				if (exportType == 2) {
					if (!score) {
						er.push("");
						er.push("");
						if (rubric.collect_evidence) {
							er.push("");
						}
						if (rubric.meta_traits && rubric.meta_traits.length != 0) {
							er.push("");
						}
					} else {
						_.each(resp.true_score.trait_scores, (ts) => {
							let uts = _.find(score.trait_scores, { trait_id: ts.trait_id });
							er.push(uts.score);
							er.push(getScoreAgreement(uts, ts));
							if (rubric.collect_evidence) {
								er.push(uts.evidence2);
							}
						});

						_.each(rubric.meta_traits, (trait) => {
							let mts = _.find(score.meta_trait_scores, { meta_trait_id: trait.id });
							er.push(mts.value);
						});
					}
				}
			});

			//get user agreement stats for item
			_.each(traits, (trait) => {
				let i = 0;
				while (i < allUserAgreementStats.length) {
					if (allUserAgreementStats[i].user_id == row.user.id) {
						let ta = allUserAgreementStats[i].trait_agreements;
						_.each(ta, (element) => {
							if (element.trait_id == trait.id) {
								er.push(
									this.getCountAtAdjacency(
										element.trait_id,
										"nal",
										allUserAgreementStats[i].trait_agreements
									)
								),
									er.push(
										this.getCountAtAdjacency(
											element.trait_id,
											"al",
											allUserAgreementStats[i].trait_agreements
										)
									),
									er.push(
										this.getCountAtAdjacency(
											element.trait_id,
											"agreed",
											allUserAgreementStats[i].trait_agreements
										)
									),
									er.push(
										this.getCountAtAdjacency(
											element.trait_id,
											"ah",
											allUserAgreementStats[i].trait_agreements
										)
									),
									er.push(
										this.getCountAtAdjacency(
											element.trait_id,
											"nah",
											allUserAgreementStats[i].trait_agreements
										)
									);
							}
						});
					}
					i++;
				}
			});

			ers.push(er);
		});
		return this.processCSV(ers, filenameFields);
	},

	qcReportAllSetsCSV(rows, qcSets, qcTypeID, section, item, adjacencyUserItemSets, adjacencyUserStatTotals, traits) {
		const [csvFile, fileName] = this.qcReportAllSetsFileInfo(
			rows,
			qcSets,
			qcTypeID,
			section,
			item,
			adjacencyUserItemSets,
			adjacencyUserStatTotals,
			traits
		);
		this.downloadFile(fileName, csvFile, "text/csv;charset=utf-8;");
	},

	qcReportAllSetsFileInfo(
		rows,
		qcSets,
		qcTypeID,
		section,
		item,
		adjacencyUserItemSets,
		adjacencyUserStatTotals,
		traits
	) {
		const csvRows =
			qcTypeID == QC_TYPES.VALIDITY.id
				? this.allSetsValidityRows(rows, qcSets, traits)
				: this.allSetRows(rows, qcSets, qcTypeID, adjacencyUserItemSets, traits);

		const totalRows = this.totalRows(rows, qcSets, qcTypeID, adjacencyUserStatTotals, traits);

		let i = 0;
		let useridMap = new Map();
		while (i < rows.length) {
			useridMap.set(rows[i].user_id, rows[i].user.scorer_id);
			i++;
		}

		const dateStr = new Date().toLocaleDateString().split("/").join(".");
		const fileNameParts = [idToName(QC_TYPES, qcTypeID), section.name, item.name, dateStr];

		return this.processCSVWithTotals(csvRows, fileNameParts, totalRows, useridMap);
	},

	getCountAtAdjacency(traitID, key, trait_agreements) {
		var result = "-";
		_.each(trait_agreements, (ta) => {
			if (ta.trait_id == traitID) {
				result = ((ta[key] / ta.total) * 100).toFixed(2);
			}
		});
		return result;
	},

	allSetRows(rows, qcSets, qcTypeID, adjacencyUserItemSets, traits) {
		const useState = qcTypeID === QC_TYPES.QUALIFICATION.id;
		const headers = ["Scorer ID", "First Name", "Last Name", "Set Name", "Percent Passed"];

		if (useState) {
			headers.push("State");
		}

		_.each(traits, (trait) => {
			headers.push(trait.name + " - low non-adj%");
			headers.push(trait.name + " - low adj%");
			headers.push(trait.name + " - agree%");
			headers.push(trait.name + " - high adj%");
			headers.push(trait.name + " - high non-adj%");
		});

		const body = qcSets
			.map(({ id, name }) =>
				rows.map(({ user_id, cells, stat, user: { scorer_id, first_name, last_name } }) => {
					const cell = cells.find(({ qc_set_id }) => qc_set_id === id);
					const percentPassed = cell && cell.any_results ? fs.fixedPercent1d(cell.agreement) : "NA";
					const row = [scorer_id, first_name, last_name, name, percentPassed];

					if (useState) {
						const qcState = (stat && stat.state) || QC_STATES.MISSING.id;
						row.push(idToName(QC_STATES, qcState));
					}

					//get user agreement stats for item
					_.each(traits, (trait) => {
						let i = 0;
						while (i < adjacencyUserItemSets.length) {
							if (
								adjacencyUserItemSets[i].user_id == user_id &&
								adjacencyUserItemSets[i].qc_set_id == id
							) {
								let ta = adjacencyUserItemSets[i].trait_agreements;
								_.each(ta, (element) => {
									if (element.trait_id == trait.id) {
										row.push(
											this.getCountAtAdjacency(
												element.trait_id,
												"nal",
												adjacencyUserItemSets[i].trait_agreements
											)
										),
											row.push(
												this.getCountAtAdjacency(
													element.trait_id,
													"al",
													adjacencyUserItemSets[i].trait_agreements
												)
											),
											row.push(
												this.getCountAtAdjacency(
													element.trait_id,
													"agreed",
													adjacencyUserItemSets[i].trait_agreements
												)
											),
											row.push(
												this.getCountAtAdjacency(
													element.trait_id,
													"ah",
													adjacencyUserItemSets[i].trait_agreements
												)
											),
											row.push(
												this.getCountAtAdjacency(
													element.trait_id,
													"nah",
													adjacencyUserItemSets[i].trait_agreements
												)
											);
									}
								});
							}
							i++;
						}
					});

					return row;
				})
			)
			.flat(1);

		return [headers].concat(body);
	},

	totalRows(rows, qcSets, qcTypeID, adjacencyUserStatTotals, traits) {
		const useState = qcTypeID === QC_TYPES.QUALIFICATION.id;
		const nSets = qcSets.length;

		const users = new Map();

		const body = qcSets
			.map(({ id, name }) =>
				rows.map(({ user_id, cells, stat, user: { scorer_id, first_name, last_name } }) => {
					const cell = cells.find(({ qc_set_id }) => qc_set_id === id);
					let percentPassed = "";
					let totalRow = [];
					if (!users.has(user_id)) {
						totalRow = [scorer_id, first_name, last_name, "TOTAL", percentPassed];

						if (useState) {
							const qcState = (stat && stat.state) || QC_STATES.MISSING.id;
							totalRow.push("");
						}

						// Make blank cells for the number of sets to align the totals
						// correctly
						if (qcTypeID == QC_TYPES.VALIDITY.id) {
							for (let i = 0; i < nSets - 1; i++) {
								totalRow.push("");
							}
						}

						_.each(traits, (trait) => {
							let i = 0;
							while (i < adjacencyUserStatTotals.length) {
								if (adjacencyUserStatTotals[i].user_id == user_id) {
									let ta = adjacencyUserStatTotals[i].trait_agreements;
									_.each(ta, (element) => {
										if (element.trait_id == trait.id) {
											totalRow.push(
												this.getCountAtAdjacency(
													element.trait_id,
													"nal",
													adjacencyUserStatTotals[i].trait_agreements
												)
											),
												totalRow.push(
													this.getCountAtAdjacency(
														element.trait_id,
														"al",
														adjacencyUserStatTotals[i].trait_agreements
													)
												),
												totalRow.push(
													this.getCountAtAdjacency(
														element.trait_id,
														"agreed",
														adjacencyUserStatTotals[i].trait_agreements
													)
												),
												totalRow.push(
													this.getCountAtAdjacency(
														element.trait_id,
														"ah",
														adjacencyUserStatTotals[i].trait_agreements
													)
												),
												totalRow.push(
													this.getCountAtAdjacency(
														element.trait_id,
														"nah",
														adjacencyUserStatTotals[i].trait_agreements
													)
												);
										}
									});
								}
								i++;
							}
						});
						users.set(user_id, "");
					}
					return totalRow;
				})
			)
			.flat(1);

		return body;
	},

	allSetsValidityRows(rows, qcSets, traits) {
		const qcSetIDs = qcSets.map(({ id }) => id);
		const qcSetNames = qcSets.map(({ name }) => name);

		const headers = ["Scorer ID", "First Name", "Last Name", "State"].concat(qcSetNames);

		_.each(traits, (trait) => {
			headers.push(trait.name + " - low non-adj%");
			headers.push(trait.name + " - low adj%");
			headers.push(trait.name + " - agree%");
			headers.push(trait.name + " - high adj%");
			headers.push(trait.name + " - high non-adj%");
		});

		const body = rows.map(({ cells, stat, user: { scorer_id, first_name, last_name } }) => {
			const setPercents = qcSetIDs.map((id) => {
				const cell = cells.find(({ qc_set_id }) => qc_set_id === id);
				return cell && cell.any_results ? fs.fixedPercent1d(cell.agreement) : "NA";
			});
			const qcState = (stat && stat.state) || QC_STATES.MISSING.id;
			const row = [scorer_id, first_name, last_name, idToName(QC_STATES, qcState)];
			return row.concat(setPercents);
		});

		return [headers].concat(body);
	},

	exportPDF(doc, name) {
		pdfMake.createPdf(doc).download(name);
	},

	exportCSV(rows, filenameFields) {
		const [csvFile, fileName] = this.processCSV(rows, filenameFields);
		this.downloadFile(fileName, csvFile, "text/csv;charset=utf-8;");
	},

	processCSV(rows, filenameFields) {
		rows = rows.map((row) => row.map((val) => (val === "——" ? 0 : val)));

		var processRow = function (row) {
			var finalVal = "";
			for (var j = 0; j < row.length; j++) {
				var innerValue = row[j] == undefined ? "" : row[j].toString();
				if (row[j] instanceof Date) {
					innerValue = row[j].toLocaleString();
				}
				var result = innerValue.replace(/"/g, '""'); //Escape single quotes
				if (result.search(/("|,|\n)/g) >= 0)
					//Escape other problematic characters
					result = '"' + result + '"';
				if (j > 0) finalVal += ",";
				finalVal += result;
			}
			return finalVal + "\n";
		};

		var processFileName = function (filenameFields) {
			var filename = "";
			for (var i = 0; i < filenameFields.length; i++) {
				var field = filenameFields[i];
				field = field.replace(/\s/g, "_"); //Replace white space with dashes
				field = field.replace(/<|>|\/|\\|:|"|\||\?|\*/g, "#"); //Replace forbidden characters with pound signs 'cause why not

				filename = filename + (i > 0 ? " " : "") + field;
			}

			return filename + ".csv";
		};

		//var csvFile = "sep=,\n";
		var csvFile = "";
		for (var i = 0; i < rows.length; i++) {
			csvFile += processRow(rows[i]);
		}

		return [csvFile, processFileName(filenameFields)];
	},

	processCSVWithTotals(rows, filenameFields, totalRows, useridMap) {
		rows = rows.map((row) => row.map((val) => (val === "——" ? 0 : val)));

		var processRow = function (row) {
			var finalVal = "";
			for (var j = 0; j < row.length; j++) {
				var innerValue = row[j] == undefined ? "" : row[j].toString();
				if (row[j] instanceof Date) {
					innerValue = row[j].toLocaleString();
				}
				var result = innerValue.replace(/"/g, '""'); //Escape single quotes
				if (result.search(/("|,|\n)/g) >= 0)
					//Escape other problematic characters
					result = '"' + result + '"';
				if (j > 0) finalVal += ",";
				finalVal += result;
			}
			return finalVal + "\n";
		};

		var processFileName = function (filenameFields) {
			var filename = "";
			for (var i = 0; i < filenameFields.length; i++) {
				var field = filenameFields[i];
				field = field.replace(/\s/g, "_"); //Replace white space with dashes
				field = field.replace(/<|>|\/|\\|:|"|\||\?|\*/g, "#"); //Replace forbidden characters with pound signs 'cause why not

				filename = filename + (i > 0 ? " " : "") + field;
			}

			return filename + ".csv";
		};

		var csvFile = rows[0] + "\n";
		useridMap.forEach((value, key, map) => {
			var percentPassed = 0.0;
			var totalCount = 0;
			for (var i = 0; i < rows.length; i++) {
				if (value == rows[i][0]) {
					if (rows[i][4] != "NA") {
						percentPassed += parseFloat(rows[i][4]);
						totalCount += 1;
					}
					csvFile += processRow(rows[i]);
				}
			}
			for (var i = 0; i < totalRows.length; i++) {
				if (value == totalRows[i][0]) {
					if (totalCount > 0) {
						percentPassed = percentPassed / totalCount;
						totalRows[i][4] = percentPassed.toFixed(1).toString() + "%";
					} else {
						totalRows[i][4] = "0.0%";
					}
					csvFile += processRow(totalRows[i]);
				}
			}
		});

		return [csvFile, processFileName(filenameFields)];
	},

	CSVToArray(strData, strDelimiter) {
		// Check to see if the delimiter is defined. If not,
		// then default to comma.
		strDelimiter = strDelimiter || ",";
		// Create a regular expression to parse the CSV values.
		var objPattern = new RegExp(
			// Delimiters.
			"(\\" +
				strDelimiter +
				"|\\r?\\n|\\r|^)" +
				// Quoted fields.
				'(?:"([^"]*(?:""[^"]*)*)"|' +
				// Standard fields.
				'([^"\\' +
				strDelimiter +
				"\\r\\n]*))",
			"gi"
		);
		// Create an array to hold our data. Give the array
		// a default empty first row.
		var arrData = [[]];
		// Create an array to hold our individual pattern
		// matching groups.
		var arrMatches = null;
		// Keep looping over the regular expression matches
		// until we can no longer find a match.
		while ((arrMatches = objPattern.exec(strData))) {
			// Get the delimiter that was found.
			var strMatchedDelimiter = arrMatches[1];
			// Check to see if the given delimiter has a length
			// (is not the start of string) and if it matches
			// field delimiter. If id does not, then we know
			// that this delimiter is a row delimiter.
			if (strMatchedDelimiter.length && strMatchedDelimiter != strDelimiter) {
				// Since we have reached a new row of data,
				// add an empty row to our data array.
				arrData.push([]);
			}
			// Now that we have our delimiter out of the way,
			// let's check to see which kind of value we
			// captured (quoted or unquoted).
			if (arrMatches[2]) {
				// We found a quoted value. When we capture
				// this value, unescape any double quotes.
				var strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
			} else {
				// We found a non-quoted value.
				var strMatchedValue = arrMatches[3];
			}
			// Now that we have our value string, let's add
			// it to the data array.
			arrData[arrData.length - 1].push(strMatchedValue);
		}
		// Return the parsed data.
		return arrData;
	},

	downloadText(filename, text) {
		var element = document.createElement("a");
		element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
		element.setAttribute("download", filename);

		element.style.display = "none";
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	},

	getAttachmentFilename(r) {
		let contentDisposition = r.headers && r.headers["content-disposition"];
		if (!contentDisposition) {
			return null;
		}

		const prefix = "attachment; filename=";
		if (contentDisposition.startsWith(prefix)) {
			return contentDisposition.slice(prefix.length);
		}
		return null;
	},

	downloadZipFile(fileName, data) {
		this.downloadFile(fileName, this.base64ToArrayBuffer(data), "application/zip");
	},

	downloadFile(fileName, data, type) {
		const blob = new Blob([data], { type });
		if (navigator.msSaveBlob) {
			// IE 10+
			navigator.msSaveBlob(blob, fileName);
		} else {
			const link = document.createElement("a");
			if (link.download !== undefined) {
				// feature detection
				// Browsers that support HTML5 download attribute
				const url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", fileName);
				link.style.visibility = "hidden";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	},

	base64ToArrayBuffer(base64) {
		const binaryString = window.atob(base64);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		return bytes;
	},
};
