import axios from "axios";
import $ from "jquery"

export default {
	newResource(formData, progressFunc) {
		return axios.post("/v1/admin/resource", formData, {
			headers: { "Content-Type": undefined },
			onUploadProgress: progressFunc
		});
	},

	removeResource(item_id, resource_id) {
		return axios.delete(`/v1/admin/resource/${item_id}/${resource_id}`);
	},

	checkResource(item_id, resource_id) {
		return axios.get(`/v1/admin/resource_check/${item_id}/${resource_id}`);
	},

	downloadResource(item, resource) {
		let url = `/v1/admin/resource_url/${item.id}/${resource.id}`
		let origFileName = resource.path
		let parts = origFileName.split(".")
		let ext = parts[parts.length - 1]
		let niceFileName = `${item.ref_id}_${resource.name}.${ext}`
		return this.downloadFile(url, niceFileName)
	},

	downloadTrainingResource(course, element) {
		let url = `/v1/admin/training_resource_url/${course.id}/${element.id}`
		let origFileName = element.resource.path
		let parts = origFileName.split(".")
		let ext = parts[parts.length - 1]
		let niceFileName = `${course.name}_${element.name}.${ext}`
		return this.downloadFile(url, niceFileName)
	},

	downloadFile(url, filename) {
		var request = axios.get(url);
		return request.then(resp => {
			$.ajax({
				url: resp.data,
				xhrFields: {
					responseType: 'blob'
				},
				success: data => {
					var url = window.URL || window.webkitURL;
					var path = url.createObjectURL(data);
					const link = document.createElement('a');
					link.href = path;
					link.setAttribute('download', filename);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				},
				error: e => {
					console.error(e)
				}
			});
		})
	}
};
