<template>
	<div>
		<template v-if="!testSucceeded && !testError && !testRunning">
			<button class="ml-auto btn btn-primary" @click="testIeaIntegration">{{ label }}</button>
		</template>
		<template v-if="testRunning">
			<button class="ml-auto btn btn-primary" disabled><loading type="icon" class="mr-2" />{{ label }}</button>
		</template>
		<template v-if="!testRunning && testSucceeded">
			<button class="ml-auto btn btn-success" @click="testIeaIntegration">
				<i class="fas fa-check mr-2" />{{ label }}
			</button>
			<div class="text-success">{{ successText }}</div>
		</template>
		<template v-if="!testRunning && !testSucceeded && testError">
			<button class="ml-auto btn btn-danger" @click="testIeaIntegration">
				<i class="fas fa-times mr-2" />{{ label }}
			</button>
			<span class="text-danger">{{ testError }}</span>
		</template>
	</div>
</template>

<script>
import ItemService from "@/services/ItemService";
import Notie from "@/services/NotieService";

export default {
	name: "TestIeaItemButton",

	props: {
		client: true,
		item: true,
		adminName: true,
		label: { type: String, default: "Test IEA" },
		successText: { type: String, default: "IEA Item is properly initialized" },
	},

	data() {
		return {
			testRunning: false,
			testSucceeded: false,
			testError: null,
		};
	},

	computed: {},

	watch: {},

	methods: {
		testIeaIntegration() {
			this.testRunning = true;
			ItemService.getIeaItem(this.item.id, this.adminName)
				.then((r) => {
					this.testSucceeded = true;
				})
				.catch((e) => {
					this.testSucceeded = false;
					this.testError = Notie.extractErrorMessage(e);
				})
				.finally(() => {
					this.testRunning = false;
				});
		},
	},
};
</script>

<style scoped>
</style>
