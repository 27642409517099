<template>
	<h5>
		<a @click="weekBack" v-tippy :title="$t('tooltip.previous_week')" class="pr-2 no-select">
			<i class="fa fa-caret-left"></i>
		</a>
		<a @click="weekForward" v-if="hasWeekForward" v-tippy :title="$t('tooltip.next_week')" class="pr-2 no-select">
			<i class="fa fa-caret-right"></i>
		</a>
		<span>{{ displayWeekRange }}</span>
	</h5>
</template>


<script>
import moment from "moment";

export default {
	name: "WeekSelect",

	data() {
		return {
			selectedTime: moment(),
		};
	},

	created() {
		this.emitTimeSpan();
	},

	computed: {
		start() {
			return moment(this.selectedTime).startOf("week");
		},

		end() {
			return moment(this.selectedTime).endOf("week");
		},

		hasWeekForward() {
			return this.selectedTime.isBefore(moment(), "week");
		},

		displayWeekRange() {
			const { start, end } = this;

			let sd = start.format(this.$i18n.t("Dashboard.day_format"));
			let ed = end.format(this.$i18n.t("Dashboard.day_format"));

			if (start.year() == end.year()) {
				let y = start.format(this.$i18n.t("Dashboard.year_format"));
				if (start.month() == end.month()) {
					let m = start.format(this.$i18n.t("Dashboard.month_long_format"));
					return `${m} ${sd} – ${ed}, ${y}`;
				}
				let sm = start.format(this.$i18n.t("Dashboard.month_short_format"));
				let em = end.format(this.$i18n.t("Dashboard.month_short_format"));
				return `${sm} ${sd} – ${em} ${ed}, ${y}`;
			}
			let sy = start.format(this.$i18n.t("Dashboard.year_format"));
			let ey = start.format(this.$i18n.t("Dashboard.year_format"));
			let sm = start.format(this.$i18n.t("Dashboard.month_short_format"));
			let em = end.format(this.$i18n.t("Dashboard.month_short_format"));
			return `${sm} ${sd}, ${sy} – ${em} ${ed}, ${ey}`;
		},
	},

	methods: {
		emitTimeSpan() {
			const { selectedTime, start, end } = this;
			this.$emit("newTimeSpan", { selectedTime, start, end });
		},

		weekBack() {
			this.selectedTime = moment(this.selectedTime).subtract(1, "weeks");
			this.emitTimeSpan();
		},

		weekForward() {
			this.selectedTime = moment(this.selectedTime).add(1, "weeks");
			this.emitTimeSpan();
		},
	},
};
</script>
