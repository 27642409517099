// import PDFJS from "pdfjs-dist";
import axios from "axios";
// PDFJS.workerSrc = location.origin + "/static/js/pdf.worker.min.js";
// PDFJS.PDFJS.workerSrc = "/static/js/pdf.worker.min.js";

var unsupportedMedia = [
	{
		type: "text",
		text: "This media type is not currently supported.",
	},
];

// pdfjsLib.disableWorker = true;

import * as spreadExcel from "@grapecity/spread-excelio";
import SpreadJSKey from "@/services/SpreadJSKey";

//Service for setting up response media pages. (see response.service.js)
export default {
	/**
	 * @returns {Promise} of "Pages" that can be rendered
	 * @param {*} response
	 */
	setupResponse(response) {
		var _this = this;
		//For backwards compatibility we'll convert top-level text/pdf responses into a media object
		if (response.media && response.media.length) {
			//Normal path
		} else {
			response.media = [
				{
					sequence: 1,
					value: response.text,
					signed_url: response.signed_url,
					mime_type: response.mime_type.toLowerCase(),
					label: response.label,
					force_download: response.force_download,
				},
			];
			response.back_combatable = true;
		}

		var media = _.sortBy(response.media, (m) => {
			return m.sequence;
		});

		var promises = [];
		_.each(media, (m, i) => {
			m.mime_type = m.mime_type.toLowerCase();
			promises.push(_this.setupResponseMedia(m, i, response));
		});

		return new Promise(function (resolve, reject) {
			Promise.all(promises).then(function (results) {
				//console.log("results", results);
				let flattened = { pages: [], pdfs: [] };
				_.each(results, (result) => {
					_.each(result.pages, (page) => {
						flattened.pages.push(page);
					});
					if (result.pdf) {
						flattened.pdfs.push(result.pdf);
					}
				});
				if (flattened.pdfs.length == 0) {
					delete flattened.pdfs;
				}
				//console.log("flattened", flattened);
				resolve(flattened);
			});
		});
	},

	setupResource(resource, item) {
		//Have to get the signed url first
		var request = `/v1/admin/resource_url/${item.id}/${resource.id}`;
		if (resource.type == 3) {
			request = `/v1/admin/resource_url+cookies/${item.id}/${resource.id}`;
		}
		return axios.get(request).then((resp) => {
			//Set up the resource so it looks like a response media, then it can be processed the same way
			let media = {
				sequence: 1,
				mime_type: resource.mime_type,
				width: resource.width,
				height: resource.height,
				path: resource.path,
			};
			if (resource.type == 3) {
				media.value = resp.data.url;
				media.signing_cookies = resp.data.signing_cookies;
			} else {
				media.signed_url = resp.data;
			}

			return this.setupResponseMedia(media);
		});
	},

	setupTrainingResource(resource, course, element) {
		//Have to get the signed url first
		var request = `/v1/admin/training_resource_url/${course.id}/${element.id}`;
		if (resource.type == 3) {
			request = `/v1/admin/training_resource_url+cookies/${course.id}/${element.id}`;
		}
		return axios.get(request).then((resp) => {
			//Set up the resource so it looks like a response media, then it can be processed the same way
			let media = {
				sequence: 1,
				mime_type: resource.mime_type,
				width: resource.width,
				height: resource.height,
				path: resource.path,
			};
			if (resource.type == 3) {
				media.value = resp.data.url;
				media.signing_cookies = resp.data.signing_cookies;
			} else {
				media.signed_url = resp.data;
			}

			return this.setupResponseMedia(media);
		});
	},

	setupResponseMedia(media, index, response) {
		return new Promise((resolve, reject) => {
			var pages = [];

			if (media.force_download || media.mime_type == "application/vnd.ms-excel") {
				pages.push({
					type: "download",
					media: media,
					page_ref_id: media.page_ref_id,
					mediaSeq: media.sequence,
				});
				resolve({ pages: pages });
				return;
			}

			if (media.mime_type == "application/pdf") {
				// pdfjsLib.disableWorker = true;

				var now = new Date().getTime();
				pdfjsLib
					.getDocument(media.signed_url)
					.then(function (pdf) {
						console.log("GetPDF:" + (new Date().getTime() - now));
						var numPages = pdf.pdfInfo.numPages;
						var loaded = 0;
						for (let i = 1; i <= numPages; i++) {
							pdf.getPage(i).then((page) => {
								var p = {
									type: "pdf",
									pdf: page,
									label: media.label,
									index: page.pageNumber,
									page_ref_id: media.page_ref_id,
								};

								if (response && response.qc_type) {
									p.qc = true;
								}
								if (i == 1) {
									p.mediaSeq = media.sequence;
								}

								pages.push(p);

								loaded++;
								if (loaded == numPages) {
									resolve({ pages: pages, pdf: pdf });
								}
							});
						}
					})
					.catch(function (e) {
						reject(e);
					});
			} else if (_.endsWith(media.mime_type, "text")) {
				pages.push({
					type: "text",
					text: media.value,
					label: media.label,
					page_ref_id: media.page_ref_id,
					mediaSeq: media.sequence,
					copyleaks: media.copyleaks,
				});
				resolve({ pages: pages });
			} else if (_.startsWith(media.mime_type, "video")) {
				pages.push({
					type: "video",
					text: media.value,
					url: media.signed_url,
					label: media.label,
					page_ref_id: media.page_ref_id,
					mediaSeq: media.sequence,
				});
				resolve({ pages: pages });
			} else if (_.startsWith(media.mime_type, "audio")) {
				pages.push({
					type: "audio",
					text: media.value,
					url: media.signed_url,
					label: media.label,
					page_ref_id: media.page_ref_id,
					mediaSeq: media.sequence,
				});
				resolve({ pages: pages });
			} else if (_.startsWith(media.mime_type, "image")) {
				pages.push({
					type: "image",
					text: media.value,
					url: media.signed_url,
					label: media.label,
					page_ref_id: media.page_ref_id,
					mediaSeq: media.sequence,
				});
				resolve({ pages: pages });
			} else if (
				media.mime_type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
				media.mime_type == "application/vnd.ms-excel.sheet.macroenabled.12"
			) {
				SpreadJSKey.getKey().then((licenseKey) => {
					console.log("SpreadJS excelIO license key:", licenseKey);
					spreadExcel.LicenseKey = licenseKey;
					let excelIO = new spreadExcel.IO();

					let req = new XMLHttpRequest();
					req.open("get", media.signed_url, true);
					req.responseType = "blob";
					req.onload = () => {
						var blob = req.response;
						excelIO.open(
							blob,
							(json) => {
								pages.push({
									type: "excel",
									json: json,
									url: media.signed_url,
									label: media.label,
									page_ref_id: media.page_ref_id,
									mediaSeq: media.sequence,
								});
								resolve({ pages: pages });
							},
							(message) => {
								console.log("ExcelIO message:", message);
							}
						);
					};
					req.send(null);
				});
			} else if (media.mime_type == "learnosity") {
				// Get signed learnosity packet
				var request = axios.get(`/v1/learnosity/response/${response.id}`);
				return request.then((resp) => {
					pages.push({
						type: "learnosity",
						label: media.label,
						learnosity_packet: resp.data,
						mediaSeq: media.sequence,
					});
					resolve({ pages: pages });
				});
			} else if (media.mime_type.startsWith("text/csv")) {
				let req = new XMLHttpRequest();
				req.open("get", media.signed_url, true);
				req.onload = () => {
					let blob = req.response;

					pages.push({
						type: "csv",
						csvString: blob,
						url: media.signed_url,
						label: media.label,
						page_ref_id: media.page_ref_id,
						mediaSeq: media.sequence,
					});
					resolve({ pages: pages });
				};
				req.send(null);
			} else if (media.mime_type == "package/html") {
				pages.push({
					type: "html",
					url: media.value,
					label: media.label,
					page_ref_id: media.page_ref_id,
					signing_cookies: media.signing_cookies,
					width: media.width,
					height: media.height,
					mediaSeq: media.sequence,
				});
				resolve({ pages: pages });
			} else if (media.mime_type.startsWith("text/html") || media.mime_type == "text/plain") {
				var previewerPayload = "";
				if (media.previewer_r != "") {
					let enc = new TextEncoder();
					let u8array = enc.encode(media.previewer_r);
					previewerPayload = `new TextDecoder().decode(new Uint8Array([${u8array}]))`;
				}
				pages.push({
					type: "html",
					url: media.signed_url,
					label: media.label,
					page_ref_id: media.page_ref_id,
					width: media.width,
					height: media.height,
					mediaSeq: media.sequence,
					previewer_item_identifier: media.previewer_item_identifier,
					previewer_interaction_identifier: media.previewer_interaction_identifier,
					previewer_r: previewerPayload,
					raw_source: media.previewer_r,
					viewSource: false,
				});
				resolve({ pages: pages });
			} else if (media.mime_type == "html/brightcove") {
				pages.push({
					type: "brightcove",
					videoID: media.path,
					label: media.label,
					page_ref_id: media.page_ref_id,
					mediaSeq: media.sequence,
				});
				resolve({ pages: pages });
			} else if (media.mime_type === "iris") {
				pages.push({
					type: "iris",
				});
				resolve({ pages });
			} else {
				resolve({ pages: [{ blank: true }] });
			}
		});
	},

	getMediaURL(response, media) {
		var surl = `/v1/signed/media/${response.id}/${media.sequence}`;
		if (response.back_combatable) {
			surl = `/v1/signed/response/${response.id}`;
		}
		if (response.qc_type) {
			surl = `/v1/signed/qc/media/${response.id}/${media.sequence}`;
			if (response.back_combatable) {
				surl = `/v1/signed/qc/response/${response.id}/${media.sequence}`;
			}
		}

		return axios.get(surl);
	},

	getCopyleaksReportURL(responseID, mediaIndex) {
		return axios.get(`/v1/signed/copyleaks_report/${responseID}/${mediaIndex}`);
	},

	downloadResponse(item, response) {
		let url = "";
		if (response.qc_type) {
			url = `/v1/print/qc/${response.id}`;
		} else {
			url = `/v1/print/response/${response.id}`;
		}

		let origFileName = response.text;
		let parts = origFileName.split(".");
		let ext = parts[parts.length - 1];
		let niceFileName = `${item.ref_id}_${response.ref_id}.${ext}`;

		$.ajax({
			url: url,
			xhrFields: {
				responseType: "blob",
			},
			success: (data) => {
				var url = window.URL || window.webkitURL;
				var path = url.createObjectURL(data);
				const link = document.createElement("a");
				link.href = path;
				link.setAttribute("download", niceFileName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
			error: (e) => {
				console.error(e);
			},
		});
	},

	getIrisToken(respID, isQc) {
		return axios.get(`/v1/iris/token?responseID=${respID}&isQc=${isQc}`);
	},

	getCambiumUrl() {
		return axios.get(`/v1/iris/url`);
	},
};
