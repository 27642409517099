<template>
	<div class="tab-pane" :class="{ active: defaultTab, show: defaultTab }" :id="id">
		<div class="p-4 b-b _600 nav-title">
			{{ $t("ItemEdit.Integrations.title") }}
		</div>

		<!-- Learnosity -->
		<div class="row m-0 pt-4">
			<form role="form" class="px-4 col-2">
				<div class="form-group _600">
					{{ $t("ItemEdit.Integrations.learnosity.title") }}
				</div>
				<div class="form-group">
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="item.learnosity_config.sync_enabled" />
						<i></i>
					</label>
				</div>
			</form>

			<div role="form" class="px-4 col-10" v-if="item.learnosity_config.sync_enabled">
				<div class="row">
					<!-- <div class="col-md-7 col-10 form-group">
						<label>Optional Bank ID</label><input type="text" class="form-control" v-model="item.learnosity_config.bank_id">
					</div> -->
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.learnosity.activity_id") }}</label
						><input
							type="text"
							class="form-control"
							v-model="item.learnosity_config.learnosity_activity_id"
						/>
					</div>
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.learnosity.item_ref") }}</label
						><input type="text" class="form-control" v-model="item.learnosity_config.learnosity_item_ref" />
					</div>
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.learnosity.organisation_id") }}</label
						><input
							type="text"
							class="form-control"
							v-model.number="item.learnosity_config.organisation_id"
						/>
					</div>
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.learnosity.section_ref_id") }}</label
						><input type="text" class="form-control" v-model="item.learnosity_config.section_ref_id" />
					</div>
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.learnosity.optional_question_ref") }}</label
						><input
							type="text"
							class="form-control"
							v-model="item.learnosity_config.learnosity_question_ref"
						/>
					</div>
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.learnosity.res_filter") }}</label
						><input type="text" class="form-control" v-model="item.learnosity_config.filter" />
					</div>
					<div class="col-md-7 col-10">
						<div class="row">
							<div class="form-group col-sm-6 col-12 d-flex flex-row align-items-center">
								{{ $t("ItemEdit.Integrations.learnosity.render_item") }}
								<label class="ml-2 ui-switch ui-switch-md theme-accent">
									<input type="checkbox" v-model="item.learnosity_config.render_item" />
									<i></i>
								</label>
							</div>
							<div class="form-group col-md-6 col-12">
								<button
									class="btn btn-block btn-success"
									@click="fullResync"
									v-tippy
									:title="resyncTooltip()"
									:disabled="!item.id"
								>
									<i class="fas fa-sync mr-1" />
									{{ $t("tooltip.adam_resync.full_resync") }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<hr class="mt-0 mb-2" />
		<!-- CRASE -->
		<!-- <div class="row m-0">
      <form role="form" class="px-4 col-2">
        <div class="form-group _600">
          {{ $t("ItemEdit.Integrations.crase.title") }}
        </div>
        <div class="form-group">
          <label class="ui-switch ui-switch-md theme-accent">
            <input type="checkbox" v-model="item.crase_config.enabled" />
            <i></i>
          </label>
        </div>
      </form>

      <div role="form" class="px-4 col-10" v-if="item.crase_config.enabled">
        <div class="row">
          <div class="col-md-7 col-10 form-group">
            <label>{{ $t("ItemEdit.Integrations.crase.account") }}</label
            ><input
              type="text"
              class="form-control"
              v-model="item.crase_config.account"
            />
          </div>
          <div class="col-md-7 col-10 form-group">
            <label>{{ $t("ItemEdit.Integrations.crase.program") }}</label
            ><input
              type="text"
              class="form-control"
              v-model="item.crase_config.program"
            />
          </div>
          <div class="col-md-7 col-10 form-group">
            <label>{{ $t("ItemEdit.Integrations.crase.item_id") }}</label
            ><input
              type="text"
              class="form-control"
              v-model="item.crase_config.item_id"
            />
          </div>
          <div class="col-md-7 col-10 form-group">
            <label>{{ $t("ItemEdit.Integrations.crase.ai_scorer_id") }}</label
            ><input
              type="text"
              class="form-control"
              v-model="item.crase_config.system_user"
            />
          </div>
          <div class="col-md-7 col-10 form-group">
            <div class="row">
              <div class="col-8">
                <label>{{
                  $t("ItemEdit.Integrations.crase.ai_score_type")
                }}</label>
                <select
                  class="form-control"
                  v-model="item.crase_config.scoring_type"
                >
                  <option value="FIRST">{{
                    $t("ItemEdit.Integrations.crase.first")
                  }}</option>
                  <option value="SECOND">{{
                    $t("ItemEdit.Integrations.crase.second")
                  }}</option>
                  <option value="RESOLUTION">{{
                    $t("ItemEdit.Integrations.crase.resolution")
                  }}</option>
                </select>
              </div>
              <div class="col-4">
                <label>{{
                  $t("ItemEdit.Integrations.crase.percentage")
                }}</label>
                <percent-input v-model="item.crase_config.percent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-0 mb-2" /> -->

		<!-- Writeshift -->
		<div class="row m-0">
			<form role="form" class="px-4 col-2">
				<div class="form-group _600">
					{{ $t("ItemEdit.Integrations.writeshift.title") }}
				</div>
				<div class="form-group">
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="item.writeshift_config.enabled" />
						<i></i>
					</label>
				</div>
			</form>

			<div role="form" class="px-4 col-10" v-if="item.writeshift_config.enabled">
				<div class="row">
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.writeshift.prompt_code") }}</label>
						<input type="text" class="form-control" v-model="item.writeshift_config.prompt_code" />
					</div>
					<div class="col-md-5 col-2 form-group"></div>
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.writeshift.ai_scorer_id") }}</label>
						<input type="text" class="form-control" v-model="item.writeshift_config.system_user" />
					</div>
					<div class="col-md-5 col-2 form-group"></div>
					<div class="col-md-5 col-6 form-group">
						<label>{{ $t("ItemEdit.Integrations.writeshift.credential") }}</label>
						<v-select
							:options="creds"
							label="cred_id"
							v-model="item.writeshift_config.credential_id"
							:reduce="(o) => o.id"
						/>
					</div>
					<div class="col-4 col-md-3">
						<div class="form-group">{{ $t("ItemEdit.Integrations.writeshift.workkeys") }}</div>
						<label
							class="ui-switch ui-switch-md theme-accent"
							v-tippy="{
								theme: 'popover',
								placement: 'top-start',
							}"
							:title="$t('ItemEdit.Integrations.writeshift.workkeys_tooltip')"
						>
							<input type="checkbox" v-model="item.writeshift_config.work_keys" />
							<i></i>
						</label>
					</div>
					<div class="col-md-4 col-2 form-group"></div>
					<div class="col-md-5 col-6 form-group">
						<label>{{ $t("ItemEdit.Integrations.writeshift.ai_score_type") }}</label>
						<v-select
							:options="aiScoringTypes"
							label="name"
							v-model="item.writeshift_config.scoring_type"
							:reduce="(o) => o.id"
						/>
					</div>
					<div class="col-4 col-md-3 form-group">
						<label>{{ $t("ItemEdit.Integrations.writeshift.percentage") }}</label>
						<percent-input v-model="item.writeshift_config.percent" />
					</div>
					<div class="col-2 col-md-4 form-group"></div>

					<div class="col-2 col-md-2 form-group">
						<div class="form-group">{{ $t("ItemEdit.Integrations.writeshift.holistic_only") }}</div>
						<label
							v-if="canActivateWriteshiftHolisticOnly"
							class="ui-switch ui-switch-md theme-accent"
							v-tippy="{
								theme: 'popover',
								placement: 'top-start',
							}"
							:title="$t('ItemEdit.Integrations.writeshift.holistic_only_tooltip')"
						>
							<input type="checkbox" v-model="item.writeshift_config.holistic_only" />
							<i></i>
						</label>
						<label
							v-else
							class="ui-switch ui-switch-md theme-accent"
							v-tippy="{
								theme: 'popover',
								placement: 'top-start',
							}"
							:title="$t('ItemEdit.Integrations.writeshift.holistic_only_tooltip')"
						>
							<input type="checkbox" value="false" disabled />
							<i class="disabled"></i>
						</label>
					</div>

					<div class="col-2 col-md-2 form-group">
						<div class="form-group">{{ $t("ItemEdit.Integrations.writeshift.reroute_errors") }}</div>
						<label
							class="ui-switch ui-switch-md theme-accent"
							v-tippy="{
								theme: 'popover',
								placement: 'top-start',
							}"
							:title="$t('ItemEdit.Integrations.writeshift.reroute_errors_tooltip')"
						>
							<input type="checkbox" v-model="item.writeshift_config.reroute_errors" />
							<i></i>
						</label>
					</div>
					<div
						class="col-4 col-md-3 form-group"
						:class="{ invalid: !valid.field('writeshift_reroute_section') }"
					>
						<template v-if="item.writeshift_config.reroute_errors">
							<label>{{ $t("Auditing.to_section") }}</label>
							<config-select
								:options="sections"
								v-model="item.writeshift_config.reroute_section_id"
								byField="id"
								@input="moveToSectionChanged"
							></config-select>
						</template>
					</div>
					<div
						class="col-4 col-md-3 form-group"
						:class="{ invalid: !valid.field('writeshift_reroute_item') }"
					>
						<template
							v-if="item.writeshift_config.reroute_errors && item.writeshift_config.reroute_section_id"
						>
							<label>{{ $t("Auditing.to_item") }}</label>
							<config-select
								:options="moveToItems"
								v-model="item.writeshift_config.reroute_item_id"
								byField="id"
								@input="moveToItemChanged"
							></config-select>
						</template>
					</div>
					<div class="col-2 col-md-4 form-group"></div>
				</div>
			</div>
		</div>
		<hr class="mt-0 mb-2" />
		<!-- AMI -->
		<div class="row m-0">
			<form role="form" class="px-4 col-2">
				<div class="form-group _600">
					{{ $t("ItemEdit.Integrations.ami.title") }}
				</div>
				<div class="form-group">
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="item.ami_config.enabled" />
						<i></i>
					</label>
				</div>
			</form>
			{{ item.ami_config.error }}

			<div role="form" class="px-4 col-10" v-if="item.ami_config.enabled">
				<div class="row">
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.ami.api_key") }}</label
						><input type="text" class="form-control" v-model="item.ami_config.api_key" />
					</div>
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.ami.item_id") }}</label
						><input type="text" class="form-control" v-model="item.ami_config.item_id" />
					</div>
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.ami.url") }}</label
						><input type="text" class="form-control" v-model="item.ami_config.url" />
					</div>
					<!-- <div class="col-md-7 col-10 form-group">
            <label>{{ $t("ItemEdit.Integrations.ami.region") }}</label
            ><input
              type="text"
              class="form-control"
              v-model="item.ami_config.region"
            />
          </div> -->
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.erater.ai_scorer_id") }}</label
						><input type="text" class="form-control" v-model="item.ami_config.system_user" />
					</div>
					<div class="col-md-7 col-10 form-group">
						<div class="row">
							<div class="col-8">
								<label>{{ $t("ItemEdit.Integrations.erater.ai_score_type") }}</label>
								<select class="form-control" v-model="item.ami_config.scoring_type">
									<option value="FIRST">{{ $t("ItemEdit.Integrations.erater.first") }}</option>
									<option value="SECOND">{{ $t("ItemEdit.Integrations.erater.second") }}</option>
									<option value="RESOLUTION">
										{{ $t("ItemEdit.Integrations.erater.resolution") }}
									</option>
								</select>
							</div>
							<div class="col-4">
								<label>{{ $t("ItemEdit.Integrations.erater.percentage") }}</label>
								<percent-input v-model="item.ami_config.percent" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<hr class="mt-0 mb-2" />
		<!-- EMMA -->
		<div class="row m-0">
			<form role="form" class="px-4 col-2">
				<div class="form-group _600">
					{{ $t("ItemEdit.Integrations.emma.title") }}
				</div>
				<div class="form-group">
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="item.emma_config.enabled" />
						<i></i>
					</label>
				</div>
			</form>

			<div role="form" class="px-4 col-10" v-if="item.emma_config.enabled">
				<div class="row">
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.emma.prompt_code") }}</label
						><input type="text" class="form-control" v-model="item.emma_config.prompt_code" />
					</div>
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.emma.ai_scorer_id") }}</label
						><input type="text" class="form-control" v-model="item.emma_config.system_user" />
					</div>
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.emma.url_override") }}</label
						><input type="text" class="form-control" v-model="item.emma_config.url_override" />
					</div>
					<div class="col-md-7 col-10 form-group">
						<div class="row">
							<div class="col-8">
								<label>{{ $t("ItemEdit.Integrations.emma.ai_score_type") }}</label>
								<select class="form-control" v-model="item.emma_config.scoring_type">
									<option value="FIRST">{{ $t("ItemEdit.Integrations.emma.first") }}</option>
									<option value="SECOND">{{ $t("ItemEdit.Integrations.emma.second") }}</option>
									<option value="RESOLUTION">
										{{ $t("ItemEdit.Integrations.emma.resolution") }}
									</option>
								</select>
							</div>
							<div class="col-4">
								<label>{{ $t("ItemEdit.Integrations.emma.percentage") }}</label>
								<percent-input v-model="item.emma_config.percent" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<hr class="mt-0 mb-2" />
		<!-- Continuous Flow -->
		<div v-if="client.iea_integration && client.iea_tenant_id" class="row m-0">
			<form role="form" class="px-4 col-2">
				<div class="form-group _600">
					{{ $t("ItemEdit.Integrations.cf.title") }}
				</div>
				<div class="form-group">
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="item.cf_config.enabled" />
						<i></i>
					</label>
				</div>
			</form>

			<div role="form" class="px-4 col-10" v-if="item.cf_config.enabled">
				<div class="row">
					<div class="col-md-7 col-10">
						<div class="row">
							<div class="col-12 col-sm-6 form-group">
								<label>{{ $t("ItemEdit.Integrations.cf.iea_tenant_id") }}</label
								><input type="text" class="form-control" disabled :value="client.iea_tenant_id" />
							</div>
						</div>
						<div class="row">
							<div class="col-12 form-group">
								<label class="mb-0">
									{{ $t("ItemEdit.Integrations.cf.admin_name") }}
									<i
										v-if="anyProjects() && !anyProjectsCanDoIEA()"
										class="text-warning fas fa-exclamation-triangle"
										v-tippy="{ theme: 'popover' }"
										:title="$t('ItemEdit.Integrations.cf.no_iea_projects')"
									/>
									<i
										v-if="!anyProjects()"
										class="text-warning fas fa-exclamation-triangle"
										v-tippy="{ theme: 'popover' }"
										:title="$t('ItemEdit.Integrations.cf.no_projects')"
									/>
								</label>

								<input
									style="width: 50%"
									v-if="allIeaAdminNames.length <= 0"
									type="text"
									class="form-control text-force-muted mt-2"
									value="(none)"
									disabled
								/>
								<template v-else>
									<div
										v-for="name in allIeaAdminNames"
										:key="name"
										class="d-flex flex-row align-items-start"
									>
										<input
											style="max-width: calc(50% - 15px); margin-right: 15px"
											type="text"
											class="form-control mt-2"
											:value="name"
											disabled
										/>
										<test-iea-item-button
											class="mt-2"
											:client="client"
											:item="item"
											:adminName="name"
										/>
									</div>
								</template>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<div class="text-xxs">
									<i
										class="fas fa-exclamation-triangle text-warning mr-1"
										style="font-size: 14px"
									/>Changing the IEA Item ID directly may cause problems with IEA integration.<br />Only
									do so if you know the value you're using is valid.<br />Otherwise, use the
									<a tabindex class="link" @click="goToIeaModal">IEA configuration interface</a> or
									the
									<a tabindex class="link" @click="goToPreviewerImport">Previewer import interface</a>
								</div>
							</div>
							<div class="col-12 col-sm-6 form-group">
								<label>{{ $t("ItemEdit.Integrations.cf.item_id") }}</label
								><input
									type="text"
									class="form-control fake-disabled"
									v-model="item.cf_config.item_id"
								/>
							</div>
						</div>
					</div>
					<div class="col-md-7 col-10 form-group">
						<label>
							{{ $t("ItemEdit.Integrations.cf.system_user") }}
						</label>
						<config-user-search v-model="cfSystemUser" :clientID="client.id" />
					</div>
					<div class="col-md-7 col-10 form-group">
						<label
							v-tippy="{ theme: 'popover' }"
							:title="$t('ItemEdit.Integrations.cf.catchall_flag_tooltip')"
						>
							{{ $t("ItemEdit.Integrations.cf.catchall_flag") }}
						</label>
						<v-select
							:options="item.avail_alerts"
							v-model="item.cf_config.catchall_flag_id"
							:reduce="(o) => o.alert_id"
							label="code"
						>
							<template slot="selected-option" slot-scope="option">
								<span>{{ option.description }} ({{ option.code }})</span>
							</template>

							<template slot="option" slot-scope="option">
								<span>{{ option.description }} ({{ option.code }})</span>
							</template>
						</v-select>
					</div>
					<!-- <div class="col-md-3 col-2 align-self-end form-group">
						<button class="btn theme btn-block">
							{{ $t("ItemEdit.Integrations.cf.check_flags") }}
						</button>
					</div> -->
					<div class="col-md-7 col-10">
						<div class="row">
							<div class="col-6">
								<div class="form-group">
									{{ $t("ItemEdit.Integrations.cf.score_passback") }}
								</div>
								<div>
									<label
										class="ui-switch ui-switch-md theme-accent"
										v-tippy="{ theme: 'popover' }"
										:title="$t('ItemEdit.Integrations.cf.score_passback_tooltip')"
									>
										<input type="checkbox" v-model="item.cf_config.score_passback" />
										<i></i>
									</label>
								</div>
							</div>
							<div class="col-6">
								<div class="form-group">
									{{ $t("ItemEdit.Integrations.cf.conditions_as_scores") }}
								</div>
								<div>
									<label
										class="ui-switch ui-switch-md theme-accent"
										v-tippy="{ theme: 'popover' }"
										:title="$t('ItemEdit.Integrations.cf.conditions_as_scores_tooltip')"
									>
										<input type="checkbox" v-model="item.cf_config.conditions_as_scores" />
										<i></i>
									</label>
								</div>
							</div>
						</div>
					</div>
					<hr class="w-100" />
					<div class="col-md-7 col-10 d-flex flex-row">
						<div>
							<div class="form-group">
								{{ $t("ItemEdit.Integrations.cf.flag_lid") }}
							</div>
							<div class="form-group">
								<label
									class="ui-switch ui-switch-md theme-accent"
									v-tippy="{ theme: 'popover' }"
									:title="$t('ItemEdit.Integrations.cf.flag_lid_tooltip')"
								>
									<input type="checkbox" v-model="item.cf_config.flag_lid" />
									<i></i>
								</label>
							</div>
						</div>
						<div v-if="item.cf_config.flag_lid" class="flex ml-4">
							<label>
								{{ $t("ItemEdit.Integrations.cf.lid_alert_flag") }}
							</label>
							<v-select
								:options="item.avail_alerts"
								v-model="item.cf_config.lid_flag_id"
								:reduce="(o) => o.alert_id"
								label="code"
							>
								<template slot="selected-option" slot-scope="option">
									<span>{{ option.description }} ({{ option.code }})</span>
								</template>

								<template slot="option" slot-scope="option">
									<span>{{ option.description }} ({{ option.code }})</span>
								</template>
							</v-select>
						</div>
					</div>
					<div v-if="item.cf_config.flag_lid" class="col-md-7 col-10">
						<div class="form-group">
							{{ $t("ItemEdit.Integrations.cf.ignore_score") }}
						</div>
						<div class="form-group">
							<label
								class="ui-switch ui-switch-md theme-accent"
								v-tippy="{ theme: 'popover' }"
								:title="$t('ItemEdit.Integrations.cf.ignore_score_tooltip')"
							>
								<input type="checkbox" v-model="item.cf_config.ignore_score" />
								<i></i>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <hr class="mt-0 mb-2" /> -->
		<!-- AMI -->
		<!-- <div class="row m-0">
      <form role="form" class="px-4 col-2">
        <div class="form-group _600">
          {{ $t("ItemEdit.Integrations.ami.title") }}
        </div>
        <div class="form-group">
          <label class="ui-switch ui-switch-md theme-accent">
            <input type="checkbox" v-model="item.ami_config.enabled" />
            <i></i>
          </label>
        </div>
      </form>
      {{ item.ami_config.error }}

      <div role="form" class="px-4 col-10" v-if="item.ami_config.enabled">
        <div class="row">
          <div class="col-md-7 col-10 form-group">
            <label>{{ $t("ItemEdit.Integrations.ami.api_key") }}</label
            ><input
              type="text"
              class="form-control"
              v-model="item.ami_config.api_key"
            />
          </div>
          <div class="col-md-7 col-10 form-group">
            <label>{{ $t("ItemEdit.Integrations.ami.item_id") }}</label
            ><input
              type="text"
              class="form-control"
              v-model="item.ami_config.item_id"
            />
          </div>
          <div class="col-md-7 col-10 form-group">
            <label>{{ $t("ItemEdit.Integrations.ami.url") }}</label
            ><input
              type="text"
              class="form-control"
              v-model="item.ami_config.url"
            />
          </div>
          <div class="col-md-7 col-10 form-group">
            <label>{{ $t("ItemEdit.Integrations.erater.ai_scorer_id") }}</label
            ><input
              type="text"
              class="form-control"
              v-model="item.ami_config.system_user"
            />
          </div>
          <div class="col-md-7 col-10 form-group">
            <div class="row">
              <div class="col-8">
                <label>{{
                  $t("ItemEdit.Integrations.erater.ai_score_type")
                }}</label>
                <select
                  class="form-control"
                  v-model="item.ami_config.scoring_type"
                >
                  <option value="FIRST">{{
                    $t("ItemEdit.Integrations.erater.first")
                  }}</option>
                  <option value="SECOND">{{
                    $t("ItemEdit.Integrations.erater.second")
                  }}</option>
                  <option value="RESOLUTION">{{
                    $t("ItemEdit.Integrations.erater.resolution")
                  }}</option>
                </select>
              </div>
              <div class="col-4">
                <label>{{
                  $t("ItemEdit.Integrations.erater.percentage")
                }}</label>
                <percent-input v-model="item.ami_config.percent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
		<hr class="mt-0 mb-2" />
		<!-- API Export -->
		<div class="row m-0">
			<form role="form" class="px-4 col-2">
				<div class="form-group _600">
					{{ $t("ItemEdit.Integrations.scores_api.title") }}
				</div>
				<div class="form-group">
					<label class="ui-switch ui-switch-md theme-accent">
						<input type="checkbox" v-model="item.api_export_config.enabled" />
						<i></i>
					</label>
				</div>
			</form>

			<div role="form" class="px-4 col-10" v-if="item.api_export_config.enabled">
				<div class="row mb-2">
					<div class="col-md-7 col-10 form-group">
						<label>{{ $t("ItemEdit.Integrations.scores_api.endpoint") }}</label>
						<input
							type="text"
							v-if="!item.api_export_config.loopback"
							class="form-control"
							:disabled="item.api_export_config.type == 4"
							v-model="item.api_export_config.url"
						/>
						<input
							type="text"
							v-if="item.api_export_config.loopback"
							class="form-control"
							:disabled="true"
							:value="$t('ItemEdit.Integrations.scores_api.oscar_loopback')"
						/>
					</div>
					<div class="col-2 col-md-5">
						<div class="form-group">{{ $t("ItemEdit.Integrations.scores_api.loopback") }}</div>
						<label
							class="ui-switch ui-switch-md theme-accent"
							v-tippy="{
								theme: 'popover',
								placement: 'top-start',
							}"
							:title="$t('ItemEdit.Integrations.scores_api.loopback_tooltip')"
						>
							<input type="checkbox" v-model="item.api_export_config.loopback" />
							<i></i>
						</label>
					</div>

					<template
						v-if="
							item.api_export_config.type == 3 ||
							item.api_export_config.type == 5 ||
							item.api_export_config.type == 7 ||
							item.api_export_config.type == 8
						"
					>
						<div class="col-md-7 col-10 form-group">
							<label>
								{{ $t("ItemEdit.Integrations.scores_api.auth_url") }}
							</label>
							<input type="text" class="form-control" v-model="item.api_export_config.auth_url" />
						</div>
						<div class="col-2 col-md-5"></div>
					</template>

					<div class="col-10 col-md-6">
						<div class="row">
							<div class="col-6 form-group">
								<div style="margin-bottom: 12.5px">
									{{ $t("ItemEdit.Integrations.scores_api.last_score_only") }}
								</div>
								<label class="ui-switch ui-switch-md theme-accent">
									<input type="checkbox" v-model="item.api_export_config.only_last_score" />
									<i></i>
								</label>
							</div>

							<div class="col-6 form-group">
								<div style="margin-bottom: 12.5px">
									{{ $t("ItemEdit.Integrations.scores_api.last_score_final_score") }}
								</div>
								<label class="ui-switch ui-switch-md theme-accent">
									<input
										type="checkbox"
										v-model="item.api_export_config.use_last_score_final_score"
									/>
									<i></i>
								</label>
							</div>

							<div class="col-6 form-group">
								<div style="margin-bottom: 12.5px">
									{{ $t("ItemEdit.Integrations.scores_api.include_media") }}
								</div>
								<label class="ui-switch ui-switch-md theme-accent">
									<input type="checkbox" v-model="item.api_export_config.include_media" />
									<i></i>
								</label>
							</div>
							
							<div class="col-6 form-group">
								<div style="margin-bottom: 12.5px">
									{{ $t("ItemEdit.Integrations.scores_api.calculate_final_score_by_trait") }}
								</div>
								<label
									class="ui-switch ui-switch-md theme-accent"
									v-tippy="{ theme: 'popover' }"
									:title="
										$t('ItemEdit.Integrations.scores_api.calculate_final_score_by_trait_tooltip')
									"
								>
									<input type="checkbox" v-model="item.api_export_config.trait_score_final_score" />
									<i></i>
								</label>
							</div>
							<div class="col-6 form-group">
								<div style="margin-bottom: 12.5px">
									{{ $t("ItemEdit.Integrations.scores_api.include_blank_score_flag") }}
								</div>
								<label class="ui-switch ui-switch-md theme-accent">
									<input type="checkbox" v-model="item.api_export_config.include_blank_score_flag" />
									<i></i>
								</label>
							</div>
							<!-- <div class="col-6 form-group">
								<div style="margin-bottom: 12.5px">
									{{ $t("ItemEdit.Integrations.scores_api.use_final_score_mapping_table") }}
								</div>
								<label
									class="ui-switch ui-switch-md theme-accent"
									v-tippy="{ theme: 'popover' }"
									:title="
										$t('ItemEdit.Integrations.scores_api.use_final_score_mapping_table_tooltip')
									"
								>
									<input type="checkbox" v-model="item.api_export_config.use_final_score_mapping_table" />
									<i></i>
								</label>
								&nbsp;&nbsp;&nbsp;
								<button v-show="item.api_export_config.use_final_score_mapping_table"
								        class="my-1 btn btn-primary btn-sm theme-accent text-white m-0"
										data-toggle="modal"
										data-target="#scoreMappingModal"
										v-tippy
										:title="$t('RubricEdit.configure_mapping_table')"
									>
											<span>{{ $t("RubricEdit.configure_mapping_table") }}</span>

								</button>							
							</div> -->

							<div v-if="item.api_export_config.type != 4" class="col-12 form-group">
								<label>{{ $t("ItemEdit.Integrations.scores_api.final_score") }}</label>
								<v-select
									:options="finalScoreTypes"
									label="name"
									v-model="item.api_export_config.final_score_type"
									:reduce="(o) => o.id"
								>
									<template slot="option" slot-scope="option">
										<div>{{ option.name }}</div>
										<div
											v-if="option.description"
											class="text-xxs text-muted"
											v-html="option.description"
										></div>
									</template>
								</v-select>
							</div>
						</div>
					</div>

					<div class="col-5 col-md-4 mb-2">
						<label>{{ $t("ItemEdit.Integrations.scores_api.endpoint_type") }}</label>
						<v-select
							class="form-group"
							:options="endpointTypes"
							label="name"
							v-model="item.api_export_config.type"
							:reduce="(o) => o.id"
						/>

						<label
							v-if="
								item.api_export_config.type == 2 ||
								item.api_export_config.type == 3 ||
								item.api_export_config.type == 4 ||
								item.api_export_config.type == 5 ||
								item.api_export_config.type == 6 ||
								item.api_export_config.type == 7 ||
								item.api_export_config.type == 8
							"
							>{{ $t("ItemEdit.Integrations.scores_api.credential") }}</label
						>
						<v-select
							v-if="
								item.api_export_config.type == 2 ||
								item.api_export_config.type == 3 ||
								item.api_export_config.type == 4 ||
								item.api_export_config.type == 5 ||
								item.api_export_config.type == 6 ||
								item.api_export_config.type == 7 ||
								item.api_export_config.type == 8
							"
							:options="creds"
							label="cred_id"
							v-model="item.api_export_config.credential_id"
							:reduce="(o) => o.id"
						/>
					</div>
					<div class="col-md-7 col-10 form-group">
						<div class="row">
							<div class="col-3">
								<div class="form-group">
									{{ $t("ItemEdit.Integrations.scores_api.auto_deliver") }}
								</div>
							</div>
							<div class="col-6" v-show="item.api_export_config.auto_deliver_enabled">
								<label>{{ $t("ItemEdit.Integrations.scores_api.delivery_type") }}</label>
							</div>
							<div
								class="col-3"
								v-show="
									item.api_export_config.auto_deliver_enabled &&
									item.api_export_config.auto_deliver_type == 2
								"
							>
								<label>{{ $t("ItemEdit.Integrations.scores_api.delay_hours") }}</label>
							</div>
						</div>
						<div class="row">
							<div class="col-3">
								<div class="form-group">
									<label class="ui-switch ui-switch-md theme-accent">
										<input type="checkbox" v-model="item.api_export_config.auto_deliver_enabled" />
										<i></i>
									</label>
								</div>
							</div>
							<div class="col-6" v-show="item.api_export_config.auto_deliver_enabled">
								<v-select
									class="form-group"
									:options="autoDeliverTypes"
									label="name"
									v-model="item.api_export_config.auto_deliver_type"
									:reduce="(o) => o.id"
								/>
							</div>
							<div
								class="col-3"
								v-show="
									item.api_export_config.auto_deliver_enabled &&
									item.api_export_config.auto_deliver_type == 2
								"
							>
								<input
									type="number"
									class="form-control"
									v-model.number="item.api_export_config.delay_hours"
								/>
							</div>
						</div>
						<div class="row">
							<div class="col-3"></div>
							<div class="col-9 d-flex flex-column" v-show="item.api_export_config.auto_deliver_enabled">
								<label class="display-block">Export on:</label>

								<label class="md-check nowrap">
									<input type="checkbox" disabled checked />
									<i class="theme-accent"></i>
									{{ $t("ItemEdit.Integrations.scores_api.complete_first") }}
								</label>

								<label class="md-check nowrap">
									<input type="checkbox" v-model="item.api_export_config.complete_subsequent" />
									<i class="theme-accent"></i>
									{{ $t("ItemEdit.Integrations.scores_api.complete_subsequent") }}
								</label>

								<label
									v-if="
										item.api_export_config.auto_deliver_type == 1 &&
										item.api_export_config.type != 4
									"
									class="md-check nowrap"
								>
									<input type="checkbox" v-model="item.api_export_config.complete_backread" />
									<i class="theme-accent"></i>
									{{ $t("ItemEdit.Integrations.scores_api.complete_backread") }}
								</label>

								<label
									v-if="
										item.api_export_config.auto_deliver_type == 1 &&
										item.api_export_config.type != 4
									"
									class="md-check nowrap"
								>
									<input type="checkbox" v-model="item.api_export_config.complete_rescore" />
									<i class="theme-accent"></i>
									{{ $t("ItemEdit.Integrations.scores_api.complete_rescore") }}
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Score Mapping Modal -->
		<div class="modal" id="scoreMappingModal" data-backdrop="static" data-keyboard="false" v-if="item.rubric">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5>
							{{ $t("RubricEdit.final_score_mapping") }}
						</h5>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12">
								<table class="table table-condensed mb-0">
									<thead>
										 <th style="width: 0px" v-for="(row, i) in item.rubric.traits" :key="i">
											{{row.name}}
										</th> 
										<th>{{ $t("RubricEdit.final_score") }}</th>
									</thead>
									<tbody>
										 <tr v-for="row in item.final_score_mapping" :key="row">
											<td class="v-mid" v-for="(value, key, index) in row" :key="index">
												<input type="number" class="form-control" v-model="row[key]" />
											</td>
											<td class="v-mid">
												<button class="btn btn-sm btn-icon btn-rounded btn-danger">
													<i class="fas fa-minus" @click="deleteMappingRow(row)" />
												</button>
											</td> 
										</tr> 
									</tbody>
								</table>
								<button class="mt-2 float-right btn btn-sm btn-icon btn-rounded btn-success">
									<i class="fas fa-plus" @click="addMappingRow"/> 
								</button>
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<button class="btn btn-flat" data-dismiss="modal">{{ $t("buttons.done") }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
i.disabled::after {
	background-color: #adb5bd;
}

i.disabled::before {
	background-color: #e9ecef;
}
</style>

<script>
import TenantService from "@/services/TenantService";
import ItemService from "@/services/ItemService";
import ConfigService from "@/services/ConfigService";
import UserService from "@/services/UserService";
import Store from "@/services/Store";
import Notie from "@/services/NotieService";
import fs from "@/services/FormatService";

import PercentInput from "@/components/PercentInput";
import ConfigSelect from "@/components/ConfigSelect";
import ConfigUserSearch from "@/components/ConfigUserSearch";
import TestIeaItemButton from "@/components/TestIeaItemButton";

export default {
	name: "ItemEditIntegrations",

	props: ["id", "item", "client", "defaultTab", "user", "projects", "valid"],

	components: {
		PercentInput,
		ConfigSelect,
		ConfigUserSearch,
		TestIeaItemButton,
	},

	data() {
		return {
			creds: [],
			finalScoreTypes: fs.finalScoreTypes(),
			aiScoringTypes: fs.aiScoringTypes(),
			endpointTypes: fs.endpointTypes(),
			autoDeliverTypes: fs.autoDeliverTypes(),

			//Writeshift moving section/item
			sections: [],
			moveToSection: null,
			moveToItems: [],
			moveToItem: null,

			cfSystemUser: null,
		};
	},

	created() {
		this.loadData();
		if (
			this.item.cf_config &&
			this.item.cf_config.system_user_id &&
			this.item.cf_config.system_user_id != "000000000000000000000000"
		) {
			this.initCfSystemUser();
		}
	},

	computed: {
		canActivateWriteshiftHolisticOnly() {
			if (!this.item.rubric) return false;
			const { traits } = this.item.rubric;
			return traits.length === 1 && traits[0].external_trait_id === "Holistic";
		},

		allIeaAdminNames() {
			if (!this.projects) {
				return [];
			}
			let nameMap = {};
			for (let project of this.projects) {
				if (project.iea_project && project.iea_project.projectId) {
					nameMap[project.iea_project.projectId] = true;
				}
			}
			let names = Object.keys(nameMap);
			return names;
		},
	},

	watch: {
		"item.api_export_config.type"() {
			this.item.api_export_config.url = "";
			this.item.api_export_config.auth_url = "";
			if (this.item && this.item.api_export_config && this.item.api_export_config.type == 4) {
				this.item.api_export_config.url = "https://scorpion.caveon.com";
				this.item.api_export_config.complete_backread = false;
				this.item.api_export_config.complete_rescore = false;
			}
			if (this.item && this.item.api_export_config && this.item.api_export_config.type == 5) {
				this.item.api_export_config.url =
					"https://sirest.iscore.cognia.org/scoringintegrationrestrest/api/PostMZDScore";
				this.item.api_export_config.auth_url =
					"https://sirest.iscore.cognia.org/scoringintegrationrestrest/api/Account/Token";
			}
		},

		"item.api_export_config.auto_deliver_type"() {
			if (this.item && this.item.api_export_config && this.item.api_export_config.auto_deliver_type != 1) {
				this.item.api_export_config.complete_backread = false;
				this.item.api_export_config.complete_rescore = false;
			}
		},

		canActivateWriteshiftHolisticOnly(value) {
			if (!value) {
				this.item.writeshift_config.holistic_only = false;
			}
		},

		// moveToSection() {
		// 	if (!this.moveToSection) {
		// 		return;
		// 	}
		// 	this.moveToItems = this.moveToSection.items;
		// 	this.moveToItems = [];
		// 	if (!this.moveToItem || !_.find(this.moveToItems, { id: this.moveToItem.id })) {
		// 		if (this.moveToItems.length > 0) {
		// 			this.moveToItem = this.moveToItems[0];
		// 		} else {
		// 			this.moveToItem = null;
		// 		}
		// 	}
		// },

		// moveToItem() {
		// 	if (!this.moveToItem) {
		// 	}
		// },

		cfSystemUser() {
			if (!this.item.cf_config) {
				this.item.cf_config = {};
			}
			if (this.cfSystemUser) {
				if (this.item.cf_config.system_user_id != this.cfSystemUser.id) {
					this.item.cf_config.system_user_id = this.cfSystemUser.id;
				}
			} else {
				if (this.item.cf_config.system_user_id) {
					this.item.cf_config.system_user_id = null;
				}
			}
		},
		"item.cf_config.system_user_id"() {
			this.initCfSystemUser();
		},
	},

	methods: {
		loadData() {
			TenantService.listCredentialsSafe(this.user.client.id)
				.then((r) => {
					this.creds = r.data;
				})
				.catch((e) => {
					console.log(e);
					Notie.error("Failed to load credentials", e);
				});

			this.showHiddenProjects = Store.getDefault(this, "projects.showHiddenProjects");
			var ctx = {};
			ctx.showHiddenProjects = this.showHiddenProjects;
			ConfigService.listSectionsShallow(ctx).then((r) => {
				this.sections = r.data.sections;
				if (this.item.writeshift_config && this.item.writeshift_config.reroute_section_id) {
					this.moveToSectionChanged(this.item.writeshift_config.reroute_section_id);
				}
			});

			if (!this.canActivateWriteshiftHolisticOnly) {
				this.item.writeshift_config.holistic_only = false;
			}
		},

		fullResync() {
			ItemService.adamFullResync(this.item.id)
				.then((r) => {
					Notie.info("Item will be fully resynced from ADAM");
				})
				.catch((e) => {
					console.log(e);
					Notie.error("Failed to clear sync details", e);
				});
		},

		resyncTooltip() {
			let tooltip = `
<p>${this.$i18n.t("tooltip.adam_resync.1")}</p>
<p>${this.$i18n.t("tooltip.adam_resync.2")}</p>
<p>${this.$i18n.t("tooltip.adam_resync.3")}</p>
<p class="mt-2 text-muted">${this.$i18n.t("tooltip.adam_resync.4")}</p>`;
			return tooltip;
		},

		moveToSectionChanged(id) {
			let section = _.find(this.sections, { id: id });
			if (!section) {
				console.error("Failed to find picked section", id);
				return;
			}

			if (!this.item.rubric_id) {
				console.error("No rubric selected in item");
				return;
			}

			this.moveToItems = _.filter(section.items, { rubric_id: this.item.rubric_id });
			if (this.moveToItems && this.moveToItems.length > 0) {
				if (!this.item.writeshift_config.reroute_item_id) {
					this.item.writeshift_config.reroute_item_id = this.moveToItems[0].id;
				}
			} else {
				this.item.writeshift_config.reroute_item_id = null;
			}
		},

		moveToItemChanged(id) {
			console.log("moveToItemChanged", id);
		},

		initCfSystemUser() {
			if (this.item.cf_config.system_user_id) {
				UserService.getUser(this.item.cf_config.system_user_id).then((r) => {
					this.cfSystemUser = r.data;
					this.cfSystemUser.name = fs.nameWithScorerID(this.cfSystemUser);
				});
			} else {
				this.cfSystemUser = null;
			}
		},

		goToIeaModal() {
			this.$emit("openIeaSetupModal");
			console.log("GO TO IEA MODAL");
		},

		goToPreviewerImport() {
			console.log("GO TO PREVIEWER IMPORT");
			Store.set(this, "openPreviewerImportModal", true);
			this.$router.push("/items");
		},

		anyProjects() {
			if (!this.projects) {
				return false;
			}
			return this.projects.length > 0;
		},

		anyProjectsCanDoIEA() {
			if (!this.projects) {
				return false;
			}
			for (let project of this.projects) {
				if (project.iea_project && project.iea_project.projectId) {
					return true;
				}
			}
			return false;
		},

		addMappingRow() {
			var jsonData = {};
			for (let trait of this.item.rubric.traits){
				var columnName = trait.name;
        		jsonData[columnName] = ""
			}
			var columnName = "final_score";
        	jsonData[columnName] = ""
			this.item.final_score_mapping.push(jsonData);
			console.log(this.item)
		},

		deleteMappingRow(row) {
			let index = this.item.final_score_mapping.indexOf(row);
			if (index >= 0) {
				this.$delete(this.item.final_score_mapping, index);
			}
		},
	},
};
</script>
