//Utility functions
import moment from "moment";
import _ from "lodash";
import vm from "../main.js";

export default {
	generateUUID() {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			var r = (Math.random() * 16) | 0,
				v = c == "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	},

	diff(object, base) {
		function changes(object, base) {
			return _.transform(object, function (result, value, key) {
				if (!_.isEqual(value, base[key])) {
					result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
				}
			});
		}
		return changes(object, base);
	},

	truncateString(str, length) {
		var trimmable =
			"\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF";
		var reg = new RegExp("(?=[" + trimmable + "])");
		var words = str.split(reg);
		var count = 0;
		var result = words
			.filter(function (word) {
				count += word.length;
				return count <= length;
			})
			.join("");
		if (result !== str) {
			result += "...";
		}
		return result;
	},

	isNumeric(n) {
		return typeof n == "number" && !isNaN(parseFloat(n)) && isFinite(n);
	},

	getTimeString(seconds) {
		return moment().startOf("day").seconds(seconds).format("H:mm:ss");
	},

	throttleMathJAX(delim) {
		_.debounce(() => {
			console.log("MathJAX Init");
			const inlineMath = [["\\(", "\\)"]];
			if (delim) {
				inlineMath.push([delim, delim]);
			}
			MathJax.Hub.Config({
				tex2jax: {
					inlineMath,
					displayMath: [],
				},
			});
			MathJax.Hub.Typeset();
		}, 50)();
	},

	//Takes time as a formatted string (e.g. "11:30 PM") and converts to an integer number of minutes from midnight
	//Used for comparisons, sorting, time period slicing, etc.
	minutesFromMidnight(time) {
		let rawTime = time.substring(0, time.length - 3);
		let split = rawTime.indexOf(":");
		let hours = parseInt(rawTime.substring(0, split)) % 12;
		let minutes = parseInt(rawTime.substring(split + 1));

		let isAfternoon = time.substring(time.length - 2) == "PM";
		if (isAfternoon) {
			hours += 12;
		}

		return hours * 60 + minutes;
	},

	isRenderableResource(resource) {
		let rmt = this.isRenderableMimeType(resource.mime_type);
		let cpmt = this.isContentPackageMimeType(resource.mime_type);
		return rmt || (cpmt && resource.type == 3);
	},

	isRenderableMimeType(mime_type) {
		if (mime_type == "application/pdf") {
			return true;
		}
		// if (_.endsWith(mime_type, "text")) {
		// 	return true
		// }
		if (_.startsWith(mime_type, "video")) {
			return true;
		}
		if (_.startsWith(mime_type, "audio")) {
			return true;
		}
		if (_.startsWith(mime_type, "image")) {
			return true;
		}
		if (
			mime_type == "application/vnd.ms-excel" ||
			mime_type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
			mime_type.startsWith("text/csv")
		) {
			return true;
		}
		if (_.startsWith(mime_type, "text/html")) {
			return true;
		}
		if (mime_type == "html/brightcove") {
			return true;
		}

		return false;
	},

	isContentPackageMimeType(mime_type) {
		if (mime_type == "application/zip") {
			return true;
		}
		if (mime_type == "application/x-zip-compressed") {
			return true;
		}
		if (mime_type == "package/html") {
			return true;
		}

		return false;
	},

	printElement(el, orientation) {
		// Copy styles from the requesting page into the print page
		let styles = "";
		for (let node of [...document.querySelectorAll('link[rel="stylesheet"], link[as="style"], style')]) {
			styles += node.outerHTML;
		}

		let toPrint = window.open("", "", "left=0,top=0,width=990,height=1280,toolbar=0,scrollbars=0,status=0");

		toPrint.document.write(`<!DOCTYPE html>
		<html>
		<head>
			${styles}
`);
		if (orientation) {
			toPrint.document.write(`<style>
		@page {size: ${orientation}}
		</style>`);
		}

		toPrint.document.write(`</head>
		<body style="background-color: white; -webkit-print-color-adjust: exact; height: 0px; display: block;">
			${el.innerHTML}
		</body>
		</html>`);

		let pages = toPrint.document.querySelectorAll(".printable-page");
		for (let i = 0; i < pages.length; i++) {
			let page = pages[i];
			page.classList.add("printing-page");

			if (i > 0) {
				let pageBreakDiv = document.createElement("div");
				pageBreakDiv.style.breakAfter = "page";
				page.parentNode.insertBefore(pageBreakDiv, page);
			}

			console.log(`PAGE ${i + 1} HEIGHT`, page.offsetHeight);

			if (page.offsetHeight < 1300) {
				let essay = page.querySelector(".essay");
				console.log("get essay", essay);
				if (essay) {
					essay.style.minHeight = `${1415 - page.offsetHeight}px`;
				}
			}
		}

		let origCanvases = el.querySelectorAll("canvas");
		let toPrintCanvases = toPrint.document.querySelectorAll("canvas");
		if (origCanvases.length == toPrintCanvases.length) {
			for (let i = 0; i < origCanvases.length; i++) {
				let imageData = origCanvases[i]
					.getContext("2d")
					.getImageData(0, 0, origCanvases[i].width, origCanvases[i].height);
				toPrintCanvases[i].getContext("2d").putImageData(imageData, 0, 0);
			}
		} else {
			alert("Error loading canvases for rendering");
		}

		// eslint-disable-next-line no-console
		// console.log(toPrint.document);

		toPrint.document.close();
		toPrint.focus();

		//Ensure that images load
		let imgs = toPrint.document.images;
		let links = toPrint.document.querySelectorAll("head link");
		let len = imgs.length + links.length;
		let counter = 0;

		let increment = function () {
			counter++;
			if (counter == len) {
				toPrint.print();
				// toPrint.close();
			}
		};

		_.each(imgs, function (img) {
			img.addEventListener("load", increment, false);
		});

		_.each(links, function (link) {
			link.onload = increment;
		});
	},

	// Return a function that can be used to get meta-data associated with the given element.
	// The metadata is keyed using the "id" field of the element, and stored in the provided metadata object.
	// This is intended so that additional data about an element in an array can be stored without triggering Vue watch events.
	metaAccessor(element, metadata) {
		return function (field) {
			let id = element.id;
			if (!id) {
				console.log("Attempt to get metadata for element with no id", element);
				return;
			}
			if (!metadata[id]) {
				metadata[id] = {};
			}
			return metadata[id][field];
		};
	},

	// Return a function that can be used to set meta-data associated with the given element.
	// The metadata is keyed using the "id" field of the element, and stored in the provided metadata object.
	// This is intended so that additional data about an element in an array can be stored without triggering Vue watch events.
	metaSetter(element, metadata) {
		return function (field, value) {
			let id = element.id;
			if (!id) {
				console.log("Attempt to set metadata for element with no id", element);
				return;
			}
			if (!metadata[id]) {
				metadata[id] = {};
			}
			metadata[id][field] = value;
		};
	},

	generateDebugCode(user) {
		let clientID = user.client ? user.client.id : "<none>";
		let userID = user.id;
		let url = window.location.href;
		let linkString = `${userID}|${clientID}|${url}`;
		let code = btoa(linkString);
		return code;
	},

	detectEnvironment(hostname) {
		console.log("DETECT ENVIRONMENT", hostname);
		if (hostname == "localhost") {
			return "Local";
		}
		if (hostname == "staging.oscarscore.com") {
			return "Staging";
		}
		if (hostname == "app.oscarscore.com") {
			return "Production";
		}
		if (hostname == "oscarscore.uk") {
			return "Production-UK";
		}
		return "Unknown";
	},

	errorPromise(emsg, e) {
		let payload = {
			internalError: {
				emsg: emsg,
				e: e,
			},
		};
		return new Promise((_, reject) => {
			reject(payload);
		});
	},

	isMobileOrTablet() {
		let check = false;
		(function (a) {
			if (
				/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
					a
				) ||
				/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
					a.substr(0, 4)
				)
			)
				check = true;
		})((navigator && (navigator.userAgent || navigator.vendor)) || (window && window.opera));
		return check;
	},

	deepObject(obj, field) {
		let fieldParts = field.split(".");
		if (fieldParts.length == 1) {
			return obj[field];
		}

		let lastObj = obj;
		let key = field;
		while (fieldParts.length > 1) {
			key = fieldParts.shift();
			lastObj = lastObj[key];
		}
		return lastObj[fieldParts[0]];
	},

	// Wraps the existence of a field on the Vue instance
	ensure(vue, field, ready) {
		return new Promise((resolve, reject) => {
			let val = this.deepObject(vue, field);
			if (ready ? ready() : val) {
				resolve(val);
			} else {
				let unwatch = vue.$watch(
					field,
					() => {
						let val = this.deepObject(vue, field);
						if (ready ? ready() : val) {
							unwatch();
							resolve(val);
						}
					},
					{ deep: true }
				);
			}
		});
	},

	ensureWithPolling(vue, field, ready, period) {
		return new Promise((resolve, reject) => {
			if (ready ? ready() : vue[field]) {
				resolve(vue[field]);
			} else {
				let initChecker = setInterval(() => {
					if (ready ? ready() : vue[field]) {
						clearInterval(initChecker);
						resolve(vue[field]);
					}
				}, period || 100);
			}
		});
	},

	trimHtml(el) {
		console.log("TRIM HTML ON ELEMENT", el);
		this.printHtml(el);
		let treeWalker = document.createTreeWalker(el, NodeFilter.SHOW_ELEMENT);
		let currentNode = treeWalker.currentNode;
		let emptyNodes = [];
		let exemptNodes = [];

		// test if a node has no text, regardless of whitespaces
		var isNodeEmpty = (node) => {
			// True when there is a node before and after this node, and they both have text content
			let separator = node.previousSibling && node.nextSibling && node.previousSibling.textContent.trim();
			return !(separator || node.textContent.trim());
		};

		var isNodeNonTextContent = (node) => {
			return node.nodeName == "IMG";
		};

		// find all empty nodes
		while (currentNode) {
			if (isNodeEmpty(currentNode)) {
				emptyNodes.push(currentNode);
			}
			if (isNodeNonTextContent(currentNode)) {
				exemptNodes.push(currentNode);
			}
			currentNode = treeWalker.nextNode();
		}

		console.log("empty nodes", emptyNodes);
		console.log("exempt nodes", exemptNodes);

		exemptNodes.forEach((node) => {
			while (node) {
				console.log("Remove exempt node", node.nodeName, node);
				let i = emptyNodes.indexOf(node);
				if (i >= 0) {
					emptyNodes.splice(i, 1);
					console.log("Found and removed");
				}
				node = node.parentNode;
			}
		});

		// remove found empty nodes
		emptyNodes.forEach((node) => node.parentNode.removeChild(node));

		this.printHtml(el);
	},

	printHtml(el) {
		console.log(el.innerHTML);
		let treeWalker = document.createTreeWalker(el, NodeFilter.SHOW_ELEMENT);
		let currentNode = treeWalker.currentNode;
		while (currentNode) {
			console.log(currentNode.nodeName);
			console.log(currentNode.textContent || "(none)");
			currentNode = treeWalker.nextNode();
		}
	},

	generatePassword(n) {
		let charSet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
		let charSetLength = charSet.length;
		let result = "";
		for (var i = 0; i < n; i++) {
			var charIndex = Math.floor(Math.random() * charSetLength);
			result += charSet.substring(charIndex, charIndex + 1);
		}

		return result;
	},

	async confirmDelete(objType, name, extraWarning) {
		let objName = vm.$i18n.t(`delete_modal.objs.${objType}`);
		if (objName.includes("delete_modal")) {
			objName = objType;
		}
		let capitalObjName = objName.charAt(0).toUpperCase() + objName.slice(1);

		let prompt;
		if (name) {
			prompt = vm.$i18n.t("delete_modal.confirm_prompt_with_name", { obj: objName, name: name });
		} else {
			prompt = vm.$i18n.t("delete_modal.confirm_prompt", { obj: objName });
		}

		if (extraWarning) {
			prompt += `\n\n${extraWarning}`;
		}

		return await vm.$bvModal.msgBoxConfirm(prompt, {
			title: vm.$i18n.t("delete_modal.title", { obj: capitalObjName }),
			size: "sm",
			centered: true,
			headerBgVariant: "danger",
			okTitle: vm.$i18n.t("delete_modal.delete"),
			okVariant: "danger",
		});
	},
};
