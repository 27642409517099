<template>
	<div class="row">
		<div v-if="!sections || sections.length == 0" class="text-center p-4 w-100">
			<h3 class="m-0 text-very-muted">{{ $t("data_description.no_matching_items") }}</h3>
		</div>
		<div class="col-12 col-md-6 col-xl-4" v-for="section in sections" :key="section.id">
			<div class="box section">
				<div class="header theme-lighten-3 text-white flex">
					{{ section.name }}
					<div
						v-if="canExportScore"
						class="dropdown float-right"
						v-tippy="{ placement: 'top' }"
						:title="$t('tooltip.export_complete_scores')"
					>
						<i class="fas fa-download align-self-end" data-toggle="dropdown" aria-expanded="false"></i>
						<div class="dropdown-menu dropdown-menu-left">
							<a
								v-if="canApiExportScore"
								class="dropdown-item"
								@click="apiExportSection(section.id, false)"
							>
								<i class="far fa-file-import"></i>
								<label class="mb-0">{{ $t("Dashboard.api_export_unexported") }}</label>
							</a>
							<a
								v-if="canApiExportScore"
								class="dropdown-item"
								@click="apiExportSection(section.id, true)"
							>
								<i class="far fa-file-import"></i>
								<label class="mb-0">{{ $t("Dashboard.api_export_all") }}</label>
							</a>
							<a class="dropdown-item" @click="csvExportSection(section)">
								<i class="far fa-file"></i>
								<label class="mb-0">{{ $t("Dashboard.csv_export") }}</label>
							</a>
						</div>
					</div>
				</div>
				<div v-if="section.items && section.items.length" class="box-body light lt p-0">
					<table>
						<tbody>
							<template v-for="item in section.items">
								<tr :key="item.id">
									<td rowspan="3" class="pl-3 pr-2 b-b">
										<strong>{{ item.name }}</strong>
									</td>
									<td class="pt-2 pr-1">
										<span class="text-muted">{{ $t("fields.total") }}:</span>
									</td>
									<td class="pt-2">{{ item.total }}</td>
									<td rowspan="3" class="pl-2 pr-3 b-b">
										<div class="text-lg text-center w65" v-tippy>
											{{ ((item.complete * 100) / item.total).toFixed(1) }}%
										</div>
									</td>
								</tr>
								<tr :key="item.id">
									<td class="pr-1">
										<span class="text-muted">{{ $t("data_description.complete") }}:</span>
									</td>
									<td>{{ item.complete }}</td>
								</tr>
								<tr :key="item.id">
									<td class="pb-2 pr-1 b-b">
										<span class="text-muted">{{ $t("data_description.exported") }}:</span>
									</td>
									<td class="pb-2 b-b">{{ item.exported }}</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
table {
	width: 100%;
}
.w65 {
	min-width: 65px;
}
.section > .header {
	position: relative;
	padding: 0.5rem;
	text-align: center;
	font-size: 20px;
}
</style>

<script>
import DashboardService from "@/services/DashboardService";
import FileService from "@/services/FileService";
import Notie from "@/services/NotieService";

export default {
	name: "ProjectDashSections",

	props: ["project", "labels"],

	data() {
		return {
			busy: false,
			sections: {},
		};
	},
	created() {
		this.loadData();
	},

	computed: {
		canExportScore() {
			const { user } = this.$route.meta;
			return user && user.role.export_scores;
		},

		canApiExportScore() {
			const { user } = this.$route.meta;
			return user && user.role.queue_for_export;
		},
	},

	watch: {
		"project.id"() {
			this.loadData();
		},
		labels() {
			this.loadData();
		},
	},
	methods: {
		loadData() {
			if (!this.project) {
				return;
			}
			if (this.busy) {
				return;
			}

			this.busy = true;
			console.log("Get completion sections");
			DashboardService.getProjectCompletionSections(this.project.id, this.labels)
				.then((resp) => {
					this.sections = resp.data;
					this.busy = false;
				})
				.catch((err) => {
					console.log(err);
					this.busy = false;
				});
		},

		async csvExportSection({ id, name }) {
			try {
				const { data } = await DashboardService.sectionExportCSV(id);
				FileService.downloadZipFile(`${name}.zip`, data);
			} catch (err) {
				Notie.error("Failed to create csv exports", err);
			}
		},

		async apiExportSection(sectionID, useExported) {
			try {
				await DashboardService.sectionExportAPI(sectionID, useExported);
				Notie.success("Score export in progress");
			} catch (err) {
				Notie.error("Failed to export section responses", err);
			}
		},
	},
};
</script>
