import vm from "@/main";
import i18n from "@/i18n/setup";
import Utils from "@/services/Utils";
import sanitizeHtml from "sanitize-html";

export default {
	info(msg, submsg) {
		this._alert("default", msg, submsg);
	},

	success(msg, submsg, time) {
		this._alert("success", msg, submsg, time);
	},

	extractErrorMessage(err) {
		if (typeof err == "string") {
			return err;
		} else if (err && err.response && err.response.data && err.response.data.data && err.response.data.data.Err) {
			return err.response.data.data.Err;
		} else if (err && err.response && err.response.data && err.response.data.message) {
			if (
				err &&
				err.response &&
				err.response.data.message == "Internal Error" &&
				err.response.data.data &&
				typeof err.response.data.data == "string"
			) {
				return "Internal Error: " + err.response.data.data;
			}
			if (err.response.data.message == "Job ended in error" && err.response.data.data) {
				return "Job ended in error: " + err.response.data.data;
			}
			return err.response.data.message;
		} else if (err && err.response && err.response.data && typeof err.response.data == "string") {
			return err.response.data;
		} else if (err.message) {
			return `Client error: ${err.message}`;
		}
	},

	getErrorStackEls(errMsg) {
		const h = vm.$createElement;
		let lines = errMsg.split(":");
		let els = [];

		for (let i in lines) {
			let offset = 12 * i;
			let style = `position: relative; margin-left: ${offset}px`;
			if (i == 1) {
				style += "; font-size: 14px;";
			} else if (i > 1) {
				style += "; font-size: 12px;";
			}
			let subNodes = [lines[i]];
			if (i >= 1) {
				subNodes.push(
					h("div", {
						style: `position: absolute; bottom: calc(50% - 1px); height: 50%; left: ${-1 * offset + 2}px; width: ${offset - 4}px; border-bottom: 1px solid rgba(255, 255, 255, 0.25); border-left: 1px solid rgba(255, 255, 255, 0.25)`,
					})
				);
			}
			if (i >= 1 && i < lines.length - 1) {
				subNodes.push(
					h("div", {
						style: `position: absolute; bottom: -1px; height: 50%; left: ${-1 * offset + 2}px; width: ${offset - 4}px; border-left: 1px solid rgba(255, 255, 255, 0.25)`,
					})
				);
			}
			els.push(h("div", { style: style }, subNodes));
		}
		return els;
	},

	// will not display debug code
	userError(msg, err, time) {
		this._alert("error", msg, err, time || 5000);
	},

	error(msg, err) {
		if (err && err.suppress) {
			return;
		}

		let errMsg = "Error";
		if (err) {
			errMsg = this.extractErrorMessage(err);
		}
		const errStackEls = this.getErrorStackEls(errMsg);

		const h = vm.$createElement;
		const { user } = vm.$route.meta;
		const titleVNode = [h("h4", {}, msg), h("h6", { class: "mb-0" }, errStackEls)];
		let messageVNode = null;
		if (user) {
			const errCode = Utils.generateDebugCode(user);
			messageVNode = h(
				"div",
				{
					class: "text-dark",
					style: "font-size: 16px",
				},
				[
					i18n.t("Navbar.debug_code"),
					h("div", { class: "d-flex flex-row mt-2" }, [
						h("b-form-input", {
							props: {
								readonly: true,
								value: errCode,
							},
						}),
						h(
							"b-button",
							{
								class: "ml-2",
								props: { center: true },
								on: {
									click: () => navigator.clipboard.writeText(errMsg + "\n" + errCode),
								},
							},
							[i18n.t("buttons.copy")]
						),
					]),
					h("div", { class: "text-xs text-muted mt-1" }, i18n.t("Navbar.debug_code_explanation")),
				]
			);
		}

		vm.$bvModal.msgBoxOk([messageVNode], {
			centered: true,
			title: [titleVNode],
			headerBgVariant: "danger",
			titleClass: ["text-break"],
		});
	},

	warning(msg) {
		this._alert("warning", msg);
	},

	_alert(type, msg, submsg = null, duration = 5000, position = "bottom") {
		console.log("GOT MAIN VM", vm);
		console.log("toast", vm.$toast);
		console.log("i18n", vm.$i18n);
		let message = `<span class="text-lg">${sanitizeHtml(msg)}</span>`;

		if (submsg) {
			message += `<br/><div class="text-md text-muted">${sanitizeHtml(submsg)}</div>`;
		}

		vm.$toast.open({
			message,
			type,
			duration,
			position,
		});
	},
};
