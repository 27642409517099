<template>
	<div ref="essay" class="pdf-box">
		<canvas :id="page.id"></canvas>

		<template v-for="ar in annotationRegions">
			<div
				:key="`box-${ar.timestamp}`"
				class="annotation-box"
				:class="[`_highlight-box-${ar.annotation.color}`, { hovered: ar.hovered }]"
				:style="{
					left: `${ar.boxLeft * zoomScale}px`,
					top: `${ar.boxTop * zoomScale}px`,
					width: `${ar.boxWidth * zoomScale}px`,
					height: `${ar.boxHeight * zoomScale}px`,
				}"
				:id="`box-${ar.timestamp}`"
				@mouseenter="applyHoverToGroup(ar.timestamp)"
				@mouseleave="applyUnhoverToGroup(ar.timestamp)"
			></div>
			<div
				:key="`controls-${ar.timestamp}`"
				class="annotation-margin"
				:style="{
					left: `${(ar.boxLeft + ar.boxWidth) * zoomScale + 8}px`,
					top: `${ar.boxTop * zoomScale}px`,
				}"
				:id="`overlay-${ar.timestamp}`"
				@mouseenter="applyHoverToGroup(ar.timestamp)"
				@mouseleave="applyUnhoverToGroup(ar.timestamp)"
			>
				<div
					class="annotation-side-border"
					:class="{ [`highlight-${ar.annotation.color}`]: true }"
					:style="{ height: `${ar.boxHeight * zoomScale}px` }"
					:id="`overlay-border-${ar.timestamp}`"
					v-tippy="{
						placement: 'top-end',
						theme: 'popover',
						interactive: true,
						reactive: true,
						hideOnClick: false,
						trigger: 'hover',
						html: `#overlay-popup-${ar.timestamp}`,
					}"
				></div>
				<div :id="`overlay-popup-${ar.timestamp}`" class="overlay-popup">
					<span v-if="!ar.editing" v-html="ar.annotation.text"></span>
					<div class="d-flex flex-row" v-if="ar.editing">
						<RichText
							:initial="annotationInput"
							:text.sync="annotationOutput"
							:options="{ height: 104 }"
							class="flex mb-0"
						/>
						<div class="pl-2 ml-2 border-left d-flex flex-column">
							<div class="flex d-flex flex-row">
								<div class="flex d-flex flex-column">
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-blue"
										:class="{
											selected: ar.annotation.color == 'blue',
										}"
										@click="changeHighlightColor(ar, 'blue')"
									></button>
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-red"
										:class="{
											selected: ar.annotation.color == 'red',
										}"
										@click="changeHighlightColor(ar, 'red')"
									></button>
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-yellow"
										:class="{
											selected: ar.annotation.color == 'yellow',
										}"
										@click="changeHighlightColor(ar, 'yellow')"
									></button>
								</div>
								<div class="flex d-flex flex-column ml-1">
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-green"
										:class="{
											selected: ar.annotation.color == 'green',
										}"
										@click="changeHighlightColor(ar, 'green')"
									></button>
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-orange"
										:class="{
											selected: ar.annotation.color == 'orange',
										}"
										@click="changeHighlightColor(ar, 'orange')"
									></button>
									<button
										class="btn-xs btn-icon btn-rounded mb-1 highlight-purple"
										:class="{
											selected: ar.annotation.color == 'purple',
										}"
										@click="changeHighlightColor(ar, 'purple')"
									></button>
								</div>
							</div>
							<div class="btn btn-sm" @click="saveAnnotationRegion(ar)">Save</div>
						</div>
					</div>
				</div>
				<div
					v-if="ar.editable"
					class="position-absolute"
					style="top: 0; left: -8px; width: 10px"
					:style="{ height: `${ar.boxHeight * zoomScale}px` }"
				>
					<a
						class="btn-subtle overlay-btn position-absolute"
						style="left: 8px; font-size: 18px"
						:style="{ transform: `rotate(-${rotate}deg)` }"
						@click="toggleEditAnnotation(ar)"
					>
						<i class="fa-comment" :class="{ [ar.editing ? 'fas' : 'far']: true }" />
					</a>
					<a
						class="btn-subtle overlay-btn position-absolute"
						style="font-size: 18px"
						:style="{
							left: ar.boxHeight >= 56 ? '8px' : '36px',
							top: ar.boxHeight >= 56 ? '28px' : '0',
							transform: `rotate(-${rotate}deg)`,
						}"
						@click="removeAnnotationRegion(ar.timestamp)"
					>
						<i class="far fa-trash" />
					</a>
				</div>
				<div
					v-if="!ar.editable"
					class="position-absolute"
					style="top: 0; left: -8px; width: 10px"
					:style="{ height: `${ar.boxHeight * zoomScale}px` }"
				>
					<a class="overlay-btn position-absolute" style="font-size: 18px; left: 10px">
						<i class="far fa-comment-lines" />
					</a>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import _ from "lodash";
import RichText from "@/components/RichText";
import $ from "jquery";
import store from "../../services/Store";
import ds from "../../services/DrawingService";

export default {
	name: "PDFMedia",
	props: ["page", "chan", "item", "annotations"],
	components: { RichText },
	data() {
		return {
			events: [],
			sizes: [0.5, 0.65, 0.8, 1, 1.2, 1.5, 1.8, 2],
			zoomScale: 0,
			totalScale: 0,
			rotate: 0,
			angleEnabled: false,
			angleState: {},
			rulerEnabled: false,
			rulerState: {},

			selectingHighlight: false,
			annotationRegions: [],
			annotationInput: null,
			annotationOutput: null,
			annotationOrigColor: "blue",
			annosToLoad: null,

			selectionBox: null,
			hX1: null,
			hY1: null,
			hX2: null,
			hY2: null,

			canvasContext: null,
		};
	},

	created() {
		if (!this.chan) {
			return;
		}

		this.chan.$emit("angle-off");
		this.chan.$emit("ruler-off");

		//Zoom
		var zoom = () => {
			this.drawPDF();
		};
		this.events.push({ key: "zoom-in", val: zoom });
		this.events.push({ key: "zoom-out", val: zoom });
		this.chan.$on("zoom-in", zoom);
		this.chan.$on("zoom-out", zoom);

		//Invert
		var invert = () => {
			var canv = $("#" + this.page.id).get(0);
			var ctx = canv.getContext("2d");
			var imgData = ctx.getImageData(0, 0, canv.width, canv.height);
			var data = imgData.data;

			for (var i = 0; i < data.length; i += 4) {
				data[i] = Math.abs(data[i] - 255); // red
				data[i + 1] = Math.abs(data[i + 1] - 255); // green
				data[i + 2] = Math.abs(data[i + 2] - 255); // blue
			}
			// imgData.data = newData
			ctx.putImageData(imgData, 0, 0);
		};
		this.events.push({ key: "invert", val: invert });
		this.chan.$on("invert", invert);

		//Rotate
		var rotate = () => {
			var ele = $(this.$refs.essay);
			this.rotate = (this.rotate + 90) % 360;
			ele.css("transform", "rotate(" + this.rotate + "deg)");

			this.positionAnnotationTooltips();
		};
		this.events.push({ key: "rotate", val: rotate });
		this.chan.$on("rotate", rotate);

		var disableAngle = (element) => {
			this.angleState = {};
			$(element).css("cursor", "default");
			$(element).off("click");
			this.angleEnabled = false;
			this.chan.$emit("angle-off");
			this.drawPDF();
		};

		var disableRuler = (element) => {
			this.rulerState = {};
			$(element).css("cursor", "default");
			$(element).off("click");
			this.rulerEnabled = false;
			this.chan.$emit("ruler-off");
			this.drawPDF();
		};

		//Angle
		var angle = () => {
			var element = $(this.$refs.essay);
			//If ruler is in use, cancel it
			if (this.rulerEnabled) {
				disableRuler(element);
			}
			//If we're already enabled, disable
			if (this.angleEnabled) {
				disableAngle(element);
				return;
			}
			this.angleEnabled = true;
			this.chan.$emit("angle-on");

			$(element).css("cursor", "crosshair");
			// console.log(state);
			$(element).on("click", (event) => {
				// console.log(deg);
				// console.log(state);
				var canv = $("#" + this.page.id).get(0);
				var ctx = canv.getContext("2d");

				var clickX = event.offsetX;
				var clickY = event.offsetY;

				if (!this.angleState.firstClick) {
					this.angleState.firstClick = {
						x: clickX,
						y: clickY,
					};
					ds.drawCrossPoint(ctx, clickX, clickY);
				} else if (!this.angleState.secondClick) {
					this.angleState.secondClick = {
						x: clickX,
						y: clickY,
					};
					ds.drawLine(ctx, this.angleState.firstClick, this.angleState.secondClick);
					ds.drawCrossPoint(ctx, clickX, clickY);
				} else if (!this.angleState.thirdClick) {
					this.angleState.thirdClick = {
						x: clickX,
						y: clickY,
					};
					ds.drawLine(ctx, this.angleState.secondClick, this.angleState.thirdClick);
					ds.drawCrossPoint(ctx, clickX, clickY);
					var angle =
						ds.find_angle(
							this.angleState.firstClick,
							this.angleState.thirdClick,
							this.angleState.secondClick
						) *
						(180 / Math.PI);
					var rounded = Math.round(angle * 100) / 100;
					$(element).css("cursor", "not-allowed");

					ds.drawText(
						ctx,
						rounded + "°",
						this.angleState.secondClick.x,
						this.angleState.secondClick.y,
						canv.width,
						canv.height
					);
					console.log(angle);
				} else {
					this.angleState = {};
					$(element).css("cursor", "crosshair");
					this.drawPDF();
				}
				console.log(this.angleState);
			});
		};
		this.events.push({ key: "angle", val: angle });
		this.chan.$on("angle", angle);

		//Ruler
		var ruler = () => {
			var element = $(this.$refs.essay);
			//If angle is in use, cancel it
			if (this.angleEnabled) {
				disableAngle(element);
			}
			//If we're already enabled, disable
			if (this.rulerEnabled) {
				disableRuler(element);
				return;
			}
			this.rulerEnabled = true;
			this.chan.$emit("ruler-on");

			$(element).css("cursor", "crosshair");
			// console.log(state);
			$(element).on("click", (event) => {
				// console.log(deg);
				// console.log(state);
				var canv = $("#" + this.page.id).get(0);
				var ctx = canv.getContext("2d");

				var clickX = event.offsetX;
				var clickY = event.offsetY;

				if (!this.rulerState.firstClick) {
					this.rulerState.firstClick = {
						x: clickX,
						y: clickY,
					};
					ds.drawCrossPoint(ctx, clickX, clickY);
				} else if (!this.rulerState.secondClick) {
					this.rulerState.secondClick = {
						x: clickX,
						y: clickY,
					};
					ds.drawLine(ctx, this.rulerState.firstClick, this.rulerState.secondClick);
					ds.drawCrossPoint(ctx, clickX, clickY);
					var distance = ds.find_distance(
						this.rulerState.firstClick,
						this.rulerState.secondClick,
						this.totalScale,
						this.item
					);
					$(element).css("cursor", "not-allowed");

					var unitStr = "";
					var rounded = distance;
					if (this.item.ruler_units == 1) {
						unitStr = '"';
						rounded = Math.round(distance * 10) / 10;
					} else if (this.item.ruler_unit == 2) {
						unitStr = "cm";
						rounded = Math.round(distance * 10) / 10;
					} else if (this.item.ruler_units == 3) {
						unitStr = "mm";
						rounded = Math.round(distance);
					}

					ds.drawText(
						ctx,
						rounded + unitStr,
						this.rulerState.secondClick.x,
						this.rulerState.secondClick.y,
						canv.width,
						canv.height
					);
					console.log(distance);
				} else {
					this.rulerState = {};
					$(element).css("cursor", "crosshair");
					this.drawPDF();
				}
				console.log(this.rulerState);
			});
		};
		this.events.push({ key: "ruler", val: ruler });
		this.chan.$on("ruler", ruler);

		this.chan.$on("annotation", this.toggleAnnotation);
		this.chan.$on("annotation-interrupt", this.interruptAnnotation);
		this.chan.$on("annotation-cancel", this.cancelAnnotation);
	},

	mounted() {
		this.drawPDF();
	},
	destroyed() {
		if (!this.chan) {
			return;
		}

		_.each(this.events, (e) => {
			this.chan.$off(e.key, e.val);
		});

		var canvas = $("#" + this.page.id).get(0);
		if (canvas) {
			canvas.parentElement.removeChild(canvas);
		}
	},

	methods: {
		drawPDF() {
			var zoom = store.get(this, "viewerScale");
			this.zoomScale = this.sizes[zoom] || this.sizes[4];
			var targetWidth = 800 * this.zoomScale;

			var pdfPage = this.page.pdf;

			var viewport = pdfPage.getViewport(1);
			this.totalScale = targetWidth / viewport.width;

			viewport = pdfPage.getViewport(this.totalScale);
			// canvas.id = canvId;
			//$(element).append(canvas);
			var canvas = $("#" + this.page.id).get(0);
			var context = this.getBlankContext(canvas);

			canvas.width = viewport.width;
			canvas.height = viewport.height;

			if (this.chan) {
				$(this.$refs.essay).css("max-width", viewport.width);
				$(this.$refs.essay).css("min-width", viewport.width);
			}
			// debugger;

			var renderContext = {
				canvasContext: context,
				viewport: viewport,
			};

			var now = new Date().getTime();
			var _this = this;
			pdfPage.render(renderContext).then(() => {
				console.log(
					`PageRender (${this.page.id}): ${new Date().getTime() - now}`,
					new Date().getTime() - _this.page.start
				);

				this.initHighlightInterface();

				// debugger;
				if (_this.item && (_this.item.clip_percent || _this.item.clip_v_percent)) {
					if (this.page.qc) {
						// SME hack to ignore QC responses
						return;
					}

					var percent = _this.item.clip_percent;
					var v_percent = _this.item.clip_v_percent;

					let sx = 0;
					let width = canvas.width;
					let sy = 0;
					let height = canvas.height;

					//If vertical clipping
					if (v_percent > 0) {
						//Take first half of image data
						width = (canvas.width * v_percent) / 100;
					} else if (v_percent < 0) {
						//Take first half of image data
						width = (canvas.width * -v_percent) / 100;
						sx = canvas.width - width;
					}

					//If horizontal clipping
					if (percent > 0) {
						//Take first half of image data
						height = (canvas.height * percent) / 100;
					} else if (percent < 0) {
						//Take first half of image data
						height = (canvas.height * -percent) / 100;
						sy = canvas.height - height;
					}

					if (percent != 0 || v_percent != 0) {
						console.log("new image bounds", sx, sy, width, height);
						var imgData = context.getImageData(sx, sy, width, height);
						if (v_percent != 0) {
							canvas.width = width;
							$(canvas).css("width", width);
							$(canvas).parent().css("min-width", width);
							$(canvas).parent().css("max-width", width);
						}
						if (percent != 0) {
							canvas.height = height;
							$(canvas).css("height", height);
						}
						context.putImageData(imgData, 0, 0);
					}
				}
			});

			this.positionAnnotationTooltips();
		},

		getBlankContext(canvas) {
			if (!this.canvasContext) {
				this.canvasContext = canvas.getContext("2d");
			} else {
				this.canvasContext.clearRect(0, 0, canvas.width, canvas.height);
			}

			return this.canvasContext;
		},

		interruptAnnotation() {
			if (this.selectingHighlight) {
				this.disableAnnotation();
			}
		},

		toggleAnnotation() {
			if (!this.selectingHighlight) {
				this.enableAnnotation();
			} else {
				this.disableAnnotation();
			}
		},

		cancelAnnotation() {
			var el = this.$refs.essay;
			if (!el) return;
			this.selectingHighlight = false;
			el.style.cursor = "default";

			el.removeEventListener("mousedown", this.startSelection);
			el.removeEventListener("touchstart", this.startSelection);
			el.removeEventListener("mouseup", this.endSelection);
			el.removeEventListener("touchend", this.endSelection);
		},

		disableAnnotation() {
			this.cancelAnnotation();
			this.chan.$emit("annotation-off");
		},

		enableAnnotation() {
			var el = this.$refs.essay;
			this.selectingHighlight = true;
			el.style.cursor = "alias";
			this.chan.$emit("annotation-on");

			el.addEventListener("mousedown", this.startSelection);
			el.addEventListener("touchstart", this.startSelection);
			el.addEventListener("mouseup", this.endSelection);
			el.addEventListener("touchend", this.endSelection);
		},

		startSelection(event) {
			let container = this.$refs.essay;
			this.selectionBox = document.createElement("div");
			this.selectionBox.style.position = "absolute";
			this.selectionBox.style.border = "2px dashed #888888";
			container.appendChild(this.selectionBox);
			window.selectionBox = this.selectionBox;

			let containerRect = container.getBoundingClientRect();
			this.hX1 = event.clientX - containerRect.left;
			this.hY1 = event.clientY - containerRect.top;
			this.hX2 = event.clientX;
			this.hY2 = event.clientY;
			this.redrawSelection(event);

			container.addEventListener("mousemove", this.redrawSelection);
			container.addEventListener("touchmove", this.redrawSelection);
		},

		endSelection(event) {
			let container = this.$refs.essay;
			let containerRect = container.getBoundingClientRect();
			this.hX2 = event.clientX - containerRect.left;
			this.hY2 = event.clientY - containerRect.top;

			this.selectionBox.remove();

			let left = Math.min(this.hX1, this.hX2);
			let right = Math.max(this.hX1, this.hX2);
			let top = Math.min(this.hY1, this.hY2);
			let bottom = Math.max(this.hY1, this.hY2);

			container.removeEventListener("mousemove", this.redrawSelection);
			container.removeEventListener("touchmove", this.redrawSelection);

			this.disableAnnotation();
			let timestamp = +new Date();
			this.createAnnotationRegion(
				timestamp,
				left / this.zoomScale,
				(right - left) / this.zoomScale,
				top / this.zoomScale,
				(bottom - top) / this.zoomScale
			);
		},

		redrawSelection(event) {
			let container = this.$refs.essay;
			let containerRect = container.getBoundingClientRect();
			this.hX2 = event.clientX - containerRect.left;
			this.hY2 = event.clientY - containerRect.top;

			let left = Math.min(this.hX1, this.hX2);
			let right = Math.max(this.hX1, this.hX2);
			let top = Math.min(this.hY1, this.hY2);
			let bottom = Math.max(this.hY1, this.hY2);

			this.selectionBox.style.left = `${left}px`;
			this.selectionBox.style.top = `${top}px`;
			this.selectionBox.style.width = `${right - left}px`;
			this.selectionBox.style.height = `${bottom - top}px`;
		},

		applyHoverToGroup(timestamp) {
			let ar = _.find(this.annotationRegions, {
				timestamp: timestamp,
			});
			if (!ar) {
				console.log("Could not find annotation with timestamp", timestamp);
				return;
			}

			ar.hovered = true;

			let box = document.getElementById(`box-${timestamp}`);
			if (box) {
				box.classList.add("hovered");
			}

			let overlay = document.getElementById(`overlay-${timestamp}`);
			if (overlay) {
				overlay.classList.add("hovered");
			}

			let border = document.getElementById(`overlay-border-${timestamp}`);
			border._tippy.show();
		},

		applyUnhoverToGroup(timestamp, force) {
			let ar = _.find(this.annotationRegions, {
				timestamp: timestamp,
			});
			if (!ar) {
				console.log("Could not find annotation with timestamp", timestamp);
				return;
			}

			if (!force && ar.editing) {
				return;
			}

			ar.hovered = false;

			let box = document.getElementById(`box-${timestamp}`);
			if (box) {
				box.classList.remove("hovered");
			}

			let overlay = document.getElementById(`overlay-${timestamp}`);
			if (overlay) {
				overlay.classList.remove("hovered");
			}

			let border = document.getElementById(`overlay-border-${timestamp}`);

			//Delay the hide until next tick to ensure that we're actually not hovered
			//over anything anymore. Prevents flickering between contiguous regions
			this.$nextTick(() => {
				if (!ar.hovered) {
					border._tippy.hide();
				}
			});
		},

		createAnnotationRegion(timestamp, left, width, top, height) {
			let serialized = `${left}:${width}:${top}:${height}`;
			let annotation = this.createAnnotation(serialized, timestamp);
			this.annotationRegions.push({
				timestamp: timestamp,
				annotation: annotation,
				editable: true,
				boxLeft: left,
				boxWidth: width,
				boxTop: top,
				boxHeight: height,
			});
			this.disableAnnotation();

			//Open the tooltip in edit mode right after the highlight is created
			//We need to wait three ticks here: 1 for the annotation region overlay to be created,
			//2 for the tippy code to hook into it, and 3 for the tooltip to position itself properly
			this.$nextTick(() => {
				this.$nextTick(() => {
					this.$nextTick(() => {
						let ar = _.find(this.annotationRegions, {
							timestamp: timestamp,
						});
						this.toggleEditAnnotation(ar);
					});
				});
			});
		},

		createAnnotation(serialized, timestamp) {
			let annotation = {
				id: timestamp.toString(),
				user_id: "5cd9b8c9098fc151a4780de2",
				type: 1, //Box highlight
				region: serialized,
				color: "blue",
				// text: `Annotation ${this.annotationRegions.length + 1}`,
				text: "",
			};
			this.$emit("saveAnnotation", annotation);
			return annotation;
		},

		toggleEditAnnotation(ar) {
			if (ar.editing) {
				ar.annotation.color = this.annotationOrigColor;

				this.applyUnhoverToGroup(ar.timestamp, true);

				//Delaying this to prevent the tooltip from flickering back into non-edit mode during its hiding animation.
				//Tippy has on onHidden callback that would be better here, but I couldn't get it to fire.
				//So we're using setTimeout as a hacky replacement. It works, though.
				setTimeout(() => {
					ar.editing = false;
					this.$forceUpdate();
				}, 200);
			} else {
				this.annotationOrigColor = ar.annotation.color;
				ar.editing = true;
				this.annotationInput = ar.annotation.text;

				let el = document.getElementById(`overlay-border-${ar.timestamp}`);
				let popup = document.getElementById(`overlay-popup-${ar.timestamp}`);

				if (!popup) {
					//If edit is toggled from an outside source, the actual region might not be in a hovered state yet
					//If this is the case, change it to hovered before opening the tooltip
					this.applyHoverToGroup(ar.timestamp);
					el._tippy.popperInstance.update();
				}
				el._tippy.onMount = () => {
					new ResizeObserver(el._tippy.popperInstance.update).observe(popup);
				};
			}

			this.$forceUpdate();
		},

		removeAnnotationRegion(timestamp) {
			let ar = _.find(this.annotationRegions, { timestamp, timestamp });
			if (!ar) {
				console.log(`Tried to remove annotation region ${timestamp} but it did not exist`);
			}

			this.annotationRegions = _.without(this.annotationRegions, ar);
			this.$emit("deleteAnnotation", timestamp);
		},

		removeAllAnnotationRegions() {
			_.each(this.annotationRegions, (_, timestamp) => {
				this.removeAnnotationRegion(timestamp);
			});
		},

		positionAnnotationTooltips() {
			this.$nextTick(() => {
				console.log("Position annotation tooltips");
				_.each(this.annotationRegions, (ar) => {
					let el = document.getElementById(`overlay-border-${ar.timestamp}`);
					if (el && el._tippy && el._tippy.popperInstance) {
						console.log("update", el);
						el._tippy.popperInstance.update();
					}
				});
			});
		},

		changeHighlightColor(ar, color) {
			this.doChangeHighlightColor(ar.timestamp, color);
			ar.annotation.color = color;
		},

		doChangeHighlightColor(timestamp, color) {
			let colors = ["blue", "red", "yellow", "green"];
			_.each(colors, (c) => {
				// this.highlighter.removeClassFromGroup(
				// 	`_highlighter-${c}`,
				// 	timestamp
				// );
			});
			// this.highlighter.addClassToGroup(
			// 	`_highlighter-${color}`,
			// 	timestamp
			// );
		},

		saveAnnotationRegion(ar) {
			ar.annotation.text = this.annotationOutput;
			this.annotationOrigColor = ar.annotation.color;
			this.toggleEditAnnotation(ar);
		},

		initHighlightInterface() {
			let highlightInterface = {};
			highlightInterface.setAnnotations = (annos) => {
				this.setAnnotations(annos);
			};
			highlightInterface.getAnnotations = () => {
				return this.getAnnotations();
			};
			this.$emit("update:highlighter", highlightInterface);
		},

		setAnnotations(annos) {
			this.annotationRegions = [];

			_.each(annos, (annotation) => {
				let coords = annotation.region.split(":");
				let left = parseFloat(coords[0]);
				let width = parseFloat(coords[1]);
				let top = parseFloat(coords[2]);
				let height = parseFloat(coords[3]);

				this.annotationRegions.push({
					timestamp: parseInt(annotation.id),
					annotation: annotation,
					boxLeft: left,
					boxWidth: width,
					boxTop: top,
					boxHeight: height,
					editable: annotation.editable,
				});

				this.positionAnnotationTooltips();
			});
		},

		getAnnotations() {
			let annos = [];
			_.each(this.annotationRegions, (ar) => {
				let anno = ar.annotation;
				annos.push(anno);
			});

			return annos;
		},
	},
};
</script>

<style scoped>
.tag {
	background-color: #ffff91;
	min-width: 10px;
	min-height: 10px;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.5;
	-webkit-box-shadow: 2px 2px 0px 0px rgba(231, 237, 197, 0.41);
	-moz-box-shadow: 2px 2px 0px 0px rgba(231, 237, 197, 0.41);
	box-shadow: 2px 2px 0px 0px rgba(231, 237, 197, 0.41);
}

.tag-closer {
	position: absolute;
	display: none;
	top: -8px;
	right: -8px;
	height: 16px;
	width: 16px;
	/* content: "X"; */
	border-radius: 8px;
	background-color: purple;
	font-size: 8px;
	/* opacity: 0.8; */
	border: 1px;
	color: black;
}

.tag:hover .tag-closer {
	display: block;
}

.pdf-box {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	/*min-height:1250px;*/
	/*width:1000px;*/
	/*font-size: 17pt;*/

	/*min-height:1000px;*/
	max-width: 800px;
	min-width: 400px;
	font-size: 13pt;

	/*padding:80px;*/
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
}

.annotation-box {
	position: absolute;
}

.annotation-margin {
	position: absolute;
	width: 60px;
	right: 10px;
	display: flex;
	flex-direction: row;
}
.annotation-side-border {
	position: absolute;
	width: 3px;
	left: -5px;
	height: 100px;
	opacity: 0;
	transition: opacity 0.15s;
	pointer-events: none;
}
.annotation-margin.hovered .annotation-side-border {
	opacity: 1;
}

a.btn-subtle {
	margin-top: -2px;
	opacity: 1;
	transition: background-color 0.25s;
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 2rem;
	transition: opacity 0.15s;
}
a.btn-subtle:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
.annotation-margin:not(.hovered) a.overlay-btn {
	opacity: 0;
}

.highlight-btn {
	width: 23px;
	height: 23px;
	border: 1px solid #111;
	border-radius: 12px;
	margin: 5px 8px;
	display: inline-block;
	cursor: pointer;
}
.highlight-btn.selected {
	box-shadow: #666 0 0 4px 2px;
}
.highlight-blue {
	background-color: rgb(41, 182, 246);
}
.highlight-red {
	background-color: rgba(244, 67, 54, 0.75);
}
.highlight-yellow {
	background-color: rgb(248, 240, 100);
}
.highlight-green {
	background-color: rgb(139, 195, 74);
}
.highlight-orange {
	background-color: rgb(246, 154, 77);
}
.highlight-purple {
	background-color: rgb(143, 100, 150);
}
</style>
