<template>
	<div class="border-top pl-2 pt-3 pb-1 align-middle">
		<b-row>
			<b-col cols="9">
				<h5 class="align-bottom">{{ ruleName }}</h5>
			</b-col>
			<b-col>
				<h5 :class="statusTheme">{{ statusName }}</h5></b-col
			>
		</b-row>
	</div>
</template>

<script>
import fs from "@/services/FormatService";

export default {
	name: "ValidityRuleStatRow",

	props: {
		ruleStat: Object,
	},

	computed: {
		ruleName() {
			return fs.getQcRuleText(this.ruleStat);
		},

		statusColor() {},

		statusName() {
			return this.status.name;
		},

		statusTheme() {
			return this.status.theme;
		},

		status() {
			const {
				$i18n,
				ruleStat: { complete, passed },
			} = this;
			if (!complete)
				return {
					name: $i18n.t("QCStates.in_progress"),
					theme: "text-warning",
				};
			return passed
				? { name: $i18n.t("QCStates.passed"), theme: "text-success" }
				: { name: $i18n.t("QCStates.failed"), theme: "text-danger" };
		},
	},
};
</script>

<style>
</style>