<template>
	<page :title="$t('Training.title')">
		<div v-if="loading" class="padding text-center h-100 text-muted">
			<loading type="large" />
		</div>
		<div v-else-if="trainingCourses && trainingCourses.length > 0" class="padding pb-0">
			<div class="row">
				<div class="col-12" style="margin-bottom: 2rem" v-for="course in trainingCourses" :key="course.id">
					<div class="box training-course mb-0">
						<div class="box-header theme lighten-2">
							<span class="wrap-text">{{ course.name }}</span>
							<div v-if="course.section_names && course.section_names.length > 0" class="sections-icon">
								<i
									class="fas fa-lg fa-clipboard-check"
									v-tippy="{
										theme: 'popover',
										html: `#course-sections-${course.id}`,
										placement: 'bottom-end',
									}"
								></i>
								<div class="d-none" :id="`course-sections-${course.id}`">
									<div class="m-2">
										<h5>{{ $t("Training.required_for_access_to") }}:</h5>
										<ul>
											<li v-for="(name, i) in course.section_names" :key="i">
												{{ name }}
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div v-if="course.item_names && course.item_names.length > 0" class="sections-icon">
								<i
									class="fas fa-lg fa-clipboard-check"
									v-tippy="{
										theme: 'popover',
										html: `#course-items-${course.id}`,
										placement: 'bottom-end',
									}"
								></i>
								<div class="d-none" :id="`course-items-${course.id}`">
									<div class="m-2">
										<h5>{{ $t("Training.required_for_access_to") }}:</h5>
										<ul>
											<li v-for="(name, i) in course.item_names" :key="i">
												{{ name }}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="!(course.elements && course.elements.length > 0)"
						class="box-body light lt text-center p-4"
					>
						<p class="m-0">{{ $t("Training.no_elements") }}</p>
					</div>
					<div v-else class="box-body light lt p-0 pt-4">
						<!-- <table class="table">
										<tbody>
											<tr v-for="element in course.elements" :key="element.id">
												<td>
													{{ element.name }}
												</td>
											</tr>
										</tbody>
									</table> -->
						<div class="streamline px-4">
							<div
								v-for="(element, i) in course.elements"
								:key="element.id"
								class="sl-item"
								:class="{
									'b-secondary': element.progress.progress == INCOMPLETE,
									'b-warning': element.progress.progress == IN_PROGRESS,
									'b-success': element.progress.progress == COMPLETE,
									'b-danger': element.progress.progress == FAILED,
								}"
							>
								<div
									class="sl-icon"
									:class="{
										'text-dark text-muted': element.progress.progress == INCOMPLETE,
									}"
								>
									<i
										class="fa fa-lg"
										:class="[
											{
												'text-dark text-muted': element.progress.progress == INCOMPLETE,
											},
											fs.trainingElementTypeIcon(element.type),
										]"
									></i>
								</div>
								<div class="d-flex flex-row justify-content-between align-items-center">
									<div class="sl-content">
										<div class="sl-date">
											<span
												:class="{
													'text-muted': element.progress.progress == INCOMPLETE,
													'text-warning': element.progress.progress == IN_PROGRESS,
													'text-success': element.progress.progress == COMPLETE,
													'text-danger': element.progress.progress == FAILED,
												}"
												>{{ getElementProgress(element) }}</span
											>
											•
											<span
												:class="{
													'text-muted': element.progress.progress != FAILED,
													'text-danger': element.progress.progress == FAILED,
												}"
												>{{ getElementType(element) }}</span
											>
										</div>
										<div class="sl-date text-extra-muted text-xxs" v-if="element.qual_location">
											<span class="mr-2"
												><i class="far fa-briefcase mr-1" />{{
													element.qual_location.section_name
												}}</span
											>
											<span
												><i class="far fa-book mr-1" />{{
													element.qual_location.item_name
												}}</span
											>
										</div>
										<p>{{ i + 1 }}. {{ element.name }}</p>
									</div>
									<button
										v-if="
											element.progress.progress == INCOMPLETE &&
											element.isNext &&
											!element.progress.DO_NOT_ALLOW_TO_TAKE
										"
										class="btn btn-success"
										@click="goToElement(course, element)"
										:disabled="element.inProgress"
									>
										<template v-if="mustDownloadElement(element)">
											<loading v-if="element.inProgress" type="icon" class="mr-1" />
											<i v-else class="fas fa-download mr-1" />
											{{ $t("Training.download") }}
										</template>
										<template v-else>
											<loading v-if="element.inProgress" type="icon" class="mr-1" />
											<i v-else class="fas fa-arrow-alt-to-right mr-1" />
											{{ $t("Training.begin") }}
										</template>
									</button>
									<button
										v-else-if="element.progress.progress == IN_PROGRESS"
										class="btn btn-success"
										@click="goToElement(course, element)"
									>
										<i class="fas fa-arrow-alt-to-right mr-1" />
										{{ $t("Training.resume") }}
									</button>
									<button
										v-else-if="element.progress.progress == COMPLETE"
										class="btn btn-secondary"
										style="opacity: 0.5"
										@click="goToElementReview(course, element)"
									>
										<i class="fas fa-redo-alt mr-1" />
										{{ $t("Training.review") }}
									</button>
									<button
										v-else-if="element.progress.progress == FAILED"
										class="btn btn-secondary"
										style="opacity: 0.5"
										@click="goToElementReview(course, element)"
									>
										<i class="fas fa-redo-alt mr-1" />
										{{ $t("Training.review") }}
									</button>
								</div>
							</div>
						</div>
						<div>
							<div
								class="p-2 d-flex flex-row justify-content-center align-items-center"
								style="margin-left: 1px; margin-right: 1px; background-color: #eaf0f1"
							>
								<button
									v-if="course.progress.overall_progress == COMPLETE"
									class="btn btn-success ml-3 d-flex flex-row align-items-center"
									@click="goToScoring"
								>
									<i class="fas fa-check fa-2x mr-1" />
									<div class="mx-2 b-l">
										<h5 class="mb-0">{{ $t("Training.training_complete") }}</h5>
										<small class="text-muted">
											{{ $t("Training.continue_to_scoring") }}
										</small>
									</div>
								</button>
								<button
									v-else-if="failedQual(course)"
									class="btn btn-secondary ml-3 d-flex flex-row align-items-center"
									disabled
								>
									<i class="fas fa-times fa-2x mr-1" />
									<div class="mx-2 b-l">
										<h5 class="mb-0">{{ $t("Training.cannot_continue") }}</h5>
										<small class="text-muted">
											{{ $t("Training.no_more_qualification_activities") }}
										</small>
									</div>
								</button>
								<button
									v-else-if="!user.active"
									class="btn btn-secondary ml-3 d-flex flex-row align-items-center"
									disabled
								>
									<i class="fas fa-user-lock fa-2x mr-1" />
									<div class="mx-2 b-l">
										<h5 class="mb-0">{{ $t("Training.cannot_continue") }}</h5>
										<small class="text-muted">
											{{ $t("Training.user_locked") }}
										</small>
									</div>
								</button>
								<button
									v-else
									class="btn btn-success ml-3 d-flex flex-row align-items-center"
									@click="goToNextElement(course)"
									:disabled="course.progress.DO_NOT_ALLOW_TO_TAKE"
								>
									<i class="fas fa-arrow-alt-to-right fa-2x mr-1" />
									<div class="mx-2 b-l">
										<h5 class="mb-0">{{ $t("Training.continue") }}</h5>
										<small class="text-muted">
											{{ completeElements(course) }}/{{ course.elements.length }}
											{{ $t("Training.complete") }}
										</small>
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="padding text-center h-100">
			<h5 class="text-muted">{{ $t("Training.no_courses_found") }}</h5>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.training-course > .box-header {
	text-align: center;
	font-size: 28px;
}

.sections-icon {
	position: absolute;
	right: 7px;
	top: 5px;
	font-size: 16px;
	opacity: 0.8;
}

.sl-item {
	margin-bottom: 4px;
}

.sl-icon:before {
	width: 24px;
	height: 24px;
	border-width: 12px;
}

.sl-icon {
	left: -7px;
}

.sl-icon > i {
	width: 24px;
	height: 24px;
}

.sl-item:before {
	width: 26px;
	height: 26px;
	top: -1px;
	left: -8px;
	border-width: 1px;
}

.sl-item:after {
	top: 24px;
}

ul {
	padding-inline-start: 20px;
	margin-bottom: 0;
}
</style>

<script>
import TrainingService from "@/services/TrainingService";
import ResourceService from "@/services/ResourceService";
import QualOnlyScoringDriver from "@/services/ScoringDrivers/QualOnlyScoringDriver";
import PracticeDriver from "@/services/ScoringDrivers/PracticeDriver";
import Notie from "@/services/NotieService";
import Utils from "@/services/Utils";
import fs from "@/services/FormatService";
import ExemplarSetPageVue from "../QC/ExemplarSetPage.vue";
import { respectPriority, allowIfSkipTrainingEnabled, getUseSkipTrainingRole } from "@/utils/TrainingUtils";

const SCORM = 1;
const RESOURCE = 2;
const QUALIFICATION = 3;
const PRACTICE = 4;
const EXEMPLAR = 5;

export default {
	name: "MyTraining",

	props: ["user"],

	data() {
		return {
			fs: fs,

			INCOMPLETE: 0,
			IN_PROGRESS: 1,
			COMPLETE: 2,
			FAILED: -1,

			SCORM: SCORM,
			RESOURCE: RESOURCE,
			QUALIFICATION: QUALIFICATION,
			PRACTICE: PRACTICE,

			loading: true,
			trainingCourses: null,
		};
	},
	created() {
		this.loadTrainingCourses();
	},

	beforeDestroy() {},

	computed: {},

	methods: {
		loadTrainingCourses() {
			TrainingService.getCombinedCoursesAndProgress()
				.then((r) => {
					this.loading = false;
					this.trainingCourses = r;

					var determinedCourseStartingPoint = false;
					var doNotAllowToTake = false;
					//training courses are sorted in priority order
					for (let course of this.trainingCourses) {
						console.log("course");
						console.log(course);

						if (course.progress.overall_progress == 2) {
							doNotAllowToTake = false;
							course.progress.DO_NOT_ALLOW_TO_TAKE = false;
						} else if (course.progress.overall_progress == 1) {
							doNotAllowToTake = false;
							determinedCourseStartingPoint = true;
							course.progress.DO_NOT_ALLOW_TO_TAKE = false;
						} else if (course.progress.overall_progress == 0 && !determinedCourseStartingPoint) {
							doNotAllowToTake = false;
							determinedCourseStartingPoint = true;
							course.progress.DO_NOT_ALLOW_TO_TAKE = false;
						} else {
							doNotAllowToTake = true;
							course.progress.DO_NOT_ALLOW_TO_TAKE = true;
						}

						for (let i in course.elements) {
							if (!doNotAllowToTake) {
								course.elements[i].progress.DO_NOT_ALLOW_TO_TAKE = false;
							} else {
								course.elements[i].progress.DO_NOT_ALLOW_TO_TAKE = true;
							}

							if (i == 0) {
								if (course.elements[i].progress.progress == this.INCOMPLETE) {
									course.elements[i].isNext = true;
								}
							} else if (
								course.elements[i - 1].progress.progress == this.COMPLETE &&
								course.elements[i].progress.progress == this.INCOMPLETE
							) {
								course.elements[i].isNext = true;
							}
						}
					}
					// Iterate through and re-enable courses with same priority, if the first course is available.
					let trainingCourses = respectPriority(this.trainingCourses);

					// Figure out if the user has skip-training enabled for their role. If so, allow them to take every course (priority ignored)
					if (this.trainingCourses) {
						allowIfSkipTrainingEnabled(this.trainingCourses, getUseSkipTrainingRole)
							.then((enabledTrainingCourses) => {
								this.trainingCourses = enabledTrainingCourses;
							})
							.catch((error) => {
								console.log(error);
								this.trainingCourses = trainingCourses;
							});
					} else {
						console.log("NO TRAINING COURSES FOUND");
					}

					this.trainingCourses = trainingCourses;
				})
				.catch((e) => {
					this.loading = false;
					console.error(e);
					Notie.error("Failed to load training courses");
				});
		},

		completeElements(course) {
			if (!(course && course.elements && course.elements.length > 0)) {
				return 0;
			}

			let num = 0;
			for (let element of course.elements) {
				if (element.progress.progress == this.COMPLETE) {
					num++;
				}
			}
			return num;
		},

		failedQual(course) {
			console.log("Failed qual", course);
			for (let element of course.elements) {
				if (element.type == QUALIFICATION && element.progress.progress == this.FAILED) {
					return true;
				}
			}
		},

		goToNextElement(course) {
			// Try to avoid double navigation
			for (let element of course.elements) {
				if (element.inProgress) {
					return;
				}
			}

			for (let element of course.elements) {
				if (
					element.progress.progress == this.IN_PROGRESS ||
					(element.progress.progress == this.INCOMPLETE && element.isNext)
				) {
					return this.goToElement(course, element);
				}
			}
		},

		mustDownloadElement(element) {
			return element.type == RESOURCE && !Utils.isRenderableResource(element.resource);
		},

		goToElement(course, element) {
			element.inProgress = true;
			this.$forceUpdate();
			TrainingService.initProgress(course.id)
				.then((r) => {
					switch (element.type) {
						case SCORM:
							this.$router.push(`/my_training/scorm/${course.id}/${element.id}`);
							return;
						case RESOURCE:
							if (!Utils.isRenderableResource(element.resource)) {
								ResourceService.downloadTrainingResource(course, element)
									.then(() => {
										this.completeTrainingElement(course.id, element.id);
									})
									.catch((e) => {
										console.error(e);
										Notie.error("Failed to download resource", e);
									});
							} else {
								this.$router.push(`/training_resource/${course.id}/${element.id}`);
							}
							return;
						case QUALIFICATION:
							if (!element.qual_location) {
								element.inProgress = false;
								this.$forceUpdate();
								Notie.error("Malformed element has no qual_location field");
								return;
							}
							this.enterScoring(element, element.qual_location.section_id, element.qual_location.item_id);
							return;
						case PRACTICE:
							if (!element.practice_location) {
								element.inProgress = false;
								this.$forceUpdate();
								Notie.error("Malformed element has no practice_location field");
								return;
							}
							this.goToPracticeSet(
								element,
								element.practice_location.section_id,
								element.practice_location.item_id,
								element.practice_location.set_id
							);
							return;
						case EXEMPLAR:
							this.goToExemplar(element, () => this.completeTrainingElement(course.id, element.id));
							return;
					}
				})
				.catch((e) => {
					element.inProgress = false;
					this.$forceUpdate();
					console.error(e);
					Notie.error("Failed to initialize training progress", e);
				});
		},

		async completeTrainingElement(courseID, elementID) {
			try {
				await TrainingService.updateElementProgress(courseID, elementID, this.COMPLETE);
				this.loadTrainingCourses();
			} catch (e) {
				Notie.error("Failed to update progress", e);
			}
		},

		goToElementReview(course, element) {
			switch (element.type) {
				case SCORM:
					this.$router.push(`/my_training/scorm/${course.id}/${element.id}?preview=true`);
					return;
				case RESOURCE:
					if (!Utils.isRenderableResource(element.resource)) {
						ResourceService.downloadTrainingResource(course, element)
							.then(() => {
								TrainingService.updateElementProgress(course.id, element.id, this.COMPLETE);
							})
							.catch((e) => {
								console.error(e);
								Notie.error("Failed to download resource", e);
							});
					} else {
						this.$router.push(`/training_resource/${course.id}/${element.id}`);
					}
					return;
				case QUALIFICATION:
					if (!element.qual_location) {
						Notie.error("Malformed element has no qual_location field");
						return;
					}
					let sectID = element.qual_location.section_id;
					let itemID = element.qual_location.item_id;
					this.$router.push(
						`/my_training/my_qc_portfolio?qc=2&sect=${sectID}&item=${itemID}&sq=true&ss=true&si=true`
					);
					return;
				case PRACTICE:
					if (!element.practice_location) {
						Notie.error("Malformed element has no practice_location field");
						return;
					}
					let setID = element.practice_location.set_id;
					this.$router.push(`/my_training/qc_set_report/${setID}`);
					return;
				case EXEMPLAR:
					this.goToExemplar(element);
			}
		},

		goToExemplar(element, callback) {
			const { exemplar_location } = element;
			if (!exemplar_location) {
				element.inProgress = false;
				this.$forceUpdate();
				Notie.error("Malformed element has no exemplar_location field");
				return;
			}
			if (callback) callback();
			const { section_id, item_id, set_id } = exemplar_location;
			var isForTraining = true;
			this.$router.push(`/exemplar_set/${section_id}/${item_id}/${set_id}/${isForTraining}`);
		},

		enterScoring(element, sectionID, itemID) {
			element.loading = true;
			this.$forceUpdate();
			QualOnlyScoringDriver.getNext({
				section_id: sectionID,
				item_id: itemID,
			})
				.then((resp) => {
					console.log("RESP", resp);
					this.$router.push(`my_training/scoring/${sectionID}/${itemID}?qualonly=true`);
					element.inProgress = false;
					this.$forceUpdate();
				})
				.catch((err) => {
					Notie.error(this.$i18n.t("notie.cannot_score_item", { item: element.name }), err);
					element.inProgress = false;
					this.$forceUpdate();
				});
		},

		goToPracticeSet(element, sectionID, itemID, setID) {
			PracticeDriver.getNext({
				item_id: itemID,
				section_id: sectionID,
				set_id: setID,
			})
				.then((resp) => {
					var isForTraining = true;
					this.$router.push(`my_training/practice/${sectionID}/${itemID}/${setID}`);
					element.inProgress = false;
					this.$forceUpdate();
				})
				.catch((e) => {
					this.$router.push(`/my_training/qc_set_report/${setID}`);
					element.inProgress = false;
					this.$forceUpdate();
				});
		},

		goToResource(element) {
			if (!element.scorm) {
				console.error("Can't go to SCORM player, element did not contain scorm field");
				return;
			}

			this.$router.push(`/training_resource/${this.course.id}/${element.id}`);
		},

		goToQualification(element) {
			if (!element.qual_location) {
				console.error("Malformed element has no qual_location field");
				return;
			}
			this.$router.push("/qc_setup");
		},

		goToPractice(element) {
			if (!element.practice_location) {
				console.error("Malformed element has no practice_location field");
				return;
			}
			Store.set(this, "audit.r.selectedSection", element.practice_location.section_id);
			Store.set(this, "audit.r.selectedItem", element.practice_location.item_id);
			Store.set(this, "qc.setup.qc_type", QC_PRACTICE);
			this.$router.push(`/qc_setup/set/${element.practice_location.set_id}`);
		},

		goToScoring() {
			this.$router.push(`/scoring`);
		},

		getElementType(element) {
			let str = fs.trainingElementType(element.type);
			if (this.mustDownloadElement(element)) {
				str += ` (${this.$i18n.t("ExternalResource.download_only")})`;
			}
			return str;
		},

		getElementProgress(element) {
			return fs.trainingElementProgress(element.progress.progress);
		},
	},
};
</script>
