<template>
	<div class="row m-0">
		<form role="form" class="px-4 col-2">
			<div class="form-group _600">{{ $t("ItemEdit.Quality.validity.title") }}</div>
			<div class="form-group">
				<label class="ui-switch ui-switch-md theme-accent">
					<input type="checkbox" v-model="validityConfig.enabled" />
					<i></i>
				</label>
			</div>
		</form>
		<div v-if="validityConfig.enabled" role="form" class="px-4 col-10">
			<div class="row">
				<div class="col-md-3 col-6">
					<label>{{ $t("ItemEdit.Quality.validity.behavior") }}</label>
					<div class="radio">
						<label
							class="ui-check pr-2"
							v-tippy="{
								theme: 'popover',
								placement: 'top-start',
							}"
							title="Lock the user out from scoring upon failing validity"
						>
							<input
								type="radio"
								name="valBehavior"
								:value="false"
								v-model="validityConfig.calibration"
							/>
							<i class="dark-white"></i> {{ $t("ItemEdit.Quality.validity.lockout") }}
						</label>
					</div>
					<div class="radio form-group">
						<label
							class="ui-check pr-2"
							v-tippy="{
								theme: 'popover',
								placement: 'top-start',
							}"
							title="Send the user a calibration set upon failing validity"
						>
							<input type="radio" name="valBehavior" :value="true" v-model="validityConfig.calibration" />
							<i class="dark-white"></i> {{ $t("ItemEdit.Quality.validity.calibration") }}
						</label>
					</div>
				</div>
				<div class="col-md-3 col-6">
					<label>{{ $t("ItemEdit.Quality.validity.mode") }}</label>
					<div class="radio">
						<label
							class="ui-check pr-2"
							v-tippy="{
								theme: 'popover',
								placement: 'top-start',
							}"
							title="Judge validity based on a rolling window of # responses"
						>
							<input type="radio" name="valMode" :value="false" v-model="validityConfig.uses_threshold" />
							<i class="dark-white"></i> {{ $t("ItemEdit.Quality.validity.window") }}
						</label>
					</div>
					<div class="radio form-group">
						<label
							class="ui-check pr-2"
							v-tippy="{
								theme: 'popover',
								placement: 'top-start',
							}"
							title="Fail validity immediately upon missing # responses total"
						>
							<input type="radio" name="valMode" :value="true" v-model="validityConfig.uses_threshold" />
							<i class="dark-white"></i> {{ $t("ItemEdit.Quality.validity.threshold") }}
						</label>
					</div>
				</div>
				<div
					class="col-md-2 form-group"
					:class="{ invalid: !valid.field('window_above_zero') }"
					v-show="!validityConfig.uses_threshold"
				>
					<label>{{ $t("ItemEdit.Quality.validity.window_size") }}</label>
					<input type="number" class="form-control" min="1" v-model.number="validityConfig.window" />
				</div>

				<div
					class="col-md-2 form-group"
					:class="{ invalid: !valid.field('threshold_at_least_zero') }"
					v-show="validityConfig.uses_threshold"
				>
					<label>{{ $t("ItemEdit.Quality.validity.num_incorrect") }}</label>
					<input type="number" min="0" class="form-control" v-model.number="validityConfig.threshold" />
				</div>
				<div class="col-md-4 form-group">
					<label>{{ $t("ItemEdit.Quality.validity.res_interval") }}</label>
					<div
						class="d-flex flex-row align-items-center"
						:class="{
							invalid: !(
								valid.field('response_interval_low_min') &&
								valid.field('response_interval_high_min') &&
								valid.field('val_response_interval')
							),
						}"
					>
						<label
							class="mb-0 mr-2"
							v-tippy="{
								theme: 'popover',
								placement: 'top-start',
							}"
							title="The minimum number of live scores since the last validity before another validity is delivered"
							>From</label
						>
						<input
							type="number"
							class="flex form-control"
							v-model.number="validityConfig.interval_low"
							min="0"
						/>
						<label
							class="mb-0 mx-2"
							v-tippy="{
								theme: 'popover',
								placement: 'top-start',
							}"
							title="The maximum number of live scores since the last validity before another validity is delivered"
							>to</label
						>
						<input
							type="number"
							class="flex form-control"
							v-model.number="validityConfig.interval_high"
							min="0"
						/>
					</div>

					<label
						class="md-check mt-1"
						v-tippy="{ theme: 'popover' }"
						:title="$t('ItemEdit.Quality.validity.normalize_explanation')"
					>
						<input v-model="validityConfig.normalize_interval" type="checkbox" />
						<i class="theme-accent"></i> {{ $t("ItemEdit.Quality.validity.normalize") }}
					</label>
				</div>
			</div>

			<div v-if="promptValReset" class="row" style="margin-top: -12px">
				<div class="col-12 form-group">
					<label
						class="md-check"
						v-tippy="{ theme: 'popover' }"
						:title="$t('ItemEdit.Quality.validity.reset_thresholds_explanation')"
					>
						<input v-model="item.resetValThresholds" type="checkbox" />
						<i class="theme-accent"></i> {{ $t("ItemEdit.Quality.validity.reset_thresholds") }}
					</label>
				</div>
			</div>
			<qc-rule-config
				v-if="validityConfig.uses_threshold"
				:config="validityConfig.threshold_rule"
				is-validity
				validityThreshold
				class="mb-3"
				:class="{ invalid: !valid.field('val_has_rule') }"
			></qc-rule-config>
			<val-rule-config
				v-else
				v-model="validityConfig.window_rules"
				:title="$t('ItemEdit.Quality.validity.validity_rules')"
				:class="{ invalid: !valid.field('val_has_rule') }"
			/>
		</div>
	</div>
</template>

<script>
import ValRuleConfig from "./ValRuleConfig";
import QcRuleConfig from "@/components/QcRuleConfig";

export default {
	name: "ValConfig",

	components: {
		ValRuleConfig,
		QcRuleConfig,
	},

	props: {
		item: Object,
		originalItem: Object,
		valid: Object,
		hasValStats: Boolean,
	},

	data() {
		return {};
	},

	computed: {
		validityConfig() {
			return this.item.validity_config;
		},

		originalValConfig() {
			const { originalItem } = this;
			return originalItem && originalItem.validity_config;
		},

		promptValReset() {
			const { hasValStats, validityConfig, originalValConfig } = this;
			if (!hasValStats) return false;
			if (validityConfig && validityConfig.enabled && validityConfig.uses_threshold) return false;

			return originalValConfig && originalValConfig.enabled && originalValConfig.uses_threshold;
		},
	},
};
</script>

<style>
</style>