<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg flex-nowrap box-shadow">
				<!-- Page title -->
				<a class="navbar-brand">{{ $t("ScormPool.title") }}</a>

				<!-- Create new -->
				<div class="py-0 ml-auto">
					<button class="btn btn-sm hover-darken theme-accent btn-block" @click="openNewLocationModal">
						<i class="far fa-fw fa-plus"></i>
						<span class="hidden-folded d-inline">{{ $t("ScormPool.new_location") }}</span>
					</button>
				</div>
			</div>
		</template>

		<loading type="page" v-if="loading" />
		<div class="content scroll-y" v-else>
			<div class="padding">
				<!-- Page content goes here -->
				<div v-if="!locations.length" class="box section m-auto no-assignments">
					<div class="box-header theme-lighten-2 text-white">
						{{ $t("ScormPool.no_buckets") }}
					</div>
					<div class="box-body light lt text-center p-4">
						<p class="m-0">{{ $t("ScormPool.no_buckets_defined") }}</p>
					</div>
				</div>

				<div class="row" ref="locationsContainer" v-if="locations.length">
					<div class="col-md-6 location-card" v-for="(location, i) in locations" :key="location.id">
						<div class="box location">
							<div
								class="box-header theme-lighten-2 text-white d-flex flex-row align-items-center justify-content-between"
							>
								<div>
									<template v-if="location.bucket == 'local'">
										<div>
											<i class="fas fa-folder-open mr-1" />
											{{ $t("ScormPool.local") }}
										</div>
										<div class="text-sm">
											{{ $t("ScormPool.uploaded_packages") }}
										</div>
									</template>
									<template v-else>
										<div>
											<i class="fas fa-download mr-1" />
											<span v-tippy :title="$t('ScormPool.bucket')">{{ location.bucket }}</span
											><span
												v-tippy
												:title="$t('ScormPool.base_path')"
												class="text-muted"
												style="font-size: 22px"
												>/{{ location.base_path }}</span
											>
										</div>
										<div class="text-sm">
											{{ getCredentialName(location.credential_id) }}
										</div>
									</template>
								</div>
								<div>
									<div v-if="location.bucket == 'local'">
										<input
											:id="`scorm-upload-input`"
											class="d-none"
											type="file"
											multiple
											accept=".zip"
											@change="selectScormFile($event)"
										/>
										<button
											v-if="!uploading"
											class="btn theme text-white"
											@click="click(`scorm-upload-input`)"
										>
											<i class="fas fa-file-upload mr-1" />
											{{ $t("buttons.upload") }}
										</button>
										<button v-else class="btn btn-secondary text-white" disabled>
											<loading type="icon" class="mr-1" />
											{{ $t("buttons.uploading") }}
										</button>
									</div>
									<div v-else>
										<button
											@click="scanAllPackages(location, i)"
											class="btn btn-icon btn-lg btn-rounded theme text-white"
											v-tippy
											:title="$t('ScormPool.scan_all_packages')"
										>
											<i class="fas fa-sync" />
										</button>
										<button
											@click="deleteLocation(location)"
											class="btn btn-icon btn-lg btn-rounded theme text-white"
											v-tippy
											:title="$t('ScormPool.delete_location')"
										>
											<i class="fas fa-trash" />
										</button>
									</div>
								</div>
							</div>
							<div v-if="location.loading" class="box-body light lt text-center p-4">
								<loading type="large" />
							</div>
							<div
								v-else-if="!(location.packages && location.packages.length > 0)"
								class="box-body light lt text-center p-4"
							>
								<p class="m-0">{{ $t("ScormPool.no_packages") }}</p>
							</div>
							<div v-else class="box-body light lt p-0">
								<table class="table table-condensed">
									<thead>
										<tr>
											<th></th>
											<th class="py-2 w-100">{{ $t("ScormPool.package_ref_id") }}</th>
											<th class="py-2">{{ $t("fields.status") }}</th>
											<th class="py-2" style="text-align: center">
												{{ $t("fields.actions") }}
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="pack in location.packages" :key="pack.id">
											<td></td>
											<template v-if="pack.uploading">
												<td colspan="3">
													<div
														class="form-control pack-progress-bar d-flex flex-row align-items-center py-0"
														:style="progressBarStyle(pack)"
													>
														<i class="fas fa-lg fa-file-upload mr-2" />
														<div class="mr-auto">{{ pack.ref_id }}</div>

														<loading
															v-if="pack.loaded >= pack.total && !pack.uploaded"
															style="font-size: 18px"
															type="icon"
															class="mr-2"
														/>
														<div class="d-flex flex-column align-items-end">
															<div v-if="pack.loaded < pack.total">
																{{ fs.calcPercent1d(pack.loaded, pack.total) }}
															</div>
															<div v-else-if="!pack.uploaded">
																{{ $t("ScormPool.processing") }}
															</div>
															<div v-else>
																{{ $t("ScormPool.processed") }}
															</div>
															<div class="text-muted text-xxs">
																{{ fs.byteSize(pack.loaded) }} /
																{{ fs.byteSize(pack.total) }}
															</div>
														</div>
													</div>
												</td>
											</template>
											<template v-else>
												<td class="v-mid">
													<div class="wrap-text">{{ pack.ref_id }}</div>
													<div v-if="pack.name" class="text-muted text-xs">
														{{ pack.name }}
													</div>
												</td>
												<td class="v-mid text-center">
													<template v-if="pack.error">
														<i
															v-tippy
															:title="$t('ScormPool.error_tooltip') + pack.error"
															class="fas fa-exclamation text-danger"
														/>
													</template>
													<template v-else-if="pack.verified">
														<i
															v-tippy
															:title="$t('ScormPool.verified_tooltip')"
															class="fas fa-check text-success"
														/>
													</template>
													<template v-else>
														<i
															v-tippy
															:title="$t('ScormPool.unverified_tooltip')"
															class="fas fa-question text-warning"
														/>
													</template>
												</td>
												<td class="v-mid text-center">
													<button
														@click="previewPackage(pack, location)"
														class="btn btn-icon btn-sm btn-rounded lighten-2 theme text-white"
														v-tippy
														:title="$t('tooltip.preview')"
													>
														<i class="fas fa-presentation" style="margin-top: 2px" />
													</button>
												</td>
											</template>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" id="newLocationModal" data-backdrop="static" data-keyboard="false">
			<div class="modal-dialog">
				<div class="modal-content" v-if="newLocation">
					<div class="modal-header">
						<h5>{{ $t("ScormPool.new_scorm_package_location") }}</h5>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12 form-group">
								<label>{{ $t("ScormPool.credential") }}</label>
								<v-select
									:options="creds"
									label="cred_id"
									v-model="newLocation.credential_id"
									:reduce="(o) => o.id"
								/>
							</div>
							<div class="col-12 form-group">
								<label>{{ $t("ScormPool.bucket") }}</label>
								<input
									disabled
									class="form-control"
									:class="{ 'is-invalid': !newLocationCredential }"
									v-model="newLocation.bucket"
								/>
							</div>
							<div class="col-12 form-group">
								<label>{{ $t("ScormPool.base_path") }}</label>
								<input class="form-control" v-model="newLocation.base_path" placeholder="scorm/" />
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button class="btn btn-flat" data-dismiss="modal">{{ $t("buttons.cancel") }}</button>
						<button
							@click="saveLocation(newLocation)"
							class="btn btn-flat"
							:class="{
								'btn-success': canSaveNewLocation,
								'btn-secondary': !canSaveNewLocation,
							}"
							:disabled="!canSaveNewLocation"
						>
							{{ $t("buttons.save") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.no-assignments {
	max-width: 600px;
}

.location > .box-header {
	font-size: 28px;
}

.avail-icon {
	position: absolute;
	right: 7px;
	top: 2px;
	font-size: 16px;
	opacity: 0.8;
}

.button-pad {
	padding: 0.1rem;
}

.pack-progress-bar {
	border-left-color: rgba(120, 130, 140, 0.4);
}
</style>

<script>
//Libraries
import _ from "lodash";
import $ from "jquery";
import Masonry from "masonry-layout";
import BB from "bluebird";

//Services
import TrainingService from "@/services/TrainingService";
import TrainingScormService from "@/services/TrainingScormService";
import TenantService from "@/services/TenantService";
import ThemeService from "@/services/ThemeService";

import fs from "../../services/FormatService";
import Notie from "../../services/NotieService";

export default {
	name: "ScormPool",

	props: ["user"],

	components: {},

	data() {
		return {
			fs: fs,

			loading: true,
			uploading: false,
			creds: null,
			locations: null,
			newLocation: null,
			newLocationCredential: null,

			themeColor: null,
			masonry: null,
		};
	},

	created() {
		this.themeColor = ThemeService.getThemeColor();

		this.loadLocations(true);

		TenantService.listCredentialsSafe(this.user.client.id)
			.then((r) => {
				this.creds = r.data;
			})
			.catch((e) => {
				console.log(e);
				Notie.error("Failed to load credentials", e);
			});
	},

	computed: {
		canSaveNewLocation() {
			if (!this.newLocation) return false;
			if (!this.newLocation.credential_id) return false;
			if (!this.newLocation.bucket) return false;
			if (!this.newLocation.base_path) return false;
			if (!this.newLocationCredential) return false;
			if (this.newLocationCredential.type != "S3") return false;

			return true;
		},
	},

	watch: {
		"newLocation.credential_id"() {
			if (!this.newLocation) return;
			if (!this.newLocation.credential_id) return;
			let cred = _.find(this.creds, { id: this.newLocation.credential_id });
			if (!cred) {
				this.newLocation.bucket = "";
				this.newLocationCredential = false;
				return;
			}
			if (cred.type != "S3") {
				this.newLocation.bucket = "Invalid Credential Type (must be S3)";
				this.newLocationCredential = false;
				return;
			}
			this.newLocation.bucket = cred.bucket;
			this.newLocationCredential = cred;
		},
	},

	methods: {
		loadLocations(loadDetails) {
			TrainingService.listScormLocations()
				.then((r) => {
					this.locations = r.data;

					this.loading = false;

					this.$nextTick(() => {
						if (!this.masonry) {
							this.masonry = new Masonry(this.$refs.locationsContainer, {
								itemSelector: ".location-card",
								gutter: 0,
							});
						} else {
							this.masonry.layout();
						}
					});

					if (loadDetails) {
						this.loadLocationDetails();
					}
				})
				.catch((err) => {
					console.error(err);
					Notie.error("Failed to load SCORM locations", err);
				});
		},

		loadLocationDetails() {
			this.locations.forEach((location, i) => this.scanAllPackages(location, i));
		},

		async scanAllPackages(location, i) {
			this.locations[i].loading = true;
			this.$forceUpdate();
			this.$nextTick(() => {
				this.masonry.layout();
			});
			const locID = location.bucket === "local" ? "local" : location.id;
			try {
				const { data } = await TrainingService.getScormLocationWithPackages(locID);
				this.locations[i] = data;
				this.$forceUpdate();
				this.$nextTick(() => {
					this.masonry.layout();
				});
			} catch (err) {
				console.error(err);
				Notie.error(`Failed to load SCORM location: ${location.bucket} - ${location.base_path}`, err);
			}
		},

		saveLocation(location) {
			TrainingService.saveScormLocation(location)
				.then((r) => {
					Notie.success("Saved new location");

					this.loadLocations(true);
					$("#newLocationModal").modal("hide");
				})
				.catch((err) => {
					console.error(err);
					Notie.error("Failed to save location", err);
				});
		},

		async deleteLocation(location) {
			const ok = await this.$bvModal.msgBoxConfirm("Are you sure you want to Delete this SCORM location?", {
				title: "Remove Location",
				centered: true,
			});

			if (ok) {
				try {
					await TrainingService.deleteScormLocation(location.id);
					this.locations = this.locations.filter(({ id }) => id !== location.id);
				} catch (err) {
					Notie.error("Failed to Delete Location", err);
				}
			}
		},

		openNewLocationModal() {
			this.newLocation = {
				bucket: "",
				credential_id: null,
				base_path: "",
			};
			this.newLocationCredential = false;

			$("#newLocationModal").modal("show");
		},

		async previewPackage(pack, location) {
			const { ref_id, verified } = pack;
			let { import_record_id } = pack;

			if (!verified) {
				this.loading = true;
				import_record_id = await this.moveToLocal(location.id, ref_id);
			}

			this.$router.push(`/my_training/scorm_preview/${import_record_id}`);
		},

		async moveToLocal(scormLocationID, scormRefID) {
			try {
				const {
					data: { import_record_id },
				} = await TrainingScormService.addExternalScormPackage(scormLocationID, scormRefID);
				return import_record_id;
			} catch (err) {
				console.log("Failed to add to local", err);
				Notie.error("Failed to add package to local S3", err);
			}
		},

		getCredentialName(credentialID) {
			let cred = _.find(this.creds, { id: credentialID });
			return cred ? cred.cred_id : "(Unknown credential)";
		},

		selectScormFile(event) {
			let localLocation = null;
			for (let loc of this.locations) {
				if (loc.bucket == "local") {
					localLocation = loc;
				}
			}
			if (!localLocation) {
				let emsg = "Failed to find local location";
				console.error(emsg);
				Notie.error(emsg, e);
				return;
			}

			this.uploading = true;
			BB.map(event.target.files, (f) => {
				let newPackage = {
					ref_id: f.name,
					uploading: true,
				};
				localLocation.packages = localLocation.packages || [];
				localLocation.packages.push(newPackage);
				return TrainingService.uploadScormPackage(
					f,
					(e) => {
						newPackage.loaded = e.loaded;
						newPackage.total = e.total;
						console.log(e.loaded, "out of", e.total);
						this.$forceUpdate();
					},
					{ concurrency: 2 }
				).then(() => {
					newPackage.uploaded = true;
					this.$forceUpdate();
				});
			})
				.then(() => {
					console.log("FINISHED MAP");
					this.uploading = false;
					this.loadLocationDetails(this.locations[0], 0);
				})
				.catch((e) => {
					console.log("ERROR MAP");
					this.uploading = false;
					console.error(e);
					Notie.error("Failed to upload SCORM package", e);
					localLocation.packages.pop();
				});
		},

		progressBarStyle(prog) {
			let r = this.themeColor.r;
			let g = this.themeColor.g;
			let b = this.themeColor.b;
			let p = 0;
			if (prog.loaded && prog.total) {
				p = (prog.loaded / prog.total) * 100;
			}
			return {
				background: `linear-gradient(90deg, rgba(${r}, ${g}, ${b}, 0.2) ${p}%, #00000000 0%)`,
			};
		},

		click(id) {
			var el = document.getElementById(id);
			if (el) {
				el.click();
			}
		},
	},
};
</script>
