<template>
	<page customNavBar customBody>
		<template #navbar>
			<div class="navbar navbar-expand-lg flex-nowrap box-shadow">
				<!-- Page title -->
				<div class="navbar-text nav-title flex" id="pageTitle">
					{{ $t("Auditing.responses") }}
					<span class="far fa-fw fa-angle-right"></span>{{ $t("ScoreAuditing.scores") }}
				</div>

				<!-- Multiselect actions -->
				<div class="btn-group" v-if="numSelected > 0">
					<div class="navbar-text nav-title no-grow mr-2" id="pageTitle">
						{{ $t("Auditing.num_selected", { num: numSelected }) }}
					</div>
					<button class="btn white" data-toggle="dropdown" aria-expanded="false">
						<i class="fas fa-ellipsis-v faw"></i>
					</button>
					<div class="dropdown-menu dropdown-menu-right w-md">
						<a v-if="user.role.delete_data" class="dropdown-item" @click="confirmResetModal = true">
							<i class="far fa-strikethrough faw"></i>
							<label class="mb-0">{{ $t("ScoreAuditing.clear_scores") }}</label>
							<span class="far fa-fw fa-angle-right"></span>
							<label class="mb-0 mr-3">{{ $t("ScoreAuditing.execute") }}</label>
						</a>
						<a class="dropdown-item" @click="planResetScores">
							<i class="far fa-strikethrough faw"></i>
							<label class="mb-0">{{ $t("ScoreAuditing.clear_scores") }}</label>
							<span class="far fa-fw fa-angle-right"></span>
							<label class="mb-0 mr-3">{{ $t("ScoreAuditing.plan") }}</label>
						</a>
					</div>
				</div>
			</div>
		</template>

		<div
			class="d-flex flex flex-row h100 mb-auto align-items-stretch"
			style="background-color: white; overflow: auto"
		>
			<div class="aside scroll-y">
				<div class="modal-dialog w-md light b-r b-t white p-2 audit-search">
					<div class="form-group">
						<label>{{ $t("Auditing.section") }}</label>
						<config-select :options="sections" v-model="selectedSection"></config-select>
					</div>
					<div class="form-group">
						<label>{{ $t("Auditing.item") }}</label>
						<config-select :options="items" v-model="selectedItem"></config-select>
					</div>
					<div class="form-group">
						<label>{{ $t("Auditing.response_id") }}</label>
						<input v-model="response_id" type="text" class="form-control" />
					</div>

					<!-- Scored by -->
					<div class="form-group">
						<label>{{ $t("Auditing.scored_by") }}</label>
						<config-user-search
							:clientID="user.client && user.client.id"
							v-model="users"
							includeTeams
							:multiple="!user.role.limit_score_auditing_self"
							:disabled="user.role.limit_score_auditing_self"
						/>
					</div>

					<div class="form-group">
						<label>{{ $t("ScoreAuditing.score_type") }}</label>
						<v-select
							multiple
							:selectable="({ divider }) => !divider"
							:options="scoreTypes"
							v-model="filter_types"
						>
							<template slot="option" slot-scope="option">
								<span v-if="option.divider" class="config-select-divider"></span>
								<span v-else>
									{{ option.label }}
								</span>
							</template>
						</v-select>
					</div>

					<div class="form-group">
						<label>
							{{ $t("ScoreAuditing.from_date") }}
							<user-timezone />
						</label>
						<config-date
							v-model="fromDate"
							:config="{ showClear: true }"
							:disabled="user.role.limit_score_auditing_24h"
							class="dtp-scrollfix dtp-scrollfix-date"
						></config-date>
					</div>
					<div v-if="!user.role.limit_score_auditing_24h" class="form-group">
						<label>
							{{ $t("ScoreAuditing.to_date") }}
							<user-timezone />
						</label>
						<config-date
							v-model="toDate"
							:config="{ showClear: true }"
							defaultTime="11:59 PM"
							class="dtp-scrollfix dtp-scrollfix-time"
						></config-date>
					</div>

					<div class="form-group">
						<label>{{ $t("Auditing.exported") }}</label>
						<v-select :options="exportStates" :searchable="false" v-model="selectedExportState"> </v-select>
					</div>

					<div v-if="!user.role.limit_score_auditing_24h" class="form-group">
						<label>
							{{ $t("ScoreAuditing.exported_from_date") }}
							<user-timezone />
						</label>
						<config-date
							v-model="exportedFromDate"
							:config="{ showClear: true }"
							:disabled="user.role.limit_score_auditing_24h"
							class="dtp-scrollfix dtp-scrollfix-date"
						></config-date>
					</div>
					<div class="form-group">
						<label>
							{{ $t("ScoreAuditing.exported_to_date") }}
							<user-timezone />
						</label>
						<config-date
							v-model="exportedToDate"
							:config="{ showClear: true }"
							defaultTime="11:59 PM"
							class="dtp-scrollfix dtp-scrollfix-time"
						></config-date>
					</div>

					<div class="btn-group mt-2 btn-block w-100">
						<button @click="showQueryModal()" class="btn btn-block theme">
							{{ $t("buttons.query_scores") }}
						</button>
						<button
							@click="clearScoreSearch()"
							class="btn theme lighten-2"
							v-tippy
							:title="$t('tooltip.clear')"
						>
							<i class="far fa-times"></i>
						</button>
					</div>
					<table class="table condensed-table" v-if="ats_display && ats_display.length > 0">
						<tr>
							<th>{{ $t("ScoreAuditing.trait") }}</th>
							<th>{{ $t("ScoreAuditing.score") }}</th>
						</tr>
						<tr v-for="(row, i) in ats_display" :key="i">
							<td>{{ row.name }}</td>
							<td>{{ row.score }}</td>
						</tr>
					</table>
				</div>
			</div>
			<div class="flex d-flex flex-column" style="overflow: hidden">
				<div class="flex scroll-y scroll-x">
					<!-- Page content goes here -->
					<b-table
						ref="table"
						hover
						:show-empty="true"
						:items="provider"
						:fields="fields"
						:current-page="currentPage"
						:per-page="perPage"
						:filter="filter"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
					>
						<!-- Select -->
						<template #head(select)>
							<table-multiselect
								v-model="multiSelect"
								:selectAll.sync="selectAll"
								master
								:items="currentItems"
								byField="score_id"
								:totalRows="totalRows"
								:numSelected.sync="numSelected"
								:query="query"
								:reset="resetSelection"
							></table-multiselect>
						</template>
						<template #cell(select)="data">
							<table-multiselect
								:value="multiSelect"
								:selectAll="selectAll"
								:id="data.item.score_id"
							></table-multiselect>
						</template>

						<template #cell(score_type)="data">
							<span
								v-tippy
								:title="fs.scoreTypeName(data.item.score_type, vm.$i18n)"
								class="d-inline-flex flex-row align-items-center"
							>
								<i class="material-icons tiny mr-1">{{ fs.scoreTypeIcon(data.item.score_type) }}</i>
								<span>{{ fs.scoreTypeAbr(data.item.score_type, vm.$i18n) }}</span>
							</span>
						</template>

						<template #cell(first_name)="data">
							<span>{{ data.item.user.first_name }} {{ data.item.user.last_name }}</span>
							<span class="text-xxs text-muted">({{ data.item.user.scorer_id }})</span>
						</template>

						<!-- State -->
						<template #cell(state)="data">{{ getState(data.item.state) }}</template>

						<!-- Scores -->
						<template #cell(score)="data">
							<i
								v-if="data.item.protected"
								class="fas fa-lock mr-2"
								v-tippy="{ theme: 'popover' }"
								title="Protected"
							/>
							<inline-score :score="data.item" :rubric="selectedItem && selectedItem.rubric" tooltip />
						</template>

						<!-- Think time -->
						<template #cell(think_time)="data">{{ getTime(data.item.think_time) }}</template>

						<!-- Created At -->
						<template #cell(applied_at)="data">{{ format(data.item.applied_at) }}</template>

						<!-- Exported At -->
						<template #cell(exported_at)="data">
							<span v-if="data.item.exporting" v-tippy :title="$t('Auditing.exporting_tooltip')">{{
								$t("Auditing.exporting")
							}}</span>
							<template v-if="!data.item.exporting">
								<span
									:title="formatExportTooltip(data.item.export_error)"
									v-tippy
									v-if="data.item.export_error"
								>
									<i class="fas fa-exclamation-triangle"></i>
								</span>
								<span v-if="data.item.exported">{{ format(data.item.exported_at) }}</span>
								<span v-if="!data.item.exported" class="text-muted"></span>
							</template>
						</template>

						<!-- Actions -->
						<template #cell(actions)="data">
							<div class="d-flex flex-rows">
								<div class="w30">
									<a
										:href="`#/score_audit/${selectedSection.id}/${selectedItem.id}/${data.item.id}`"
										class="btn btn-sm btn-icon btn-rounded theme-accent text-white m-0"
										v-tippy
										:title="$t('tooltip.view')"
									>
										<i class="far fa-search"></i>
									</a>
								</div>
							</div>
						</template>
					</b-table>
				</div>
				<div class="p-1 b-t mt-auto white d-flex flex-row align-items-center">
					<a class="flex mx-3"
						>{{ $t("pagination.page") }} {{ totalRows > 0 ? currentPage : 0 }} {{ $t("pagination.of") }}
						{{ Math.ceil(totalRows / perPage) }} ({{ totalRows }})</a
					>
					<div>
						<b-pagination
							size="md"
							class="m-1"
							:total-rows="totalRows"
							v-model="currentPage"
							:per-page="perPage"
						></b-pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- TraitScore query modal -->
		<b-modal
			id="traitScoreModal"
			v-model="queryModal"
			v-if="selectedItem && selectedItem.rubric"
			no-fade
			@hide="hideQueryModal"
		>
			<template slot="modal-title">{{ $t("Auditing.score_query") }}</template>
			<div class="row">
				<table class="table ats-table">
					<thead v-if="score_specific_query">
						<tr>
							<th style="width: 50%"></th>
							<th></th>
							<th class="text-center">
								<span class="d-flex flex-row align-items-center">
									<i class="material-icons mi-small mr-1">{{ fs.scoreTypeIcon(1) }}</i>
									{{ fs.scoreTypeAbr(1, vm.$i18n) }}
								</span>
							</th>
							<th class="text-center">
								<span class="d-flex flex-row align-items-center">
									<i class="material-icons mi-small mr-1">{{ fs.scoreTypeIcon(2) }}</i>
									{{ fs.scoreTypeAbr(2, vm.$i18n) }}
								</span>
							</th>
							<th class="text-center">
								<span class="d-flex flex-row align-items-center">
									<i class="material-icons mi-small mr-1">{{ fs.scoreTypeIcon(3) }}</i>
									{{ fs.scoreTypeAbr(3, vm.$i18n) }}
								</span>
							</th>
							<th class="text-center">
								<span class="d-flex flex-row align-items-center">
									<i class="material-icons mi-small mr-1">{{ fs.scoreTypeIcon(4) }}</i>
									{{ fs.scoreTypeAbr(4, vm.$i18n) }}
								</span>
							</th>
							<th style="width: 50%"></th>
						</tr>
					</thead>
					<thead v-if="!score_specific_query">
						<tr>
							<th style="width: 50%"></th>
							<th></th>
							<th class="text-center">Score</th>
							<th style="width: 50%"></th>
						</tr>
					</thead>
					<tbody>
						<template v-for="trait in selectedItem.rubric.traits">
							<tr v-if="!(trait.separator || trait.is_parent)" :key="trait.id">
								<td></td>
								<td class="p-custom pr-4 v-mid">{{ trait.reported_name || trait.name }}</td>
								<td v-if="!score_specific_query" style="width: 10px">
									<v-select
										@input="tsChange(trait.id, 0, $event)"
										class="v-select-narrow"
										:options="traitSelectionMap[trait.id][0].sps"
										v-model="traitSelectionMap[trait.id][0].selected"
										:searchable="false"
										:clearable="false"
									></v-select>
								</td>
								<template v-for="st in scoreTypes">
									<td v-if="score_specific_query" :key="st.type">
										<v-select
											@input="tsChange(trait.id, st.type, $event)"
											class="v-select-narrow"
											:options="traitSelectionMap[trait.id][st.type].sps"
											:searchable="false"
											:clearable="false"
											v-model="traitSelectionMap[trait.id][st.type].selected"
										></v-select>
									</td>
								</template>
								<td></td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<template slot="modal-footer">
				<button @click="applyQueryModal" class="btn btn-flat primary">{{ $t("Auditing.apply") }}</button>
			</template>
		</b-modal>

		<b-modal id="confirmReset" v-model="confirmResetModal">
			<template slot="modal-title">Reset Scores</template>
			<h6>{{ $t("ScoreAuditing.confirm_reset_scores", { num: numSelected }) }}</h6>
			<h6>
				<span class="text-danger far faw fa-exclamation-triangle"></span>
				{{ $t("ScoreAuditing.reset_information") }}
			</h6>
			<template slot="modal-footer">
				<button class="btn btn-flat btn-primary" @click="confirmResetModal = false">
					{{ $t("buttons.cancel") }}
				</button>
				<button @click="resetScores" class="btn btn-flat btn-danger">{{ $t("buttons.reset") }}</button>
			</template>
		</b-modal>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.w30 {
	width: 30px;
}
.audit-search > .form-group {
	margin-bottom: 5px;
}
.badge {
	width: 1.25rem;
	height: 1.5rem;
	line-height: 1.4rem;
	padding: 0 0;
}
.wide-badge {
	font-size: 1rem;
	width: initial !important;
}
.empty-badge {
	box-shadow: inset 0px 0px 0px 1.2px rgba(0, 0, 0, 0.2);
	background-color: white;
}
.small {
	font-size: 1.25rem;
}
.tiny {
	font-size: 1rem;
}
.ats-table {
	white-space: nowrap;
}
label {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}
</style>

<script>
//UI Components
//Libraries
//import _ from "lodash";
import moment from "moment";
import $ from "jquery";

//Services
import ConfigService from "../../services/ConfigService";
import AuditingService from "../../services/AuditingService";
import ScoringService from "../../services/ScoringService";
import ResetJobService from "../../services/ResetJobService";
import fs from "../../services/FormatService";
import notie from "../../services/NotieService";
import store from "../../services/Store";

import TableMultiselect from "../../components/TableMultiselect";
import ConfigUserSearch from "../../components/ConfigUserSearch";
import ConfigSelect from "../../components/ConfigSelect";
import ConfigDate from "../../components/ConfigDate";
import InlineScore from "../../components/InlineScore";
import UserTimezone from "../../components/UserTimezone";

import { SCORE_TYPES } from "@/services/Constants";

export default {
	name: "ScoreAuditing",
	components: {
		InlineScore,
		TableMultiselect,
		ConfigUserSearch,
		ConfigSelect,
		ConfigDate,
		UserTimezone,
	},
	data() {
		var response_id = store.get(this, "audit.s.response_id");
		var users = store.getDefault(this, "audit.s.users", []);
		if (this.$route.meta.user.role.limit_score_auditing_self) {
			this.$route.meta.user.name = fs.nameWithScorerID(this.$route.meta.user);
			users = [this.$route.meta.user];
		}
		var score_specific_query = store.get(this, "audit.s.score_specific_query");
		var sortBy = store.get(this, "audit.s.sortBy");
		var sortDesc = store.get(this, "audit.s.sortDesc");
		var currentPage = store.get(this, "audit.s.currentPage");
		// debugger;

		var toDate = store.get(this, "audit.s.toDate");
		var fromDate = store.get(this, "audit.s.fromDate");
		if (this.$route.meta.user.role.limit_score_auditing_24h) {
			fromDate = moment().add(-1, "day");
		}

		let fields = [
			{
				key: "select",
				label: this.$i18n.t("fields.select"),
				thClass: "p-custom p-0 v-mid",
				thStyle: "width: 40px;",
				tdClass: "p-custom p-0 v-mid",
			},
			{ key: "ref_id", label: this.$i18n.t("fields.response"), sortable: true },
			{
				key: "first_name",
				label: this.$i18n.t("fields.scorer"),
				sortable: true,
			},
			{
				key: "score_type",
				label: this.$i18n.t("fields.type"),
				tdClass: "p-custom py-0 v-mid",
				sortable: true,
			},
			{
				key: "score",
				label: this.$i18n.t("fields.score"),
				tdClass: "p-custom py-0 v-mid",
				sortable: false,
			},
			{
				key: "think_time",
				label: this.$i18n.t("fields.time"),
				sortable: true,
			},
			{
				key: "applied_at",
				label: this.$i18n.t("fields.applied_at"),
				tdClass: "text-truncate",
				sortable: true,
			},
			{
				key: "exported_at",
				label: this.$i18n.t("fields.exported_at"),
				tdClass: "text-truncate",
				sortable: true,
			},
			{
				key: "actions",
				label: this.$i18n.t("fields.actions"),
				tdClass: "p-custom py-0 v-mid",
			},
		];

		//Dynamically bound fields
		return {
			vm: this,
			fs: fs,
			user: this.$route.meta.user,
			loading: true,
			fields: fields,
			currentPage: currentPage,
			perPage: 20,
			totalRows: 0,
			filter: "",
			sortBy: sortBy,
			sortDesc: sortDesc,
			currentItems: [],
			query: null,
			multiSelect: [],
			selectAll: null,
			numSelected: 0,
			queueResetSelection: false,

			sections: [],
			selectedSection: null,

			items: [],
			selectedItem: null,

			fromDate: fromDate,
			toDate: toDate,

			selectedExportState: { value: null },

			exportedFromDate: null,
			exportedToDate: null,

			response_id: response_id ? response_id : "",
			users: users,

			selected_resp: null,

			filter_types: [],
			score_specific_query: score_specific_query,
			traitSelectionMap: null,
			audit_trait_scores: [],
			ats_display: null,

			queryModal: false,
			confirmResetModal: false,
		};
	},

	created() {
		this.loadConfigData();
		this.refreshTableDelayed = _.debounce(() => {
			this.$refs.table.refresh();
		}, 500);
		this.refreshTable = _.debounce(() => {
			this.$refs.table.refresh();
		}, 10);

		this.filter_types = store.resolveSelectedScoreTypes(this, this.scoreTypes, "audit.s.filter_types");
	},

	watch: {
		selectedState() {
			this.refreshTable();
			store.set(this, "audit.s.selectedState", this.selectedState ? this.selectedState.value : null);
		},
		selectedSection() {
			if (!this.selectedSection) {
				return;
			}
			this.loadItems().then(() => {
				this.refreshTable();
			});

			store.set(this, "audit.s.selectedSection", this.selectedSection ? this.selectedSection.id : null);
		},
		selectedItem() {
			this.audit_trait_scores = store.resolveAuditingTraitScores(
				this,
				this.selectedItem,
				"audit.s.scores.trait_scores"
			);
			this.setupTraitScoreSelections();
			this.refreshTable();
			store.set(this, "audit.s.selectedItem", this.selectedItem ? this.selectedItem.id : null);
		},
		response_id() {
			this.refreshTableDelayed();
			store.set(this, "audit.s.response_id", this.response_id);
		},
		users() {
			if (this.user.role.limit_score_auditing_self) return;
			this.refreshTable();
			store.set(this, "audit.s.users", this.users);
		},
		filter_types() {
			this.refreshTable();
			store.set(this, "audit.s.filter_types", this.filter_types);
		},
		score_specific_query() {
			this.audit_trait_scores = [];
			store.set(this, "audit.s.score_specific_query", this.score_specific_query);
		},
		audit_trait_scores() {
			store.set(this, "audit.s.scores.trait_scores", this.audit_trait_scores);
			this.updateATSDisplay();
		},
		fromDate() {
			if (this.user.role.limit_score_auditing_24h) return;
			this.refreshTable();
			store.set(this, "audit.s.fromDate", this.fromDate);
		},
		toDate() {
			if (this.user.role.limit_score_auditing_24h) return;
			this.refreshTable();
			store.set(this, "audit.s.toDate", this.toDate);
		},
		selectedExportState() {
			this.refreshTable();
		},
		exportedFromDate() {
			this.refreshTable();
		},
		exportedToDate() {
			this.refreshTable();
		},
	},

	computed: {
		scoreTypes() {
			const {
				FIRST,
				SECOND,
				RESOLUTION,
				BACKREAD,
				ADJUDICATION,
				APPEAL,
				APPEAL2,
				INVALIDATED_RESOLUTION,
				INVALIDATED_ADJUDICATION,
			} = SCORE_TYPES;

			const toOption = ({ id }) => ({
				label: fs.scoreTypeAbr(id, this.$i18n),
				type: id,
			});

			const normalScoreTypes = [FIRST, SECOND, RESOLUTION, BACKREAD, ADJUDICATION, APPEAL, APPEAL2].map(toOption);
			const divider = {
				id: -1,
				label: "",
				divider: true,
				class: "config-select-divider",
			};
			const invalidatedScores = [INVALIDATED_RESOLUTION, INVALIDATED_ADJUDICATION].map(toOption);

			return normalScoreTypes.concat([divider]).concat(invalidatedScores);
		},

		exportStates() {
			return [
				{ label: "　", value: null },
				{ label: "Yes", value: true },
				{ label: "No", value: false },
			];
		},
	},

	methods: {
		loadConfigData() {
			this.showHiddenProjects = store.getDefault(this, "projects.showHiddenProjects");
			var ctx = {};
			ctx.showHiddenProjects = this.showHiddenProjects;
			ConfigService.listSectionsShallow(ctx).then((resp) => {
				this.sections = resp.data.sections;
				this.selectedSection = store.resolveSelectedSection(this, this.sections, "audit.s.selectedSection");

				if (this.selectedItem) {
					this.audit_trait_scores = store.resolveAuditingTraitScores(
						this,
						this.selectedItem,
						"audit.s.trait_scores"
					);
				}

				this.loading = false;
				this.refreshTable();
			});
		},

		loadItems() {
			if (!this.selectedSection) {
				return new Promise();
			}

			return ConfigService.getSection(this.selectedSection.id)
				.then((resp) => {
					this.items = resp.data.items;
					this.selectedSection.items = this.items;

					this.selectedItem = store.resolveSelectedItem(this, this.selectedSection, "audit.s.selectedItem");
					if (!this.selectedItem || !_.find(this.items, { id: this.selectedItem.id })) {
						this.selectedItem = this.items[0];
					}

					if (this.selectedItem) {
						this.audit_trait_scores = store.resolveAuditingTraitScores(
							this,
							this.selectedItem,
							"audit.s.trait_scores"
						);
					}
				})
				.catch((err) => {
					console.log(err);
					Notie.error(this.$i18n.t("notie.load_items_fail"), err);
				});
		},

		getQuery() {
			let user_ids = [];
			if (!this.user.role.limit_score_auditing_self) {
				_.each(this.users, (u) => {
					if (u.users) {
						_.each(u.users, (tu) => {
							user_ids.push(tu.id);
						});
					} else {
						user_ids.push(u.id);
					}
				});
			} else {
				user_ids = [this.user.id];
			}

			if (this.user.role.limit_score_auditing_24h) {
				this.fromDate = moment().add(-1, "day");
			}

			let trait_scores = [];
			_.each(this.audit_trait_scores, (ats) => {
				let ts = _.cloneDeep(ats);
				if (isNaN(ts.score)) {
					ts.condition = ts.score;
					ts.score = -1;
				}
				trait_scores.push(ts);
			});

			let query = {
				client_id: this.user.client.id,
				section_id: this.selectedSection && this.selectedSection.id,
				item_id: this.selectedItem && this.selectedItem.id,
				response_id: this.response_id,
				user_ids: user_ids,
				trait_scores: trait_scores,
				score_types: _.map(this.filter_types, (t) => {
					return t.type;
				}),
				start_date: this.fromDate,
				exported: this.selectedExportState.value,
				exported_start_date: this.exportedFromDate,
				exported_end_date: this.exportedToDate,
			};

			if (!this.user.role.limit_score_auditing_24h) {
				query.end_date = this.toDate;
			}

			return query;
		},

		provider(ctx) {
			store.set(this, "audit.s.sortBy", this.sortBy);
			store.set(this, "audit.s.sortDesc", this.sortDesc);
			store.set(this, "audit.s.currentPage", this.currentPage);
			if (this.loading) {
				return [];
			}

			if (!(this.selectedSection && this.selectedItem)) {
				return [];
			}

			let query = this.getQuery();
			return AuditingService.scoreAuditQuery(query, ctx)
				.then((resp) => {
					if (this.queueResetSelection) {
						this.resetSelection();
						this.queueResetSelection = false;
					}

					this.query = query;
					this.totalRows = resp.data.totalRows;
					this.currentItems = resp.data.rows;
					this.smartStripe(this.currentItems);
					return this.currentItems;
				})
				.catch((err) => {
					return [];
				});
		},

		planResetScores() {
			this.addResetJob(false);
		},

		resetScores() {
			this.addResetJob(true);
		},

		addResetJob(do_execute) {
			let job = {
				section_id: this.selectedSection.id,
				item_id: this.selectedItem.id,
				do_execute: do_execute,
				selection: {
					select_all: this.selectAll,
					selected_ids: this.multiSelect,
					num_selected: this.numSelected,
				},
			};
			if (this.selectAll) {
				job.selection.query = this.getQuery();
			}

			ResetJobService.addJob(job)
				.then((resp) => {
					if (this.user.role.reset_history) {
						this.$router.push("/reset_history");
					} else {
						this.confirmResetModal = false;
					}
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.add_reset_job_fail"), err);
				});
		},

		smartStripe(responses) {
			var lastRef = "";
			var variant = "nostripe";
			_.each(responses, (r) => {
				if (r.ref_id != lastRef) {
					variant = variant == "nostripe" ? "stripe" : "nostripe";
				}
				lastRef = r.ref_id;
				r._rowVariant = variant;
			});
		},

		getTime(time) {
			if (time == 0) {
				return "——";
			} else if (!time) {
				return "——";
			} else if (time < 3600) {
				return moment().startOf("day").seconds(time).format(this.$i18n.t("Auditing.time_format_minutes"));
			} else {
				return moment().startOf("day").seconds(time).format(this.$i18n.t("Auditing.time_format_hours"));
			}
		},

		format(date) {
			return moment(date).format(this.$i18n.t("ScoreAuditing.date_format"));
		},

		tsChange(trait_id, score_type, sp) {
			//Remove all entries for this trait_id and score_type
			_.remove(this.audit_trait_scores, (e) => {
				return e.trait_id == trait_id && e.score_type == score_type;
			});

			if (sp) {
				this.audit_trait_scores.push({
					trait_id: trait_id,
					score_type: score_type,
					score: sp.value,
				});
			}
		},

		setupTraitScoreSelections() {
			//For each trait, and each score type, we need to create selection lists
			this.traitSelectionMap = null;
			var traitSelectionMap = {};
			var _this = this;
			_.each(this.selectedItem.rubric.traits, (t) => {
				if (t.separator) return;
				if (t.is_parent) return;

				var scoreTypeMap = {};
				var sps = [];
				for (var i = t.min; i <= t.max; i += t.step) {
					sps.push({
						trait_id: t.id,
						label: "" + i,
						value: i,
					});
				}

				_.each(t.condition_codes, (cc) => {
					if (cc.action == 1) {
						sps.push({
							trait_id: t.id,
							label: cc.symbol,
							value: cc.symbol,
						});
					}
				});

				let selectedATS = _.find(this.audit_trait_scores, {
					trait_id: t.id,
					score_type: 0,
				});
				let selectedSP = selectedATS ? _.find(sps, { value: selectedATS.score }) : null;
				scoreTypeMap[0] = { selected: null, sps: sps };
				_.each(_this.scoreTypes, (st) => {
					let selectedATS = _.find(this.audit_trait_scores, {
						trait_id: t.id,
						score_type: 0,
					});
					let selectedSP = selectedATS ? _.find(sps, { value: selectedATS.score }) : null;
					scoreTypeMap[st.type] = { selected: null, sps: sps };
				});

				_.each(scoreTypeMap, (st) => {
					_.each(st.sps, (sp) => {
						sp.st = st.type;
					});
				});

				traitSelectionMap[t.id] = scoreTypeMap;
			});
			this.traitSelectionMap = traitSelectionMap;
		},

		clearScoreSearch() {
			this.audit_trait_scores = [];
			this.score_specific_query = true;
			this.score_specific_query = false;
			this.traitSelectionMap = null;
			this.setupTraitScoreSelections();
			this.$forceUpdate();
			this.refreshTable();
		},

		updateATSDisplay() {
			let rows = [];
			let scoreTypeNumMap = {};
			_.each(this.audit_trait_scores, (ats) => {
				var trait = _.find(this.selectedItem.rubric.traits, {
					id: ats.trait_id,
				});
				if (trait) {
					scoreTypeNumMap[ats.score_type] = scoreTypeNumMap[ats.score_type] || 0;
					scoreTypeNumMap[ats.score_type]++;
					rows.push({
						name: trait.reported_name || trait.name,
						score_type: ats.score_type,
						score: ats.score,
					});
				}
			});

			rows = _.orderBy(rows, ["score_type", "name"]);
			let currentScoreType = -1;
			_.each(rows, (row) => {
				if (currentScoreType != row.score_type) {
					row.first_of_type = true;
					row.rowspan = scoreTypeNumMap[row.score_type] || 1;
					currentScoreType = row.score_type;
				}
			});

			this.ats_display = rows;
		},

		//Scoring stuff
		canScore(resp) {
			return ScoringService.canScore(this.user, resp);
		},

		showAlreadyScoredModal(resp) {
			$("#alreadyScoredWarning").modal("show");
		},

		unlock(resp) {
			ScoringService.unlockResponse(resp)
				.then(() => {
					notie.info(this.$i18n.t("notie.response_unlocked"));
					this.refreshTable();
				})
				.catch((e) => {
					notie.error(this.$i18n.t("notie.unlock_response_fail"));
					this.refreshTable();
				});
		},

		resetSelection() {
			this.multiSelect = [];
			this.selectAll = false;
		},

		refreshAndResetSelection() {
			this.queueResetSelection = true;
			this.refreshTable();
		},

		groupResponses() {
			return this.sortBy == "" || this.sortBy == "ref_id";
		},

		showQueryModal() {
			this.oldScoreQuery = _.cloneDeep(this.audit_trait_scores);
			this.applyATS = false;
			this.updateTraitScoreMap();
			this.queryModal = true;
		},

		updateTraitScoreMap() {
			_.each(this.traitSelectionMap, (trait) => {
				_.each(trait, (opt) => {
					opt.selected = null;
				});
			});

			_.each(this.audit_trait_scores, (ats) => {
				let trait = this.traitSelectionMap[ats.trait_id];
				if (trait) {
					let opt = trait[ats.score_type];
					if (opt) {
						let choice = _.find(opt.sps, { value: ats.score });
						if (choice) {
							opt.selected = choice;
						}
					}
				}
			});

			this.$forceUpdate();
		},

		hideQueryModal() {
			if (!this.applyATS) {
				this.audit_trait_scores = _.cloneDeep(this.oldScoreQuery);
			} else {
				this.applyATS = false;
			}
		},

		applyQueryModal() {
			this.applyATS = true;
			this.queryModal = false;
			this.refreshTable();
		},

		formatExportTooltip(str) {
			let parts = str.split(" | ");
			str = parts.join("<br />");
			return str;
		},
	},
};
</script>
