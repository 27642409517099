<template>
	<b-modal visible no-close-on-backdrop hide-header-close no-close-on-esc>
		<template slot="modal-title">
			<h5 class="mb-0">
				{{ $t("QCSetup.configure_set_modal.title", { type: setTypeString }) }}
			</h5>
		</template>
		<div class="modal-body">
			<div>
				<div class="form-group">
					<label>{{ $t("QCSetup.configure_set_modal.set_name") }}</label>
					<input type="text" class="form-control" v-model="editSet.name" />
				</div>

				<!-- Pass/fail settings -->
				<template v-if="editSet.qc_type == 2">
					<qc-rule-config :config="editSet"></qc-rule-config>
					<hr class="mb-4" style="margin-left: -15px; margin-right: -15px" />
				</template>

				<val-rule-config
					v-if="isCalibrationSet"
					:title="$t('QCSetup.configure_set_modal.calibration_rules')"
					v-model="editSet.calibration_rules"
				/>

				<template v-if="editSet.qc_type == 3">
					<div class="row">
						<div class="col-12">
							<label
								>{{ $t("QCSetup.configure_set_modal.rules") }}
								<small class="text-muted"
									>({{ $t("QCSetup.configure_set_modal.item_settings_notice") }})</small
								></label
							>
							<v-select
								:options="valRules"
								:value="valRules"
								label="ruleName"
								class="v-select-apply"
								disabled
							/>
						</div>
					</div>
					<hr class="mb-4" style="margin-left: -15px; margin-right: -15px" />
				</template>
			</div>

			<!-- Calibration specific settings -->
			<div class="d-flex flex-row align-items-center mb-2" v-if="isCalibrationSet">
				<label class="ui-switch ui-switch-md theme-accent">
					<input type="checkbox" v-model="editSet.auto_deliver" />
					<i></i>
				</label>
				<span class="ml-2">{{ $t("QCSetup.configure_set_modal.auto_deliver") }}</span>
			</div>
			<div class="d-flex flex-row align-items-center mb-2" v-if="isCalibrationSet">
				<label
					class="ui-switch ui-switch-md theme-accent"
					v-tippy="{ theme: 'popover', placement: 'bottom-start' }"
					:title="$t('QCSetup.configure_set_modal.scorers_not_notified')"
				>
					<input type="checkbox" v-model="editSet.is_blind" />
					<i></i>
				</label>
				<span class="ml-2">{{ $t("QCSetup.configure_set_modal.blind") }}</span>
			</div>

			<!-- Validity specific settings -->
			<div class="d-flex flex-row align-items-center mb-2" v-if="editSet.qc_type == 3">
				<label class="ui-switch ui-switch-md theme-accent">
					<input type="checkbox" v-model="editSet.show_negative" />
					<i></i>
				</label>
				<span class="ml-2">{{ $t("QCSetup.configure_set_modal.show_negative_feedback") }}</span>
			</div>
			<div class="d-flex flex-row align-items-center mb-2" v-if="editSet.qc_type == 3">
				<label class="ui-switch ui-switch-md theme-accent">
					<input type="checkbox" v-model="editSet.show_positive" />
					<i></i>
				</label>
				<span class="ml-2">{{ $t("QCSetup.configure_set_modal.show_positive_feedback") }}</span>
			</div>

			<!-- Exemplar specific settings -->
			<div class="row" v-if="editSet.qc_type == 6">
				<div class="col-6" v-if="editSet.qc_type == 6">
					<div class="form-group">
						<label>{{ $t("QCSetup.configure_set_modal.annotation_position") }}</label>
						<config-select
							:options="fs.exemplarPositions()"
							byField="id"
							v-model="editSet.anno_position"
							sortBy="sequence"
						></config-select>
					</div>
				</div>
				<div class="col-6">
					<div class="form-group">
						<label>{{ $t("QCSetup.configure_set_modal.score_position") }}</label>
						<config-select
							:options="fs.exemplarPositions()"
							byField="id"
							v-model="editSet.score_position"
							sortBy="sequence"
						></config-select>
					</div>
				</div>
				<div class="w-100 form-group d-flex flex-row justify-content-center">
					<!-- Previous page -->
					<div
						class="exemplar-preview-page d-flex flex-column align-items-center"
						v-if="editSet.score_position == 3 || editSet.anno_position == 3"
					>
						<div class="exemplar-preview-score" v-if="editSet.score_position == 3">
							<i class="fas fa-check" />
						</div>
						<div class="exemplar-preview-annotation" v-if="editSet.anno_position == 3">
							<i class="fas fa-comment-alt" />
						</div>
					</div>

					<!-- Main page -->
					<div class="exemplar-preview-page d-flex flex-column">
						<div
							class="exemplar-preview-section d-flex flex-row justify-content-center"
							v-if="editSet.score_position == 1 || editSet.anno_position == 1"
						>
							<div class="exemplar-preview-annotation flex" v-if="editSet.anno_position == 1">
								<i class="fas fa-comment-alt" />
							</div>
							<div class="exemplar-preview-score flex" v-if="editSet.score_position == 1">
								<i class="fas fa-check" />
							</div>
						</div>
						<div class="flex exemplar-preview-response">
							<i class="fas fa-file-alt" />
						</div>
						<div
							class="exemplar-preview-section d-flex flex-row justify-content-center"
							v-if="editSet.score_position == 2 || editSet.anno_position == 2"
						>
							<div class="exemplar-preview-annotation flex" v-if="editSet.anno_position == 2">
								<i class="fas fa-comment-alt" />
							</div>
							<div class="exemplar-preview-score flex" v-if="editSet.score_position == 2">
								<i class="fas fa-check" />
							</div>
						</div>
					</div>

					<!-- Following page -->
					<div
						class="exemplar-preview-page d-flex flex-column align-items-center"
						v-if="editSet.score_position === 4 || editSet.anno_position === 4"
					>
						<div class="exemplar-preview-score" v-if="editSet.score_position == 4">
							<i class="fas fa-check" />
						</div>
						<div class="exemplar-preview-annotation" v-if="editSet.anno_position == 4">
							<i class="fas fa-comment-alt" />
						</div>
					</div>
				</div>
			</div>

			<div v-if="editSet.qc_type == 2 || isCalibrationSet" class="d-flex flex-row align-items-center mb-2">
				<label class="ui-switch ui-switch-md theme-accent">
					<input type="checkbox" v-model="editSet.reviewable" />
					<i></i>
				</label>
				<span class="ml-2" v-tippy :title="$t('tooltip.review_qc_set')">{{
					$t("data_description.reviewable")
				}}</span>
			</div>

			<div v-if="editSet.qc_type == 2" class="d-flex flex-row align-items-center mb-2">
				<label class="ui-switch ui-switch-md theme-accent">
					<input type="checkbox" v-model="editSet.randomize" />
					<i></i>
				</label>
				<span class="ml-2">{{ $t("QCSetup.randomize_delivery") }}</span>
			</div>

			<div class="d-flex flex-row align-items-center mb-2">
				<label class="ui-switch ui-switch-md theme-accent">
					<input type="checkbox" v-model="editSet.active" />
					<i></i>
				</label>
				<span class="ml-2">{{ $t("data_description.active") }}</span>
			</div>
		</div>
		<template slot="modal-footer">
			<div v-if="editSet.qc_type == 2 || isCalibrationSet" class="mr-auto">
				<button
					v-show="!savingTemplate"
					class="btn btn-flat btn-primary"
					data-toggle="dropdown"
					aria-expanded="false"
				>
					{{ $t("QCSetup.configure_set_modal.templates") }}<i class="fas fa-caret-down ml-2" />
				</button>
				<div class="dropdown-menu dropdown-menu-left w-md">
					<a class="dropdown-item clickable px-3" @click="savingTemplate = true">
						<label class="mb-0">{{ $t("QCSetup.configure_set_modal.save_current_settings") }}</label>
					</a>
					<div v-if="templates && templates.length > 0" class="dropdown-divider"></div>
					<div class="px-3 text-muted">{{ $t("QCSetup.configure_set_modal.load_template") }}:</div>
					<a
						v-for="template in templates"
						:key="template.name"
						class="dropdown-item clickable pl-4 pr-3 d-flex flex-row"
					>
						<label class="flex mb-0" @click="mergeTemplate(template)">{{ template.name }}</label>
						<a
							class="btn-subtle mr-2"
							v-tippy="{ placement: 'right' }"
							:title="$t('buttons.delete')"
							@click="deleteTemplate(template)"
						>
							<i class="fas fa-trash mx-0" />
						</a>
					</a>
				</div>
				<div class="d-flex flex-row">
					<input
						v-if="savingTemplate"
						type="text"
						class="form-control"
						:placeholder="$t('QCSetup.configure_set_modal.new_template_name')"
						v-model="newTemplateName"
					/>
					<div class="d-flex flex-column" style="margin-top: -4px; margin-bottom: -4px">
						<button v-if="savingTemplate" @click="cancelSaveTemplate" class="btn btn-danger btn-label ml-2">
							{{ $t("buttons.cancel") }}
						</button>
						<button v-if="savingTemplate" @click="saveTemplate" class="btn btn-primary btn-label ml-2 mt-1">
							{{ $t("buttons.save") }}
						</button>
					</div>
				</div>
			</div>
			<div>
				<button class="btn btn-flat btn-secondary mr-2" @click="close">
					{{ $t("buttons.cancel") }}
				</button>
				<button v-if="canSaveQcSet" @click="saveSet" class="btn btn-flat theme">
					{{ $t("buttons.save") }}
				</button>
				<button v-else class="btn btn-flat btn-secondary" disabled>
					{{ $t("buttons.save") }}
				</button>
			</div>
		</template>
	</b-modal>
</template>

<style scoped>
.exemplar-preview-page {
	width: 120px;
	height: 150px;
	border-radius: 10px;
	border: 2px solid lightgrey;
	margin: 4px;
	padding: 4px;
}
.exemplar-preview-section {
	flex-basis: 25%;
}
.exemplar-preview-response {
	border-radius: 10px;
	border: 1px solid lightgrey;
	margin: 3px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: 50%;
}
.exemplar-preview-response > i {
	font-size: 30px;
	opacity: 0.75;
}
.exemplar-preview-score {
	border-radius: 10px;
	border: 1px solid lightgrey;
	width: 50%;
	max-width: 50%;
	margin: 3px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: 30%;
}
.exemplar-preview-section > .exemplar-preview-score {
	max-width: 33% !important;
}
.exemplar-preview-score > i {
	font-size: 20px;
	opacity: 0.75;
}
.exemplar-preview-section > .exemplar-preview-score > i {
	font-size: 16px !important;
}
.exemplar-preview-annotation {
	border-radius: 10px;
	border: 1px solid lightgrey;
	width: 100%;
	margin: 3px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: 30%;
}
.exemplar-preview-annotation > i {
	font-size: 20px;
	opacity: 0.75;
}
.exemplar-preview-section > .exemplar-preview-annotation > i {
	font-size: 16px !important;
}
</style>

<script>
import { QC_TYPES } from "@/services/Constants";

import QCService from "@/services/QCService";
import QCConfigService from "@/services/QCConfigService";
import Notie from "@/services/NotieService";

import fs from "@/services/FormatService";

import ConfigSelect from "@/components/ConfigSelect";
import QcRuleConfig from "@/components/QcRuleConfig";
import ValRuleConfig from "@/components/item/ItemEditQual/ValRuleConfig";

export default {
	name: "QcSetModal",

	components: { QcRuleConfig, ValRuleConfig, ConfigSelect },

	props: {
		editSet: Object,
		selectedItem: Object,
	},

	data() {
		return {
			newTemplateName: null,
			savingTemplate: false,
			templates: [],
			fs,
		};
	},

	created() {
		this.loadTemplates();
	},

	methods: {
		async saveSet() {
			try {
				await QCConfigService.saveSet(this.editSet);
				Notie.success(this.$i18n.t("notie.set_saved"));
				this.close();
			} catch (err) {
				console.log(err);
				Notie.error(this.$i18n.t("notie.save_set_fail"), err);
			}
		},

		async saveTemplate() {
			let saveTemplate = _.cloneDeep(this.editSet);
			saveTemplate.name = this.newTemplateName;
			delete saveTemplate.id;
			try {
				await QCService.saveQcRuleTemplate(saveTemplate);
				this.loadTemplates();
				Notie.success(this.$i18n.t("QCSetup.template_saved", { template: saveTemplate.name }));
				this.savingTemplate = false;
			} catch (e) {
				console.log(e);
				Notie.error(e);
			}
		},

		async loadTemplates() {
			try {
				const { data } = await QCService.getQcRuleTemplates();
				this.templates = data;
				_.each(this.templates, (template) => {
					template.id = template.name;
				});
			} catch (e) {
				console.log(e);
				Notie.error(e);
			}
		},

		async deleteTemplate(template) {
			try {
				await QCService.deleteQcRuleTemplate(template);
				this.loadTemplates();
				Notie.success(this.$i18n.t("QCSetup.template_deleted", { template: template.name }));
			} catch (e) {
				console.log(e);
				Notie.error(e);
			}
		},

		mergeTemplate(template) {
			let mergeTemplate = _.cloneDeep(template);
			delete mergeTemplate.id;
			delete mergeTemplate.name;
			_.merge(this.editSet, mergeTemplate);
		},

		cancelSaveTemplate() {
			this.template = this.tempTemplate;
			this.tempTemplate = null;
			this.$nextTick(() => {
				this.savingTemplate = false;
			});
		},

		close() {
			this.$emit("close"); // probly query sets after this
		},
	},

	computed: {
		isCalibrationSet() {
			return this.editSet.qc_type === QC_TYPES.CALIBRATION.id;
		},

		setTypeString() {
			const { editSet, $i18n } = this;
			const { EXEMPLAR, PRACTICE, QUALIFICATION, VALIDITY, CALIBRATION } = QC_TYPES;
			switch (editSet.qc_type) {
				case EXEMPLAR.id:
					return $i18n.t("QCSetup.exemplar");
				case PRACTICE.id:
					return $i18n.t("QCSetup.practice");
				case QUALIFICATION.id:
					return $i18n.t("QCSetup.qualification");
				case VALIDITY.id:
					return $i18n.t("QCSetup.validity");
				case CALIBRATION.id:
					return $i18n.t("QCSetup.calibration");
				default:
					return "???";
			}
		},

		canSaveQcSet() {
			const { editSet } = this;
			const { QUALIFICATION, CALIBRATION } = QC_TYPES;
			switch (editSet.qc_type) {
				case QUALIFICATION.id:
					return editSet.rule;
				case CALIBRATION.id:
					return (editSet.calibration_rules || []).length > 0;
			}
			return true;
		},

		valRules() {
			const { selectedItem } = this;
			if (!selectedItem) return [];

			const { validity_config } = selectedItem;
			if (!(validity_config && validity_config.enabled)) return [];

			const rules = validity_config.uses_threshold
				? [validity_config.threshold_rule]
				: validity_config.window_rules;

			return rules.map((rule) => ({
				ruleName: fs.qcRuleIdToName(rule.rule),
				...rule,
			}));
		},
	},
};
</script>
