<template>
	<page customNavbar>
		<template #navbar>
			<div class="navbar navbar-expand-lg">
				<!-- Page title -->
				<loading type="header" v-if="loading" />
				<div v-if="!loading" class="navbar-text nav-title flex" id="pageTitle">
					<i
						class="fas fa-fw fa-lock-alt"
						v-if="item.isScored"
						v-tippy="{ placement: 'bottom-start' }"
						title="This item has been used for scoring, so some options can no longer be changed"
					></i>
					{{ item.name }}
				</div>
				<button
					v-if="user.client.iea_integration && item"
					class="ml-auto btn btn-info"
					@click="openIeaSetupModal"
					v-tippy="{ placement: 'bottom', theme: 'popover' }"
					:title="$t('ItemEdit.IeaItemSetupModal.configure_with_cf_tooltip')"
				>
					{{ $t("ItemEdit.IeaItemSetupModal.configure_with_cf") }}
				</button>
			</div>
		</template>
		<div class="h-100" v-if="!loading">
			<div class="d-sm-flex b-t h-100">
				<div class="w w-auto-xs light bg b-r">
					<div class="py-3">
						<div class="nav-active-border left b-primary">
							<ul class="nav flex-column nav-sm">
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 1, show: tab == 1 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-1"
									>
										{{ $t("ItemEdit.Sidebar.item_details") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('details') }"
										></i>
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 2, show: tab == 2 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-2"
									>
										{{ $t("ItemEdit.Sidebar.scoring_setup") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('scoring') }"
										></i>
									</a>
								</li>
								<!-- <li v-if="item.assigned_delivery" class="nav-item">
												<a
													class="nav-link"
													:class="{ active: tab == 3, show: tab == 3 }"
													href="#"
													data-toggle="tab"
													data-target="#tab-3"
													>{{ $t("ItemEdit.Sidebar.assigned_delivery") }}</a
												>
											</li> -->
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 4, show: tab == 4 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-4"
									>
										{{ $t("ItemEdit.Sidebar.viewer_controls") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('viewer') }"
										></i>
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 5, show: tab == 5 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-5"
										>{{ $t("ItemEdit.Sidebar.resources") }}</a
									>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 6, show: tab == 6 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-6"
									>
										{{ $t("ItemEdit.Sidebar.quality_settings") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('quality') }"
										></i>
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 7, show: tab == 7 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-7"
										>{{ $t("ItemEdit.Sidebar.quotas") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('quotas') }"
										></i
									></a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 8, show: tab == 8 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-8"
									>
										{{ $t("ItemEdit.Sidebar.integrations") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('integrations') }"
										></i>
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 9, show: tab == 9 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-9"
									>
										{{ $t("ItemEdit.Sidebar.copyleaks") }}
										<i
											class="valid-hint text fa fa-angle-left"
											:class="{ invalid: !valid.group('copyleaks') }"
										></i>
									</a>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										:class="{ active: tab == 10, show: tab == 10 }"
										href="#"
										data-toggle="tab"
										data-target="#tab-10"
										>{{ $t("ItemEdit.Sidebar.flag_codes") }}</a
									>
								</li>
								<!-- <li class="nav-item">
												<a
													class="nav-link"
													:class="{ active: tab == 11, show: tab == 11 }"
													href="#"
													data-toggle="tab"
													data-target="#tab-11"
												>
													{{ $t("ItemEdit.Sidebar.emma") }}
												</a>
											</li> -->
							</ul>
						</div>
					</div>
				</div>

				<edit-pane :cancel="loadData" :save="saveItem" :dirty="dirty" :valid="valid">
					<div class="tab-content pos-rlt">
						<!-- Item Details -->
						<item-edit-details
							id="tab-1"
							:item="item"
							:client="user.client"
							:valid="valid"
							:defaultTab="tab == 1"
						></item-edit-details>

						<!-- Scoring Setup -->
						<item-edit-scoring
							id="tab-2"
							:item="item"
							:client="client"
							:valid="valid"
							:defaultTab="tab == 2"
							:changedResSettings="changedResSettings"
							:changedRelSettings="changedRelSettings"
							:user="user"
							:rubrics="rubrics"
						></item-edit-scoring>

						<!-- Assigned Delivery -->
						<!-- <item-edit-assigned-delivery
										v-if="item.assigned_delivery"
										id="tab-3"
										:item="item"
										:valid="valid"
										:defaultTab="tab == 3"
									></item-edit-assigned-delivery> -->

						<!-- Viewer Controls -->
						<item-edit-viewer id="tab-4" :item="item" :valid="valid" :defaultTab="tab == 4">
						</item-edit-viewer>

						<!-- Resources -->
						<item-edit-resources
							id="tab-5"
							:item="item"
							:originalResources="originalResources"
							:dirty="dirty"
							:valid="valid"
							:save="saveItem"
							:defaultTab="tab == 5"
						>
						</item-edit-resources>

						<!-- Quality Settings -->
						<item-edit-quality
							id="tab-6"
							:item="item"
							:valid="valid"
							:defaultTab="tab == 6"
							:hasValStats="hasValStats"
							:originalItem="originalItem"
						></item-edit-quality>

						<!-- Quotas -->
						<item-edit-quotas
							id="tab-7"
							v-if="client && item"
							:valid="valid"
							:item="item"
							:client="client"
							:defaultTab="tab == 7"
						></item-edit-quotas>

						<!-- Integrations -->
						<item-edit-integrations
							id="tab-8"
							:item="item"
							:client="client"
							:valid="valid"
							:defaultTab="tab == 8"
							:user="user"
							:projects="projects"
							@openIeaSetupModal="openIeaSetupModal"
						></item-edit-integrations>

						<!-- Copyleaks -->
						<item-edit-copyleaks
							id="tab-9"
							:item="item"
							:client="client"
							:valid="valid"
							:defaultTab="tab == 9"
							:user="user"
						></item-edit-copyleaks>

						<!-- Flag Codes -->
						<item-edit-flags id="tab-10" :item="item" :defaultTab="tab == 10"></item-edit-flags>

						<!-- Emma -->
						<!-- <item-edit-emma @pushed="pushed" id="tab-11" :item="item" :defaultTab="tab == 11">
									</item-edit-emma> -->
					</div>
				</edit-pane>
				<save-optional-modal
					:dirty="dirty"
					:valid="valid"
					:save="saveItem"
					:next="saveOptNext"
					:cancel="saveOptCancel"
					objectText="Item"
					:objectName="item.name"
					actionText="leave the page"
					v-model="saveOptModal"
				/>
			</div>
		</div>

		<iea-item-setup-modal
			id="ieaSetupModal"
			:client="client"
			:item="item"
			:rubrics="rubrics"
			:projects="projects"
			:visible="showIeaSetupModal"
			@hide="showIeaSetupModal = false"
		>
		</iea-item-setup-modal>
	</page>
</template>

<style scoped>
.margin-overlap {
	margin-right: -2.5rem;
}

.invalid {
	color: red;
}
</style>

<script>
import ItemEditDetails from "@/components/item/ItemEditDetails";
import ItemEditScoring from "@/components/item/ItemEditScoring";
// import ItemEditAssignedDelivery from "@/components/item/ItemEditAssignedDelivery";
import ItemEditViewer from "@/components/item/ItemEditViewer";
import ItemEditResources from "@/components/item/ItemEditResources";
import ItemEditQuality from "@/components/item/ItemEditQuality";
import ItemEditQuotas from "@/components/item/ItemEditQuotas";
import ItemEditIntegrations from "@/components/item/ItemEditIntegrations";
import ItemEditCopyleaks from "@/components/item/ItemEditCopyleaks";
import ItemEditFlags from "@/components/item/ItemEditFlags";
// import ItemEditEmma from "@/components/item/ItemEditEmma";
import IeaItemSetupModal from "@/components/item/IeaItemSetupModal";

import EditPane from "@/components/EditPane";
import SaveOptionalModal from "@/components/SaveOptionalModal";

import ItemService from "@/services/ItemService";
import RubricService from "@/services/RubricService";
import TenantService from "@/services/TenantService";
import ProjectService from "@/services/ProjectService";
import QCService from "@/services/QCService";
import IeaService from "@/services/IEAService";
import ValidationService from "@/services/ValidationService";
import Store from "@/services/Store";
import fs from "@/services/FormatService";
import Notie from "@/services/NotieService";
import BB from "bluebird";

export default {
	name: "ItemEdit",

	props: ["user", "params", "query"],

	components: {
		"item-edit-details": ItemEditDetails,
		"item-edit-scoring": ItemEditScoring,
		// "item-edit-assigned-delivery": ItemEditAssignedDelivery,
		"item-edit-viewer": ItemEditViewer,
		"item-edit-resources": ItemEditResources,
		"item-edit-quality": ItemEditQuality,
		"item-edit-quotas": ItemEditQuotas,
		"item-edit-integrations": ItemEditIntegrations,
		"item-edit-copyleaks": ItemEditCopyleaks,
		// "item-edit-emma": ItemEditEmma,
		"iea-item-setup-modal": IeaItemSetupModal,

		"item-edit-flags": ItemEditFlags,
		"edit-pane": EditPane,
		"save-optional-modal": SaveOptionalModal,
	},

	data() {
		return {
			fs: fs,
			tab: 1,
			item: {
				validity_config: { enabled: false, window_rules: [] },
				qualification_config: {},
				calibration_config: {},
				learnosity_config: {},
				crase_config: {},
				erater_config: {},
				api_export_config: {},
				resources: [],
			},
			originalItem: null,
			client: {
				roles: [],
			},
			projects: [],
			hasValStats: null,
			originalResources: [],
			origRel: null,
			origResSettings: {},
			dirty: false,
			loading: true,
			valid: {},
			saveOptModal: false,
			showIeaSetupModal: false,
			rubrics: [],

			saveOptNext: () => {},
			saveOptCancel: () => {},
		};
	},

	created() {
		this.loadData();
		this.loadRubrics();
		this.initValidation();

		if (this.query.tab) {
			this.tab = this.query.tab;
		}
	},

	watch: {},

	computed: {
		changedResSettings() {
			let changed =
				this.origResSettings.type != this.item.type ||
				this.origResSettings.weighted_resolution_threshold != this.item.weighted_resolution_threshold ||
				this.origResSettings.weighted_resolution_range_min != this.item.weighted_resolution_range_min ||
				this.origResSettings.weighted_resolution_range_max != this.item.weighted_resolution_range_max ||
				this.origResSettings.resolution_choose_score != this.item.resolution_choose_score ||
				this.origResSettings.omaha_min_max != this.item.omaha_min_max ||
				this.origResSettings.omaha_max_non_adj != this.item.omaha_max_non_adj ||
				this.origResSettings.omaha_max_weighted_score_diff != this.item.omaha_max_weighted_score_diff;
			return this.item.isScored && changed;
		},

		changedRelSettings() {
			let changed = this.origRel != this.item.reliability_percent;
			return changed;
		},
	},

	beforeRouteLeave(to, from, next) {
		if (this.dirty) {
			this.saveOptNext = () => {
				next();
			};
			this.saveOptCancel = () => {
				Store.set("openPreviewerImportModal", false);
				next(false);
			};
			this.saveOptModal = true;
		} else {
			next();
		}
	},

	methods: {
		initValidation() {
			const numericValidator = (group, categoryName, field, min) => ({
				group: group,
				errorMsg: `"${field
					.split("_")
					.map((word) => word[0].toUpperCase() + word.slice(1))
					.join(" ")}" must be at least ${min}`,
				func: () => {
					const category = this.item[categoryName];
					const numberField = category[field];
					return !category.enabled || (Number.isInteger(numberField) && numberField >= min);
				},
			});
			const QualNumericValidator = (categoryName, field, min) =>
				numericValidator("quality", categoryName, field, min);

			const QuotaNumericValidator = (categoryName, field, min) =>
				numericValidator("quotas", categoryName, field, min);

			this.valid = ValidationService.newValidator({
				name: {
					group: "details",
					errorMsg: "You must assign a name",
					func: () => {
						return this.item && this.item.name && this.item.name != "";
					},
				},
				ref: {
					group: "details",
					errorMsg: "You must assign a reference ID",
					func: () => {
						return this.item && this.item.ref_id && this.item.ref_id != "";
					},
				},
				ref_char: {
					group: "details",
					errorMsg:
						"Reference IDs may not contain spaces, non-ascii characters, or any of the following characters: ! # % ^ * ( )  / [ ] { } < > ? | ' \" :",
					func: () => {
						return this.item && this.item.ref_id && fs.isGoodRefID(this.item.ref_id);
					},
				},
				// cf_item_model: {
				// 	group: "details",
				// 	errorMsg: "You must choose a CF Item Model",
				// 	func: () => {
				// 		return (
				// 			this.item &&
				// 			(!this.item.cf_config ||
				// 				!this.item.cf_config.enabled ||
				// 				this.item.cf_config.tof_enabled ||
				// 				this.item.cf_config.item_id)
				// 		);
				// 	},
				// },
				rubric: {
					group: "scoring",
					errorMsg: "You must choose a rubric",
					func: () => {
						return (
							this.item &&
							((this.item.cf_config && this.item.cf_config.enabled && !this.item.cf_config.tof_enabled) ||
								(this.item.rubric && this.item.rubric_id))
						);
					},
				},
				type: {
					group: "scoring",
					errorMsg: "You must choose a resolution rule",
					func: () => {
						return this.item && this.item.type;
					},
				},
				mathjax_delimiter: {
					group: "viewer",
					errorMsg: "You must define a Mathjax delimiter",
					func: () => {
						return this.item && (!this.item.use_mathjax || this.item.mathjax_delimiter);
					},
				},
				emma_blanks: {
					group: "integrations",
					errorMsg: "You must choose a flag code for blanks",
					func: () => {
						return (
							this.item &&
							(!this.item.emma_config ||
								!this.item.emma_config.enabled ||
								!this.item.emma_config.flag_blanks ||
								this.item.emma_config.blank_alert_id)
						);
					},
				},
				must_see: {
					group: "quality",
					errorMsg: "Qualification requirement of Must Pass cannot be higher than Must See",
					func: () => {
						return (
							this.item &&
							(!this.item.qualification_config.enabled ||
								this.item.qualification_config.must_see >= this.item.qualification_config.must_pass)
						);
					},
				},

				must_see_min: QualNumericValidator("qualification_config", "must_see", 0),
				must_pass_min: QualNumericValidator("qualification_config", "must_pass", 0),
				response_interval_low_min: QualNumericValidator("validity_config", "interval_low", 0),
				response_interval_high_min: QualNumericValidator("validity_config", "interval_high", 0),

				// wpm_window: QuotaNumericValidator("words_per_minute", "window", 1),
				// wpm_count: QuotaNumericValidator("words_per_minute", "count", 1),
				// avg_time_window: QuotaNumericValidator("min_avg_resp_time", "window", 1),
				// avg_time_count: QuotaNumericValidator("min_avg_resp_time", "count", 1),

				keep_best_must_pass_trait_agreement: {
					group: "quality",
					errorMsg: this.$i18n.t("ItemEdit.Quality.qualification.trait_req_trait_agreement_error"),
					func: () => {
						const { item } = this;
						if (!item) return true;
						const { qualification_config } = item;
						if (!qualification_config) return true;
						const reqs = qualification_config.requirements || [];
						return !reqs.some((req) => QCService.needsTraitAgreementAddedForReq(req, qualification_config));
					},
				},

				must_pass_trait_less_than_must_see: {
					group: "quality",
					errorMsg: this.$i18n.t("ItemEdit.Quality.qualification.trait_must_pass_error"),
					func: () => {
						const { item } = this;
						if (!item) return true;
						const { qualification_config } = item;
						if (!qualification_config) return true;
						const reqs = qualification_config.requirements || [];
						return !reqs.some((req) =>
							QCService.traitReqMustPassGreaterThanMustSee(req, qualification_config)
						);
					},
				},

				window_above_zero: {
					group: "quality",
					errorMsg: "Validity Window Size must be greater than zero",
					func: () => {
						const { enabled, uses_threshold, window } = this.item.validity_config;
						return !enabled || uses_threshold || (Number.isInteger(window) && window > 0);
					},
				},

				threshold_at_least_zero: {
					group: "quality",
					errorMsg: "Validity Incorrect # must be at least zero",
					func: () => {
						const { enabled, uses_threshold, threshold } = this.item.validity_config;
						return !(enabled || uses_threshold) || (Number.isInteger(threshold) && threshold >= 0);
					},
				},

				val_response_interval: {
					group: "quality",
					errorMsg: "Validity Response Interval 'to' value must be less than 'from'",
					func: () => {
						const { enabled, interval_low, interval_high } = this.item.validity_config;
						return !enabled || interval_low <= interval_high;
					},
				},

				val_has_rule: {
					group: "quality",
					errorMsg: "Must set up a Validity Rule",
					func: () => {
						const { enabled, window_rules, threshold_rule, uses_threshold } = this.item.validity_config;
						if (!enabled) return true;
						if (uses_threshold) {
							return threshold_rule.rule !== "";
						}
						return (window_rules || []).length > 0;
					},
				},

				blank_scoring_system_first_scorer_id: {
					group: "scoring",
					errorMsg: "You must choose a first scorer ID",
					func: () => {
						return (
							this.item &&
							(!this.item.blank_scoring_config.enabled ||
								(this.item.blank_scoring_config.enabled &&
									this.item.blank_scoring_config.action == "withhold") ||
								(this.item.blank_scoring_config.enabled &&
									this.item.blank_scoring_config.action == "apply_score" &&
									this.item.blank_scoring_config.system_first_scorer_id == "" &&
									this.item.blank_scoring_config.alert_id == "") ||
								(this.item.blank_scoring_config.system_first_scorer_id &&
									this.item.blank_scoring_config.system_first_scorer_id !=
										this.item.blank_scoring_config.system_reliability_scorer_id))
						);
					},
				},
				blank_scoring_system_reliability_scorer_id: {
					group: "scoring",
					errorMsg:
						"You must choose a reliability scorer ID. The reliability scorer ID must be different than the first scorer ID",
					func: () => {
						const { item } = this;
						if (!item) return false;
						const {
							blank_scoring_config: {
								enabled,
								action,
								system_first_scorer_id,
								system_reliability_scorer_id,
								alert_id,
								ignore_reliability,
							},
						} = item;
						if (!enabled) return true;
						if (action === "withhold") return true;
						const applyScore =
							action === "apply_score" && system_reliability_scorer_id == "" && alert_id == "";
						const reliability =
							system_reliability_scorer_id && system_first_scorer_id !== system_reliability_scorer_id;
						return applyScore || reliability || ignore_reliability;
					},
				},
				writeshift_reroute_section: {
					group: "integrations",
					errorMsg: "You must choose a section for Writeshift reroute-on-error",
					func: () => {
						return (
							this.item &&
							(!this.item.writeshift_config ||
								!this.item.writeshift_config.reroute_errors ||
								this.item.writeshift_config.reroute_section_id)
						);
					},
				},
				writeshift_reroute_item: {
					group: "integrations",
					errorMsg: "You must choose an item for Writeshift reroute-on-error",
					func: () => {
						return (
							this.item &&
							(!this.item.writeshift_config ||
								!this.item.writeshift_config.reroute_errors ||
								this.item.writeshift_config.reroute_item_id)
						);
					},
				},
			});
		},
		pushed() {
			this.loadData();
		},
		blankItem() {
			return {
				validity_config: {
					enabled: false,
					window_rules: [],
					uses_threshold: false,
					calibration: false,
					interval: 0,
					feedback: false,
					interval_low: 0,
					interval_high: 0,
					threshold: 0,
					threshold_rule: {
						rule: "",
					},
				},
				qualification_config: {},
				calibration_config: {
					enabled: false,
					lockout: true,
					unlock_on_pass: false,
					continue_cal: false,
				},
				learnosity_config: {},
				crase_config: {},
				erater_config: {},
				emma_config: {},
				api_export_config: {
					final_score_type: "average",
				},
				ami_config: {},
				exception_config: {
					rate_exceptions: [],
				},
				school_code_config: {},
				writeshift_config: {},
				reliability_percent: 100,
				resources: [],
				blank_scoring_config: {
					enabled: false,
					system_first_scorer_id: "",
					system_reliability_scorer_id: "",
					alert_id: "",
					code: "",
					action: "",
				},
				metadata_reliability_config: [],
				cf_config: {
					enabled: false,
					admin_name: "",
					item_id: "",
					system_user_id: "",
					flag_lid: false,
					lid_flag_id: "",
				},
				stateless_reliability: false,
				trait_resolution_adjacency: [],
			};
		},

		watchChanges() {
			if (this.unwatch) {
				this.unwatch();
			}
			this.unwatch = this.$watch(
				"item",
				(newc, old) => {
					console.log("marking dirty");
					this.dirty = true;
				},
				{ deep: true }
			);
		},

		loadData() {
			this.dirty = false;
			if (this.unwatch) {
				this.unwatch();
			}

			if (this.params.id != "new") {
				console.log("GET ITEM NOT NEW");
				return BB.props({
					item: ItemService.getItemNoCache(this.params.id).catch((e) => {
						console.error(err);
						Notie.error(this.$i18n.t("notie.item_not_found"), err);
						this.$router.replace("/items/new");
					}),
					isScored: ItemService.isScored(this.params.id).catch((err) => {
						console.error(err);
						Notie.error("Failed to check scores", err);
					}),
					hasValStats: QCService.hasValStats(this.params.id).catch((err) => {
						console.error(err);
						Notie.error("Failed to check validity stats", err);
					}),
					client: TenantService.getClient("current").catch((err) => {
						console.error(err);
						Notie.error("Failed to load client", err);
					}),
					projects: ProjectService.getProjectsForItem(this.params.id).catch((err) => {
						console.error(err);
						Notie.error("Failed to load projects", err);
					}),
				}).then((resps) => {
					// getItem
					let item = resps.item.data;
					ItemService.prepIncomingData(item);
					this.item = item;
					this.originalItem = _.cloneDeep(item);
					this.originalResources = _.cloneDeep(this.item.resources);
					this.origRel = this.item.reliability_percent;
					this.setOrigResSettings();
					this.loading = false;

					// isScored
					this.item.isScored = resps.isScored.data;

					// hasValStats
					this.hasValStats = resps.hasValStats.data;

					// getClient
					this.client = resps.client.data;
					this.loadAlerts(this.item, this.client);
					this.$nextTick(this.watchChanges);

					// getProjectsForItem
					this.projects = resps.projects.data;
				});
			} else {
				return TenantService.getClient("current")
					.then((resp) => {
						this.client = resp.data;

						this.item = this.blankItem();
						this.$set(this.item, "name", "New Item");
						this.$set(this.item, "ref_id", "new_item");
						this.loading = false;

						this.loadAlerts(this.item, resp.data);
						this.$nextTick(this.watchChanges);
					})
					.catch((err) => {
						Notie.error(this.$i18n.t("notie.initialize_item_fail"), err);
					});
			}
		},

		loadRubrics() {
			RubricService.listAllRubrics()
				.then((resp) => {
					this.rubrics = resp.data.rubrics;
				})
				.catch((e) => {
					console.error(e);
					Notie.error(this.$i18n.t("notie.load_rubrics_fail"), e);
				});
		},

		loadAlerts(item, client) {
			if (client.alerts) {
				item.avail_alerts = [];

				_.each(client.alerts, (client_alert) => {
					//Make an option object for each possible alert
					item.avail_alerts.push({
						alert_id: client_alert.id,
						code: client_alert.code,
						description: client_alert.description,
						item_id: item.id,
						action: client_alert.action,
						requires_score: client_alert.requires_score,
					});
				});
			}
		},

		setOrigResSettings() {
			this.origResSettings.type = this.item.type;
			this.origResSettings.weighted_resolution_threshold = this.item.weighted_resolution_threshold;
			this.origResSettings.weighted_resolution_range_min = this.item.weighted_resolution_range_min;
			this.origResSettings.weighted_resolution_range_max = this.item.weighted_resolution_range_max;
			this.origResSettings.resolution_choose_score = this.item.resolution_choose_score;
			this.origResSettings.reliability_percent = this.item.reliability_percent;
			this.origResSettings.omaha_min_max = this.item.omaha_min_max;
			this.origResSettings.omaha_max_non_adj = this.item.omaha_max_non_adj;
			this.origResSettings.omaha_max_weighted_score_diff = this.item.omaha_max_weighted_score_diff;
		},

		saveItem() {
			console.log("SAVING ITEM");
			if (this.item.cfCreateRubric) {
				console.log("HAD CF CREATE RUBRIC", this.item.cfCreateRubric);
				this.item.cfCreateRubric.name = this.item.name + " Rubric";
				this.item.cfCreateRubric.ref_id = this.item.ref_id + "_rubric";

				RubricService.saveRubric(this.item.cfCreateRubric)
					.then((r) => {
						console.log("Got new rubric", r.data);
						this.item.rubric_id = r.data.id;
						Notie.info(this.$i18n.t("notie.rubric_saved"), `"${this.item.cfCreateRubric.name}"`);
						delete this.item.cfCreateRubric;
						this.saveItem();
					})
					.catch((e) => {
						console.error(e);
						Notie.error("Failed to create rubric for IEA item", e);
					});
				return;
			}

			let saveItem = ItemService.prepOutgoingData(this.item);
			console.log("OUTGOING DATA", saveItem);

			return new Promise((resolve, reject) => {
				ItemService.saveItem(saveItem)
					.then((resp) => {
						let messages = [];
						let finish = () => {
							let lines = [`<div>${this.$i18n.t("notie.item_saved")}</div>`];

							for (let message of messages) {
								if (message.error) {
									lines.push(`<div class="text-danger">${message.text}</div>`);
								} else {
									lines.push(`<div class="text-muted">${message.text}</div>`);
								}
							}

							Notie.info(lines.join(`<hr class="my-2">`));
							if (this.item.id != resp.data.id) {
								this.$router.replace("/items/" + resp.data.id);
							}

							this.loadData();
							resolve();
						};

						let calls = [];
						if (this.changedResSettings && this.item.recalcRes) {
							let resCall = ItemService.recalculateResolution(resp.data.id)
								.then((r) => {
									messages.push({
										text: `Changed the resolution/completion state of ${r.data.count} responses`,
									});
								})
								.catch((e) => {
									messages.push({ text: "Failed to recalculate resolution", error: true });
								});
							calls.push(resCall);
						}

						if (this.changedRelSettings && this.item.recalcRel && !this.item.stateless_reliability) {
							let relCall = ItemService.recalculateReliability(resp.data.id)
								.then((r) => {
									messages.push({
										text: `Changed the reliability marking of ${r.data.changed_responses} responses`,
									});
								})
								.catch((e) => {
									messages.push({ text: "Failed to recalculate reliability", error: true });
								});
							calls.push(relCall);
						}

						if (this.item.resetValThresholds) {
							let valCall = QCService.resetValThresholds(resp.data.id)
								.then((r) => {
									messages.push({
										text: `Reset the validity thresholds of all users`,
									});
								})
								.catch((e) => {
									messages.push({ text: "Failed to reset validity thresholds", error: true });
								});
							calls.push(valCall);
						}

						if (calls.length > 0) {
							BB.all(calls).then(() => {
								finish();
							});
						} else {
							finish();
						}
					})
					.catch((err) => {
						console.log(err);
						Notie.error(this.$i18n.t("notie.save_item_fail"), err);
						reject();
					});
			});
		},

		openIeaSetupModal() {
			this.showIeaSetupModal = true;
		},
	},
};
</script>
