
<template>
	<div>
		<b-container fluid>
			<b-row>
				<mz-pagination
					v-if="perPage"
					size="md"
					:total-rows="content.length"
					v-model="currentPage"
					:per-page="perPage"
				/>
				<slot name="header" />
			</b-row>
		</b-container>
		<b-table
			ref="table"
			striped
			hover
			:per-page="perPage"
			:currentPage="currentPage"
			showEmpty
			:items="provider"
			:fields="processedFields"
			:emptyText="emptyText"
		>
			<template v-for="slotName in Object.keys($scopedSlots)" v-slot:[slotName]="slotScope">
				<slot :name="slotName" v-bind="slotScope"></slot>
			</template>
		</b-table>
	</div>
</template>


<script>
import MzPagination from "@/components/MzPagination";

export default {
	name: "ReportTable",
	props: ["emptyText", "fields", "totals", "totalsClass", "content", "perPage"],
	data() {
		let items = this.content;
		if (this.totals) {
			const totalsHead = _.cloneDeep(this.totals);
			const totalsFoot = _.cloneDeep(this.totals);
			totalsHead.forceHead = true;
			totalsHead.hl = true;
			totalsFoot.forceFoot = true;
			totalsFoot.hl = true;
			items = [];
			if (this.content && this.content.length > 0) {
				items = items.concat(totalsHead);
				items = items.concat(this.content);
				items.push(totalsFoot);
			}
		}
		return {
			currentPage: 1,
			items,
		};
	},

	components: {
		MzPagination,
	},

	watch: {
		content() {
			this.$refs.table.refresh();
		},
	},

	computed: {
		processedFields() {
			let processedFields = [];
			_.each(this.fields, (field) => {
				let nf = {};
				_.each(field, (v, k) => {
					nf.tdClass = (value, key, row) => {
						let classes = field.tdClass;
						if (row.hl) {
							classes = classes + " " + this.totalsClass;
						}
						return classes;
					};
					nf[k] = v;
				});
				processedFields.push(nf);
			});
			return processedFields;
		},
	},

	methods: {
		provider(ctx) {
			const { currentPage, perPage, sortBy, sortDesc } = ctx;
			const { content, totals } = this;
			let items = content.slice();
			items.sort((a, b) => {
				let val = 0;
				if (typeof a[sortBy] === "number" && typeof b[sortBy] === "number") {
					val = a[sortBy] - b[sortBy];
					if (Number.isNaN(a[sortBy])) {
						val = -1;
					}
					if (Number.isNaN(b[sortBy])) {
						val = 1;
					}
				} else {
					// Stringify the field data and use String.localeCompare
					val = this.toString(a[sortBy]).localeCompare(this.toString(b[sortBy]));
				}
				if (sortDesc) val *= -1;
				return val;
			});
			const rows = perPage > 0 ? items.slice((currentPage - 1) * perPage, currentPage * perPage) : items;
			if (totals) {
				const totalRow = { hl: true, ...this.totals };
				return [totalRow].concat(rows).concat(totalRow);
			}
			return rows;
		},

		toString(v) {
			if (!v) {
				return "";
			}
			if (v instanceof Object) {
				return keys(v)
					.map((k) => toString(v[k]))
					.join(" ");
			}
			return String(v);
		},
	},
};
</script>

<style scoped>
</style>
