<template>
	<div ref="essay" class="essay">
		<canvas :id="page.id"></canvas>
	</div>
</template>

<script>
import _ from "lodash";
import Utils from "@/services/Utils";
import ResponseMediaService from "../../services/ResponseMediaService";
import $ from "jquery";

export default {
	name: "PDFResource",
	props: ["page"],
	components: {},
	data() {
		return {
			events: []
		};
	},

	created() {},

	mounted() {
		this.drawPDF();
	},
	destroyed() {},

	methods: {
		drawPDF() {
			var targetWidth = 800;

			var canvas = $("#" + this.page.id).get(0);
			var pdfPage = this.page.pdf;

			var viewport = pdfPage.getViewport(1);
			var thisScale = targetWidth / viewport.width;
			viewport = pdfPage.getViewport(thisScale);
			var context = canvas.getContext("2d");
			canvas.width = viewport.width;
			canvas.height = viewport.height;

			var renderContext = {
				canvasContext: context,
				viewport: viewport
			};

			var now = new Date().getTime();
			var _this = this;
			pdfPage.render(renderContext).then(function() {
				console.log("PageRender:" + (new Date().getTime() - now));
				console.log(new Date().getTime() - _this.page.start);
			});
		}
	}
};
</script>

<style >
</style>
