import { render, staticRenderFns } from "./ValidityRuleStats.vue?vue&type=template&id=48318f1f&scoped=true"
import script from "./ValidityRuleStats.vue?vue&type=script&lang=js"
export * from "./ValidityRuleStats.vue?vue&type=script&lang=js"
import style0 from "./ValidityRuleStats.vue?vue&type=style&index=0&id=48318f1f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48318f1f",
  null
  
)

export default component.exports