<template>
	<page
		:title="!(trainingCourses && trainingCourses.length > 0) ? $t('Landing.title') : $t('Landing.training_title')"
	>
		<div v-if="loading" class="padding text-center h-100 text-muted">
			<loading type="large" />
		</div>
		<div
			v-if="!(trainingCourses && trainingCourses.length > 0)"
			class="padding text-center h-100 d-flex flex-column justify-content-center"
		>
			<h1 class="text-muted _600 mb-4">{{ user.first_name }} {{ user.last_name }} ({{ user.email }})</h1>
			<h2 class="text-muted mb-4">
				{{ $t("Landing.welcome_to") }}
				<span class="text-theme">{{ $t("Landing.oscar") }}</span>
			</h2>
			<h5 class="text-muted mb-4 d-lg-none">
				{{ $t("Landing.please_use_the") }}
				<i class="far fa-bars fa-fw"></i> {{ $t("Landing.menu_at_top_left") }}
			</h5>
			<h5 class="text-muted mb-4 d-none d-lg-block">
				{{ $t("Landing.use_side_bar") }}
			</h5>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.landing-box {
	min-height: 200px;
	height: 100%;
}
</style>

<script>
import TrainingService from "@/services/TrainingService";
import Notie from "@/services/NotieService";

export default {
	name: "Landing",

	props: ["user"],

	data() {
		return {
			loading: true,
			trainingCourses: null,
		};
	},
	components: {},
	created() {
		this.loadTrainingCourses();
	},
	methods: {
		loadTrainingCourses() {
			TrainingService.getCombinedCoursesAndProgress()
				.then((r) => {
					this.loading = false;
					this.trainingCourses = r.data;
				})
				.catch((e) => {
					this.loading = false;
					console.error(e);
					Notie.error("Failed to load training courses");
				});
		},
	},
};
</script>


