<template>
	<div class="video-box">
		<video ref="video" class="mzd-video" preload="metadata" controls controlsList="nodownload noplaybackrate">
			<source :src="page.url" />
			Your browser does not support the video tag.
		</video>
	</div>
</template>

<script>

export default {
	name: "VideoMedia",
	props: {
		page: Object,
		chan: Object,
	},

	data() {
		return {
			events: [],
		};
	},

	created() {
		if (!this.chan) {
			return;
		}
		this.events.push({ key: "set-playback-speed", val: this.setPlaybackSpeed });
		this.chan.$on("set-playback-speed", this.setPlaybackSpeed);
	},

	mounted() {
		const { playback_speed } = this.$route.meta.user;
		if (playback_speed) {
			this.setPlaybackSpeed(playback_speed);
		}
	},

	destroyed() {
		const _this = this;
		_.each(this.events, (e) => {
			_this.chan.$off(e.key, e.val);
		});
	},

	methods: {
		setPlaybackSpeed(playbackRate) {
			this.$refs.video.playbackRate = playbackRate;
		}
	}
};
</script>

<style scoped>
.mzd-video {
	width: 100%;
}

.video-box {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	/*min-height:1250px;*/
	/*width:1000px;*/
	/*font-size: 17pt;*/

	/*min-height:1000px;*/
	max-width: 800px;
	min-width: 200px;
	font-size: 13pt;

	/*padding:80px;*/
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
	line-height: 0;
}
</style>
