<template>
	<div>
		<div
			v-if="!directRender && !previewerItem"
			ref="box"
			class="lrn-box d-flex flex-column align-items-center"
			:width="width"
		>
			<template v-if="page.signing_cookies">
				<iframe v-if="page.redirect_url" :src="page.redirect_url" :width="width" :height="height" />
				<div v-else class="p-4 text-danger">
					Error loading page: the content could not be properly authenticated
				</div>
			</template>
			<iframe v-else :src="page.url" :width="width" :height="height" />
		</div>
		<template v-if="directRender && !previewerItem">
			<template v-if="page.signing_cookies">
				<iframe
					v-if="page.redirect_url && !page.viewSource"
					:src="page.redirect_url"
					:width="windowWidth"
					:height="windowHeight"
					class="iframe-media"
				/>
				<div v-else class="p-4 text-danger">
					Error loading page: the content could not be properly authenticated
				</div>
			</template>
			<iframe v-else :src="page.url" :width="windowWidth" :height="windowHeight" class="iframe-media" />
		</template>
		<template v-if="previewerItem">
			<div ref="box" class="lrn-box d-flex flex-column align-items-center" :width="width">
				<form
					ref="form"
					method="post"
					:action="page.url"
					:target="`previewerIframe_${response.ref_id}_${page.id}`"
				>
					<input type="hidden" name="itemIdentifier" :value="page.previewer_item_identifier" />
					<input type="hidden" name="responses" :value="page.previewer_r" />
				</form>
				<div
					v-if="previewerParsingError && !page.viewSource"
					class="text-danger"
					style="font-family: monospace"
				>
					Previewer content parsing error:<br />
					{{ this.previewerParsingError }}
				</div>
				<iframe
					v-show="!page.viewSource"
					:name="`previewerIframe_${response.ref_id}_${page.id}`"
					:width="width"
					:height="height"
					class="iframe-media"
				></iframe>
				<div v-if="page.viewSource" class="w-100 json-tree-wrap" style="font-family: monospace">
					<JsonTree :raw="page.raw_source" />
					<div class="p-3">
						<div
							v-if="previewerParsingError"
							class="text-danger mb-2 card px-2 text-xxs"
							style="font-family: monospace"
						>
							Previewer content parsing error:<br />
							{{ this.previewerParsingError }}
						</div>
						<div v-if="previewerErrorColumn">
							<span>{{ page.raw_source.substring(0, previewerErrorColumn - 1) }}</span>
							<span class="bg-danger text-white" style="padding: 2px; border-radius: 4px">{{
								page.raw_source.substring(previewerErrorColumn - 1, previewerErrorColumn)
							}}</span>
							<span>{{ page.raw_source.substring(previewerErrorColumn) }}</span>
						</div>
						<div v-else>{{ page.raw_source }}</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import JsonTree from "vue-json-tree";

export default {
	name: "IframeMedia",
	props: ["item", "page", "response", "resource", "trainingElement", "directRender", "rawSource", "viewSource"],
	components: { JsonTree },

	data() {
		return {
			width: 800,
			height: 450,
			windowWidth: 800,
			windowHeight: 450,
			labelHeight: 46,
			observer: null,
			previewerItem: false,
			previewerParsingError: null,
			previewerErrorColumn: null,
		};
	},

	computed: {
		hasLabel() {
			return (page.mediaSeq != undefined && showPrint) || (page.type != "text" && page.label);
		},
	},

	watch: {},

	created() {
		console.log("created - iFrameMedia");
		console.log(this.page);
		//check if response is a previewer item response
		var responseMetaElk = this.response && this.response.meta && this.response.meta.elk;
		if (responseMetaElk || (this.page.url && this.page.url.includes("https://tn8previewer"))) {
			this.previewerItem = true;
		}

		if (this.page.signing_cookies) {
			this.page.redirect_url = this.getRedirectURL(this.page);
		}

		if (this.page.raw_source) {
			this.debug(this.page.raw_source);

			let enc = new TextEncoder();
			let u8 = enc.encode(this.page.raw_source);
			this.debug("raw bytes", `${u8}`);
			try {
				let literalResult = eval(`new TextDecoder().decode(new Uint8Array([${u8}]))`);
				this.debug("literalResult", literalResult);
				let parsedResult = JSON.parse(literalResult);
				this.debug("parsedResult", parsedResult);
			} catch (e) {
				let errString = e.toString();
				this.previewerParsingError = errString;
				this.debug(errString);
				let posDetails = errString.match(/line ([\d]+) column ([\d]+)/);
				this.debug("match", posDetails);
				if (
					posDetails &&
					posDetails[2] &&
					parseInt(posDetails[2]) &&
					parseInt(posDetails[2]) <= this.page.raw_source.length
				) {
					this.previewerErrorColumn = parseInt(posDetails[2]);
				}
			}
		}
	},

	mounted() {
		this.resizeIframe();

		if (this.previewerItem) {
			this.$refs.form.submit();
		}

		window.addEventListener("resize", this.resizeIframe);
	},

	beforeDestroy() {
		window.removeEventListener("resize", this.resizeIframe);
	},

	methods: {
		resizeIframe() {
			console.log("RESIZE IFRAME");
			// Width/Height on 'this.page' are preferred values, while Width/Height on 'this' are actual rendered values

			if (this.directRender) {
				this.windowWidth = window.innerWidth - 40;
				this.windowHeight = window.innerHeight - 70;
				if (this.hasLabel) {
					this.windowHeight = this.windowHeight - this.labelHeight;
				}
			} else {
				let aspectRatio = 0.6105; // Cognia content package aspect ratio, use as a default
				if (this.page && this.page.width && this.page.height) {
					aspectRatio = this.page.height / this.page.width;
				}
				let containerWidth = this.$refs.box.offsetWidth;
				this.width = containerWidth;
				if (!this.previewerItem) {
					this.height = this.width * aspectRatio;
				} else if (this.previewerItem) {
					this.height = this.width - 100;
				}
			}
		},

		getRedirectURL(page) {
			if (!page.signing_cookies) return;

			let dir = encodeURIComponent(page.url);
			let pol = "";
			let sig = "";
			let key = "";

			for (let cookie of page.signing_cookies) {
				if (cookie.Name == "CloudFront-Policy") {
					pol = cookie.Value;
				}
				if (cookie.Name == "CloudFront-Signature") {
					sig = cookie.Value;
				}
				if (cookie.Name == "CloudFront-Key-Pair-Id") {
					key = cookie.Value;
				}
			}

			let url = `/v1/redirect/content_package?dir=${dir}&pol=${pol}&sig=${sig}&key=${key}`;
			if (this.resource) {
				if (this.trainingElement) {
					url += "&tres=true";
				} else {
					url += "&res=true";
				}
			}
			return url;
		},
	},
};
</script>

<style scoped>
.lrn-box {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: 1000px;
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
}
iframe {
	resize: vertical;
	user-select: none;
}
.iframe-media {
	resize: both;
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
}
</style>
