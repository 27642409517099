<template>
	<div class="navbar theme bg box-shadow py-0">
		<div class="navbar-brand oscar-logo">
			<!-- Logo -->
			<img
				src="static/OSCAR-logo-white-cropped.png"
				alt="."
				class="d-none d-sm-none d-md-inline d-lg-inline mr-1"
			/>
			<!-- Hamburger -->
			<a
				v-if="sidenavExists"
				class="d-lg-none"
				data-toggle="modal"
				data-target="#aside"
				data-pjax-click-state="external"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="19"
					height="38"
					viewBox="66 113 380 285"
					preserveAspectRatio="none"
				>
					<path d="M80 304h352v16H80zM80 248h352v16H80zM80 192h352v16H80z"></path>
				</svg>
			</a>
		</div>

		<ul class="nav flex-row order-2">
			<li v-if="user.client && sortedClients.length == 0" class="d-flex align-items-center pr-3">
				<span class="px-2">{{ user.client.name }} </span>
			</li>
			<!-- Change Tenant Dropdown -->
			<li v-if="user.client && sortedClients.length > 0" class="dropdown d-flex align-items-center pr-3">
				<a
					data-toggle="dropdown"
					class="d-flex align-items-center"
					data-pjax-click-state="anchor-empty"
					aria-expanded="false"
				>
					<span class="px-2"
						>{{ user.client.name }}
						<i class="fa fa-caret-down"></i>
					</span>
				</a>
				<div v-if="user.clients" class="dropdown-menu dropdown-menu-right animate fadeIn limit-dropdown">
					<a class="dropdown-item disabled">
						<strong>{{ $t("Navbar.change_tenant") }}</strong>
					</a>
					<div class="dropdown-divider"></div>
					<a
						v-for="client in sortedClients"
						:key="client.client_id"
						@click="changeClient(client.client_id)"
						class="dropdown-item"
						>{{ client.name }}</a
					>
				</div>
			</li>
			<!-- User Dropdown -->
			<li class="dropdown d-flex align-items-center">
				<a
					data-toggle="dropdown"
					class="d-flex align-items-center"
					data-pjax-click-state="anchor-empty"
					aria-expanded="false"
				>
					<span class=""
						>{{ user.first_name }} {{ user.last_name }}
						<i class="fa fa-caret-down"></i>
					</span>
				</a>
				<div class="dropdown-menu dropdown-menu-right w pt-0 mt-2 animate fadeIn">
					<div class="row no-gutters b-b mb-2">
						<!-- <div class="col-6 b-r">
							<a href="app.message.html" class="py-2 pt-3 d-block text-center">
								<i class="fa text-md fa-comment text-muted"></i>
								<small class="d-block">Chat</small>
							</a>
						</div>
						<div class="col-6">
							<a href="app.inbox.html" class="py-2 pt-3 d-block text-center">
								<i class="fa text-md fa-video-camera text-muted"></i>
								<small class="d-block">Video</small>
							</a>
						</div> -->
					</div>
					<a class="dropdown-item" href="#/profile">
						<span>{{ $t("Navbar.profile") }}</span>
					</a>

					<div class="dropdown-divider"></div>

					<a class="dropdown-item" @click="openDebugCodeModal">
						{{ $t("Navbar.get_debug_code") }}
					</a>
					<a v-if="user.superuser" class="dropdown-item" @click="openDebugCodeEntryModal">
						{{ $t("Navbar.enter_debug_code") }}
					</a>
					<a v-if="user.superuser" class="dropdown-item" href="#/admin_stats">
						{{ $t("Navbar.admin_stats") }}
					</a>
					<a v-if="user.superuser" class="dropdown-item" href="#/admin_tasks">
						{{ $t("Navbar.admin_tasks") }}
					</a>

					<div class="dropdown-divider"></div>
					<a
						v-if="user && user.role && user.role.show_help"
						class="dropdown-item"
						href="https://oscarscore.zendesk.com/"
						target="_blank"
						>{{ $t("Navbar.need_help") }}
					</a>
					<a class="dropdown-item" @click="logout">{{ $t("Navbar.sign_out") }}</a>

					<!-- <a class="dropdown-item" @click="startOverlay()">DEBUG: Auto-logout</a> -->
				</div>
			</li>

			<!-- Video Meeting active -->
			<li v-if="videoMeetingParticipant && videoMeetingUiState && videoMeetingUiState.meeting">
				<div
					class="video-meeting-reminder shadow-inset d-flex flex-row align-items-center"
					v-tippy
					:title="videoMeetingReturnTooltip()"
					@click="returnToVideoMeeting"
				>
					<i class="fa-2x far fa-camera" />
					<div class="ml-2 d-flex flex-column align-items-center">
						<div>{{ videoMeetingUiState.meeting.name }}</div>

						<div v-if="!videoMeetingParticipant.muted" class="mb-1 volume-bar-container w-100">
							<div class="volume-bar w-100">
								<div class="volume-bar-tick" />
								<i class="volume-icon-overlay far fa-volume mr-1" />
								<div
									class="volume-bar-progress"
									:style="{ width: `${Math.ceil(videoMeetingParticipant.volumeLevel)}%` }"
								></div>
							</div>
						</div>
					</div>
				</div>

				<audio ref="audio" />
			</li>
		</ul>

		<div class="relog-overlay dark" :class="{ 'show-30': overlayed }"></div>
		<div class="inner-overlay" :class="{ 'show-1': overlayed }">
			<div class="dark h-100 d-flex flex align-items-center rounded">
				<div v-if="overlayed" class="mx-auto w-xl w-auto-xs animate fadeIn text-center text-match-color">
					<div class="my-3">
						<img src="static/OSCAR-logo-white-cropped.png" style="height: 100px; opacity: 0.4" />
						<div class="mt-3 font-bold">
							{{ user.first_name }} {{ user.last_name }}
							<span v-if="loggedOut"> </span>
						</div>
					</div>
					<div class="md-form-group" v-if="!loggedOut">
						<input type="text" class="md-input text-center" readonly :value="timeoutCount + 1 + 's'" />
						<label class="block w-100">{{ $t("Navbar.will_be_logged_out") }}</label>
					</div>
					<div class="md-form-group" v-if="loggedOut">
						<input
							type="password"
							class="md-input text-center"
							autocomplete="off"
							v-model="overlayPassword"
							@keydown="overlayKeydown"
						/>
						<label class="block w-100">{{ $t("Navbar.your_password") }}</label>
					</div>
					<div class="my-3">
						<button class="btn theme" @click="unlockOverlay" v-if="!loggedOut">Unlock</button>
						<a class="btn theme px-2" href="#/" v-tippy :title="$t('tooltip.change_user')" v-if="loggedOut">
							<i class="far fa-user"></i>
						</a>
						<button
							:disabled="!overlayPassword"
							class="btn"
							:class="{ [overlayPassword ? 'theme' : 'btn-secondary']: true }"
							@click="unlockOverlay"
							v-if="loggedOut"
						>
							{{ $t("Navbar.sign_in") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<b-modal id="eulaModal" size="lg" v-model="eulaModal" no-close-on-backdrop no-close-on-esc>
			<template slot="modal-header">
				<h5 class="modal-title pl-4">{{ $t("Navbar.eula") }}</h5>
			</template>
			<div id="eulaContainer" class="p-4 modal-scroll" v-html="EULA.text"></div>
			<template slot="modal-footer">
				<button @click="logout" class="btn btn-flat btn-danger">{{ $t("buttons.cancel") }}</button>
				<button
					@click="acceptEula"
					class="btn btn-flat"
					:disabled="!eulaScrolled"
					:class="{ [eulaScrolled ? 'btn-success' : 'btn-secondary']: true }"
				>
					{{ $t("buttons.accept") }}
				</button>
			</template>
		</b-modal>

		<b-modal id="termsModal" size="lg" v-model="termsModal" no-close-on-backdrop no-close-on-esc v-if="user.client">
			<template slot="modal-header">
				<h5 class="modal-title pl-4">{{ $t("Navbar.terms_of_use") }}</h5>
				<h5 class="modal-title text-muted pr-4">{{ user.client.name }}</h5>
			</template>
			<div class="p-4" v-html="user.client.terms_text"></div>
			<template slot="modal-footer">
				<button @click="logout" class="btn btn-flat btn-danger">{{ $t("buttons.cancel") }}</button>
				<button @click="acceptTerms" class="btn btn-flat btn-success">{{ $t("buttons.accept") }}</button>
			</template>
		</b-modal>

		<b-modal id="versionModal" v-model="versionModal" no-close-on-backdrop no-close-on-esc v-if="user.client">
			<template slot="modal-header">
				<h5 class="modal-title pl-4">{{ $t("Navbar.new_version_detected") }}</h5>
			</template>
			<p class="p-4">{{ $t("Navbar.please_refresh") }} ({{ serverVersion }}).</p>
			<template slot="modal-footer">
				<button @click="reload()" class="btn btn-flat btn-success">{{ $t("buttons.refresh") }}</button>
			</template>
		</b-modal>

		<b-modal id="debugCodeModal" v-model="debugCodeModal" @hide="debugCodeModal = false">
			<template slot="modal-header">
				<h5 class="modal-title pl-4">{{ $t("Navbar.debug_code") }}</h5>
			</template>
			<div v-if="debugCodeModal" class="p-3 d-flex flex-column">
				<p>{{ $t("Navbar.debug_code_explanation") }}</p>
				<div class="d-flex flex-row align-items-center">
					<input
						ref="debugCode"
						class="form-control"
						:class="{ 'is-valid': debugCodeCopied }"
						readonly
						:value="Utils.generateDebugCode(user)"
					/>
					<i v-if="debugCodeCopied" class="fas fa-2x fa-check-circle text-success ml-2" />
				</div>
				<button class="btn btn-primary align-self-center mt-3" @click="copyDebugCode">
					{{ $t("buttons.copy") }}
				</button>
			</div>
			<template slot="modal-footer">
				<button @click="debugCodeModal = false" class="btn btn-flat btn-success">{{ $t("buttons.ok") }}</button>
			</template>
		</b-modal>

		<b-modal id="debugCodeEntryModal" v-model="debugCodeEntryModal" @hide="debugCodeEntryModal = false">
			<template slot="modal-header">
				<h5 class="modal-title pl-4">{{ $t("Navbar.enter_debug_code") }}</h5>
			</template>
			<div v-if="debugCodeEntryModal" class="p-3 d-flex flex-column">
				<p>{{ $t("Navbar.debug_code_entry_explanation") }}</p>
				<input
					v-if="debugCodeEntryModal"
					class="form-control"
					:class="{
						'is-invalid': debugCodeInfo && debugCodeInfoError(debugCodeInfo),
						'is-valid': debugCodeInfo && !debugCodeInfoError(debugCodeInfo),
					}"
					v-model="debugCode"
					@input="parseDebugCode"
				/>
				<div v-if="debugCodeInfo" class="card mt-3 px-3 py-2">
					<template v-if="debugCodeInfo.error">
						<div>
							<span class="_600">{{ $t("Navbar.error") }}:</span>
							<span>{{ debugCodeInfo.error }}</span>
						</div>
					</template>
					<template v-else-if="debugCodeInfo.changeEnv">
						<div class="mb-2">
							<span
								v-html="$t('Navbar.change_env_explanation', { environment: debugCodeInfo.changeEnv })"
							></span>
						</div>
						<div>
							<span class="_600">{{ $t("Navbar.user") }}:</span>
							<span>{{ debugCodeInfo.user.id }}</span>
						</div>
						<div>
							<span class="_600">{{ $t("Navbar.tenant") }}:</span>
							<span>{{ debugCodeInfo.client.id }}</span>
						</div>
						<div>
							<span class="_600">{{ $t("Navbar.page") }}:</span>
							<span>{{ debugCodeInfo.url }}</span>
						</div>
					</template>
					<template v-else>
						<div>
							<span class="_600">{{ $t("Navbar.environment") }}:</span>
							<span>{{ debugCodeInfo.env }}</span>
						</div>
						<div>
							<span class="_600">{{ $t("Navbar.user") }}:</span>
							<loading v-if="!debugCodeInfo.user || debugCodeInfo.user.loading" type="icon" />
							<span v-else-if="debugCodeInfo.user.error">{{ debugCodeInfo.user.error }}</span>
							<span v-else>{{ debugCodeInfo.user.full_name }}</span>
						</div>
						<div>
							<span class="_600">{{ $t("Navbar.tenant") }}:</span>
							<loading v-if="!debugCodeInfo.client || debugCodeInfo.client.loading" type="icon" />
							<span v-else-if="debugCodeInfo.client.error">{{ debugCodeInfo.client.error }}</span>
							<span v-else>{{ debugCodeInfo.client.name }}</span>
						</div>
						<div>
							<span class="_600">{{ $t("Navbar.page") }}:</span>
							<span>{{ debugCodeInfo.url }}</span>
						</div>
					</template>
				</div>
				<div
					v-if="debugCodeInfo && debugCodeInfo.promptForce"
					class="align-self-end"
					style="margin-top: 1rem; margin-bottom: -1rem"
				>
					<label
						class="md-check"
						v-tippy="{ placement: 'bottom-left', theme: 'popover' }"
						:title="$t('Navbar.force_logout_explanation')"
					>
						<input v-model="debugForceLogout" type="checkbox" />
						<i class="theme-accent"></i> {{ $t("Navbar.force_logout") }}
					</label>
				</div>
			</div>
			<template slot="modal-footer">
				<button @click="debugCodeEntryModal = false" class="btn btn-flat btn-secondary">
					{{ $t("buttons.cancel") }}
				</button>
				<button
					v-if="debugCodeInfo && !debugCodeInfo.changeEnv && !debugCodeInfoError(debugCodeInfo)"
					@click="executeDebugCode(debugCode)"
					class="btn btn-flat btn-success"
				>
					Login As {{ debugCodeInfo.user ? debugCodeInfo.user.full_name : "(Unknown)" }}
				</button>
				<button
					v-if="debugCodeInfo && debugCodeInfo.changeEnv && !debugCodeInfoError(debugCodeInfo)"
					@click="crossEnvDebugLink(debugCodeInfo.url, debugCode)"
					class="btn btn-flat btn-success"
				>
					Continue to {{ debugCodeInfo.changeEnv }}
				</button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import AuthService from "../../services/AuthService";
import UserService from "@/services/UserService";
import TenantService from "@/services/TenantService";
import TimeoutService from "../../services/TimeoutService";
import VersionService from "../../services/VersionService";
import Utils from "@/services/Utils";
import notie from "../../services/NotieService";
import rawnotie from "notie";
import Router from "../../router";
import moment from "moment";
import pack from "../../../package.json";

import VideoMeetingService from "@/services/VideoMeetingService";
import Chime from "@/vues/VideoMeeting/services/ChimeEngine";

import EULA from "@/services/EULA";

export default {
	name: "Navbar",

	props: ["refresh", "versionCheckRefresh"],

	data() {
		return {
			Utils: Utils,
			EULA: EULA,
			user: {},
			overlayed: false,
			timeoutTimer: null,
			timeoutCount: 0,
			loggedOut: false,
			overlayPassword: "",
			sidenavExists: false,
			termsModal: false,
			eulaModal: false,
			eulaScrolled: false,
			ts: {},
			version: "",

			versionChecker: null,
			versionModal: false,
			debugCode: null,
			debugCodeModal: false,
			debugCodeCopied: false,
			debugCodeEntryModal: false,
			debugCodeInfo: null,
			debugForceLogout: null,
			serverVersion: "???",

			mockMeetingID: "",
			videoMeetings: [],

			videoMeetingUiState: null,
			videoMeetingParticipant: null,
		};
	},

	created() {
		this.ts = TimeoutService;
		this.version = pack.version;
		console.log("ClientVersion:" + this.version);
		// console.log("version check");
		this.checkVersion();
		if (!this.versionCheckRefresh) {
			this.versionChecker = setInterval(this.checkVersion, 60 * 1000);
		}
	},

	mounted() {
		this.debug("mounted");
		let sidenav = document.getElementById("aside");
		if (sidenav) {
			this.sidenavExists = true;
		}

		TimeoutService.registerCallback("navbar", () => {
			this.startOverlay();
		});

		this.advanceModals();

		window.addEventListener("resize", this.eulaScrollCheck);

		this.debug("Chime state", Chime);
		this.videoMeetingUiState = Chime.uiState;
		this.videoMeetingParticipant = Chime.localParticipant;

		if (Chime.inMeeting()) {
			this.$nextTick(() => {
				Chime.registerAudioOutputElement(this.$refs.audio);
			});
		}
	},

	destroyed() {
		clearInterval(this.versionChecker);
		TimeoutService.clearCallback("navbar");

		window.removeEventListener("resize", this.eulaScrollCheck);
	},

	computed: {
		sortedClients() {
			const { user } = this;
			let clients = _.filter(user.clients, (uc) => {
				return uc.client_id != user.client.id && (uc.client_active || user.superuser);
			});
			return _.sortBy(clients, (c) => {
				return c.name.toLowerCase();
			});
		},
	},

	watch: {
		refresh() {
			this.refreshUser();
		},

		versionCheckRefresh() {
			if (this.versionCheckRefresh != "nocheck") {
				this.checkVersion();
			}
		},
	},

	methods: {
		logout() {
			AuthService.logout();
		},

		reload() {
			window.location.reload(true);
		},

		refreshUser() {
			AuthService.getUser(true).then((user) => {
				this.user = user;
			});
		},

		checkVersion() {
			if (!this.overlayed) {
				VersionService.getVersion().then((resp) => {
					this.serverVersion = resp.data;
					if (this.serverVersion == "dev2") {
						return;
					}

					let clientBuild = this.buildFromVersion(this.version);
					let serverBuild = this.buildFromVersion(this.serverVersion);

					console.log("Compare version", clientBuild, serverBuild, serverBuild != clientBuild);
					if (serverBuild != clientBuild) {
						this.versionModal = true;
					}
				});
			}
		},

		buildFromVersion(version) {
			let parts = version.split(".");
			return parts[parts.length - 1];
		},

		acceptEula() {
			AuthService.acceptEula()
				.then((resp) => {
					this.advanceModals();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.accept_eula_fail"));
				});
		},

		eulaScrollHook() {
			let eula = document.getElementById("eulaContainer");
			eula.addEventListener("scroll", this.eulaScrollCheck);
			//Need to do one initial check in case the div isn't scrollable to begin with
			//But it has to be delayed I guess or else the modal won't actually be rendered
			setTimeout(this.eulaScrollCheck, 100);
		},

		eulaScrollCheck() {
			let eula = document.getElementById("eulaContainer");
			if (!eula) {
				console.log("EULA container not found for scroll check");
				return;
			}
			if (eula.scrollHeight - (eula.scrollTop + eula.clientHeight) < 4) {
				this.eulaScrolled = true;
			}
		},

		acceptTerms() {
			AuthService.acceptTerms()
				.then((resp) => {
					this.advanceModals();
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.accept_terms_fail"));
				});
		},

		advanceModals() {
			AuthService.getUser().then((user) => {
				this.user = user;
				let client = this.user.client;
				let showEULA = false;
				let showTerms = false;

				if (EULA.required) {
					let accepted_at = moment(user.eula_accepted_at);
					let updated_at = moment(EULA.updated_at);

					if (!user.eula_accepted || updated_at.isAfter(accepted_at)) {
						showEULA = true;
					}
				}

				if (client.terms_required) {
					let uc = _.find(this.user.clients, {
						client_id: client.id,
					});
					if (uc) {
						let accepted_at = moment(uc.terms_accepted_at);
						let updated_at = moment(client.terms_updated_at);

						if (!uc.terms_accepted || updated_at.isAfter(accepted_at)) {
							showTerms = true;
						}
					}
				}

				if (showEULA) {
					this.eulaModal = true;
					this.termsModal = false;
					this.$nextTick(() => {
						this.eulaScrollHook();
					});
				} else if (showTerms) {
					this.eulaModal = false;
					this.termsModal = true;
				} else {
					this.eulaModal = false;
					this.termsModal = false;
				}
			});
		},

		startOverlay() {
			this.versionModal = false;
			this.overlayed = true;
			this.overlayPassword = "";
			let timeout = Math.floor(TimeoutService.getServerTimeout() / 1000);
			this.$set(this, "timeoutCount", timeout);
			clearInterval(this.timeoutTimer);
			this.timeoutTimer = setInterval(() => {
				if (this.timeoutCount == 0) {
					this.loggedOut = true;
					clearInterval(this.timeoutTimer);
				} else {
					this.$set(this, "timeoutCount", this.timeoutCount - 1);
				}
			}, 1000);
		},

		getKey(e) {
			//Presumably necessary to polyfill for Safari
			if (e.key) return e.key;

			let keyFromCode = String.fromCharCode(e.keyCode);
			if (keyFromCode) return keyFromCode;

			if (e.keyCode === 13) return "Enter";
			if (e.keyCode === 16) return "Shift";
			if (e.keyCode === 8) return "Tab";
			return null;
		},

		overlayKeydown(event) {
			if (event.key == "Enter") {
				this.unlockOverlay();
			}
		},

		unlockOverlay() {
			if (this.loggedOut) {
				AuthService.login(this.user.email, this.overlayPassword)
					.then((resp) => {
						AuthService.getUser()
							.then((resp) => {
								this.$emit("login", true);
								this.doUnlockOverlay();
							})
							.catch((err) => {
								console.log(err);
								notie.error(this.$i18n.t("notie.initialize_user_fail"), err);
							});
					})
					.catch((err) => {
						console.log(err);
						notie.error(this.$i18n.t("notie.login_fail"), err);
					});
			} else {
				//Just refresh user to ping to the server
				AuthService.getUser(true)
					.then((user) => {
						this.user = user;
						this.doUnlockOverlay();
					})
					.catch((err) => {
						console.log(err);
						notie.error(this.$i18n.t("notie.initialize_user_fail"), err);
					});
			}
		},

		doUnlockOverlay() {
			clearInterval(this.timeoutTimer);
			this.overlayed = false;
			this.loggedOut = false;
		},

		changeClient(clientID) {
			AuthService.changeClient(clientID)
				.then(() => {
					AuthService.getUser(true).then((user) => {
						this.user = user;
						Router.push("/landing?client=" + clientID);
					});
				})
				.catch((err) => {
					notie.error("Failed to change client", err);
				});
		},

		openDebugCodeModal() {
			this.debugCode = Utils.generateDebugCode(this.user);
			this.debugCodeCopied = false;
			this.debugCodeModal = true;
		},

		openDebugCodeEntryModal() {
			this.debugCode = null;
			this.debugCodeEntryModal = true;
			this.debugCodeInfo = null;
		},

		copyDebugCode() {
			// do copy
			this.debugCodeCopied = true;
			this.$refs.debugCode.select();
			setTimeout(() => {
				this.$refs.debugCode.selectionStart = this.$refs.debugCode.selectionEnd;
				this.debugCodeCopied = true;
			}, 200);
			try {
				return document.execCommand("copy"); // Security exception may be thrown by some browsers.
			} catch (ex) {
				console.warn("Copy to clipboard failed.", ex);
				return false;
			}
		},

		parseDebugCode() {
			this.debugCodeInfo = {};
			let linkStr = "";
			try {
				linkStr = atob(this.debugCode);
			} catch (e) {
				this.debugCodeInfo.error = "Could not decode debug code: " + e;
				return;
			}
			let parts = linkStr.split("|");
			if (parts.length < 3) {
				console.log("parts", parts.length, parts);
				this.debugCodeInfo.error = "Could not parse debug code";
				return;
			}
			let userID = parts[0];
			let clientID = parts[1];
			let urlStr = parts.slice(2);
			let url = new URL(urlStr);
			this.debugCodeInfo.url = url.href;

			let destEnv = Utils.detectEnvironment(url.hostname);
			let currentEnv = Utils.detectEnvironment(window.location.hostname);
			if (destEnv != currentEnv) {
				this.debugCodeInfo.user = { id: userID };
				this.debugCodeInfo.client = { id: clientID };
				this.debugCodeInfo.changeEnv = destEnv;
				return;
			}
			this.debugCodeInfo.env = currentEnv;

			this.debugCodeInfo.user = { loading: true };
			UserService.getUser(userID)
				.then((r) => {
					this.debugCodeInfo.user = r.data;
					this.$forceUpdate();
				})
				.catch((e) => {
					this.debugCodeInfo.user = { error: e };
					this.$forceUpdate();
				});

			this.debugCodeInfo.client = { loading: true };
			TenantService.getClient(clientID)
				.then((r) => {
					this.debugCodeInfo.client = r.data;
					this.$forceUpdate();
				})
				.catch((e) => {
					this.debugCodeInfo.client = { error: e };
					this.$forceUpdate();
				});
		},

		debugCodeInfoError(debugCodeInfo) {
			return (
				debugCodeInfo.error ||
				(debugCodeInfo.user && debugCodeInfo.user.error) ||
				(debugCodeInfo.client && debugCodeInfo.client.error)
			);
		},

		crossEnvDebugLink(urlStr, debugCode) {
			let url = new URL(urlStr);
			let link = url.protocol + "//" + url.host + "/#/?debug=" + debugCode;
			window.location.href = link;
		},

		executeDebugCode(debugCode) {
			AuthService.followDebugCodeLink(debugCode, this.$router, this.debugForceLogout)
				.then((r) => {
					let successMsg = `
<h5>${this.$i18n.t("Navbar.logged_in_as")}</h5>
<div class="text-xs text-muted" style="line-height: 1.3">${this.$i18n.t("Navbar.logged_in_as_explanation")}</div>
<div class="card px-3 py-2 mt-2" style="background-color: transparent; border-color: rgba(255, 255, 255, 0.5)">
	<div>
		<span class="_600">${this.$i18n.t("Navbar.environment")}</span>:
		<span>${r.env}</span>
	</div>
	<div>
		<span class="_600">${this.$i18n.t("Navbar.user")}</span>:
		<span>${r.user_name}</span>
	</div>
	<div>
		<span class="_600">${this.$i18n.t("Navbar.tenant")}</span>:
		<span>${r.client_name}</span>
	</div>
	<div>
		<span class="_600">${this.$i18n.t("Navbar.page")}</span>:
		<span>${r.path}</span>
	</div>
</div>`;
					let notieCtx = {
						type: "success",
						text: successMsg,
						time: 15,
					};
					rawnotie.alert(notieCtx);
				})
				.catch((e) => {
					console.error(e);
					if (e.internalError) {
						notie.error(e.internalError.emsg, e.internalError.e);
					} else {
						notie.error("Failed to follow debug code link", e);
						console.log("e.DATA", { error: e });
						if (
							e.response &&
							e.response.data &&
							e.response.data.data == "That user is currently logged in"
						) {
							this.debugCodeInfo.promptForce = true;
							this.$forceUpdate();
						}
					}
				});
		},

		videoMeetingReturnTooltip() {
			return `<div>Return to Video Meeting</div><div class="text-muted">You are currently in a video meeting. Your video and audio are broadcasting. Click here to return to the meeting screen.</div>`;
		},

		returnToVideoMeeting() {
			this.$router.push(`/video_meeting/${this.videoMeetingUiState.meeting.id}`);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.relog-overlay {
	position: fixed;
	z-index: 2000;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	opacity: 0;
	pointer-events: none;
}
.inner-overlay {
	position: fixed;
	z-index: 2001;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 50%;
	height: 50%;

	min-height: 320px;
	min-width: 320px;

	opacity: 0;
	pointer-events: none;
}
.show-1 {
	opacity: 1;
	transition: opacity 1s;
	pointer-events: all;
}
.show-30 {
	opacity: 1;
	pointer-events: all;
	transition: opacity 20s;
}
.text-match-color {
	color: rgb(255, 255, 255, 0.45);
}
.white-bg {
	background-color: rgba(255, 255, 255, 0.7);
}
.zig-zag {
	background:
		linear-gradient(135deg, #eceddc 25%, transparent 25%) -50px 0,
		linear-gradient(225deg, #eceddc 25%, transparent 25%) -50px 0,
		linear-gradient(315deg, #eceddc 25%, transparent 25%),
		linear-gradient(45deg, #eceddc 25%, transparent 25%);
	background-size: 100px 100px;
	background-color: #ec173a;
}
.weave {
	background:
		linear-gradient(
			135deg,
			#708090 22px,
			#d9ecff 22px,
			#d9ecff 24px,
			transparent 24px,
			transparent 67px,
			#d9ecff 67px,
			#d9ecff 69px,
			transparent 69px
		),
		linear-gradient(
				225deg,
				#708090 22px,
				#d9ecff 22px,
				#d9ecff 24px,
				transparent 24px,
				transparent 67px,
				#d9ecff 67px,
				#d9ecff 69px,
				transparent 69px
			)
			0 64px;
	background-color: #708090;
	background-size: 64px 128px;
}
.marrakesh {
	background-color: rgba(255, 255, 255, 0.1);
	background-image: radial-gradient(midnightblue 9px, transparent 10px),
		repeating-radial-gradient(
			midnightblue 0,
			midnightblue 4px,
			transparent 5px,
			transparent 20px,
			midnightblue 21px,
			midnightblue 25px,
			transparent 26px,
			transparent 50px
		);
	background-size:
		30px 30px,
		90px 90px;
	background-position: 0 0;
}

.limit-dropdown {
	max-height: calc(100vh - 108px);
	overflow-y: auto;
}

.video-meeting-reminder {
	border-radius: 8px;
	height: 48px;
	background-color: rgba(0 0 0 / 10%);
	transition: background-color 0.25s;
	cursor: pointer;
	padding: 0.5rem;
	margin-right: -12px;
	margin-left: 12px;
}
.video-meeting-reminder:hover {
	background-color: rgba(0 0 0 / 25%);
}
</style>
