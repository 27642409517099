<template>
	<div class="box" style="height: 360px">
		<bulletin v-if="bulletin" :bulletin="bulletin" :user="user" noEdit stretch />
		<h1 v-if="!bulletin && !loading" style="padding-top: 150px" class="text-center text-muted">
			{{ $t("data_description.no_bulletins") }}
		</h1>
	</div>
</template>

<style scoped>
</style>

<script>
import BulletinService from "@/services/BulletinService";
import Bulletin from "@/components/Bulletin";

export default {
	name: "DashboardBulletin",

	props: ["user"],

	components: { Bulletin },

	data() {
		return {
			loading: true,
			bulletin: null,
		};
	},
	created() {
		this.loadData();
	},
	watch: {},
	methods: {
		loadData() {
			this.loading = true;
			let ctx = {
				perPage: 1,
				currentPage: 0,
			};
			BulletinService.listBulletins(ctx)
				.then((resp) => {
					if (resp.data.bulletins && resp.data.bulletins.length > 0) {
						this.bulletin = resp.data.bulletins[0];
					}
					this.loading = false;
				})
				.catch((err) => {
					notie.error(this.$i18n.t("notie.get_bulletin_fail"), err);
					this.loading = false;
				});
		},
	},
};
</script>
