//Driver for flag queue scoring

import axios from "axios";

export default {
	icon: "flag",
	name: "Flags",
	showFlaggedForRel: true,

	showScores() {
		return false;
	},
	sidebarType() {
		return "flag_queue";
	},
	canBeEscalated: true,

	getNext(ctx) {
		let sid = ctx.section_id;
		let iid = ctx.item_id;
		let aid = ctx.alert_id;
		var p = axios.get(`/v1/flags/${sid}/${iid}/${aid}`);
		p.then(function (resp) {
			console.log("FlagQueueSD GetNext:" + resp.data.response.ref_id);
		});
		return p;
	},

	advance(response) {
		let rid = response.id;
		return axios.post(`/v1/flags/advance/${rid}`);
	},

	canAdvance(flags, ctx) {
		let aid = ctx.alert_id;
		let anyUnreviewed = false;
		_.each(flags, flag => {
			if ((flag.alert_id == aid || aid == "all") && !(flag.reviewed == true || flag.reviewed == false)) {
				anyUnreviewed = true;
			}
		});

		return !anyUnreviewed;
	}
};
