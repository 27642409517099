<template>
	<page :title="$t('Dashboard.title')">
		<div class="padding">
			<div class="row">
				<!-- Include bulletin -->
				<div v-if="user.role.page_bulletin_board" class="col-12 col-sm-6">
					<dashboard-bulletin :user="user" />
				</div>
				<div v-if="user.role.page_bulletin_board" class="col-12 col-sm-6">
					<dashboard-qc-results :user="user" />
				</div>
				<!-- Exclude bulletin -->
				<div v-if="!user.role.page_bulletin_board" class="col-12">
					<dashboard-qc-results :user="user" />
				</div>

				<div class="col-12">
					<dashboard-personal-stats :user="user" />
				</div>

				<!-- Include mail -->
				<div v-if="user.role.page_mail" class="col-12 col-sm-4">
					<dashboard-recent-mail :user="user" />
				</div>
				<div v-if="user.role.page_mail" class="col-12 col-sm-4">
					<dashboard-assignments />
				</div>
				<div v-if="user.role.page_mail" class="col-12 col-sm-4">
					<dashboard-activity />
				</div>
				<!-- Exclude mail -->
				<div v-if="!user.role.page_mail" class="col-12 col-sm-6">
					<dashboard-assignments />
				</div>
				<div v-if="!user.role.page_mail" class="col-12 col-sm-6">
					<dashboard-activity />
				</div>
			</div>
		</div>
	</page>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content-main {
	background-color: #f3f6f9;
}
</style>

<script>
import _ from "lodash";

import DashboardBulletin from "@/components/dashboard/DashboardBulletin";
import DashboardQcResults from "@/components/dashboard/DashboardQCResults";
import DashboardPersonalStats from "@/components/dashboard/DashboardPersonalStats";
import DashboardRecentMail from "@/components/dashboard/DashboardRecentMail";
import DashboardActivity from "@/components/dashboard/DashboardActivity";
import DashboardAssignments from "@/components/dashboard/DashboardAssignments";

export default {
	name: "Dashboard",
	props: ["user"],
	data() {
		return {
			chartData: null,
			doughnutData: null,
			barData: null,
		};
	},
	components: {
		DashboardBulletin,
		DashboardQcResults,
		DashboardPersonalStats,
		DashboardRecentMail,
		DashboardActivity,
		DashboardAssignments,
	},
	created() {},
	mounted() {},
	methods: {},
};
</script>


