<template>
	<div class="box d-flex flex-column" style="min-height: 360px" id="dashboard-qc-results-box">
		<div v-if="qcType == 0" class="flex d-flex flex-row align-items-center justify-content-center p-5">
			<h1 class="text-muted text-center">{{ $t("Dashboard.no_qc_access") }}</h1>
		</div>
		<div v-if="qcType != 0" class="b-b nav-active-bg">
			<ul class="nav nav-tabs">
				<li v-if="user.role.page_dashboard_val" class="nav-item">
					<a class="nav-link" @click="setQCType(3)" :class="{ active: qcType == 3, show: qcType == 3 }">
						<div>
							<span class="d-none d-xl-block">{{ $t("Dashboard.validity") }}</span>
							<span class="d-xl-none">Val</span>
						</div>
					</a>
				</li>
				<li v-if="user.role.page_dashboard_practice" class="nav-item">
					<a class="nav-link" @click="setQCType(1)" :class="{ active: qcType == 1, show: qcType == 1 }">
						<div>
							<span class="d-none d-xl-block">{{ $t("Dashboard.practice") }}</span>
							<span class="d-xl-none">{{ $t("Dashboard.tra") }}</span>
						</div>
					</a>
				</li>
				<li v-if="user.role.page_dashboard_qual" class="nav-item">
					<a class="nav-link" @click="setQCType(2)" :class="{ active: qcType == 2, show: qcType == 2 }">
						<div>
							<span class="d-none d-xl-block">{{ $t("Dashboard.qualification") }}</span>
							<span class="d-xl-none">{{ $t("Dashboard.qual") }}</span>
						</div>
					</a>
				</li>
				<li v-if="user.role.page_dashboard_cal" class="nav-item">
					<a class="nav-link" @click="setQCType(4)" :class="{ active: qcType == 4, show: qcType == 4 }">
						<div>
							<span class="d-none d-xl-block">{{ $t("Dashboard.calibration") }}</span>
							<span class="d-xl-none">{{ $t("Dashboard.cal") }}</span>
						</div>
					</a>
				</li>
			</ul>
		</div>
		<div v-if="qcType != 0" class="tab-content d-flex flex-column px-3 pb-3 pt-1">
			<div class="no-grow pb-1 b-b">
				<a class="no-select btn-subtle-lg">
					<span id="section-item-picker">
						<i class="fa fa-cog text-sm"></i>
					</span>
					<span class="text-muted" @click="clickPicker" style="margin-left: -0.5rem; padding-left: 0.5rem">
						{{ selectedSection && selectedSection.name }} /
						{{ selectedItem && selectedItem.name }}
					</span>
					<span
						v-if="selectedQCSet"
						class="text-muted float-right"
						style="margin-left: -0.5rem; padding-left: 0.5rem"
					>
						<a
							:href="`#/qc_set_report/${selectedQCSet.id}`"
							v-if="selectedQCSet.id != true && !(qcType == 2 && !qcAgreementComplete)"
							>{{ $t("Dashboard.view_responses") }}</a
						>
						<a
							:href="`#/my_qc_portfolio?qc=${qcType}&sect=${selectedSection.id}&item=${selectedItem.id}&sq=true&ss=true&si=true`"
							v-if="
								selectedSection &&
								selectedItem &&
								selectedQCSet.id == true &&
								this.user.role.qc_portfolio
							"
							>{{ $t("Dashboard.view_details") }}</a
						>
					</span>
				</a>
				<b-popover target="section-item-picker" triggers="click" placement="bottomright">
					<template slot="title">{{ $t("Dashboard.section_item") }}</template>
					<div class="w">
						<div class="form-group">
							<label>{{ $t("Dashboard.section") }}</label>
							<config-select useInactive :options="sections" v-model="selectedSection" />
						</div>
						<div class="form-group">
							<label>{{ $t("Dashboard.item") }}</label>
							<config-select :options="items" v-model="selectedItem" />
						</div>
						<div class="d-flex flex-row justify-content-end" style="margin-top: -8px">
							<button class="btn btn-primary ml-auto" @click="clickPicker">
								{{ $t("buttons.done") }}
							</button>
						</div>
					</div>
				</b-popover>
			</div>
			<div class="flex d-flex flex-row">
				<div class="pt-2 d-flex flex-column" style="width: 33%; z-index: 2">
					<div class="no-grow mb-2">
						<config-select :options="qcSets" v-model="selectedQCSet" nullOption></config-select>
					</div>
					<template v-if="selectedQCSet && selectedQCSet.id == true && qualOverallStat">
						<div class="text-center my-3">
							<h1 v-if="qualOverallStat.state == 2" class="text-success">
								{{ $t("Dashboard.qualified") }}
							</h1>
							<h1 v-else class="text-warning">
								{{ $t("Dashboard.pending") }}
							</h1>
						</div>
						<div class="text-center my-2 row">
							<div class="col-6">
								<h1>
									{{ getNumSetsCompleted(qualSetStats) }}
								</h1>
								<h6 class="text-muted">
									{{ $t("Dashboard.sets_completed") }}
								</h6>
							</div>
							<div class="col-6">
								<h1>
									{{ getNumSetsPassed(qualSetStats) }}
								</h1>
								<h6 class="text-muted">
									{{ $t("Dashboard.sets_passed") }}
								</h6>
							</div>
						</div>
					</template>
					<template v-else>
						<div class="text-center my-2" :key="`${selectedQCSet && selectedQCSet.id}_${loading}`">
							<h1
								v-if="qcAgreementExplanations && qcAgreementExplanations.length > 0"
								:key="selectedQCSet.id + loading"
								:class="{ 'text-very-muted': !qcAgreementAny }"
								v-tippy="{
									theme: 'popover',
									html: `#pf-${selectedQCSet.id}`,
									placement: 'right',
								}"
							>
								{{ fs.fixed1d(qcAgreementPercent) }}%

								<div :id="`pf-${selectedQCSet.id}`" class="d-none p-0">
									<table class="table table-hover mb-0">
										<thead>
											<tr>
												<th colspan="2">
													{{ $t("QCReport.pass_fail_determination") }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(expRow, i) in qcAgreementExplanations"
												:key="`${selectedQCSet.id}_${i}`"
											>
												<td>{{ expRow.label }}</td>
												<td
													:class="{
														'text-danger': expRow.color == 1,
														'text-warning': expRow.color == 2,
														'text-success': expRow.color == 3,
													}"
												>
													{{ expRow.stat }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</h1>
							<h1
								v-if="!(qcAgreementExplanations && qcAgreementExplanations.length > 0)"
								:class="{ 'text-very-muted': !qcAgreementAny }"
							>
								{{ fs.fixed1d(qcAgreementPercent) }}%
							</h1>
							<h6 class="text-muted">
								{{ $t("Dashboard.agreement") }}
								<i
									v-if="qcAgreementAny && !qcAgreementComplete && qcType != 3"
									class="far fa-xs fa-exclamation-triangle exclamation-fix"
									v-tippy
									:title="$t('tooltip.not_complete')"
								></i>
							</h6>
						</div>
						<div class="text-center my-2">
							<h1 :class="{ 'text-very-muted': !qcAgreementAny }">
								<agreement-row :exact="qcExact" :adj="qcAdj" :nonadj="qcNonadj" placement="right" />
							</h1>
							<h6 class="text-muted">
								{{ $t("Dashboard.score_points") }}
								<i
									v-if="qcAgreementAny && !qcAgreementComplete && qcType != 3"
									class="far fa-xs fa-exclamation-triangle exclamation-fix"
									v-tippy
									:title="$t('tooltip.not_complete')"
								></i>
							</h6>
						</div>
					</template>
				</div>
				<div
					class="d-flex flex-row scroll-y"
					style="margin-left: -33%; width: 100%; max-height: 265px; z-index: 1"
				>
					<div style="width: calc(33% + 30px)"></div>
					<div style="width: calc(67% - 30px)">
						<template v-if="selectedQCSet && selectedQCSet.id == true">
							<table class="table">
								<tr>
									<th class="v-mid">{{ $t("fields.requirement") }}</th>
									<th colspan="2" class="text-center v-mid">{{ $t("fields.result") }}</th>
								</tr>
								<tbody>
									<template v-for="(comp, i) in getRequirementComps()">
										<tr :key="i">
											<td class="v-mid" style="height: 58px">
												<h6 class="mb-0">{{ getRequirementText(comp.req) }}</h6>
											</td>
											<template v-if="comp.stat.drop_set_id">
												<td colspan="2" class="text-center v-mid">
													<h6 class="mb-0">{{ getSetName(comp.stat.drop_set_id) }}</h6>
												</td>
											</template>
											<template v-else>
												<td class="text-center v-mid">
													<h3 class="mb-0">
														{{ getRequirementValue(comp.req, comp.stat.value) }}
													</h3>
												</td>
												<td class="v-mid">
													<i
														v-if="comp.stat.passed"
														class="fas fa-2x fa-check text-success"
													></i>
													<i v-else class="fas fa-2x fa-times text-danger"></i>
												</td>
											</template>
										</tr>
									</template>
								</tbody>
							</table>
							<h4 v-if="getRequirementComps().length == 0" class="text-center text-very-muted mt-5">
								{{ $t("data_description.no_responses") }}
							</h4>
						</template>
						<template v-else>
							<table class="table">
								<tr>
									<th>{{ $t("fields.response") }}</th>
									<th>{{ $t("fields.scored") }}</th>
								</tr>
								<tbody>
									<template v-for="(row, i) in qcResponses">
										<tr v-if="row.user_score" :key="row.response.id">
											<td>{{ row.response.ref_id }}</td>
											<td>
												<inline-score
													:score="row.user_score"
													:compareScore="row.response.true_score"
													tooltip
													:rubric="selectedItem && selectedItem.rubric"
												/>
											</td>
										</tr>
										<tr v-if="!row.user_score && qcType != 3" :key="row.response.id">
											<td class="text-very-muted">{{ $t("fields.response") }} {{ i + 1 }}</td>
											<td class="text-very-muted">{{ $t("fields.unscored") }}</td>
										</tr>
									</template>
								</tbody>
							</table>
							<h4 v-if="!qcResponses || qcResponses.length == 0" class="text-center text-very-muted mt-5">
								{{ $t("data_description.no_responses") }}
							</h4>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
a.btn-subtle-lg i {
	opacity: 0.35;
	transition: opacity 0.25s;
}
a.btn-subtle-lg:hover i {
	opacity: 1;
}
.buttons-position {
	padding: 0.7rem 1rem;
}
th {
	border-top: 0px !important;
}
.exclamation-fix {
	font-size: initial;
	vertical-align: initial;
}
::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>

<script>
import DashboardService from "@/services/DashboardService";
import ConfigService from "@/services/ConfigService";
import QCConfigService from "@/services/QCConfigService";
import QCService from "@/services/QCService";
import store from "@/services/Store";
import notie from "@/services/NotieService";
import fs from "@/services/FormatService";
import BB from "bluebird";

import InlineScore from "@/components/InlineScore";
import ConfigSelect from "@/components/ConfigSelect";
import AgreementRow from "@/components/AgreementRow";

export default {
	name: "DashboardQCResults",

	components: { InlineScore, ConfigSelect, AgreementRow },

	props: ["user"],

	data() {
		let qcType = store.getDefault(this, "dash.userqc.qcType", 3);

		let allowedQcTypes = [];
		if (this.user.role.page_dashboard_val) allowedQcTypes.push(3);
		if (this.user.role.page_dashboard_practice) allowedQcTypes.push(1);
		if (this.user.role.page_dashboard_qual) allowedQcTypes.push(2);
		if (this.user.role.page_dashboard_cal) allowedQcTypes.push(4);

		if (allowedQcTypes.length == 0) {
			qcType = 0;
		} else if (!allowedQcTypes.includes(qcType)) {
			qcType = allowedQcTypes[0];
		}
		store.set(this, "dash.userqc.qcType", qcType);

		return {
			fs: fs,
			qcType: qcType,
			busy: false,

			qcResponses: null,
			qcSets: [],
			selectedQCSet: null,

			qcAgreementAny: false,
			qcAgreementComplete: false,
			qcAgreementPercent: 0,
			qcAgreementExplanations: null,
			qcExact: 0,
			qcAdj: 0,
			qcNonadj: 0,
			qualOverallStat: null,
			qualSetStats: null,
			qualConfig: null,
			qualRubric: null,

			sections: null,
			selectedSection: null,

			items: null,
			selectedItem: null,

			loading: false,
		};
	},
	created() {
		this.loadConfig();
	},
	watch: {
		selectedSection() {
			if (!this.selectedSection) {
				this.selectedItem = null;
				return;
			}

			this.loadItems();

			store.set(this, "dash.userqc.selectedSection", this.selectedSection ? this.selectedSection.id : null);
		},
		selectedItem() {
			if (!this.selectedItem) {
				this.selectedQCSet = null;
				return;
			}
			store.set(this, "dash.userqc.selectedItem", this.selectedItem ? this.selectedItem.id : null);

			this.loadSetLists();
		},
		selectedQCSet() {
			this.loadSet();
			store.set(
				this,
				`dash.userqc.selectedQCSet.${this.qcType}`,
				this.selectedQCSet ? this.selectedQCSet.id : null
			);
		},
	},
	methods: {
		loadConfig() {
			this.busy = true;
			ConfigService.listUserSections()
				.then((resp) => {
					this.sections = resp.data;
					this.selectedSection = store.resolveSelectedSection(
						this,
						this.sections,
						"dash.userqc.selectedSection"
					);

					if (this.selectedSection) {
						this.items = this.selectedSection.items;
						this.selectedItem = store.resolveSelectedItem(
							this,
							this.selectedSection,
							"dash.userqc.selectedItem"
						);
					} else if (this.sections.length) {
						this.selectedSection = this.sections[0];
					}
					this.busy = false;
				})
				.catch((err) => {
					notie.error("Failed to load config options", err);
					this.busy = false;
				});
		},

		loadItems() {
			if (!this.selectedSection) {
				return;
			}

			ConfigService.getSection(this.selectedSection.id)
				.then((resp) => {
					this.items = resp.data.items;

					if (!this.selectedItem || !_.find(this.items, { id: this.selectedItem.id })) {
						this.selectedItem = this.items[0];
					}
				})
				.catch((err) => {
					notie.error("Failed to load config options", err);
				});
		},

		loadSetLists() {
			if (!(this.selectedSection && this.selectedItem)) {
				return;
			}
			this.resetSetAgreement();
			QCConfigService.listQCSets(this.selectedSection.id, this.selectedItem.id, this.qcType)
				.then((resp) => {
					this.qcSets = resp.data;

					if (this.qcType == 2) {
						this.qcSets.unshift({
							name: "Overall",
							meta: true,
							id: true,
						});
					}

					this.selectedQCSet = store.resolveFromList(
						this,
						this.qcSets,
						"id",
						0,
						`dash.userqc.selectedQCSet.${this.qcType}`
					);

					console.log("Selected set", this.selectedQCSet);
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					notie.error("Failed to load QC sets", err);
					this.loading = false;
				});
		},

		loadSet() {
			if (!this.selectedQCSet) {
				this.qcResponses = [];
				return;
			}

			this.loading = true;

			if (this.qcType == 2 && this.selectedQCSet.id == true) {
				BB.props({
					stats: QCService.getMyQualStatus(this.selectedSection.id, this.selectedItem.id),
					item: ConfigService.getItem(this.selectedItem.id),
				}).then((resps) => {
					console.log("QUAL STATUS RESP", resps);
					this.qualOverallStat = resps.stats.data.overall_stat;
					this.qualSetStats = resps.stats.data.set_stats;

					this.qualConfig = resps.item.data.qualification_config;
					this.qualRubric = resps.item.data.rubric;
				});
			} else {
				QCService.getUserQcSetReport(this.selectedQCSet.id, this.user.id)
					.then((resp) => {
						// debugger;

						this.qcResponses = _.sortBy(resp.data.responses, (r) => {
							return r.response.sequence;
						});
						_.each(this.qcResponses, (qcr) => {
							if (qcr.user_score) {
								qcr.user_score.score_type = this.selectedQCSet.qc_type + 10;
							}
						});

						this.qcAgreementPercent = resp.data.stat.percent;
						this.qcAgreementExplanations = resp.data.stat.explanations;
						this.calculateSetAgreement();
						this.loading = false;
					})
					.catch((err) => {
						console.log(err);
						notie.error("Failed to load QC set", err);
						this.loading = false;
					});
			}
		},

		resetSetAgreement() {
			this.qcAgreementAny = false;
			this.qcAgreementComplete = true;
			this.qcAgreementPercent = NaN;
			this.qcExact = 0;
			this.qcAdj = 0;
			this.qcNonadj = 0;
		},

		calculateSetAgreement() {
			this.qcAgreementAny = false;
			this.qcAgreementComplete = true;
			this.qcExact = 0;
			this.qcAdj = 0;
			this.qcNonadj = 0;

			_.each(this.qcResponses, (row) => {
				if (row.user_score) {
					this.qcAgreementAny = true;
					if (row.response.true_score) {
						_.each(row.user_score.trait_scores, (us) => {
							let ts = _.find(row.response.true_score.trait_scores, {
								trait_id: us.trait_id,
							});
							let diff = Math.abs(us.score - ts.score);
							if (diff == 0) {
								this.qcExact++;
							} else if (diff <= 1) {
								this.qcAdj++;
							} else {
								this.qcNonadj++;
							}
						});
					}
				} else {
					this.qcAgreementComplete = false;
				}
			});

			if (!this.qcAgreementComplete && this.qcType == 2) {
				this.qcExact = 0;
				this.qcAdj = 0;
				this.qcNonadj = 0;
				this.qcAgreementPercent = NaN;
			}
		},

		getTraitAdjacency(rubric, traitID) {
			let trait = _.find(rubric.traits, { id: traitID });
			if (!trait) {
				console.log("Couldn't find trait", rubric, traitID);
				return 1;
			}
		},

		setQCType(type) {
			this.qcType = type;
			store.set(this, "dash.userqc.qcType", this.qcType);

			this.loadSetLists();
		},

		getNumSetsCompleted(sets) {
			let completed = 0;
			for (let set of sets) {
				if (set.state == 2 || set.state == 3) {
					completed++;
				}
			}
			return completed;
		},

		getNumSetsPassed(sets) {
			let passed = 0;
			for (let set of sets) {
				if (set.state == 2) {
					passed++;
				}
			}
			return passed;
		},

		getRequirementComps() {
			let comps = [];

			if (
				!(
					this.qualConfig &&
					this.qualConfig.requirements &&
					this.qualConfig.requirements.length > 0 &&
					this.qualOverallStat &&
					this.qualOverallStat.requirements
				)
			) {
				return comps;
			}

			for (let req of this.qualConfig.requirements) {
				for (let reqStat of this.qualOverallStat.requirements) {
					if (req.id == reqStat.requirement_id) {
						comps.push({
							req: req,
							stat: reqStat,
						});
					}
				}
			}
			return comps;
		},

		getRequirementText(req) {
			return QCService.getRequirementText(this.$i18n, this.qualRubric, req);
		},

		getRequirementValue(req, value) {
			return QCService.getRequirementValue(req, value);
		},

		getSetName(setID) {
			for (let set of this.qcSets) {
				if (set.id == setID) {
					return set.name;
				}
			}

			return "(unknown)";
		},

		clickPicker() {
			let picker = document.getElementById("section-item-picker");
			picker.click();
		},
	},
};
</script>
