// Component to render a single score. Shows a list of the traits and their assigned values // Requires a score and
rubric to be passed in.

<template>
	<div class="box mb-0" :class="{ rubric: !fullWidth && !compareScore }" style="max-width: 430px">
		<div v-if="isInvalidatedScore" class="p-2">
			<strong class="pl-2">{{ "Invalidated Score" }}</strong>
			<!-- <button>{{ "view" }}</button> -->
			<a class="float-right pr-2" @click="showInvalidatedScore = !showInvalidatedScore">
				<i v-if="!showInvalidatedScore" class="fa fa-caret-down fa-lg" />
				<i v-else class="fa fa-caret-left fa-lg" />
			</a>
		</div>
		<b-collapse :id="`score-${score.id}`" :visible="!isInvalidatedScore || showInvalidatedScore">
			<table class="table mb-0">
				<tbody>
					<tr>
						<td :colspan="compareScore ? 3 : 2">
							<span class="d-flex flex-row align-items-center w-100">
								<i v-if="scoreTypeIconClass" class="fas mr-1" :class="scoreTypeIconClass"></i>
								<i v-else class="material-icons mr-1" style="font-size: 20px; line-height: 22px">{{
									scoreTypeIcon
								}}</i>
								<strong class="mr-1 nowrap">{{ scoreTypeStr }}</strong>
								<span
									v-if="hasUser"
									class="ml-auto wrap-text"
									v-tippy
									:title="mut_score.user.first_name + ' ' + mut_score.user.last_name"
									>{{ mut_score.user.scorer_id }}</span
								>
							</span>
						</td>
					</tr>
					<tr v-if="!hideTime">
						<td :colspan="compareScore ? 3 : 2">
							<i class="fal fa-clock"></i>
							{{ format(mut_score.created_at) }}
						</td>
					</tr>
					<tr v-if="!loading && pastScoresSameType && pastScoresSameType.length > 0">
						<td :colspan="compareScore ? 3 : 2" class="p-custom p-1">
							<button class="btn" v-tippy title="View past score history" @click="showScoreHistory">
								<i class="fas fa-clock mr-2" />{{
									pastScoresSameType.length == 1
										? "1 Past Score"
										: `${pastScoresSameType.length} Past Scores`
								}}
							</button>
						</td>
					</tr>
					<template v-if="rubric.checklist && rubric.checklist_items.length > 0">
						<tr>
							<td :colspan="compareScore ? 3 : 2" class="pt-0">
								<div class="mt-2 _700">
									{{ $t("fields.checklist") }}
								</div>
								<template v-for="(ci, i) in rubric.checklist_items">
									<div
										:key="`c-${i}`"
										class="mt-1"
										:class="{
											'text-extra-muted': !(
												score.checklist &&
												score.checklist[ci.id] &&
												score.checklist[ci.id].checked
											),
										}"
									>
										<i
											v-if="
												score.checklist &&
												score.checklist[ci.id] &&
												score.checklist[ci.id].checked
											"
											class="fas fa-check fa-fw text-success"
										/>
										<i v-else class="fas fa-times fa-fw text-muted" />
										{{ ci.name }}
									</div>
									<span :key="`n-${i}`" v-if="!getAnno(ci.id)" class="text-extra-muted"
										>No annotation specified</span
									>
									<div
										:key="`a-${i}`"
										v-if="getAnno(ci.id)"
										class="d-flex flex-row align-items-center"
									>
										<div
											class="annotation-number"
											:class="{ [getHighlightColorClass(getAnno(ci.id).color)]: true }"
										>
											{{ getAnno(ci.id).sequence }}
										</div>
										<div class="annotation-preview flex" style="margin-right: -10px">
											{{ getAnnotationText(getAnno(ci.id)) }}
										</div>
									</div>
								</template>
							</td>
						</tr>
					</template>
					<template v-for="mt in displayableMetaTraitScores">
						<tr :key="mt.meta_trait_id" v-if="isLongMetaTrait(mt)">
							<td :colspan="compareScore ? 3 : 2">
								<div class="text-muted wrap-text">{{ getMetaTraitName(mt) }}:</div>
								<div class="p-1 wrap-text">{{ mt.value }}</div>
							</td>
						</tr>
						<tr :key="mt.meta_trait_id" v-if="!isLongMetaTrait(mt)">
							<td style="padding-right: 0" class="text-overflow">
								<span class="text-muted wrap-text">{{ getMetaTraitName(mt) }}:</span>
							</td>
							<td :colspan="compareScore ? 2 : 1">
								<span class="p-1 position-relative wrap-text">{{ mt.value }}</span>
							</td>
						</tr>
					</template>
					<tr v-if="compareScore">
						<td class="text-xs text-muted">{{ numAgreed }}/{{ numTraits }}</td>
						<td class="text-xs text-muted">{{ $t("fields.scored") }}</td>
						<td class="text-xs text-muted">{{ $t("fields.actual") }}</td>
					</tr>
					<template v-if="!loading" v-for="ts in sortedTraitScores">
						<tr :key="`score-${ts.trait_id}`">
							<td class="text-overflow" style="padding-right: 0">
								<strong>{{ getTraitName(ts) }}</strong>
							</td>
							<td class="text-overflow">
								<span
									class="p-1 position-relative"
									:class="{
										disagreed: ts.disagreed,
										nonadj: ts.nonadj,
										adjacent: ts.adj,
										'text-extra-muted': getScore(ts) == '?',
									}"
								>
									{{ getScore(ts) }}
									<span v-if="ts.evidence" class="text-muted">({{ ts.evidence }})</span>
									<i v-if="ts.disagreed < -1" class="far fa-chevron-double-down text-muted"></i>
									<i
										v-if="ts.disagreed >= -1 && ts.disagreed < 0"
										class="far fa-chevron-down text-muted"
									></i>
									<i
										v-if="ts.disagreed <= 1 && ts.disagreed > 0"
										class="far fa-chevron-up text-muted"
									></i>
									<i v-if="ts.disagreed > 1" class="far fa-chevron-double-up text-muted"></i>
								</span>
							</td>
							<td v-if="compareScore">
								{{ getCompareScore(ts) }}
								<span class="text-muted" v-if="getCompareEvidence(ts)"
									>({{ getCompareEvidence(ts) }})</span
								>
							</td>
						</tr>
						<tr v-if="ts.evidence2 && !compareScore" :key="`evidence-${i}`">
							<td class="text-muted">Evidence:</td>
							<td colspan="2" class="wrap-text">{{ ts.evidence2 }}</td>
						</tr>
					</template>
					<tr v-if="!loading && score.annotations && score.annotations.length > 0">
						<td v-if="canShowAnnotations" :colspan="compareScore ? 3 : 2" class="p-custom p-1">
							<button
								class="btn float-right"
								:class="{
									'btn-success': annotationMatch(),
								}"
								v-tippy
								:title="`${this.annotationMatch() ? 'Hide' : 'Show'} this user's annotations`"
								@click="toggleAnnotations"
							>
								{{
									score.annotations.length == 1
										? "1 Annotation"
										: `${score.annotations.length} Annotations`
								}}
							</button>
						</td>
						<td v-if="!canShowAnnotations" :colspan="compareScore ? 3 : 2">
							<div class="float-right">
								{{
									score.annotations.length == 1
										? "1 Annotation"
										: `${score.annotations.length} Annotations`
								}}
							</div>
						</td>
					</tr>
					<tr v-if="loading">
						<td>
							<loading v-if="!loadingError" type="header" />
							<div v-if="loadingError">Error</div>
						</td>
					</tr>
					<tr v-if="showMessageAndReset">
						<td colspan="3">
							<div v-if="can_message" class="mx-1 w60 float-right">
								<button
									@click="messageUser()"
									class="btn btn-sm btn-icon btn-rounded blue text-white m-0"
									v-tippy
									title="Send Mail to User"
								>
									<i class="far fa-envelope"></i>
								</button>
							</div>
							<div v-if="can_reset" class="mx-1 w60 float-right">
								<button
									v-if="!responseProtected"
									@click="resetScore()"
									class="btn btn-sm btn-icon btn-rounded red text-white m-0"
									v-tippy
									title="Reset Score"
								>
									<i class="far fa-undo"></i>
								</button>
								<button
									v-else
									class="btn btn-sm btn-icon btn-rounded btn-secondary m-0 disabled"
									v-tippy
									title="Protected<br/>Cannot reset score"
								>
									<i class="far fa-lock"></i>
								</button>
							</div>
						</td>

						<!-- <td>{{mut_score.user.scorer_id}}</td> -->
					</tr>
					<tr v-if="!loading && rescore">
						<td :colspan="compareScore ? 3 : 2" class="p-custom p-1">
							<div v-if="responseProtected" v-tippy :title="$t('Auditing.protected_tooltip')">
								<button disabled class="btn btn-secondary float-right">
									<i class="fas fa-lock mr-2" />{{ $t("Score.rescore_expired") }}
								</button>
							</div>
							<button v-else class="btn btn-danger float-right" @click="goToRescore">
								{{ $t("Score.rescore") }}
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</b-collapse>

		<b-modal
			:visible="showPastScoreHistory"
			@hidden="showPastScoreHistory = false"
			v-if="pastScores && pastScores.length > 0"
			size="lg"
		>
			<template slot="modal-title">{{ $t("Viewer.score_history") }}</template>
			<div class="py-4">
				<table
					v-if="pastScoreUsers"
					class="table mb-0"
					style="
						margin-left: -1rem;
						margin-right: -1rem;
						width: calc(100% + 2rem);
						max-width: calc(100% + 2rem);
					"
				>
					<thead>
						<tr>
							<th style="width: 50px"></th>
							<th>{{ $t("fields.applied_at") }}</th>
							<th>{{ $t("fields.scorer") }}</th>
							<th>{{ $t("fields.score_time") }}</th>
							<th>{{ $t("fields.score_type") }}</th>
							<th>{{ $t("fields.score") }}</th>
							<th style="width: 50px"></th>
						</tr>
					</thead>
					<tbody>
						<template v-for="ps in pastScores">
							<tr
								:key="`row-${ps.id}`"
								:class="{ success: ps.current }"
								:style="{ opacity: ps.score_type == score_type ? 1 : 0.5 }"
							>
								<td></td>
								<td class="v-mid">{{ fs.medDate(ps.created_at) }}</td>
								<td class="v-mid">{{ pastScoreUsers[ps.user_id] }}</td>
								<td class="v-mid">{{ ps.think_time }} {{ $t("fields.seconds") }}</td>
								<td class="v-mid">
									<span class="d-flex flex-row align-items-center">
										<i
											v-if="fs.scoreTypeIconClass(ps.score_type)"
											class="fas mr-1"
											:class="fs.scoreTypeIconClass(ps.score_type)"
										></i>
										<i
											v-if="!fs.scoreTypeIconClass(ps.score_type)"
											class="material-icons mr-1"
											style="font-size: 20px; line-height: 22px"
											>{{ fs.scoreTypeIcon(ps.score_type) }}</i
										>
										<strong>{{ fs.scoreTypeName(ps.score_type, $i18n) }}</strong>
									</span>
								</td>
								<td class="v-mid"><inline-score-light :score="ps" /></td>
								<td>
									<a
										v-if="displayableMetaTraitScores && displayableMetaTraitScores.length > 0"
										class="btn-subtle"
										@click="togglePastScoreDetails(ps)"
									>
										<i
											class="fa fa-angle-left anim-rotate"
											:class="{ 'rotate-90': ps.showDetails }"
										></i>
									</a>
								</td>
							</tr>
							<tr v-if="ps.showDetails" :key="`details-${ps.id}`">
								<td></td>
								<td colspan="4" class="p-0">
									<table class="borderless">
										<template v-for="mt in getDisplayableMetaTraitScores(ps)">
											<tr :key="mt.meta_trait_id" v-if="isLongMetaTrait(mt)">
												<td :colspan="compareScore ? 3 : 2">
													<div class="text-muted">{{ getMetaTraitName(mt) }}:</div>
													<div class="p-1">{{ mt.value }}</div>
												</td>
											</tr>
											<tr :key="mt.meta_trait_id" v-if="!isLongMetaTrait(mt)">
												<td class="text-overflow" style="padding-right: 0">
													<span class="text-muted">{{ getMetaTraitName(mt) }}:</span>
												</td>
												<td :colspan="compareScore ? 2 : 1">
													<span class="p-1 position-relative">{{ mt.value }}</span>
												</td>
											</tr>
										</template>
									</table>
								</td>
								<td></td>
							</tr>
						</template>
					</tbody>
				</table>
				<div v-else class="w-100 text-center">
					<loading type="large" />
				</div>
			</div>
			<template slot="modal-footer">
				<button class="btn primary btn-flat" @click="showPastScoreHistory = false">OK</button>
			</template>
		</b-modal>
	</div>

	<!-- <div class="list-group list-group-alt box rubric">


		<p>Score Type: {{score_type}}</p>

		<a href="#" v-for="ts in mut_score.trait_scores" :key="ts.trait_id" class="list-group-item">
			{{getTraitName(ts)}}
			<span class="float-right">{{getScore(ts)}}</span>
		</a>


	</div>-->
</template>

<script>
import ItemService from "../services/ItemService";
import UserService from "../services/UserService";
import RAS from "../services/ResponseAuditService";
import fs from "../services/FormatService";
import moment from "moment";
import InlineScoreLight from "../components/InlineScoreLight";

import { SCORE_TYPES, isInvalidedScore, CONDITION_CODE_ACTIONS } from "@/services/Constants";

export default {
	name: "Score",
	props: {
		score: {
			default: null,
		},
		compareScore: {
			default: null,
		},
		rubric: {
			default: () => {
				return {};
			},
		},
		itemID: {
			default: null,
		},
		message: {
			default: null,
		},
		type: {
			type: Number,
			default: null,
		},
		reset: {
			default: null,
		},
		resetScores: {
			default: null,
		},
		currentScores: {},
		hideTime: {
			type: Boolean,
			default: false,
		},
		fullWidth: {
			type: Boolean,
			default: false,
		},
		canShowAnnotations: {
			type: Boolean,
			default: false,
		},
		showingAnnotations: {
			default: null,
		},
		rescore: {
			default: null,
		},
		responseProtected: {
			type: Boolean,
			default: false,
		},
	},
	components: { InlineScoreLight },
	data() {
		return {
			fs: fs,
			mut_score: _.cloneDeep(this.score),
			mut_rubric: _.cloneDeep(this.rubric),
			score_type: this.score.score_type,
			can_message: this.message,
			can_reset: this.reset,
			loading: false,
			loadingError: null,

			numAgreed: 0,
			numTraits: 0,

			showPastScoreHistory: false,
			pastScoreUsers: null,
			showInvalidatedScore: true,
		};
	},
	created() {
		if (this.compareScore) {
			this.checkAgreement(this.mut_score, this.compareScore);
		}
		this.showInvalidatedScore = !this.isInvalidatedScore;
	},
	mounted() {
		if (!this.rubric.id && this.itemID) {
			this.loading = true;
			ItemService.getItem(this.itemID)
				.then((resp) => {
					this.rubric = resp.data.rubric;
					this.mut_rubric = _.cloneDeep(this.rubric);
					this.loading = false;
				})
				.catch((err) => {
					this.loadingError = true;
					this.loading = false;
				});
		}
	},
	computed: {
		scoreTypeIcon() {
			return fs.scoreTypeIcon(this.score_type || this.type);
		},
		scoreTypeIconClass() {
			return fs.scoreTypeIconClass(this.score_type || this.type);
		},
		scoreTypeStr() {
			const { INVALIDATED_RESOLUTION, INVALIDATED_ADJUDICATION } = SCORE_TYPES;
			const { score_type, type, $i18n } = this;
			const scoreType = score_type || type;
			let ret = fs.scoreTypeName(scoreType, $i18n);
			switch (scoreType) {
				case INVALIDATED_RESOLUTION.id:
					ret = $i18n.t("fs.resolution");
					break;
				case INVALIDATED_ADJUDICATION.id:
					ret = $i18n.t("fs.adjudication");
					break;
			}
			if (this.hasUser) {
				return `${ret}:`;
			} else {
				return ret;
			}
		},
		scoreTypeStrLines() {
			return this.scoreTypeStr.split(" ");
		},
		hasUser() {
			if (!this.mut_score) {
				return false;
			}
			if (!this.mut_score.user) {
				return false;
			}
			return this.mut_score.user.full_name || this.mut_score.user.scorer_id;
		},
		displayableMetaTraitScores() {
			return _.filter(this.mut_score.meta_trait_scores, (mt) => {
				return mt.value != "" || this.isRequiredMetaTrait(mt);
			});
		},
		pastScores() {
			if (!this.resetScores) {
				return [];
			}

			if (!this.hasUser) {
				return [];
			}

			let pastScores = _.cloneDeep(this.resetScores);
			if (this.currentScores) {
				let currentScores = _.cloneDeep(this.currentScores);
				_.each(currentScores, (cs) => {
					cs.current = true;
				});
				pastScores.push(...currentScores);
			} else {
				this.mut_score.current = true;
				let currentScores = [this.mut_score];
				pastScores.push(...currentScores);
			}

			pastScores = _.sortBy(pastScores, "created_at");

			return pastScores;
		},
		pastScoresSameType() {
			if (!this.resetScores) {
				return [];
			}

			if (!this.hasUser) {
				return [];
			}

			let pastScores = [];
			_.each(this.resetScores, (rs) => {
				if (rs.score_type == this.score_type) {
					pastScores.push(rs);
				}
			});

			return pastScores;
		},

		sortedTraitScores() {
			const { mut_score, mut_rubric } = this;
			if (!(mut_rubric && mut_score)) return mut_score ? mut_score.trait_scores : [];

			let sortedTraits = [];
			for (let trait of mut_rubric.traits) {
				let ts = mut_score.trait_scores.find(({ trait_id }) => trait_id === trait.id);
				if (ts) {
					sortedTraits.push(ts);
				}
			}
			return sortedTraits;
		},

		showMessageAndReset() {
			const { can_reset, can_message, score } = this;
			return (can_reset, can_message) && score.score_type !== SCORE_TYPES.FINAL.id;
		},

		isInvalidatedScore() {
			return isInvalidedScore(this.score.score_type || this.type);
		},
	},
	watch: {
		score() {
			this.mut_score = _.cloneDeep(this.score);
		},
		compareScore() {
			this.checkAgreement(this.mut_score, this.compareScore);
		},
		rubric() {
			this.mut_rubric = _.cloneDeep(this.rubric);
		},
	},
	methods: {
		getTraitName(trait_score) {
			if (this.mut_rubric) {
				var trait = _.find(this.mut_rubric.traits, {
					id: trait_score.trait_id,
				});
				if (trait) {
					return trait.reported_name || trait.name;
					// return Utils.truncateString(trait.reported_name || trait.name, 20);
				}
			}

			return "——";
		},
		getMetaTraitName(meta_trait) {
			if (this.mut_rubric) {
				var trait = _.find(this.mut_rubric.meta_traits, {
					id: meta_trait.meta_trait_id,
				});
				if (trait) {
					return trait.reported_name || trait.name;
					// return Utils.truncateString(trait.reported_name || trait.name, 20);
				}
			}

			return "——";
		},
		isRequiredMetaTrait(meta_trait) {
			if (this.mut_rubric) {
				var trait = _.find(this.mut_rubric.meta_traits, {
					id: meta_trait.meta_trait_id,
				});
				if (trait) {
					return trait.required;
				}
			}

			return false;
		},
		isLongMetaTrait(meta_trait) {
			if (this.mut_rubric) {
				var trait = _.find(this.mut_rubric.meta_traits, {
					id: meta_trait.meta_trait_id,
				});
				if (trait) {
					return trait.meta_trait_type == 2;
				}
			}

			return false;
		},
		checkAgreement(score, actual) {
			this.numAgreed = 0;
			this.numTraits = 0;
			_.each(score.trait_scores, (ts) => {
				let comp =
					actual &&
					_.find(actual.trait_scores, {
						trait_id: ts.trait_id,
					});
				ts.adj = false;
				ts.nonadj = false;
				ts.disagreed = false;
				if (comp && ts.score != comp.score) {
					ts.disagreed = ts.score - comp.score;
					if (Math.abs(ts.disagreed) > 1) {
						ts.nonadj = true;
					}
					if (Math.abs(ts.disagreed) <= 1) {
						ts.adj = true;
					}
				} else {
					this.numAgreed++;
				}
				this.numTraits++;
			});
		},

		getScore(ts) {
			return this.showConditionCode(ts) ? ts.condition : ts.score;
		},

		showConditionCode(ts) {
			if (ts.score < 0) return true;
			if (ts.condition === "") return false;
			const rubricCC = this.getRubricConditionCode(ts);
			if (rubricCC) {
				const { SYMBOL_AND_VALUE, SYMBOL_AND_VALUE_LINKED } = CONDITION_CODE_ACTIONS;
				return [SYMBOL_AND_VALUE.id, SYMBOL_AND_VALUE_LINKED.id].includes(rubricCC.action);
			}
		},

		getRubricConditionCode(trait_score) {
			const trait = this.getRubricTrait(trait_score.trait_id);
			if (trait) {
				return trait.condition_codes.find(({ symbol }) => symbol === trait_score.condition);
			}
		},

		getRubricTrait(trait_id) {
			return this.rubric.traits.find(({ id }) => id === trait_id);
		},

		getCompareScore(ts) {
			const comp = this.getComp(ts);
			if (comp) {
				if (comp.score >= 0) {
					return comp.score;
				} else {
					return comp.condition;
				}
			} else {
				return "——";
			}
		},

		getCompareEvidence(ts) {
			const comp = this.getComp(ts);
			return (comp && comp.evidence) || "";
		},

		getComp(ts) {
			return _.find(this.compareScore.trait_scores, {
				trait_id: ts.trait_id,
			});
		},

		format(date) {
			return moment(date).format(this.$i18n.t("Score.date_format"));
		},
		messageUser() {
			this.$emit("on-message", this.score);
		},
		resetScore() {
			this.$emit("on-reset", this.score);
		},
		toggleAnnotations() {
			if (this.annotationMatch()) {
				RAS.recordAction("Click button", { button: "Score annotation choice", choice: "off" });
				this.$emit("showAnnotations", []);
			} else {
				RAS.recordAction("Click button", {
					button: "Score annotation choice",
					choice: this.score.id,
					scoreType: this.score_type,
				});
				this.$emit("showAnnotations", this.score.annotations);
			}
		},
		annotationMatch() {
			return this.showingAnnotations == this.score.id;
		},

		goToRescore() {
			if (!this.rescore) {
				return;
			}
			this.$router.push(
				`/rescore/${this.rescore.section_id}/${this.rescore.item_id}/${this.rescore.response_id}/${this.rescore.score_id}?return=true`
			);
		},

		getAnno(id) {
			if (!(this.score.checklist && this.score.checklist[id])) return null;
			return _.find(this.score.annotations, { id: this.score.checklist[id].annotation_id });
		},

		getHighlightColorClass(color) {
			return `highlight-${color}`;
		},

		getAnnotationText(anno) {
			console.log("getAnnotationText", anno);
			if (!anno.region) {
				return "";
			}
			let region = JSON.parse(anno.region);
			console.log("region", region);
			if (!(region.length > 0 && region[0].length > 1)) {
				return "";
			}

			return region[0][1];
		},

		showScoreHistory() {
			this.showPastScoreHistory = true;
			if (!this.pastScoreUsers) {
				this.loadPastScoreUsers();
			}
		},

		loadPastScoreUsers() {
			let userIDMap = {};
			_.each(this.pastScores, (ps) => {
				userIDMap[ps.user_id] = true;
			});
			let userIDs = [];
			_.each(userIDMap, (_, id) => {
				userIDs.push(id);
			});

			UserService.getUserNames(userIDs).then((r) => {
				this.pastScoreUsers = r.data;
				console.log("LOADED PAST SCORE USERS", this.pastScoreUsers);
			});
		},

		togglePastScoreDetails(ps) {
			console.log("toggle past score details", ps);
			ps.showDetails = !ps.showDetails;
			this.$forceUpdate();
		},

		getDisplayableMetaTraitScores(ps) {
			return _.filter(ps.meta_trait_scores, (mt) => {
				return mt.value != "" || this.isRequiredMetaTrait(mt);
			});
		},
	},
};
</script>

<style scoped>
.text-overflow {
	overflow: hidden;
	max-width: 150px;
	text-overflow: ellipsis;
}

.list-group-item:hover {
	background-color: rgba(0, 0, 0, 0.05);
}
.disagreed {
	background-color: rgb(255, 0, 0, 0.2);
	border-radius: 0.5rem;
}
.adjacent {
	background-color: rgba(253, 168, 41, 0.25);
}

.annotation-number {
	font-family: sans-serif;
	font-size: 14px;
	height: 20px;
	min-width: 20px;
	border-radius: 50%;
	margin-right: 6px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-weight: 800;
	color: white;
	line-height: 1.65;
}

.annotation-preview {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 165px;
}

.highlight-blue {
	background-color: rgb(41, 182, 246);
}
.highlight-red {
	background-color: rgba(244, 67, 54, 0.75);
}
.highlight-yellow {
	background-color: rgb(248, 240, 100);
}
.highlight-green {
	background-color: rgb(139, 195, 74);
}
.highlight-orange {
	background-color: rgb(246, 154, 77);
}
.highlight-purple {
	background-color: rgb(143, 100, 150);
}
.highlight-pink {
	background-color: rgba(217, 102, 196);
}

.borderless,
.borderless tr,
.borderless td {
	border: none;
}

a.btn-subtle {
	padding: 6px 10px;
	border-radius: 8px;
	background-color: rgb(0 0 0 / 0%);
	transition: background-color 0.25s;
}
a.btn-subtle:hover {
	background-color: rgb(0 0 0 / 10%);
}
</style>
