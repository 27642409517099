<template>
	<div class="video-box">
		<video
			class="mzd-video"
			preload="auto"
			ref="audio"
			controls
			controlsList="nodownload noplaybackrate"
			@timeupdate="timeupdate"
			@seeking="timeupdate"
			@ended="ended"
		>
			<source :src="page.url" />
			Your browser does not support the video tag.
		</video>
		<i class="far fa-volume-up text-very-muted audio-icon"></i>
	</div>
</template>

<script>
import Notie from "@/services/NotieService";

export default {
	name: "AudioMedia",
	props: {
		page: Object,
		chan: {
			type: Object,
			required: false,
		},
		item: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			events: [],
			pastTime: 0,
			canSeek: !this.item || !this.item.require_view_all,
		};
	},

	watch: {},

	created() {
		if (!this.chan) {
			return;
		}
		this.events.push({ key: "set-playback-speed", val: this.setPlaybackSpeed });
		this.chan.$on("set-playback-speed", this.setPlaybackSpeed);
	},

	destroyed() {
		const _this = this;
		_.each(this.events, (e) => {
			if (_this.chan) {
				_this.chan.$off(e.key, e.val);
			}
		});
	},

	methods: {
		setPlaybackSpeed(playbackRate) {
			this.$refs.audio.playbackRate = playbackRate;
		},

		timeupdate() {
			const {
				pastTime,
				canSeek,
				$refs: { audio },
			} = this;
			if (!canSeek && audio.seeking && Math.abs(pastTime - audio.currentTime) > 0.01) {
				this.$refs.audio.currentTime = pastTime;
				Notie.info("Must listen to the complete audio");
			}
			this.pastTime = audio.currentTime;
		},

		ended() {
			this.canSeek = true;
			this.$emit("viewedAll");
		},
	},
};
</script>

<style scoped>
.mzd-video {
	width: 100%;
}
.video-box {
	margin-top: 20px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	/*min-height:1250px;*/
	/*width:1000px;*/
	/*font-size: 17pt;*/

	/*min-height:1000px;*/
	max-width: 800px;
	min-width: 400px;
	font-size: 13pt;

	/*padding:80px;*/
	-webkit-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	-moz-box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	box-shadow: 3px 3px 11px 1px rgba(184, 182, 184, 1);
	background-color: white;
	color: black;
	font-family: "Times New Roman", Times, Georgia, serif;
}
.audio-icon {
	position: absolute;
	font-size: 8rem;
	left: calc(50% - 4rem);
	top: calc(50% - 5.8rem);
}
</style>
